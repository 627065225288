@font-face {
  font-family: LatoPDF;
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/lato-v16-latin-300.ttf') format('truetype');
}
/* lato-300italic - latin */
@font-face {
  font-family: LatoPDF;
  font-style: italic;
  font-weight: 300;
  src: url('./fonts/lato-v16-latin-300italic.ttf') format('truetype');
}
/* lato-regular - latin */
@font-face {
  font-family: LatoPDF;
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Lato-Regular.ttf') format('truetype');
}
/* lato-italic - latin */
@font-face {
  font-family: LatoPDF;
  font-style: italic;
  font-weight: 400;
  src: url('./fonts/lato-v16-latin-italic.ttf') format('truetype');
}
/* lato-700 - latin */
@font-face {
  font-family: LatoPDF;
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/lato-v16-latin-700.ttf') format('truetype');
}
/* lato-700italic - latin */
@font-face {
  font-family: LatoPDF;
  font-style: italic;
  font-weight: 700;
  src: url('./fonts/lato-v16-latin-700italic.ttf') format('truetype');
}
/* Webfont: LatoLatin-Semibold */
@font-face {
  font-family: 'Lato';
  src: url('fonts/LatoLatin-Semibold.eot');
  /* IE9 Compat Modes */
  src: url('fonts/LatoLatin-Semibold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('fonts/LatoLatin-Semibold.woff2') format('woff2'), /* Modern Browsers */ url('fonts/LatoLatin-Semibold.woff') format('woff'), /* Modern Browsers */ url('fonts/LatoLatin-Semibold.ttf') format('truetype');
  font-style: normal;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}
/* lato-900 - latin */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
h1,
h2,
h3,
h4,
h5,
.h3 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  overflow: hidden;
}
@media (max-width: 645px) {
  body {
    width: 100%;
  }
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
textarea,
select,
input,
button {
  outline: none;
}
*,
*:after,
*:before {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}
.center-vertical {
  top: 50%;
  position: relative;
  transform: translate3d(0, -50%, 0);
}
.one-line-text-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.one-line-number-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.thin-28 {
  font-weight: 200;
  font-family: 'Lato', sans-serif;
  font-size: 28px;
}
.regular-28 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 28px;
}
.bold-28 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 28px;
}
.thin-26 {
  font-weight: 200;
  font-family: 'Lato', sans-serif;
  font-size: 26px;
}
.regular-26 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 26px;
}
.bold-26 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 26px;
}
.thin-25 {
  font-weight: 200;
  font-family: 'Lato', sans-serif;
  font-size: 25px;
}
.regular-25 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 25px;
}
.bold-25 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 25px;
}
.thin-24 {
  font-weight: 200;
  font-family: 'Lato', sans-serif;
  font-size: 24px;
}
.regular-24 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 24px;
}
.bold-24 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 24px;
}
.thin-22 {
  font-weight: 200;
  font-family: 'Lato', sans-serif;
  font-size: 22px;
}
.regular-22 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 22px;
}
.bold-22 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 22px;
}
.thin-21 {
  font-weight: 200;
  font-family: 'Lato', sans-serif;
  font-size: 21px;
}
.regular-21 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 21px;
}
.bold-21 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 21px;
}
.thin-20 {
  font-weight: 200;
  font-family: 'Lato', sans-serif;
  font-size: 20px;
}
.regular-20 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 20px;
}
.bold-20 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 20px;
}
.thin-18 {
  font-weight: 200;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
}
.regular-18 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
}
.bold-18 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
}
.thin-17 {
  font-weight: 200;
  font-family: 'Lato', sans-serif;
  font-size: 17px;
}
.regular-17 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 17px;
}
.bold-17 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 17px;
}
.thin-16 {
  font-weight: 200;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}
.regular-16 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}
.bold-16 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}
.thin-15 {
  font-weight: 200;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
}
.regular-15 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
}
.bold-15 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
}
.thin-14 {
  font-weight: 200;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
.regular-14 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
.bold-14 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
.thin-13 {
  font-weight: 200;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
}
.regular-13 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
}
.bold-13 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
}
.thin-12 {
  font-weight: 200;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
}
.regular-12 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
}
.bold-12 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
}
.thin-11 {
  font-weight: 200;
  font-family: 'Lato', sans-serif;
  font-size: 11px;
}
.regular-11 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 11px;
}
.bold-11 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 11px;
}
.thin-10 {
  font-weight: 200;
  font-family: 'Lato', sans-serif;
  font-size: 10px;
}
.regular-10 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 10px;
}
.bold-10 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 10px;
}
.thin-9 {
  font-weight: 200;
  font-family: 'Lato', sans-serif;
  font-size: 9px;
}
.regular-9 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 9px;
}
.bold-9 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 9px;
}
.button-clear {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
}
.button-radius {
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}
.button-transition {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
}
.button-1 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
}
.button-1:hover {
  background-color: #46A3FF;
}
.button-1:active,
.button-1:focus {
  background-color: #1C83EA;
}
.button-1:disabled,
.button-1.disabled {
  background: #909090;
}
.button-1-arrow {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  padding: 13px 60px 13px 42px;
  background-image: url(./img/button/right-arrow-white.png);
  background-repeat: no-repeat;
  background-size: 13px 7px;
  background-position: calc(100% - 38px) 50%;
}
.button-1-arrow:hover {
  background-color: #46A3FF;
}
.button-1-arrow:active,
.button-1-arrow:focus {
  background-color: #1C83EA;
}
.button-1-arrow:disabled,
.button-1-arrow.disabled {
  background: #909090;
}
.button-2 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 25px 13px 25px;
  color: #2291FF;
  background-color: Transparent;
  border: solid 1px #2291FF;
}
.button-2:hover {
  color: #46A3FF;
  border: solid 1px #46A3FF;
}
.button-2:active,
.button-2:focus {
  color: #1C83EA;
  border: solid 1px #1C83EA;
}
.button-3 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  background-color: #26BD27;
  padding: 10px 10px 10px 10px;
  color: #fff;
}
.button-3:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #29CA2A;
}
.button-3:active,
.button-3:focus {
  background-color: #23B024;
}
.button-4 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  background-color: #26BD27;
  padding: 10px 10px 10px 10px;
  color: #fff;
  background-color: #727C8F;
}
.button-4:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #29CA2A;
}
.button-4:active,
.button-4:focus {
  background-color: #23B024;
}
.button-4:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #A3A8B2;
}
.button-4:active,
.button-4:focus {
  background-color: #575E6C;
}
.button-5 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
}
.button-5:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
.button-5:active,
.button-5:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
.button-6 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #fff;
  background-color: #2291FF;
}
.button-6:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #46A3FF;
}
.button-6:active,
.button-6:focus {
  background-color: #1C83EA;
}
.button-7 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #fff;
  background-color: #FF4E4C;
}
.button-7:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #FF4E4C;
}
.button-7:active,
.button-7:focus {
  background-color: #FF4E4C;
}
.button-8 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 25px 13px 25px;
  color: #26BD27;
  background-color: Transparent;
  border: solid 1px #26BD27;
}
.button-8:hover {
  color: #29CA2A;
  border: solid 1px #29CA2A;
}
.button-8:active,
.button-8:focus {
  border: solid 1px #23B024;
}
.button-google {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  padding: 13px 15px 13px 40px;
  background-color: #DD4A40;
  background-image: url(img/button/logo_google.png);
  background-repeat: no-repeat;
  background-size: 14px 14px;
  background-position: 20px 50%;
}
.button-google:hover {
  background-color: #46A3FF;
}
.button-google:active,
.button-google:focus {
  background-color: #1C83EA;
}
.button-google:disabled,
.button-google.disabled {
  background: #909090;
}
.button-google:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #E6574D;
}
.button-google:active,
.button-google:focus {
  background-color: #C63C32;
}
.button-facebook {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  padding: 13px 15px 13px 40px;
  background-color: #4267B2;
  background-image: url(img/button/logo_fb.png);
  background-repeat: no-repeat;
  background-size: 9px 17px;
  background-position: 20px 50%;
}
.button-facebook:hover {
  background-color: #46A3FF;
}
.button-facebook:active,
.button-facebook:focus {
  background-color: #1C83EA;
}
.button-facebook:disabled,
.button-facebook.disabled {
  background: #909090;
}
.button-facebook:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #5077C5;
}
.button-facebook:active,
.button-facebook:focus {
  background-color: #36589D;
}
.button-sort {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
  float: right;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
.button-sort:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
.button-sort:active,
.button-sort:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
.button-sort span {
  background-image: url(img/button/filter_blue.png);
  background-repeat: no-repeat;
  background-position: 0% 50%;
  background-size: 12px 12px;
  padding-left: 20px;
}
.button-back-to-results {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
  padding: 5px 16px 5px 30px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  margin-left: 40px;
  background-image: url(img/button/left-arrow-short-blue.png);
  background-size: 6px 8px;
  background-repeat: no-repeat;
  background-position: 10px 50%;
}
.button-back-to-results:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
.button-back-to-results:active,
.button-back-to-results:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
.button-get-advice {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}
.button-get-advice:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
.button-get-advice:active,
.button-get-advice:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
.button-get-advice span {
  background-image: url(img/button/right-arrow-short-blue.png);
  background-size: 7px 12px;
  background-repeat: no-repeat;
  background-position: 100% 50%;
  padding-right: 25px;
}
.button-square {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  border: 1px solid #D8DCE7;
  background-color: #fff;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  height: 36px;
  width: 36px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 15px 15px;
  box-shadow: 0 1px 0 0 #E9EBF1;
}
.button-square:hover {
  border: 1px solid #C8CCD7;
}
.animated-star {
  z-index: 1000;
  position: fixed;
  height: 37px;
  width: 40px;
  background-image: url(img/button/star_orange_filled.png);
  background-size: 40px 37px;
  background-repeat: no-repeat;
  background-color: transparent;
  transition: all 0.5s linear;
  transform: scale(0.5);
}
.shortlist-pill {
  position: absolute;
  top: 21px;
  right: 30px;
  color: #fff;
  font-size: 12px;
  display: block;
  line-height: 24px;
  font-weight: 600;
  z-index: 50;
  text-align: center;
  width: 25px;
  height: 25px;
}
.shortlist-pill:before {
  content: "";
  background-color: #2291FF;
  width: 25px;
  height: 25px;
  position: absolute;
  z-index: -1;
  border-radius: 100%;
  left: 0px;
  top: -1px;
}
.check-eligibility-pill {
  position: absolute;
  top: 21px;
  right: 30px;
  color: #fff;
  font-size: 14px;
  display: block;
  line-height: 24px;
  font-weight: bold;
  z-index: 50;
  text-align: center;
  width: 25px;
  height: 25px;
}
.check-eligibility-pill:not(:empty):before {
  content: "";
  background-color: #FF4E4C;
  width: 25px;
  height: 25px;
  position: absolute;
  z-index: -1;
  border-radius: 100%;
  left: 0px;
  top: -1px;
}
.proposal-pill {
  position: absolute;
  top: 21px;
  right: 30px;
  color: #fff;
  font-size: 14px;
  display: block;
  line-height: 24px;
  font-weight: bold;
  z-index: 50;
  text-align: center;
  width: 25px;
  height: 25px;
}
.proposal-pill:not(:empty):before {
  content: "";
  background-color: #26BD27;
  width: 25px;
  height: 25px;
  position: absolute;
  z-index: -1;
  border-radius: 100%;
  left: 0px;
  top: -1px;
}
.favourites {
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  border: 1px solid #D8DCE7;
  background-color: #fff;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  height: 36px;
  width: 36px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 15px 15px;
  box-shadow: 0 1px 0 0 #E9EBF1;
  background-image: url(img/button/star_grey.png);
}
.favourites:hover {
  border: 1px solid #C8CCD7;
}
.favourites.active {
  border: 1px solid #F5A623;
  background-image: url(img/button/star_orange_filled.png);
}
.refinance {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  border: 1px solid #D8DCE7;
  background-color: #fff;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  height: 36px;
  width: 36px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 15px 15px;
  box-shadow: 0 1px 0 0 #E9EBF1;
  background-image: url(img/button/stats-blue-large.png);
}
.refinance:hover {
  border: 1px solid #C8CCD7;
}
.question-mark {
  margin-left: 5px;
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  font-size: 10px;
  background-color: #727C8F;
  font-weight: 600;
  color: #fff;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
}
.question-mark:hover {
  text-decoration: none;
  background-color: #223241;
}
.a-arrow {
  background-image: url(./img/button/right-arrow-blue.png);
  background-repeat: no-repeat;
  background-size: 13px 7px;
  background-position: 100% 50%;
  padding-right: 25px;
}
.module {
  border: solid 1px #D8DCE7;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}
.radius-4 {
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}
.radius-12 {
  border-radius: 12px;
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
}
.form-focus:focus {
  box-shadow: 0 0 10px rgba(34, 145, 255, 0.15);
  border: 1px solid #2291FF;
  border-radius: 4px;
}
.form-focus:hover {
  border: 1px solid #C8CCD7;
  box-shadow: 0 0 10px rgba(34, 145, 255, 0.15);
  border: 1px solid #2291FF;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}
.a-style {
  color: #2291FF;
  cursor: pointer;
}
.a-style:hover {
  color: #46A3FF;
}
.a-style:active,
.a-style:focus {
  color: #1C83EA;
}
body {
  font-family: 'Lato', sans-serif;
  background-color: #fff;
}
.bottom-modal {
  min-width: 920px;
}
.hidden {
  display: none;
}
a {
  cursor: pointer;
}
[data-tooltip] {
  display: inline-block;
  position: relative;
  z-index: 99;
  cursor: pointer;
}
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}
[data-tooltip]:before {
  white-space: pre-wrap;
  display: block;
  position: absolute;
  bottom: calc(100% + 5px);
  left: 50%;
  margin-bottom: 5px;
  margin-left: -200px;
  padding: 7px;
  transform: translate(calc(-50% + 200px), 0);
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  background-color: #000;
  background-color: #203241;
  color: #fff;
  content: attr(data-tooltip);
  text-align: middle;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
}
[data-tooltip]:after {
  white-space: pre-wrap;
  position: absolute;
  bottom: calc(100% + 5px);
  left: 50%;
  transform: translate(-5px, 0);
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid hsla(0, 0%, 20%, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}
header {
  position: fixed;
  background-color: #fff;
  border-bottom: solid 1px #E9EBF1;
  height: 80px;
  width: 100%;
  z-index: 1100;
}
@media (max-width: 680px) {
  header {
    height: 60px;
  }
}
header.unactive .header-wrapper {
  max-width: 1100px;
  margin: 0 auto;
}
header.unactive .logo img {
  margin: 0 auto;
}
header.unactive .left-navigation {
  border-right: none;
}
header.unactive .right-navigation .page-title {
  display: none;
}
header.unactive .right-navigation .contact {
  float: right;
  margin-top: 15px;
  border-right: none;
  padding-right: 0;
  margin-right: 0;
  margin-right: 30px;
}
@media (max-width: 680px) {
  header.unactive .right-navigation .contact {
    margin-top: 9px;
  }
}
@media (max-width: 375px) {
  header.unactive .right-navigation .contact {
    display: none;
  }
}
@media (max-width: 680px) {
  header.unactive .right-navigation .contact .chat-button {
    padding: 10px 20px 10px 20px;
  }
}
@media (max-width: 680px) {
  header.unactive .right-navigation .contact .phone-number {
    line-height: 39px;
  }
}
@media (max-width: 600px) {
  header.unactive .right-navigation .contact .phone-number {
    display: none;
  }
}
header.active .logo img {
  margin: 0 auto;
}
header.sidebar-navigation {
  height: 100vh;
  height: Calc(var(--vh, 1vh) * 100);
  min-width: auto;
  width: 330px;
  border-bottom: none;
  border-right: solid 1px #E9EBF1;
  position: fixed;
  background-image: url(img/page_illustrations/Securefinance-notebook.png);
  background-repeat: no-repeat;
  background-position: 50% Calc(100% - 335px);
  background-size: 420px auto;
}
@media (max-width: 768px) {
  header.sidebar-navigation {
    display: none;
    pointer-events: none;
    background-image: none;
    background-color: transparent;
    border: none;
  }
}
header.sidebar-navigation .logo img {
  margin: 0 auto;
}
header.sidebar-navigation .contact {
  padding: 40px;
  bottom: 0;
  position: absolute;
}
@media (max-width: 768px) {
  header.sidebar-navigation .contact {
    display: none;
  }
}
header.sidebar-navigation .contact .phone-number,
header.sidebar-navigation .contact .chat-button {
  display: block;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #101922;
  background-position: 0% 50%;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  padding-left: 30px;
  line-height: 2.5;
}
header.sidebar-navigation .contact .phone-number span,
header.sidebar-navigation .contact .chat-button span {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}
header.sidebar-navigation .contact .phone-number:hover,
header.sidebar-navigation .contact .chat-button:hover {
  color: #2291FF;
}
header.sidebar-navigation .contact .phone-number:hover span,
header.sidebar-navigation .contact .chat-button:hover span {
  color: #101922;
}
header.sidebar-navigation .contact .phone-number {
  background-image: url(img/button/call-blue.png);
}
header.sidebar-navigation .contact .chat-button {
  background-image: url(img/button/chat-blue.png);
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}
header.sidebar-navigation .contact .chat-button:hover {
  color: #2291FF;
}
header.sidebar-navigation .contact hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #E9EBF1;
  margin: 30px 0;
  padding: 0;
}
header.sidebar-navigation .contact p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 10px;
  color: #798DA0;
  line-height: 2;
}
header.sidebar-navigation .contact p a {
  color: #2291FF;
  cursor: pointer;
}
header.sidebar-navigation .contact p a:hover {
  color: #46A3FF;
}
header.sidebar-navigation .contact p a:active,
header.sidebar-navigation .contact p a:focus {
  color: #1C83EA;
}
header.sidebar-navigation .contact h1 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  margin-bottom: 15px;
}
header.sidebar-navigation .contact h2 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  margin-bottom: 50px;
}
header.sidebar-navigation .left-navigation {
  border-right: none;
}
header .left-navigation {
  width: 260px;
  height: 80px;
  display: inline-block;
  border-right: solid 1px #E9EBF1;
}
@media (max-width: 680px) {
  header .left-navigation {
    height: 60px;
    border-right: none;
    width: 180px;
  }
}
header .left-navigation .logo {
  display: block;
  width: 100%;
}
header .left-navigation .logo img {
  width: 179px;
  margin-top: 23px;
  display: block;
}
@media (max-width: 680px) {
  header .left-navigation .logo img {
    width: 128px;
    height: 20px;
    margin-top: 18px;
  }
}
header .right-navigation {
  float: right;
  height: 100%;
  width: calc(100% - 260px);
}
@media (max-width: 680px) {
  header .right-navigation {
    width: auto;
  }
}
header .right-navigation .page-title {
  display: inline-block;
  height: 100%;
  line-height: 78px;
  padding-left: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;
}
@media (max-width: 1440px) {
  header .right-navigation .page-title {
    width: calc(100% - 510px);
  }
}
@media (max-width: 950px) {
  header .right-navigation .page-title {
    width: 100%;
    width: auto;
  }
}
@media (max-width: 680px) {
  header .right-navigation .page-title {
    display: none;
  }
}
header .right-navigation .page-title span {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 20px;
}
header .right-navigation .navigation-elements {
  float: right;
  margin-right: 40px;
}
@media (max-width: 950px) {
  header .right-navigation .navigation-elements {
    display: none;
  }
}
header .right-navigation .navigation-elements .contact-wrapper {
  display: inline-block;
  vertical-align: middle;
  border-left: 1px solid #E9EBF1;
  border-right: 1px solid #E9EBF1;
  padding: 0 40px;
  margin-right: 40px;
}
@media (max-width: 1440px) {
  header .right-navigation .navigation-elements .contact-wrapper {
    padding: 0 20px;
    margin-right: 20px;
  }
}
header .right-navigation .navigation-elements .contact-wrapper .contact-button {
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 25px 13px 25px;
  color: #2291FF;
  background-color: Transparent;
  border: solid 1px #2291FF;
}
header .right-navigation .navigation-elements .contact-wrapper .contact-button:hover {
  color: #46A3FF;
  border: solid 1px #46A3FF;
}
header .right-navigation .navigation-elements .contact-wrapper .contact-button:active,
header .right-navigation .navigation-elements .contact-wrapper .contact-button:focus {
  color: #1C83EA;
  border: solid 1px #1C83EA;
}
header .right-navigation .navigation-elements .notification-button {
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  margin: 0 30px;
  padding: 0 30px;
  border-left: solid 1px #E9EBF1;
  border-right: solid 1px #E9EBF1;
  line-height: 42px;
  background-color: #fff;
}
header .right-navigation .navigation-elements .notification-button img {
  width: 16px;
  height: 16px;
}
header .right-navigation .navigation-elements .notification-button:hover img {
  filter: brightness(80%);
}
header .right-navigation .navigation-elements .account {
  display: inline-block;
  padding-right: 30px;
  height: 80px;
  background-image: url(img/button/dropdown-grey.png);
  background-size: 10px 6px;
  background-repeat: no-repeat;
  background-position: 100% 50%;
  cursor: pointer;
  position: relative;
  vertical-align: middle;
}
header .right-navigation .navigation-elements .account .account-details {
  top: 50%;
  position: relative;
  transform: translate3d(0, -50%, 0);
}
header .right-navigation .navigation-elements .account .account-details .profile-picture {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
@media (max-width: 1440px) {
  header .right-navigation .navigation-elements .account .account-details .profile-picture {
    margin-right: 0;
  }
}
header .right-navigation .navigation-elements .account .account-details .profile-picture .avatar-pic {
  border-radius: 50%;
  height: 38px;
  width: 38px;
}
header .right-navigation .navigation-elements .account .account-details .profile-picture .avatar-pic.empty {
  line-height: 38px;
  color: #fff;
  text-align: center;
  background-color: #2291FF;
}
header .right-navigation .navigation-elements .account .account-details span {
  vertical-align: middle;
  display: inline-block;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 16px;
}
@media (max-width: 1440px) {
  header .right-navigation .navigation-elements .account .account-details span {
    display: none;
  }
}
header .right-navigation .navigation-elements .account ul.navigation {
  display: none;
  position: absolute;
  top: 100%;
  right: -41px;
  border: 1px solid red;
  z-index: 110;
  background-color: #fff;
  border: solid 1px #E9EBF1;
  border-top: none;
  padding: 0 20px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  margin-top: 0;
  min-width: 180px;
}
header .right-navigation .navigation-elements .account ul.navigation li {
  margin: 20px 0;
  color: #101922;
  border-bottom: solid 1px #E9EBF1;
  padding-bottom: 20px;
}
header .right-navigation .navigation-elements .account ul.navigation li:last-of-type {
  border-bottom: none;
  padding-bottom: 0;
}
header .right-navigation .navigation-elements .account ul.navigation li a {
  color: #101922;
}
header .right-navigation .navigation-elements .account ul.navigation li a:hover {
  color: #101922;
}
header .right-navigation .navigation-elements .account ul.navigation li a:active,
header .right-navigation .navigation-elements .account ul.navigation li a:focus {
  color: #1C83EA;
}
header .right-navigation .navigation-elements .account:hover ul.navigation {
  display: block;
}
header .right-navigation .contact {
  display: inline-block;
  vertical-align: middle;
  border-right: 1px solid #E9EBF1;
  margin-right: 30px;
  padding-right: 30px;
}
header .right-navigation .contact .chat-button {
  display: inline-block;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 25px 13px 25px;
  color: #2291FF;
  background-color: Transparent;
  border: solid 1px #2291FF;
}
header .right-navigation .contact .chat-button:hover {
  color: #46A3FF;
  border: solid 1px #46A3FF;
}
header .right-navigation .contact .chat-button:active,
header .right-navigation .contact .chat-button:focus {
  color: #1C83EA;
  border: solid 1px #1C83EA;
}
header .right-navigation .contact .phone-number {
  display: inline-block;
  vertical-align: middle;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #101922;
  background-image: url(img/elements/phone_blue.png);
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: 0 50%;
  padding-left: 25px;
  line-height: 45px;
  border-right: 1px solid #E9EBF1;
  padding: 0 30px;
  margin-right: 30px;
}
header .right-navigation .contact .phone-number:hover {
  color: #2291FF;
}
.sidebar {
  position: fixed;
  background-color: #284867;
  width: 260px;
  z-index: 100;
  top: 80px;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  height: calc(100vh - 80px);
  border-right: solid 1px #dadde8;
  transition: width 0.5s;
}
@media (max-width: 950px) {
  .sidebar {
    display: none;
  }
}
.sidebar ul {
  padding-top: 30px;
}
.sidebar ul li {
  color: #fff;
  position: relative;
}
.sidebar ul li .condensed {
  display: none;
}
.sidebar ul li a {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #D3DEE9;
  text-decoration: none;
  display: block;
  width: 100%;
  padding: 25px 0 25px 90px;
  background-repeat: no-repeat;
  background-position: 50px 50%;
  background-size: 20px auto;
  border-right: solid 0px #fff;
}
.sidebar ul li a:hover {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #fff;
  background-color: #213D58;
  transition: border 200ms linear;
}
.sidebar ul li a:hover:after {
  position: absolute;
  content: '';
  width: 6px;
  height: 100%;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #2291FF;
}
.sidebar ul li a.current {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #fff;
  background-color: #213D58;
  transition: border 200ms linear;
}
.sidebar ul li a.current:after {
  position: absolute;
  content: '';
  width: 6px;
  height: 100%;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #2291FF;
}
.sidebar ul li.menu-item-0 a {
  background-image: url(img/icons/proposal-grey.png);
}
.sidebar ul li.menu-item-0 a:hover {
  background-image: url(img/icons/proposal-blue.png);
}
.sidebar ul li.menu-item-1 a {
  background-image: url(img/icons/icon-dashboard-grey.png);
}
.sidebar ul li.menu-item-1 a:hover {
  background-image: url(img/icons/icon-dashboard-blue.png);
}
.sidebar ul li.menu-item-2 a {
  background-image: url(img/icons/icon-calculators-grey.png);
}
.sidebar ul li.menu-item-2 a:hover {
  background-image: url(img/icons/icon-calculators-blue.png);
}
.sidebar ul li.menu-item-3 a {
  background-image: url(img/icons/icon-search-grey.png);
}
.sidebar ul li.menu-item-3 a:hover {
  background-image: url(img/icons/icon-search-blue.png);
}
.sidebar ul li.menu-item-4 a {
  background-image: url(img/icons/icon-shortlist-grey.png);
}
.sidebar ul li.menu-item-4 a:hover {
  background-image: url(img/icons/icon-shortlist-blue.png);
}
.sidebar ul li.menu-item-5 a {
  background-image: url(img/icons/icon-eligibility-grey.png);
}
.sidebar ul li.menu-item-5 a:hover {
  background-image: url(img/icons/icon-eligibility-blue.png);
}
.sidebar ul li.menu-item-6 a {
  background-image: url(img/icons/icon-tracker-grey.png);
}
.sidebar ul li.menu-item-6 a:hover {
  background-image: url(img/icons/icon-tracker-blue.png);
}
.sidebar ul li.menu-item-7 a {
  background-image: url(img/icons/icon-help-grey.png);
}
.sidebar ul li.menu-item-7 a:hover {
  background-image: url(img/icons/icon-help-blue.png);
}
.sidebar ul li.menu-item-8 a {
  background-image: url(img/icons/icon-referrer-grey.png);
}
.sidebar ul li.menu-item-8 a:hover {
  background-image: url(img/icons/icon-referrer-blue.png);
}
.sidebar ul li.menu-item-9 a {
  background-image: url(img/icons/tick-circle-grey.svg);
}
.sidebar ul li.menu-item-9 a:hover {
  background-image: url(img/icons/tick-circle-blue.svg);
}
.sidebar ul li.menu-item-10 a {
  background-image: url(img/icons/pen-grey.svg);
}
.sidebar ul li.menu-item-10 a:hover {
  background-image: url(img/icons/pen-blue.svg);
}
.sidebar ul li.menu-item-11 a {
  background-image: url(img/icons/details-grey.svg);
}
.sidebar ul li.menu-item-11 a:hover {
  background-image: url(img/icons/details-blue.svg);
}
.sidebar ul li.menu-item-12 a {
  background-image: url(img/icons/back-arrow-grey.svg);
}
.sidebar ul li.menu-item-12 a:hover {
  background-image: url(img/icons/back-arrow-blue.svg);
}
.sidebar ul li.menu-item-13 a {
  background-image: url(img/icons/back-arrow-grey-flipped.svg);
}
.sidebar ul li.menu-item-13 a:hover {
  background-image: url(img/icons/back-arrow-blue-flipped.svg);
}
.sidebar.proposal {
  width: 80px;
  transition: width 0.5s;
}
.sidebar.proposal ul li a {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  background-position: 30px 23px;
  overflow: hidden;
  white-space: nowrap;
  padding: 54px 0 14px 0px;
  text-align: center;
}
.sidebar.proposal ul li a:hover {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #fff;
}
.sidebar.proposal ul li a:hover:after {
  width: 0px;
}
.sidebar.proposal ul li a.current {
  border: none;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
.sidebar.proposal ul li a.current:after {
  width: 0;
}
.sidebar.proposal ul li a span {
  display: none;
}
.sidebar.proposal ul li a span.condensed {
  display: inline;
}
.sidebar.proposal ul li .shortlist-pill,
.sidebar.proposal ul li .check-eligibility-pill,
.sidebar.proposal ul li .proposal-pill {
  display: block;
  transform: scale(0.7);
  left: 41px;
  top: 12px;
}
@media (max-width: 1440px) {
  .sidebar {
    width: 80px;
    transition: width 0.5s;
  }
  .sidebar ul li a {
    font-weight: 600;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    background-position: 30px 23px;
    overflow: hidden;
    white-space: nowrap;
    padding: 54px 0 14px 0px;
    text-align: center;
  }
  .sidebar ul li a:hover {
    font-weight: 400;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    color: #fff;
  }
  .sidebar ul li a:hover:after {
    width: 0px;
  }
  .sidebar ul li a.current {
    border: none;
    font-weight: 400;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
  }
  .sidebar ul li a.current:after {
    width: 0;
  }
  .sidebar ul li a span {
    display: none;
  }
  .sidebar ul li a span.condensed {
    display: inline;
  }
  .sidebar ul li .shortlist-pill,
  .sidebar ul li .check-eligibility-pill,
  .sidebar ul li .proposal-pill {
    display: block;
    transform: scale(0.7);
    left: 41px;
    top: 12px;
  }
}
main {
  position: absolute;
  left: 260px;
  top: 80px;
  right: 0;
  bottom: 0;
  background-color: #f6f6fa;
  padding: 40px 35px 230px 35px;
  overflow: auto;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  transition: left 0.5s;
}
@media (max-width: 1440px) {
  main {
    left: 80px;
  }
}
@media (max-width: 950px) {
  main {
    padding: 40px 20px 230px 20px;
    min-width: auto;
    left: 0;
  }
}
@media (max-width: 680px) {
  main {
    top: 60px;
  }
}
@media (max-width: 480px) {
  main {
    padding: 40px 10px 230px 10px;
  }
}
main.proposal {
  left: 80px;
}
@media (max-width: 950px) {
  main.proposal {
    left: 0;
    padding: 40px 0 230px 0px;
  }
}
@media (max-width: 840px) {
  main.proposal {
    padding: 0px 0 230px 0px;
  }
}
@media (max-width: 645px) {
  main.proposal .main-container {
    padding-left: 0;
    padding-right: 0;
  }
}
main:not(.search-loans):not(.user-authentication):not(.proposal) {
  min-width: 840px;
}
@media (max-width: 950px) {
  main:not(.search-loans):not(.user-authentication):not(.proposal) {
    min-width: 840px;
  }
}
main.initial-question-set {
  min-width: auto;
}
@media (min-width: 840px) {
  main.true {
    bottom: 180px;
    height: calc(100vh - 266px);
  }
}
main.double {
  background-color: #fff;
  overflow: hidden;
}
main.double .main-container {
  position: absolute;
  left: 240px;
  background-color: #fff;
  top: 0px;
  bottom: 0px;
  right: 0px;
  padding: 40px;
  overflow: auto;
  height: Calc(100vh - 80px);
  max-width: none;
  margin: 0;
}
@media (max-width: 1210px) {
  main.double .main-container {
    left: 200px;
  }
}
main.dashboard .col-m1 {
  border: solid 1px #D8DCE7;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border: 1px solid #D8DCE7;
  display: block;
  width: 100%;
  background-color: #fff;
  margin-top: 24px;
}
main.dashboard .m-tlt .mod-title {
  border-bottom: solid 1px #E9EBF1;
  min-height: 62px;
  padding: 20px;
  overflow: hidden;
}
main.dashboard .m-tlt .mod-title .title {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  width: 75%;
  text-align: left;
  cursor: default;
}
main.dashboard .m-tlt .mod-title .title.button {
  cursor: pointer;
}
main.dashboard .m-lnk .mod-title .title {
  display: inline-block;
}
main.dashboard .m-lnk .mod-title > a {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  display: inline-block;
  float: right;
  background-image: url(./img/button/right-arrow-blue.png);
  background-repeat: no-repeat;
  background-size: 13px 7px;
  background-position: 100% 50%;
  padding-right: 25px;
  color: #2291FF;
  line-height: 20px;
}
main.dashboard .m-const .mod-title .title {
  color: #101922;
  padding-left: 25px;
  background-image: url(img/button/down-blue.png);
  background-repeat: no-repeat;
  background-size: 10px 5px;
  background-position: 0% 50%;
  padding-right: 25px;
  background-color: transparent;
}
main.dashboard .m-const .mod-title .title:hover {
  color: #337ab7;
  text-decoration: none;
}
main.dashboard .m-const.active .mod-title .title {
  background-image: url(img/button/up-blue.png);
}
main.dashboard .mod-content {
  width: 100%;
  height: 100%;
  transition: all 1s;
}
main.dashboard .mod-content .animation-container {
  padding: 20px;
}
main.dashboard .mod-content.nb {
  padding: 0;
}
main.dashboard .mod-content .video {
  display: block;
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  margin: 0 auto;
}
main.dashboard .mod-content .col-c4 {
  display: inline-block;
  vertical-align: top;
  width: 24.5%;
}
main.dashboard .mod-content .col-c3 {
  display: inline-block;
  vertical-align: top;
  width: 25.5%;
}
main.dashboard .mod-content h3 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  line-height: 1.3;
}
main.dashboard .mod-content h4 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
}
main.dashboard .mod-content h6 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  margin-bottom: 10px;
}
main.dashboard .mod-content p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
main.dashboard .mod-content .btn-1 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
}
main.dashboard .mod-content .btn-1:hover {
  background-color: #46A3FF;
}
main.dashboard .mod-content .btn-1:active,
main.dashboard .mod-content .btn-1:focus {
  background-color: #1C83EA;
}
main.dashboard .mod-content .btn-1:disabled,
main.dashboard .mod-content .btn-1.disabled {
  background: #909090;
}
main.dashboard .mod-content .fw {
  width: 100%;
}
main.dashboard .half {
  width: Calc(50% - 15px);
  display: inline-block;
  vertical-align: top;
}
main.dashboard .half.n1 {
  margin-right: 30px;
}
main.dashboard .lets-start h4 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  margin-bottom: 15px;
}
main.dashboard .lets-start p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  line-height: 1.3;
  color: #575E6C;
  margin-bottom: 25px;
}
main.dashboard .lets-start .get-advice,
main.dashboard .lets-start .search-loans {
  width: Calc(50% - 10px);
}
main.dashboard .lets-start .get-advice {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  padding: 13px 50px 13px 50px;
  margin-right: 20px;
}
main.dashboard .lets-start .get-advice:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
main.dashboard .lets-start .get-advice:active,
main.dashboard .lets-start .get-advice:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
main.dashboard .lets-start .search-loans {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  padding: 13px 50px 13px 50px;
}
main.dashboard .lets-start .search-loans:hover {
  background-color: #46A3FF;
}
main.dashboard .lets-start .search-loans:active,
main.dashboard .lets-start .search-loans:focus {
  background-color: #1C83EA;
}
main.dashboard .lets-start .search-loans:disabled,
main.dashboard .lets-start .search-loans.disabled {
  background: #909090;
}
main.dashboard .lets-start .quick-links {
  background-color: #F6F7FB;
  margin-top: 20px;
  padding: 20px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}
main.dashboard .lets-start .quick-links ul li {
  margin-bottom: 12px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}
main.dashboard .lets-start .quick-links ul li a {
  color: #2291FF;
  background-image: url(img/button/long-right-black.png);
  background-position: 0% 60%;
  background-repeat: no-repeat;
  background-size: 16px 10px;
  display: block;
  width: 100%;
  padding-left: 30px;
}
main.dashboard .lets-start .chat {
  margin: 20px 0;
}
main.dashboard .lets-start .chat .chat-container {
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  width: 145px;
  height: 50px;
  display: block;
  margin: 0 auto;
  border-radius: 25px;
  position: relative;
  cursor: pointer;
}
main.dashboard .lets-start .chat .chat-container .chat-content {
  display: block;
  position: absolute;
  top: 7px;
  left: 10px;
  height: 34px;
  background-image: url(img/button/chat_green.png);
  background-repeat: no-repeat;
  background-size: 34px 34px;
  background-position: 0% 50%;
  padding-left: 42px;
}
main.dashboard .lets-start .chat .chat-container .chat-content h5 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  margin: 0;
  margin-top: 3px;
}
main.dashboard .lets-start .chat .chat-container .chat-content p {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 10px;
  margin: 0;
  margin-top: 2px;
  color: #23B024;
}
main.dashboard .lets-start .chat hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #E9EBF1;
  padding: 0;
  margin-top: -25px;
  margin-bottom: 50px;
}
main.dashboard .how-it-works .video {
  background-image: url(img/temp/female-business-loan-approved.png);
}
main.dashboard .budget h6 {
  color: #727C8F;
}
main.dashboard .budget .col-c4 {
  width: Calc(25% - 22px);
  background-color: #F6F7FB;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  margin-left: 25px;
  padding: 25px;
  position: relative;
  min-height: 180px;
}
main.dashboard .budget .col-c4:first-of-type {
  margin-left: 0;
}
main.dashboard .budget .col-c4 .budget-content {
  top: 0;
  margin-bottom: 65px;
}
main.dashboard .budget .col-c4 .button {
  width: 100%;
  bottom: 22px;
  position: absolute;
  width: Calc(100% - 50px);
}
main.dashboard .budget .col-c4 .button.calculate {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  background-color: #26BD27;
  padding: 10px 10px 10px 10px;
  color: #fff;
}
main.dashboard .budget .col-c4 .button.calculate:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #29CA2A;
}
main.dashboard .budget .col-c4 .button.calculate:active,
main.dashboard .budget .col-c4 .button.calculate:focus {
  background-color: #23B024;
}
main.dashboard .budget .col-c4 .button.result {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  background-color: #26BD27;
  padding: 10px 10px 10px 10px;
  color: #fff;
  background-color: #727C8F;
}
main.dashboard .budget .col-c4 .button.result:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #29CA2A;
}
main.dashboard .budget .col-c4 .button.result:active,
main.dashboard .budget .col-c4 .button.result:focus {
  background-color: #23B024;
}
main.dashboard .budget .col-c4 .button.result:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #A3A8B2;
}
main.dashboard .budget .col-c4 .button.result:active,
main.dashboard .budget .col-c4 .button.result:focus {
  background-color: #575E6C;
}
main.dashboard .budget .col-c4 .button.result span {
  font-weight: lighter;
}
main.dashboard .shortlist ::-webkit-scrollbar {
  height: 14px;
}
main.dashboard .shortlist ::-webkit-scrollbar-track {
  background: #E9EBF1;
  border-radius: 12px;
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
}
main.dashboard .shortlist ::-webkit-scrollbar-thumb {
  border: solid 3px #E9EBF1;
  background: #888;
  border-radius: 12px;
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
}
main.dashboard .shortlist ::-webkit-scrollbar-thumb:hover {
  background: #626B7B;
}
main.dashboard .shortlist .animation-container {
  width: 100%;
  overflow-y: auto;
  padding: 0;
  padding-bottom: 20px;
  margin-bottom: 15px;
}
main.dashboard .shortlist .animation-container.remove-scroll-bar {
  padding-bottom: 0;
  margin-bottom: 0;
}
main.dashboard .shortlist .animation-container .scroll-container {
  height: 100%;
}
main.dashboard .shortlist .animation-container .scroll-container .product {
  display: inline-block;
  vertical-align: top;
  border-right: 1px solid #E9EBF1;
}
main.dashboard .shortlist .animation-container .scroll-container .product .product-item {
  background-color: #fff;
  padding: 20px;
  height: 400px;
  position: relative;
  width: 290px;
}
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .close {
  display: none;
}
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .company-logo {
  height: 30px;
  width: 100px;
  display: block;
  margin: 0 auto;
  float: none;
  object-fit: contain;
}
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .product-name-container {
  height: 32px;
}
main.dashboard .shortlist .animation-container .scroll-container .product .product-item h6 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  text-align: center;
  margin-top: 15px;
  color: #101922;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .interest-rate,
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .principal-interest,
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .repayment-power {
  border-top: solid 1px #E9EBF1;
  text-align: center;
  width: 100%;
  padding: 20px 0;
}
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .interest-rate .description,
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .principal-interest .description,
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .repayment-power .description {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  margin-top: 5px;
  color: #727C8F;
}
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .interest-rate h5,
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .principal-interest h5,
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .repayment-power h5 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .interest-rate span,
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .repayment-power span {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #727C8F;
}
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .interest-rate .variable-rate,
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .repayment-power .variable-rate,
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .interest-rate .comparison-rate,
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .repayment-power .comparison-rate,
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .interest-rate .monthly-repayment,
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .repayment-power .monthly-repayment,
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .interest-rate .borrowing-power,
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .repayment-power .borrowing-power {
  display: inline-block;
  width: 49%;
}
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .interest-rate .variable-rate .rate,
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .repayment-power .variable-rate .rate,
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .interest-rate .comparison-rate .rate,
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .repayment-power .comparison-rate .rate,
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .interest-rate .monthly-repayment .rate,
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .repayment-power .monthly-repayment .rate,
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .interest-rate .borrowing-power .rate,
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .repayment-power .borrowing-power .rate {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  text-align: center;
}
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .interest-rate .borrowing-power .borrowing-value,
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .repayment-power .borrowing-power .borrowing-value {
  padding-left: 40px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 20px;
}
main.dashboard .shortlist .animation-container .scroll-container .product .product-item ul.statistics {
  border-top: solid 1px #E9EBF1;
  padding: 20px 0 10px 0;
  display: block;
  vertical-align: 50%;
}
main.dashboard .shortlist .animation-container .scroll-container .product .product-item ul.statistics li {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0% 50%;
  background-size: 10px 10px;
  padding-left: 15px;
  margin-left: 10px;
}
main.dashboard .shortlist .animation-container .scroll-container .product .product-item ul.statistics li.Y {
  background-image: url(img/elements/check-green.png);
  color: #26BD27;
}
main.dashboard .shortlist .animation-container .scroll-container .product .product-item ul.statistics li.N {
  background-image: url(img/elements/cross-red.png);
  color: #FF4E4C;
}
main.dashboard .shortlist .animation-container .scroll-container .product .product-item ul.statistics li.additional {
  background-image: url(img/elements/money_blue.png);
  color: #2291FF;
  background-size: 8px 12px;
  background-position: 0px 50%;
}
main.dashboard .shortlist .animation-container .scroll-container .product .product-item ul.statistics li:first-of-type {
  margin-left: 0;
}
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .fees {
  padding: 10px 0 20px 0;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  border-bottom: solid 1px #E9EBF1;
}
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .fees .upfront-fee,
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .fees .annual-fee {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .fees span {
  margin-top: 5px;
  display: block;
  width: 100%;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 10px;
  color: #727C8F;
}
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .more-info {
  width: 100%;
  margin-top: 20px;
}
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .more-info .details,
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .more-info .eligibility {
  width: Calc(50% - 12px);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 14px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 25px 13px 25px;
  color: #2291FF;
  background-color: Transparent;
  border: solid 1px #2291FF;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  padding: 10px 5px 10px 5px;
}
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .more-info .details:hover,
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .more-info .eligibility:hover {
  color: #46A3FF;
  border: solid 1px #46A3FF;
}
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .more-info .details:active,
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .more-info .eligibility:active,
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .more-info .details:focus,
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .more-info .eligibility:focus {
  color: #1C83EA;
  border: solid 1px #1C83EA;
}
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .more-info .details:first-of-type,
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .more-info .eligibility:first-of-type {
  margin-right: 20px;
}
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .more-info .eligibility {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  padding: 10px 5px 10px 5px;
}
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .more-info .eligibility:hover {
  background-color: #46A3FF;
}
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .more-info .eligibility:active,
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .more-info .eligibility:focus {
  background-color: #1C83EA;
}
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .more-info .eligibility:disabled,
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .more-info .eligibility.disabled {
  background: #909090;
}
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .more-info .eligibility:hover {
  padding: 10px 5px 10px 5px;
  border: none;
  color: #fff;
}
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .more-detail {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 11px;
  padding: 6px 10px 6px 10px;
  background-position: Calc(100% - 10px) 50%;
  background-repeat: no-repeat;
  padding-right: 30px;
  background-image: url(img/button/right-arrow-blue.png);
  background-size: 10px 7px;
  margin: 5px 0 15px 0;
}
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .more-detail:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .more-detail:active,
main.dashboard .shortlist .animation-container .scroll-container .product .product-item .more-detail:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
main.dashboard .loan-applications .animation-container,
main.dashboard .eligibility-assessment .animation-container {
  padding: 0;
}
main.dashboard .loan-applications table,
main.dashboard .eligibility-assessment table {
  width: 100%;
  border-spacing: 20px;
}
main.dashboard .loan-applications table tr,
main.dashboard .eligibility-assessment table tr {
  border-top: solid 1px #E9EBF1;
  background-color: white;
}
main.dashboard .loan-applications table tr th,
main.dashboard .eligibility-assessment table tr th {
  border-top: none;
}
main.dashboard .loan-applications table tr:nth-of-type(1),
main.dashboard .eligibility-assessment table tr:nth-of-type(1) {
  border-top: none;
}
main.dashboard .loan-applications table th,
main.dashboard .eligibility-assessment table th,
main.dashboard .loan-applications table td,
main.dashboard .eligibility-assessment table td {
  border-collapse: collapse;
  border: solid 1px #E9EBF1;
  vertical-align: top;
  background-color: white;
}
main.dashboard .loan-applications table th:nth-of-type(1),
main.dashboard .eligibility-assessment table th:nth-of-type(1),
main.dashboard .loan-applications table td:nth-of-type(1),
main.dashboard .eligibility-assessment table td:nth-of-type(1) {
  width: 25%;
  border: none;
}
main.dashboard .loan-applications table th:nth-of-type(2),
main.dashboard .eligibility-assessment table th:nth-of-type(2),
main.dashboard .loan-applications table td:nth-of-type(2),
main.dashboard .eligibility-assessment table td:nth-of-type(2) {
  width: 30%;
}
main.dashboard .loan-applications table th:nth-of-type(3),
main.dashboard .eligibility-assessment table th:nth-of-type(3),
main.dashboard .loan-applications table td:nth-of-type(3),
main.dashboard .eligibility-assessment table td:nth-of-type(3) {
  width: 45%;
}
main.dashboard .loan-applications .app-label th,
main.dashboard .eligibility-assessment .app-label th {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 11px;
  padding: 12px;
  color: #727C8F;
}
main.dashboard .loan-applications .applications-row td,
main.dashboard .eligibility-assessment .applications-row td {
  padding: 20px;
}
main.dashboard .loan-applications .applications-row .loan-details .company-logo,
main.dashboard .eligibility-assessment .applications-row .loan-details .company-logo {
  height: 30px;
  width: 100px;
  display: block;
  margin: 0 auto;
  object-fit: contain;
}
main.dashboard .loan-applications .applications-row .loan-details h6,
main.dashboard .eligibility-assessment .applications-row .loan-details h6 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  text-align: center;
  margin-top: 10px;
}
main.dashboard .loan-applications .applications-row .loan-details h6.value,
main.dashboard .eligibility-assessment .applications-row .loan-details h6.value {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  margin-top: -2px;
  margin-bottom: 20px;
}
main.dashboard .loan-applications .applications-row .loan-details .interest-rate,
main.dashboard .eligibility-assessment .applications-row .loan-details .interest-rate,
main.dashboard .loan-applications .applications-row .loan-details .principal-interest,
main.dashboard .eligibility-assessment .applications-row .loan-details .principal-interest,
main.dashboard .loan-applications .applications-row .loan-details .refinance-investment,
main.dashboard .eligibility-assessment .applications-row .loan-details .refinance-investment {
  border-top: solid 1px #E9EBF1;
  text-align: center;
  width: 100%;
  padding: 20px 0;
}
main.dashboard .loan-applications .applications-row .loan-details .interest-rate .description,
main.dashboard .eligibility-assessment .applications-row .loan-details .interest-rate .description,
main.dashboard .loan-applications .applications-row .loan-details .principal-interest .description,
main.dashboard .eligibility-assessment .applications-row .loan-details .principal-interest .description,
main.dashboard .loan-applications .applications-row .loan-details .refinance-investment .description,
main.dashboard .eligibility-assessment .applications-row .loan-details .refinance-investment .description {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  margin-top: 5px;
  color: #727C8F;
}
main.dashboard .loan-applications .applications-row .loan-details .interest-rate h5,
main.dashboard .eligibility-assessment .applications-row .loan-details .interest-rate h5,
main.dashboard .loan-applications .applications-row .loan-details .principal-interest h5,
main.dashboard .eligibility-assessment .applications-row .loan-details .principal-interest h5,
main.dashboard .loan-applications .applications-row .loan-details .refinance-investment h5,
main.dashboard .eligibility-assessment .applications-row .loan-details .refinance-investment h5 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
main.dashboard .loan-applications .applications-row .loan-details .interest-rate span,
main.dashboard .eligibility-assessment .applications-row .loan-details .interest-rate span {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #727C8F;
}
main.dashboard .loan-applications .applications-row .loan-details .interest-rate .variable-rate,
main.dashboard .eligibility-assessment .applications-row .loan-details .interest-rate .variable-rate,
main.dashboard .loan-applications .applications-row .loan-details .interest-rate .comparison-rate,
main.dashboard .eligibility-assessment .applications-row .loan-details .interest-rate .comparison-rate {
  display: inline-block;
  width: 49%;
}
main.dashboard .loan-applications .applications-row .loan-details .interest-rate .variable-rate .rate,
main.dashboard .eligibility-assessment .applications-row .loan-details .interest-rate .variable-rate .rate,
main.dashboard .loan-applications .applications-row .loan-details .interest-rate .comparison-rate .rate,
main.dashboard .eligibility-assessment .applications-row .loan-details .interest-rate .comparison-rate .rate {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  text-align: center;
}
main.dashboard .loan-applications .applications-row .loan-details .refinance-investment .refinance-value,
main.dashboard .eligibility-assessment .applications-row .loan-details .refinance-investment .refinance-value,
main.dashboard .loan-applications .applications-row .loan-details .refinance-investment .investment-value,
main.dashboard .eligibility-assessment .applications-row .loan-details .refinance-investment .investment-value {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}
main.dashboard .loan-applications .applications-row .loan-details .refinance-investment .refinance-value h5,
main.dashboard .eligibility-assessment .applications-row .loan-details .refinance-investment .refinance-value h5,
main.dashboard .loan-applications .applications-row .loan-details .refinance-investment .investment-value h5,
main.dashboard .eligibility-assessment .applications-row .loan-details .refinance-investment .investment-value h5 {
  text-transform: capitalize;
}
main.dashboard .loan-applications .applications-row .loan-details .more-products,
main.dashboard .eligibility-assessment .applications-row .loan-details .more-products {
  text-align: center;
  border-top: solid 1px #E9EBF1;
  padding: 20px 0;
}
main.dashboard .loan-applications .applications-row .loan-details .more-products span,
main.dashboard .eligibility-assessment .applications-row .loan-details .more-products span {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #727C8F;
  cursor: pointer;
}
main.dashboard .loan-applications .applications-row .loan-details .more-products span.info,
main.dashboard .eligibility-assessment .applications-row .loan-details .more-products span.info {
  color: #fff;
}
main.dashboard .loan-applications .applications-row .stage .application-status,
main.dashboard .eligibility-assessment .applications-row .stage .application-status {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  text-align: center;
  color: #101922;
  margin-top: 5px;
}
main.dashboard .loan-applications .applications-row .stage .application-status.lodged span,
main.dashboard .eligibility-assessment .applications-row .stage .application-status.lodged span,
main.dashboard .loan-applications .applications-row .stage .application-status.unfinished span,
main.dashboard .eligibility-assessment .applications-row .stage .application-status.unfinished span {
  padding-left: 20px;
  background-repeat: no-repeat;
  background-position: 0% 50%;
  background-size: 14px 14px;
}
main.dashboard .loan-applications .applications-row .stage .application-status.lodged span,
main.dashboard .eligibility-assessment .applications-row .stage .application-status.lodged span {
  background-image: url(img/elements/tick-circle-green.png);
}
main.dashboard .loan-applications .applications-row .stage .application-status.unfinished span,
main.dashboard .eligibility-assessment .applications-row .stage .application-status.unfinished span {
  background-image: url(img/elements/exc-circle-red.png);
}
main.dashboard .loan-applications .applications-row .stage .application-status-description,
main.dashboard .eligibility-assessment .applications-row .stage .application-status-description {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  text-align: center;
  line-height: 1.5;
  color: #101922;
  margin-top: 10px;
}
main.dashboard .loan-applications .applications-row .stage .eligiblity-progress-bar,
main.dashboard .eligibility-assessment .applications-row .stage .eligiblity-progress-bar {
  width: 240px;
  height: 18px;
  display: block;
  margin: 0 auto;
  background-color: #fff;
  margin-top: 20px;
}
main.dashboard .loan-applications .applications-row .stage .eligibility-progress-bar-definition,
main.dashboard .eligibility-assessment .applications-row .stage .eligibility-progress-bar-definition {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 10px;
  color: #2291FF;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 15px;
  display: block;
}
main.dashboard .loan-applications .applications-row .stage .application-details,
main.dashboard .eligibility-assessment .applications-row .stage .application-details {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  text-align: left;
  line-height: 1.4;
}
main.dashboard .loan-applications .applications-row .stage .application-details p,
main.dashboard .eligibility-assessment .applications-row .stage .application-details p {
  margin-bottom: 20px;
  color: #101922;
}
main.dashboard .loan-applications .applications-row .stage .application-details .grey,
main.dashboard .eligibility-assessment .applications-row .stage .application-details .grey {
  color: #727C8F;
}
main.dashboard .loan-applications .applications-row .stage .request-update,
main.dashboard .eligibility-assessment .applications-row .stage .request-update {
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
}
main.dashboard .loan-applications .applications-row .stage .request-update:hover,
main.dashboard .eligibility-assessment .applications-row .stage .request-update:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
main.dashboard .loan-applications .applications-row .stage .request-update:active,
main.dashboard .eligibility-assessment .applications-row .stage .request-update:active,
main.dashboard .loan-applications .applications-row .stage .request-update:focus,
main.dashboard .eligibility-assessment .applications-row .stage .request-update:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
main.dashboard .loan-applications .applications-row .resume-eligiblity,
main.dashboard .eligibility-assessment .applications-row .resume-eligiblity {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 14px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  background-color: #26BD27;
  padding: 10px 10px 10px 10px;
  color: #fff;
  padding: 12px 20px 12px 20px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
}
main.dashboard .loan-applications .applications-row .resume-eligiblity:hover,
main.dashboard .eligibility-assessment .applications-row .resume-eligiblity:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #29CA2A;
}
main.dashboard .loan-applications .applications-row .resume-eligiblity:active,
main.dashboard .eligibility-assessment .applications-row .resume-eligiblity:active,
main.dashboard .loan-applications .applications-row .resume-eligiblity:focus,
main.dashboard .eligibility-assessment .applications-row .resume-eligiblity:focus {
  background-color: #23B024;
}
main.dashboard .loan-applications .applications-row .comments,
main.dashboard .eligibility-assessment .applications-row .comments {
  vertical-align: top;
  position: relative;
  background-color: #FFFFFF;
  overflow: hidden;
  padding: 0;
}
main.dashboard .loan-applications .applications-row .comments .contact-box,
main.dashboard .eligibility-assessment .applications-row .comments .contact-box {
  background: #FFFFFF;
  padding: 40px 60px;
  text-align: center;
  margin-right: 20px;
  top: 50%;
  position: relative;
  transform: translate3d(0, -50%, 0);
}
main.dashboard .loan-applications .applications-row .comments .contact-box:last-of-type,
main.dashboard .eligibility-assessment .applications-row .comments .contact-box:last-of-type {
  margin-right: 0;
}
main.dashboard .loan-applications .applications-row .comments .contact-box h4,
main.dashboard .eligibility-assessment .applications-row .comments .contact-box h4 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  color: #223241;
  margin-bottom: 10px;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  padding-top: 70px;
  background-size: 45px;
}
main.dashboard .loan-applications .applications-row .comments .contact-box p,
main.dashboard .eligibility-assessment .applications-row .comments .contact-box p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #223241;
  margin-bottom: 20px;
  line-height: 1.4;
}
main.dashboard .loan-applications .applications-row .comments .contact-box .contact-button,
main.dashboard .eligibility-assessment .applications-row .comments .contact-box .contact-button {
  display: block;
  margin: 0 auto;
  line-height: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  padding: 14px 60px;
  margin-bottom: 20px;
}
main.dashboard .loan-applications .applications-row .comments .contact-box .contact-button:hover,
main.dashboard .eligibility-assessment .applications-row .comments .contact-box .contact-button:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
main.dashboard .loan-applications .applications-row .comments .contact-box .contact-button:active,
main.dashboard .eligibility-assessment .applications-row .comments .contact-box .contact-button:active,
main.dashboard .loan-applications .applications-row .comments .contact-box .contact-button:focus,
main.dashboard .eligibility-assessment .applications-row .comments .contact-box .contact-button:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
main.dashboard .loan-applications .applications-row .comments .contact-box.email-us h4,
main.dashboard .eligibility-assessment .applications-row .comments .contact-box.email-us h4 {
  background-image: url(img/icons/contact-email.png);
}
main.dashboard .loan-applications .applications-row .comments ul,
main.dashboard .eligibility-assessment .applications-row .comments ul {
  padding: 20px 20px 0 20px;
}
main.dashboard .loan-applications .applications-row .comments ul li,
main.dashboard .eligibility-assessment .applications-row .comments ul li {
  margin-bottom: 20px;
}
main.dashboard .loan-applications .applications-row .comments ul:last-of-type,
main.dashboard .eligibility-assessment .applications-row .comments ul:last-of-type {
  margin-bottom: 0;
}
main.dashboard .loan-applications .applications-row .comments .comments-main,
main.dashboard .eligibility-assessment .applications-row .comments .comments-main {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
main.dashboard .loan-applications .applications-row .comments .message .message-details .user-image,
main.dashboard .eligibility-assessment .applications-row .comments .message .message-details .user-image {
  height: 24px;
  width: 24px;
  background-color: red;
  display: inline-block;
  vertical-align: middle;
  border-radius: 12px;
  background-image: url(img/temp/temp-profile-picture.jpeg);
  background-position: 50% 50%;
  background-size: 24px 24px;
}
main.dashboard .loan-applications .applications-row .comments .message .message-details .user-details,
main.dashboard .eligibility-assessment .applications-row .comments .message .message-details .user-details {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #101922;
}
main.dashboard .loan-applications .applications-row .comments .message .message-details .user-details .date,
main.dashboard .eligibility-assessment .applications-row .comments .message .message-details .user-details .date {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #C8CCD7;
  margin-left: 5px;
}
main.dashboard .loan-applications .applications-row .comments .message .message-content,
main.dashboard .eligibility-assessment .applications-row .comments .message .message-content {
  margin-top: 10px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #575E6C;
  line-height: 1.4;
  background: transparent;
  border: 1px solid #D8DCE7;
  padding: 15px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}
main.dashboard .loan-applications .applications-row .comments .message.latest .message-content,
main.dashboard .eligibility-assessment .applications-row .comments .message.latest .message-content {
  background-color: #fff;
}
main.dashboard .loan-applications .applications-row .comments .comments-nagivation,
main.dashboard .eligibility-assessment .applications-row .comments .comments-nagivation {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: solid 1px #E9EBF1;
}
main.dashboard .loan-applications .applications-row .comments .comments-nagivation .ask-a-question,
main.dashboard .eligibility-assessment .applications-row .comments .comments-nagivation .ask-a-question {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  width: 100%;
  height: 60px;
}
main.dashboard .loan-applications .applications-row .comments .comments-nagivation .ask-a-question span,
main.dashboard .eligibility-assessment .applications-row .comments .comments-nagivation .ask-a-question span {
  color: #2291FF;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  background-image: url(./img/button/right-arrow-blue.png);
  background-repeat: no-repeat;
  background-size: 13px 7px;
  background-position: 100% 50%;
  padding-right: 25px;
}
main.dashboard .loan-tracker table {
  width: 100%;
  border-spacing: 20px;
}
main.dashboard .loan-tracker table tr {
  border-top: solid 1px #E9EBF1;
}
main.dashboard .loan-tracker table tr th {
  border-top: none;
}
main.dashboard .loan-tracker table tr:nth-of-type(1) {
  border-top: none;
}
main.dashboard .loan-tracker table th,
main.dashboard .loan-tracker table td {
  border-collapse: collapse;
  border: solid 1px #E9EBF1;
}
main.dashboard .loan-tracker table th:nth-of-type(1),
main.dashboard .loan-tracker table td:nth-of-type(1) {
  width: 19%;
  border: none;
}
main.dashboard .loan-tracker table th:nth-of-type(2),
main.dashboard .loan-tracker table td:nth-of-type(2) {
  width: 21%;
}
main.dashboard .loan-tracker table th:nth-of-type(3),
main.dashboard .loan-tracker table td:nth-of-type(3) {
  width: 18%;
}
main.dashboard .loan-tracker table th:nth-of-type(4),
main.dashboard .loan-tracker table td:nth-of-type(4) {
  width: 41%;
  border: none;
}
main.dashboard .loan-tracker .app-label th {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 11px;
  padding: 20px;
  color: #727C8F;
}
main.dashboard .loan-tracker .track-row {
  height: 340px;
}
main.dashboard .loan-tracker .track-row td {
  padding: 20px;
}
main.dashboard .loan-tracker .track-row .current-loan {
  position: relative;
}
main.dashboard .loan-tracker .track-row .current-loan .company-logo {
  height: 30px;
  width: 100px;
  display: block;
  margin: 0 auto;
  object-fit: contain;
}
main.dashboard .loan-tracker .track-row .current-loan h6 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  text-align: center;
  margin-top: 10px;
}
main.dashboard .loan-tracker .track-row .current-loan .interest-rate {
  width: 100%;
  padding: 15px 0;
  border-top: solid 1px #E9EBF1;
}
main.dashboard .loan-tracker .track-row .current-loan .interest-rate .variable-rate,
main.dashboard .loan-tracker .track-row .current-loan .interest-rate .comparison-rate {
  display: inline-block;
  width: 49%;
}
main.dashboard .loan-tracker .track-row .current-loan .interest-rate .variable-rate .rate,
main.dashboard .loan-tracker .track-row .current-loan .interest-rate .comparison-rate .rate {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  text-align: center;
}
main.dashboard .loan-tracker .track-row .current-loan .interest-rate .variable-rate .rate-description,
main.dashboard .loan-tracker .track-row .current-loan .interest-rate .comparison-rate .rate-description {
  margin-top: 5px;
  text-align: center;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 10px;
  color: #727C8F;
}
main.dashboard .loan-tracker .track-row .current-loan .more-products {
  border-top: solid 1px #E9EBF1;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  text-align: center;
}
main.dashboard .loan-tracker .track-row .current-loan .more-products span {
  margin-top: 15px;
  display: block;
}
main.dashboard .loan-tracker .track-row .current-loan .more-products .question-mark {
  margin-left: 5px;
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  font-size: 10px;
  background-color: #727C8F;
  font-weight: 600;
  color: #fff;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
}
main.dashboard .loan-tracker .track-row .current-loan .more-products .question-mark:hover {
  text-decoration: none;
  background-color: #223241;
}
main.dashboard .loan-tracker .track-row .current-loan dl {
  align-items: center;
  flex-flow: row wrap;
  margin-bottom: 15px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  width: 100%;
}
main.dashboard .loan-tracker .track-row .current-loan dl:first-of-type {
  border-top: solid 1px #E9EBF1;
  padding-top: 15px;
}
main.dashboard .loan-tracker .track-row .current-loan dl dt {
  display: table-cell;
  font-weight: 600;
  text-align: right;
  width: 80px;
  color: #727C8F;
}
main.dashboard .loan-tracker .track-row .current-loan dl dd {
  display: table-cell;
  padding-left: 10px;
}
main.dashboard .loan-tracker .track-row .current-loan .current-loan-nagivation {
  position: absolute;
  padding-bottom: 20px;
  padding-right: 40px;
  bottom: 0;
  width: 100%;
}
main.dashboard .loan-tracker .track-row .current-loan .current-loan-nagivation .edit {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
  width: 100%;
}
main.dashboard .loan-tracker .track-row .current-loan .current-loan-nagivation .edit:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
main.dashboard .loan-tracker .track-row .current-loan .current-loan-nagivation .edit:active,
main.dashboard .loan-tracker .track-row .current-loan .current-loan-nagivation .edit:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
main.dashboard .loan-tracker .track-row .alternative-product {
  position: relative;
}
main.dashboard .loan-tracker .track-row .alternative-product .company-logo {
  height: 30px;
  width: 100px;
  display: block;
  margin: 0 auto;
  object-fit: contain;
}
main.dashboard .loan-tracker .track-row .alternative-product h6 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  text-align: center;
  margin-top: 10px;
}
main.dashboard .loan-tracker .track-row .alternative-product .interest-rate {
  width: 100%;
  padding: 15px 0;
  border-top: solid 1px #E9EBF1;
}
main.dashboard .loan-tracker .track-row .alternative-product .interest-rate .variable-rate,
main.dashboard .loan-tracker .track-row .alternative-product .interest-rate .comparison-rate {
  display: inline-block;
  width: 49%;
}
main.dashboard .loan-tracker .track-row .alternative-product .interest-rate .variable-rate .rate,
main.dashboard .loan-tracker .track-row .alternative-product .interest-rate .comparison-rate .rate {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  text-align: center;
}
main.dashboard .loan-tracker .track-row .alternative-product .interest-rate .variable-rate .rate-description,
main.dashboard .loan-tracker .track-row .alternative-product .interest-rate .comparison-rate .rate-description {
  margin-top: 5px;
  text-align: center;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 10px;
  color: #727C8F;
}
main.dashboard .loan-tracker .track-row .alternative-product .more-products {
  border-top: solid 1px #E9EBF1;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  text-align: center;
}
main.dashboard .loan-tracker .track-row .alternative-product .more-products span {
  margin-top: 15px;
  display: block;
}
main.dashboard .loan-tracker .track-row .alternative-product .more-products .question-mark {
  margin-left: 5px;
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  font-size: 10px;
  background-color: #727C8F;
  font-weight: 600;
  color: #fff;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
}
main.dashboard .loan-tracker .track-row .alternative-product .more-products .question-mark:hover {
  text-decoration: none;
  background-color: #223241;
}
main.dashboard .loan-tracker .track-row .alternative-product dl {
  align-items: center;
  flex-flow: row wrap;
  margin-bottom: 15px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  width: 100%;
}
main.dashboard .loan-tracker .track-row .alternative-product dl:first-of-type {
  border-top: solid 1px #E9EBF1;
  padding-top: 15px;
}
main.dashboard .loan-tracker .track-row .alternative-product dl dt {
  display: table-cell;
  font-weight: 600;
  text-align: right;
  width: 80px;
  color: #727C8F;
}
main.dashboard .loan-tracker .track-row .alternative-product dl dd {
  display: table-cell;
  padding-left: 10px;
}
main.dashboard .loan-tracker .track-row .alternative-product .product-details-nagivation {
  position: absolute;
  padding-bottom: 20px;
  padding-right: 40px;
  bottom: 0;
  width: 100%;
}
main.dashboard .loan-tracker .track-row .alternative-product .product-details-nagivation .product-details {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
  width: 100%;
}
main.dashboard .loan-tracker .track-row .alternative-product .product-details-nagivation .product-details:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
main.dashboard .loan-tracker .track-row .alternative-product .product-details-nagivation .product-details:active,
main.dashboard .loan-tracker .track-row .alternative-product .product-details-nagivation .product-details:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
main.dashboard .loan-tracker .track-row .new-repayments-savings {
  vertical-align: top;
  position: relative;
  padding-top: 25px;
}
main.dashboard .loan-tracker .track-row .new-repayments-savings .annual-savings,
main.dashboard .loan-tracker .track-row .new-repayments-savings .savings-over-30,
main.dashboard .loan-tracker .track-row .new-repayments-savings .repayments {
  width: 100%;
  margin-bottom: 30px;
}
main.dashboard .loan-tracker .track-row .new-repayments-savings .annual-savings .rate,
main.dashboard .loan-tracker .track-row .new-repayments-savings .savings-over-30 .rate,
main.dashboard .loan-tracker .track-row .new-repayments-savings .repayments .rate {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  text-align: center;
}
main.dashboard .loan-tracker .track-row .new-repayments-savings .annual-savings .rate-description,
main.dashboard .loan-tracker .track-row .new-repayments-savings .savings-over-30 .rate-description,
main.dashboard .loan-tracker .track-row .new-repayments-savings .repayments .rate-description {
  margin-top: 5px;
  text-align: center;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 10px;
  color: #727C8F;
}
main.dashboard .loan-tracker .track-row .new-repayments-savings .repayments-savings-nagivation {
  position: absolute;
  padding-bottom: 20px;
  padding-right: 40px;
  bottom: 0;
  width: 100%;
}
main.dashboard .loan-tracker .track-row .new-repayments-savings .repayments-savings-nagivation .check-eligiblity {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #fff;
  background-color: #2291FF;
  width: 100%;
}
main.dashboard .loan-tracker .track-row .new-repayments-savings .repayments-savings-nagivation .check-eligiblity:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #46A3FF;
}
main.dashboard .loan-tracker .track-row .new-repayments-savings .repayments-savings-nagivation .check-eligiblity:active,
main.dashboard .loan-tracker .track-row .new-repayments-savings .repayments-savings-nagivation .check-eligiblity:focus {
  background-color: #1C83EA;
}
main.dashboard .loan-tracker .track-row .comments {
  vertical-align: top;
  position: relative;
}
main.dashboard .loan-tracker .track-row .comments ul li {
  margin-bottom: 20px;
}
main.dashboard .loan-tracker .track-row .comments ul:last-of-type {
  margin-bottom: 0;
}
main.dashboard .loan-tracker .track-row .comments .comments-main {
  top: 0;
  margin-bottom: 70px;
}
main.dashboard .loan-tracker .track-row .comments .comments-main:last-of-type {
  background-color: red;
}
main.dashboard .loan-tracker .track-row .comments .comments-main .comment-date {
  display: inline-block;
  float: right;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 10px;
  top: 0;
  color: #727C8F;
}
main.dashboard .loan-tracker .track-row .comments .comments-main .comment-author {
  display: inline-block;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
}
main.dashboard .loan-tracker .track-row .comments .comments-main .comment-content {
  margin-top: 20px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  line-height: 1.4;
}
main.dashboard .loan-tracker .track-row .comments .comments-nagivation {
  position: absolute;
  padding-bottom: 20px;
  padding-right: 40px;
  bottom: 0;
  width: 100%;
}
main.dashboard .loan-tracker .track-row .comments .comments-nagivation .ask-a-question {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
  float: right;
}
main.dashboard .loan-tracker .track-row .comments .comments-nagivation .ask-a-question:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
main.dashboard .loan-tracker .track-row .comments .comments-nagivation .ask-a-question:active,
main.dashboard .loan-tracker .track-row .comments .comments-nagivation .ask-a-question:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
main.dashboard .calculator-home .col-m1 {
  display: inline-block;
}
@media (max-width: 950px) {
  main.search-loans {
    padding: 40px 0 230px 0;
  }
}
main.search-loans .main-container {
  overflow: visible;
}
main.search-loans h3 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
}
main.search-loans h3 strong {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
}
main.search-loans .search-filter.open + .note-results {
  margin-top: 0px;
}
main.search-loans .note-results {
  background: transparent;
  border: 1px solid #D8DCE7;
  border-radius: 4px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #575E6C;
  text-align: left;
  margin-top: -25px;
  padding: 8px 15px;
  line-height: 17px;
}
main.search-loans .note-results strong {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
}
main.search-loans .note-results span {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  cursor: pointer;
}
main.search-loans .search-results {
  margin-top: 25px;
  position: relative;
}
@media (max-width: 645px) {
  main.search-loans .search-results {
    margin: 25px -20px 0 -20px;
  }
}
@media (max-width: 480px) {
  main.search-loans .search-results {
    margin: 25px -10px 0 -10px;
  }
}
main.search-loans .search-results .too-many-options {
  position: relative;
  margin: 0 0 -16px;
  display: block;
  width: 100%;
  text-align: center;
  background-image: url(img/page_illustrations/search.png);
  background-size: Calc(100% - 70px) auto;
  background-repeat: no-repeat;
  background-position: 50% 100%;
  padding-top: 20px;
  padding-bottom: 50px;
  overflow: hidden;
  transition: all 1s;
  height: 175px;
}
main.search-loans .search-results .too-many-options h2 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 26px;
  font-size: 24px;
  margin-top: 20px;
}
main.search-loans .search-results .too-many-options .options-container {
  margin-top: 20px;
}
main.search-loans .search-results .too-many-options .options-container button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
}
main.search-loans .search-results .too-many-options .options-container button:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
main.search-loans .search-results .too-many-options .options-container button:active,
main.search-loans .search-results .too-many-options .options-container button:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
main.search-loans .search-results .too-many-options .options-container button:first-of-type {
  margin-right: 20px;
}
main.search-loans .search-results .too-many-options .options-container button.chat span {
  background-image: url(img/button/chat-blue.png);
  background-repeat: no-repeat;
  background-position: 0% 50%;
  background-size: 12px 12px;
  padding-left: 20px;
}
main.search-loans .search-results .too-many-options .close-button {
  position: absolute;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  top: 20px;
  right: 20px;
  display: block;
  background-color: transparent;
  height: 25px;
  width: 25px;
  background-image: url(img/button/modal-close.png);
  background-repeat: no-repeat;
  background-size: 14px 14px;
  background-position: 50% 50%;
}
main.search-loans .search-results .too-many-options .close-button .hide-tips {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #FF4E4C;
  position: absolute;
  top: 29px;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 6px 0 rgba(216, 220, 231, 0.65);
  border-radius: 4px 4px 4px 4px;
  right: -1px;
  min-width: 170px;
  padding: 10px 5px;
  background-color: #fff;
}
main.search-loans .search-results .too-many-options .close-button .hide-tips:before,
main.search-loans .search-results .too-many-options .close-button .hide-tips:after {
  bottom: 100%;
  left: Calc(100% - 12px);
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
main.search-loans .search-results .too-many-options .close-button .hide-tips:before {
  border-color: rgba(216, 220, 231, 0);
  border-bottom-color: #D8DCE7;
  border-width: 7px;
  margin-left: -7px;
}
main.search-loans .search-results .too-many-options .close-button .hide-tips:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 5px;
  margin-left: -5px;
}
main.search-loans .col-m1 {
  border: solid 1px #D8DCE7;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border: 1px solid #D8DCE7;
  display: block;
  width: 100%;
  background-color: #fff;
}
main.search-loans .search-filter {
  margin-top: 30px;
  margin-bottom: 30px;
}
main.search-loans .search-filter .form {
  max-width: none;
}
main.search-loans .search-filter .form label span {
  display: none;
}
main.search-loans .search-filter .form .search-loanAmount,
main.search-loans .search-filter .form .search-purpose,
main.search-loans .search-filter .form .search-repaymentType,
main.search-loans .search-filter .form .search-rateType,
main.search-loans .search-filter .form .search-borrowingPower,
main.search-loans .search-filter .form .filters {
  display: inline-block;
  vertical-align: top;
  margin: 0 17px 0 0;
}
@media (max-width: 1440px) {
  main.search-loans .search-filter .form .search-loanAmount,
  main.search-loans .search-filter .form .search-purpose,
  main.search-loans .search-filter .form .search-repaymentType,
  main.search-loans .search-filter .form .search-rateType,
  main.search-loans .search-filter .form .search-borrowingPower,
  main.search-loans .search-filter .form .filters {
    margin-bottom: 10px;
  }
}
@media (max-width: 645px) {
  main.search-loans .search-filter .form .search-loanAmount,
  main.search-loans .search-filter .form .search-purpose,
  main.search-loans .search-filter .form .search-repaymentType,
  main.search-loans .search-filter .form .search-rateType,
  main.search-loans .search-filter .form .search-borrowingPower,
  main.search-loans .search-filter .form .filters {
    margin: 0 10px 10px 0;
  }
}
main.search-loans .search-filter .form .search-repaymentType {
  width: 280px;
}
main.search-loans .search-filter .form .search-loanAmount {
  width: 134px;
}
@media (max-width: 840px) {
  main.search-loans .search-filter .form .search-loanAmount {
    width: Calc(50% - 90px);
  }
}
@media (max-width: 645px) {
  main.search-loans .search-filter .form .search-loanAmount {
    width: Calc(50% - 83px);
  }
}
@media (max-width: 480px) {
  main.search-loans .search-filter .form .search-loanAmount {
    width: Calc(50% - 55px);
  }
}
@media (max-width: 390px) {
  main.search-loans .search-filter .form .search-loanAmount {
    width: Calc(100% - 200px);
  }
}
main.search-loans .search-filter .form .search-purpose {
  width: 188px;
}
main.search-loans .search-filter .form .search-purpose label .radio-toggle-group-switch {
  flex: 0 0 188px;
}
main.search-loans .search-filter .form .search-rateType {
  width: 113px;
}
@media (max-width: 840px) {
  main.search-loans .search-filter .form .search-rateType {
    width: Calc(50% - 90px);
  }
}
@media (max-width: 645px) {
  main.search-loans .search-filter .form .search-rateType {
    width: Calc(50% - 83px);
  }
}
@media (max-width: 480px) {
  main.search-loans .search-filter .form .search-rateType {
    width: Calc(50% - 56px);
  }
}
@media (max-width: 375px) {
  main.search-loans .search-filter .form .search-rateType {
    width: Calc(50% - 44px);
  }
}
main.search-loans .search-filter .form .search-borrowingPower {
  width: 155px;
}
main.search-loans .search-filter .form .filters {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
  float: right;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  margin-top: 26px;
  height: 40px;
  margin-right: 0;
  float: none;
  padding: 12px 0;
  text-align: center;
  width: 145px;
}
main.search-loans .search-filter .form .filters:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
main.search-loans .search-filter .form .filters:active,
main.search-loans .search-filter .form .filters:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
main.search-loans .search-filter .form .filters span {
  background-image: url(img/button/filter_blue.png);
  background-repeat: no-repeat;
  background-position: 0% 50%;
  background-size: 12px 12px;
  padding-left: 20px;
}
@media (max-width: 480px) {
  main.search-loans .search-filter .form .filters {
    width: 90px;
  }
}
@media (max-width: 390px) {
  main.search-loans .search-filter .form .filters {
    width: 40px;
    overflow: hidden;
    color: transparent;
  }
  main.search-loans .search-filter .form .filters:hover {
    color: transparent;
  }
  main.search-loans .search-filter .form .filters span {
    background-position: 13px 50%;
    overflow: hidden;
  }
}
main.search-loans .search-filter .form .filters.shown {
  border: 1px solid #2291FF;
}
main.search-loans .search-filter .additional-filters {
  margin-top: 20px;
  width: 100%;
  background-color: #F1F3F8;
  border: 1px solid #D8DCE7;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 20px;
}
main.search-loans .search-filter .additional-filters .disable {
  display: none;
}
main.search-loans .search-filter .additional-filters .required-features,
main.search-loans .search-filter .additional-filters .required-loan-types {
  border-bottom: 1px solid #D8DCE7;
}
main.search-loans .search-filter .additional-filters .required-features .checkbox-container,
main.search-loans .search-filter .additional-filters .required-loan-types .checkbox-container {
  margin-top: 20px;
}
main.search-loans .search-filter .additional-filters .required-features .checkbox-option,
main.search-loans .search-filter .additional-filters .required-loan-types .checkbox-option {
  display: inline-block;
  width: Calc(100% / 6);
  min-width: 155px;
  margin-bottom: 20px;
}
@media (max-width: 1440px) {
  main.search-loans .search-filter .additional-filters .required-features .checkbox-option,
  main.search-loans .search-filter .additional-filters .required-loan-types .checkbox-option {
    width: Calc(100% / 4);
  }
}
@media (max-width: 840px) {
  main.search-loans .search-filter .additional-filters .required-features .checkbox-option,
  main.search-loans .search-filter .additional-filters .required-loan-types .checkbox-option {
    width: Calc(100% / 3);
  }
}
@media (max-width: 680px) {
  main.search-loans .search-filter .additional-filters .required-features .checkbox-option,
  main.search-loans .search-filter .additional-filters .required-loan-types .checkbox-option {
    width: 50%;
  }
}
@media (max-width: 480px) {
  main.search-loans .search-filter .additional-filters .required-features .checkbox-option,
  main.search-loans .search-filter .additional-filters .required-loan-types .checkbox-option {
    width: 100%;
  }
}
main.search-loans .search-filter .additional-filters .required-features h5,
main.search-loans .search-filter .additional-filters .required-loan-types h5 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  margin-top: 20px;
}
main.search-loans .search-filter .additional-filters .required-features h5 span,
main.search-loans .search-filter .additional-filters .required-loan-types h5 span {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #2291FF;
  cursor: pointer;
  margin-left: 20px;
}
main.search-loans .search-filter .additional-filters .required-features h5 span:hover,
main.search-loans .search-filter .additional-filters .required-loan-types h5 span:hover {
  color: #46A3FF;
}
main.search-loans .search-filter .additional-filters .required-features h5 span:active,
main.search-loans .search-filter .additional-filters .required-loan-types h5 span:active,
main.search-loans .search-filter .additional-filters .required-features h5 span:focus,
main.search-loans .search-filter .additional-filters .required-loan-types h5 span:focus {
  color: #1C83EA;
}
main.search-loans .search-filter .additional-filters .required-features h5 {
  margin-top: 0;
}
main.search-loans .search-filter .additional-filters .misc-filters h5 {
  display: none;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  margin-top: 20px;
}
main.search-loans .search-filter .additional-filters .disabled,
main.search-loans .search-filter .additional-filters .active {
  display: none;
}
main.search-loans .search-filter .additional-filters.condensed {
  background-color: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
}
main.search-loans .search-filter .additional-filters.condensed .active {
  display: inline-block;
}
main.search-loans .search-filter .additional-filters.condensed .required-features,
main.search-loans .search-filter .additional-filters.condensed .required-loan-types,
main.search-loans .search-filter .additional-filters.condensed .misc-filters {
  border: none;
}
main.search-loans .search-filter .additional-filters.condensed .required-features .checkbox-container,
main.search-loans .search-filter .additional-filters.condensed .required-loan-types .checkbox-container,
main.search-loans .search-filter .additional-filters.condensed .misc-filters .checkbox-container {
  margin-top: 10px;
}
main.search-loans .search-filter .additional-filters.condensed .required-features .select,
main.search-loans .search-filter .additional-filters.condensed .required-loan-types .select,
main.search-loans .search-filter .additional-filters.condensed .misc-filters .select {
  display: none;
}
main.search-loans .search-filter .additional-filters.condensed .required-features .checkbox-option,
main.search-loans .search-filter .additional-filters.condensed .required-loan-types .checkbox-option,
main.search-loans .search-filter .additional-filters.condensed .misc-filters .checkbox-option {
  width: inherit;
  min-width: inherit;
  margin: 0;
}
main.search-loans .search-filter .additional-filters.condensed .required-features .checkbox-option input,
main.search-loans .search-filter .additional-filters.condensed .required-loan-types .checkbox-option input,
main.search-loans .search-filter .additional-filters.condensed .misc-filters .checkbox-option input {
  display: none;
}
main.search-loans .search-filter .additional-filters.condensed .required-features .checkbox-option input:not(:checked) + label,
main.search-loans .search-filter .additional-filters.condensed .required-loan-types .checkbox-option input:not(:checked) + label,
main.search-loans .search-filter .additional-filters.condensed .misc-filters .checkbox-option input:not(:checked) + label {
  display: none;
}
main.search-loans .search-filter .additional-filters.condensed .required-features .checkbox-option label,
main.search-loans .search-filter .additional-filters.condensed .required-loan-types .checkbox-option label,
main.search-loans .search-filter .additional-filters.condensed .misc-filters .checkbox-option label {
  margin-right: 5px;
  margin-bottom: 5px;
  display: inline-block;
  background-color: #FFFFFF;
  background-image: url(img/button/modal-close.png);
  background-size: 10px 10px;
  background-position: Calc(100% - 10px) 50%;
  background-repeat: no-repeat;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #101922;
  padding: 7px 30px 7px 10px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}
main.search-loans .search-filter .additional-filters.condensed .misc-filters h5 {
  display: block;
}
main.search-loans .search-filter .additional-filters.condensed .misc-filters .condensed-button {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  margin-right: 5px;
  margin-bottom: 5px;
  background-color: #FFFFFF;
  background-image: url(img/button/modal-close.png);
  background-size: 10px 10px;
  background-position: Calc(100% - 10px) 50%;
  background-repeat: no-repeat;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #101922;
  padding: 7px 30px 7px 10px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}
main.search-loans .search-filter .additional-filters.condensed h5 span {
  display: none;
}
main.search-loans .search-filter .additional-filters.condensed .update-filters {
  display: none;
}
main.search-loans .search-filter .misc-filters {
  margin-top: 5px;
}
main.search-loans .search-filter .misc-filters .search-loanPurpose,
main.search-loans .search-filter .misc-filters .search-lenderFilter,
main.search-loans .search-filter .misc-filters .search-repaymentFrequency,
main.search-loans .search-filter .misc-filters .search-nicheLending,
main.search-loans .search-filter .misc-filters .search-fixedTerm,
main.search-loans .search-filter .misc-filters .user_meta-searchFilter_bank {
  margin-right: 20px;
  margin-bottom: 0;
}
main.search-loans .search-filter .misc-filters .search-loanPurpose {
  width: 220px;
}
main.search-loans .search-filter .misc-filters .search-lenderFilter {
  width: 220px !important;
}
main.search-loans .search-filter .misc-filters .search-fixedTerm {
  width: 220px !important;
}
main.search-loans .search-filter .misc-filters .search-repaymentFrequency {
  width: 220px !important;
}
main.search-loans .search-filter .misc-filters .search-nicheLending {
  width: 350px;
}
main.search-loans .search-filter .misc-filters .user_meta-searchFilter_bank,
main.search-loans .search-filter .misc-filters .search-repaymentFrequency {
  width: 300px;
}
@media (max-width: 950px) {
  main.search-loans .search-filter .misc-filters .user_meta-searchFilter_bank,
  main.search-loans .search-filter .misc-filters .search-repaymentFrequency {
    width: calc(50% - 20px);
  }
}
@media (max-width: 480px) {
  main.search-loans .search-filter .misc-filters .user_meta-searchFilter_bank,
  main.search-loans .search-filter .misc-filters .search-repaymentFrequency {
    width: 100%;
  }
}
main.search-loans .search-filter .update-filters {
  margin-top: 27px;
  float: right;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 14px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  background-color: #26BD27;
  padding: 10px 10px 10px 10px;
  color: #fff;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  height: 40px;
}
main.search-loans .search-filter .update-filters:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #29CA2A;
}
main.search-loans .search-filter .update-filters:active,
main.search-loans .search-filter .update-filters:focus {
  background-color: #23B024;
}
main.search-loans .search-filter .search-loanAmount,
main.search-loans .search-filter .search-borrowingPower {
  position: relative;
}
main.search-loans .search-filter span.orange-excl {
  margin-left: 5px;
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  font-size: 10px;
  background-color: #727C8F;
  font-weight: 600;
  color: #fff;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  background-color: #F5A623;
  margin-left: 10px;
  font-weight: 900;
  z-index: 99;
  margin-right: 10px;
  pointer-events: none;
  height: 14px;
  width: 14px;
  line-height: 14px;
  font-size: 8px;
  vertical-align: middle;
}
main.search-loans .search-filter span.orange-excl:hover {
  text-decoration: none;
  background-color: #223241;
}
main.search-loans .search-filter .loan-amount,
main.search-loans .search-filter .borrowing-capacity {
  height: 40px;
  border: 1px solid #D8DCE7;
  padding-left: 15px;
  line-height: 40px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  background-repeat: no-repeat;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
}
main.search-loans .search-filter .borrowing-capacity {
  background-image: url(img/button/calculator_blue.png);
  background-size: 14px 16px;
  background-position: Calc(100% - 15px) 50%;
  padding-right: 30px;
}
main.search-loans .search-filter .borrowing-capacity .overflow-span {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: block;
}
main.search-loans .search-filter .borrowing-capacity span.orange-excl {
  transform: translateY(-1px);
}
main.search-loans .search-filter .borrowing-capacity.selected {
  background-color: #FBFBFC;
}
main.search-loans .search-filter .borrowing-capacity .small-orange {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  line-height: 14px;
  color: #D78600;
  margin-bottom: 2px;
  display: inline-block;
}
main.search-loans .search-filter .borrowing-capacity .small-orange + .orange-excl {
  margin-left: 4px;
}
main.search-loans .search-filter .loan-amount {
  background-image: url(img/button/down-blue.png);
  background-size: 10px 6px;
  background-position: Calc(100% - 15px) 50%;
}
main.search-loans .search-filter .loan-amount.selected {
  background-color: #FBFBFC;
  background-image: url(img/button/up-blue.png);
}
main.search-loans .search-filter .loan-amount-overlay,
main.search-loans .search-filter .borrowing-capacity-overlay {
  z-index: 100;
  position: absolute;
  top: 75px;
  background: #FBFBFC;
  border: 1px solid #D9DCE7;
  border-radius: 4px;
  padding: 20px;
  transition: ease-in-out 0.2s;
}
main.search-loans .search-filter .loan-amount-overlay {
  border-top-left-radius: 0;
  box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.1);
  left: 0;
  width: 300px;
}
@media (max-width: 840px) {
  main.search-loans .search-filter .loan-amount-overlay {
    border-top-left-radius: 4px;
  }
}
main.search-loans .search-filter .loan-amount-overlay:before {
  content: '';
  position: absolute;
  width: 134px;
  background-color: #FBFBFC;
  border-left: 1px solid #D9DCE7;
  border-right: 1px solid #D9DCE7;
  left: -1px;
  right: -1px;
  top: -14px;
  height: 14px;
}
@media (max-width: 840px) {
  main.search-loans .search-filter .loan-amount-overlay:before {
    display: none;
  }
}
main.search-loans .search-filter .loan-amount-overlay .modal .text,
main.search-loans .search-filter .loan-amount-overlay .modal .number,
main.search-loans .search-filter .loan-amount-overlay .modal .lvr,
main.search-loans .search-filter .loan-amount-overlay .modal .select {
  margin: 0;
  margin-bottom: 15px;
}
main.search-loans .search-filter .loan-amount-overlay .modal .text label,
main.search-loans .search-filter .loan-amount-overlay .modal .number label,
main.search-loans .search-filter .loan-amount-overlay .modal .lvr label,
main.search-loans .search-filter .loan-amount-overlay .modal .select label,
main.search-loans .search-filter .loan-amount-overlay .modal .text .field,
main.search-loans .search-filter .loan-amount-overlay .modal .number .field,
main.search-loans .search-filter .loan-amount-overlay .modal .lvr .field,
main.search-loans .search-filter .loan-amount-overlay .modal .select .field {
  display: inline-block;
  width: 100px;
  vertical-align: middle;
}
main.search-loans .search-filter .loan-amount-overlay .modal .text .field,
main.search-loans .search-filter .loan-amount-overlay .modal .number .field,
main.search-loans .search-filter .loan-amount-overlay .modal .lvr .field,
main.search-loans .search-filter .loan-amount-overlay .modal .select .field {
  width: Calc(100% - 120px);
}
main.search-loans .search-filter .loan-amount-overlay .modal .text label,
main.search-loans .search-filter .loan-amount-overlay .modal .number label,
main.search-loans .search-filter .loan-amount-overlay .modal .lvr label,
main.search-loans .search-filter .loan-amount-overlay .modal .select label {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  text-align: right;
  margin: 0;
  margin-bottom: 2px;
  margin-right: 20px;
}
main.search-loans .search-filter .loan-amount-overlay .modal .text label + div,
main.search-loans .search-filter .loan-amount-overlay .modal .number label + div,
main.search-loans .search-filter .loan-amount-overlay .modal .lvr label + div,
main.search-loans .search-filter .loan-amount-overlay .modal .select label + div {
  display: inline;
}
main.search-loans .search-filter .loan-amount-overlay .modal .text .bottom,
main.search-loans .search-filter .loan-amount-overlay .modal .number .bottom,
main.search-loans .search-filter .loan-amount-overlay .modal .lvr .bottom,
main.search-loans .search-filter .loan-amount-overlay .modal .select .bottom {
  display: none;
}
main.search-loans .search-filter .loan-amount-overlay .modal .lvr .value {
  display: inline-block;
  vertical-align: middle;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  width: Calc(100% - 120px);
}
main.search-loans .search-filter .loan-amount-overlay .modal .lvr .value .warning {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  margin-top: 5px;
  display: block;
}
main.search-loans .search-filter .loan-amount-overlay .modal .lvr.warning {
  color: #DD4A40;
}
main.search-loans .search-filter .loan-amount-overlay .modal .lvr.warning .info {
  background-color: #DD4A40;
}
main.search-loans .search-filter .loan-amount-overlay .modal .lvr.warning .value {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
main.search-loans .search-filter .loan-amount-overlay .modal .number .field {
  width: 50px;
}
main.search-loans .search-filter .loan-amount-overlay .modal .number .field:after {
  content: 'Years';
  left: 60px;
  height: 40px;
  line-height: 40px;
  position: absolute;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #2291FF;
}
main.search-loans .search-filter .loan-amount-overlay .modal .select {
  margin: 0;
}
main.search-loans .search-filter .borrowing-capacity-overlay {
  border-top-right-radius: 0;
  box-shadow: -3px 3px 4px 0 rgba(0, 0, 0, 0.1);
  right: 0;
  width: 320px;
}
@media (max-width: 1104px) {
  main.search-loans .search-filter .borrowing-capacity-overlay {
    left: 0;
    box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.1);
  }
  main.search-loans .search-filter .borrowing-capacity-overlay:before {
    left: -1px;
  }
}
@media (max-width: 840px) {
  main.search-loans .search-filter .borrowing-capacity-overlay {
    border-top-right-radius: 4px;
  }
}
@media (max-width: 527px) {
  main.search-loans .search-filter .borrowing-capacity-overlay {
    right: 0;
    left: auto;
    box-shadow: -3px 3px 4px 0 rgba(0, 0, 0, 0.1);
  }
}
@media (max-width: 494px) {
  main.search-loans .search-filter .borrowing-capacity-overlay {
    left: 0;
    box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.1);
    right: auto;
  }
}
main.search-loans .search-filter .borrowing-capacity-overlay:before {
  content: '';
  position: absolute;
  width: 155px;
  background-color: #FBFBFC;
  border-left: 1px solid #D9DCE7;
  border-right: 1px solid #D9DCE7;
  right: -1px;
  top: -14px;
  height: 14px;
}
@media (max-width: 840px) {
  main.search-loans .search-filter .borrowing-capacity-overlay:before {
    display: none;
  }
}
main.search-loans .search-filter .borrowing-capacity-overlay .modal .content {
  text-align: center;
}
main.search-loans .search-filter .borrowing-capacity-overlay .modal .content h2 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  margin-bottom: 15px;
}
main.search-loans .search-filter .borrowing-capacity-overlay .modal .content .value {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  margin-bottom: 15px;
}
main.search-loans .search-filter .borrowing-capacity-overlay .modal .content .value .number {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 21px;
  color: #2291FF;
}
main.search-loans .search-filter .borrowing-capacity-overlay .modal .content .value .to {
  margin: 0 8px;
}
main.search-loans .search-filter .borrowing-capacity-overlay .modal .content .additional-info {
  margin-bottom: 20px;
}
main.search-loans .search-filter .borrowing-capacity-overlay .modal .content .additional-info.small {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 11px;
  line-height: 1.2;
}
main.search-loans .search-filter .borrowing-capacity-overlay .modal .content .additional-info.orange {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #F5A623;
}
main.search-loans .search-filter .borrowing-capacity-overlay .modal .content .additional-info.blue {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  color: #2291FF;
  line-height: 1.2;
}
main.search-loans .search-filter .borrowing-capacity-overlay .modal .content button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  padding: 12px 20px;
}
main.search-loans .search-filter .borrowing-capacity-overlay .modal .content button:hover {
  background-color: #46A3FF;
}
main.search-loans .search-filter .borrowing-capacity-overlay .modal .content button:active,
main.search-loans .search-filter .borrowing-capacity-overlay .modal .content button:focus {
  background-color: #1C83EA;
}
main.search-loans .search-filter .borrowing-capacity-overlay .modal .content button:disabled,
main.search-loans .search-filter .borrowing-capacity-overlay .modal .content button.disabled {
  background: #909090;
}
main.search-loans .search-filter .borrowing-capacity-overlay .modal .content .mandatories {
  margin-top: 15px;
  text-align: left;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 10px;
  color: #727C8F;
  line-height: 1.2;
}
main.search-loans .references ol {
  border-top: solid 1px #E9EBF1;
  margin-top: 30px;
  padding-top: 10px;
}
main.search-loans .references ol li {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #727C8F;
  margin-top: 8px;
  line-height: 1.2;
}
main.search-loans .references strong {
  font-weight: bolder;
}
main.search-loans .load-more {
  margin-top: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
  width: 100%;
  color: #2689EB;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  border: solid 1px #E9EBF1;
}
main.search-loans .load-more:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
main.search-loans .load-more:active,
main.search-loans .load-more:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
main.search-loans .empty {
  text-align: center;
  margin-bottom: 100px;
  margin-top: 70px;
}
main.search-loans .empty img {
  width: 450px;
  height: 210px;
  object-fit: contain;
  margin: 0 auto;
}
@media (max-width: 645px) {
  main.search-loans .empty img {
    width: 80%;
    height: auto;
  }
}
main.search-loans .empty h4 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  margin-top: 30px;
}
main.search-loans .empty p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  margin-top: 15px;
}
main.search-loans .empty p a {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #2291FF;
}
main.loan-shortlist {
  overflow-y: auto;
}
main.loan-shortlist button.criteria {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
  float: right;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
main.loan-shortlist button.criteria:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
main.loan-shortlist button.criteria:active,
main.loan-shortlist button.criteria:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
main.loan-shortlist button.criteria span {
  background-image: url(img/button/filter_blue.png);
  background-repeat: no-repeat;
  background-position: 0% 50%;
  background-size: 12px 12px;
  padding-left: 20px;
}
main.loan-shortlist .loan-shortlist {
  margin-top: 40px;
}
main.loan-shortlist .loan-shortlist ::-webkit-scrollbar {
  height: 14px;
}
main.loan-shortlist .loan-shortlist ::-webkit-scrollbar-track {
  background: #E9EBF1;
  border-radius: 12px;
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
}
main.loan-shortlist .loan-shortlist ::-webkit-scrollbar-thumb {
  border: solid 3px #E9EBF1;
  background: #888;
  border-radius: 12px;
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
}
main.loan-shortlist .loan-shortlist ::-webkit-scrollbar-thumb:hover {
  background: #626B7B;
}
main.loan-shortlist .loan-shortlist .right-container {
  vertical-align: top;
  display: inline-block;
  height: 810px;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  box-shadow: 0 9px 0px 0px #f6f6fa, 0 -9px 0px 0px #f6f6fa, -20px 0px 20px -28px rgba(0, 0, 0, 0.1), 20px 0px 20px -28px rgba(0, 0, 0, 0.1);
}
main.loan-shortlist .loan-shortlist .right-container .scroll-container {
  height: 100%;
  width: 1820px;
}
main.loan-shortlist .loan-shortlist .right-container .best {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  height: 26px;
  padding: 0 20px;
  background-color: #27C229;
  border-bottom: 1px solid #24ab26;
  width: 180px;
  color: #fff;
  padding-top: 7px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  text-align: center;
  display: block;
  margin: -46px auto 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
main.loan-shortlist .loan-shortlist .right-container .shortlist-result .show-more {
  display: none;
}
main.loan-shortlist .loan-shortlist .right-container .shortlist-result:last-of-type {
  margin-right: 0;
}
main.loan-shortlist .empty {
  text-align: center;
  margin-bottom: 100px;
}
main.loan-shortlist .empty .illustration {
  background-image: url(img/page_illustrations/search_empty.png);
  width: 450px;
  height: 210px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  margin: 0 auto;
  margin-top: 100px;
}
main.loan-shortlist .empty h4 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  margin-top: 40px;
}
main.loan-shortlist .empty p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  margin-top: 12px;
}
main.loan-shortlist .empty p a {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #2291FF;
}
main.loan-shortlist .tooltip-container.shortlist {
  width: 100%;
  display: block;
  margin: 15px 0 -35px 0;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  color: #727C8F;
  position: relative;
  height: 25px;
  line-height: 25px;
}
main.loan-shortlist .tooltip-container.shortlist span {
  background-image: url(img/tooltips/chart.png);
  background-size: 15px 14px;
  background-position: 0% 50%;
  background-repeat: no-repeat;
  padding-left: 35px;
}
main.product-details {
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
main.product-details .sticky-padding.enabled {
  padding-bottom: 350px;
}
main.product-details .back-container {
  width: 100%;
}
main.product-details .back-container .go-back {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
  padding: 5px 16px 5px 30px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  margin-left: 40px;
  background-image: url(img/button/left-arrow-short-blue.png);
  background-size: 6px 8px;
  background-repeat: no-repeat;
  background-position: 10px 50%;
  margin-left: 0 !important;
}
main.product-details .back-container .go-back:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
main.product-details .back-container .go-back:active,
main.product-details .back-container .go-back:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
main.product-details .back-container .go-back.top {
  margin: 10px 0 10px 40px;
}
main.product-details .top-container {
  background-color: #fff;
  border-bottom: solid 1px #E9EBF1;
}
main.product-details .top-container .shortlist {
  float: right;
}
main.product-details .top-container .shortlist p,
main.product-details .top-container .shortlist .favourites {
  display: inline-block;
  vertical-align: middle;
}
main.product-details .top-container .shortlist p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 10px;
  text-align: right;
  margin-right: 10px;
}
main.product-details .top-container .shortlist .favourites {
  display: inline-block;
  vertical-align: middle;
}
main.product-details .top-container .featured-details {
  max-width: 1180px;
  padding: 20px 40px;
  margin: 0 auto;
}
main.product-details .top-container .featured-details .heading {
  margin-bottom: 15px;
}
main.product-details .top-container .featured-details .heading .logo,
main.product-details .top-container .featured-details .heading h1,
main.product-details .top-container .featured-details .heading .shortlist {
  display: inline-block;
  vertical-align: top;
}
main.product-details .top-container .featured-details .heading .logo {
  max-width: 105px;
  height: 38px;
  object-fit: contain;
}
main.product-details .top-container .featured-details .heading h1 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  margin-left: 40px;
  max-width: Calc(100% - 450px);
  line-height: 1.2;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-top: 5px;
}
main.product-details .top-container .featured-details .statistics .variable-rate,
main.product-details .top-container .featured-details .statistics .comparison-rate,
main.product-details .top-container .featured-details .statistics .repayment,
main.product-details .top-container .featured-details .statistics .loan-amount {
  margin-right: 20px;
  display: inline-block;
  vertical-align: top;
  border: solid 1px #E9EBF1;
  width: Calc(25% - 15px);
  height: 100px;
  overflow: hidden;
}
@media (max-width: 1440px) {
  main.product-details .top-container .featured-details .statistics .variable-rate,
  main.product-details .top-container .featured-details .statistics .comparison-rate,
  main.product-details .top-container .featured-details .statistics .repayment,
  main.product-details .top-container .featured-details .statistics .loan-amount {
    height: 130px;
  }
}
main.product-details .top-container .featured-details .statistics .variable-rate:last-of-type,
main.product-details .top-container .featured-details .statistics .comparison-rate:last-of-type,
main.product-details .top-container .featured-details .statistics .repayment:last-of-type,
main.product-details .top-container .featured-details .statistics .loan-amount:last-of-type {
  margin-right: 0;
}
main.product-details .top-container .featured-details .statistics .variable-rate .rate-container,
main.product-details .top-container .featured-details .statistics .comparison-rate .rate-container,
main.product-details .top-container .featured-details .statistics .repayment .rate-container,
main.product-details .top-container .featured-details .statistics .loan-amount .rate-container {
  top: 50%;
  position: relative;
  transform: translate3d(0, -50%, 0);
  text-align: left;
  background-size: 30px 30px;
  background-repeat: no-repeat;
  background-position: 0% 50%;
  padding-left: 50px;
  margin-left: 35px;
}
@media (max-width: 1440px) {
  main.product-details .top-container .featured-details .statistics .variable-rate .rate-container,
  main.product-details .top-container .featured-details .statistics .comparison-rate .rate-container,
  main.product-details .top-container .featured-details .statistics .repayment .rate-container,
  main.product-details .top-container .featured-details .statistics .loan-amount .rate-container {
    padding-left: 0;
    background-position: 50% 0;
    padding: 45px 0 0 0;
    margin: 0;
    text-align: center;
  }
}
main.product-details .top-container .featured-details .statistics .variable-rate .rate-container .value,
main.product-details .top-container .featured-details .statistics .comparison-rate .rate-container .value,
main.product-details .top-container .featured-details .statistics .repayment .rate-container .value,
main.product-details .top-container .featured-details .statistics .loan-amount .rate-container .value {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 26px;
}
main.product-details .top-container .featured-details .statistics .variable-rate .rate-container .value span,
main.product-details .top-container .featured-details .statistics .comparison-rate .rate-container .value span,
main.product-details .top-container .featured-details .statistics .repayment .rate-container .value span,
main.product-details .top-container .featured-details .statistics .loan-amount .rate-container .value span {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #727C8F;
}
main.product-details .top-container .featured-details .statistics .variable-rate .rate-container .value-description,
main.product-details .top-container .featured-details .statistics .comparison-rate .rate-container .value-description,
main.product-details .top-container .featured-details .statistics .repayment .rate-container .value-description,
main.product-details .top-container .featured-details .statistics .loan-amount .rate-container .value-description {
  margin-top: 5px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #727C8F;
}
main.product-details .top-container .featured-details .statistics .variable-rate > .rate-container {
  background-image: url(img/elements/variable-rate.png);
}
main.product-details .top-container .featured-details .statistics .comparison-rate > .rate-container {
  background-image: url(img/elements/comparison-rate.png);
}
main.product-details .top-container .featured-details .statistics .repayment > .rate-container {
  background-image: url(img/elements/repayment.png);
}
main.product-details .top-container .featured-details .statistics .loan-amount > .rate-container {
  background-image: url(img/elements/loan-amount.png);
}
main.product-details .top-container .featured-details .navigation {
  margin: 20px 0;
}
main.product-details .top-container .featured-details .navigation .left,
main.product-details .top-container .featured-details .navigation .middle,
main.product-details .top-container .featured-details .navigation .right {
  vertical-align: top;
  display: inline-block;
}
main.product-details .top-container .featured-details .navigation .left,
main.product-details .top-container .featured-details .navigation .right {
  width: Calc(25% - 15px);
}
main.product-details .top-container .featured-details .navigation .left {
  margin-right: 20px;
}
main.product-details .top-container .featured-details .navigation .middle {
  width: Calc(50% - 10px);
  margin-right: 20px;
}
main.product-details .top-container .featured-details .navigation .get-advice {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}
main.product-details .top-container .featured-details .navigation .get-advice:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
main.product-details .top-container .featured-details .navigation .get-advice:active,
main.product-details .top-container .featured-details .navigation .get-advice:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
main.product-details .top-container .featured-details .navigation .get-advice span {
  background-image: url(img/button/right-arrow-short-blue.png);
  background-size: 7px 12px;
  background-repeat: no-repeat;
  background-position: 100% 50%;
  padding-right: 25px;
}
main.product-details .top-container .featured-details .navigation .check-eligiblity {
  text-align: right;
}
main.product-details .top-container .featured-details .navigation .check-eligiblity .check-eligiblity-button {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  padding: 14px 0;
  min-width: 180px;
  margin: 0;
  padding: 13px 0;
}
main.product-details .top-container .featured-details .navigation .check-eligiblity .modal-a-link {
  width: 180px;
  text-align: right;
  margin-top: 10px;
  display: inline-block;
  text-align: center;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #2291FF;
  cursor: pointer;
}
main.product-details .top-container .featured-details .navigation .check-eligiblity .favourites {
  display: none;
}
main.product-details .top-container .featured-details .navigation .additional-information {
  border: solid 1px #E9EBF1;
  width: auto;
  min-height: 46px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 10px 0;
}
main.product-details .top-container .featured-details .navigation .additional-information .annual-fee,
main.product-details .top-container .featured-details .navigation .additional-information .upfront-fee,
main.product-details .top-container .featured-details .navigation .additional-information .borrowing-power {
  display: inline-block;
  vertical-align: top;
  line-height: 25px;
  padding: 0 10px;
  text-align: center;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
main.product-details .top-container .featured-details .navigation .additional-information .annual-fee span,
main.product-details .top-container .featured-details .navigation .additional-information .upfront-fee span,
main.product-details .top-container .featured-details .navigation .additional-information .borrowing-power span {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  text-align: middle;
}
main.product-details .top-container .featured-details .navigation .additional-information .annual-fee span.bolder,
main.product-details .top-container .featured-details .navigation .additional-information .upfront-fee span.bolder,
main.product-details .top-container .featured-details .navigation .additional-information .borrowing-power span.bolder {
  font-weight: bolder;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
main.product-details .top-container .featured-details .navigation .additional-information .annual-fee:last-of-type,
main.product-details .top-container .featured-details .navigation .additional-information .upfront-fee:last-of-type,
main.product-details .top-container .featured-details .navigation .additional-information .borrowing-power:last-of-type {
  border-right: none;
  margin-right: 0;
}
main.product-details .top-container .featured-details .navigation .additional-information .annual-fee {
  width: 30%;
}
main.product-details .top-container .featured-details .navigation .additional-information .upfront-fee {
  width: 40%;
  border-right: solid 1px #E9EBF1;
  border-left: solid 1px #E9EBF1;
}
main.product-details .top-container .featured-details .navigation .additional-information .borrowing-power {
  width: 30%;
}
main.product-details .top-container .featured-details .navigation .additional-information .borrowing-power .borrowing-value {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
main.product-details .top-container.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  border-top: none;
  display: flex;
  align-items: center;
  z-index: 100;
}
main.product-details .top-container.sticky .featured-details {
  width: 1180px;
}
main.product-details .top-container.sticky .shortlist {
  margin-left: 10px;
}
main.product-details .top-container.sticky .shortlist p {
  display: none;
}
main.product-details .top-container.sticky .heading {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
  width: Calc(100% - 550px);
  margin-right: 10px;
}
main.product-details .top-container.sticky .heading .logo {
  width: 100px;
  height: 30px;
  min-height: 0;
  vertical-align: middle;
}
main.product-details .top-container.sticky .heading h1 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  line-height: 1.2;
  margin-right: 0;
  margin-left: 20px;
  width: Calc(100% - 120px);
  max-width: none;
  vertical-align: middle;
}
main.product-details .top-container.sticky .statistics {
  display: inline-block;
  vertical-align: middle;
  max-width: 420px;
  overflow: auto;
  margin-top: 4px;
}
main.product-details .top-container.sticky .statistics .variable-rate,
main.product-details .top-container.sticky .statistics .comparison-rate,
main.product-details .top-container.sticky .statistics .repayment,
main.product-details .top-container.sticky .statistics .loan-amount {
  margin: 0;
  border: none;
  width: auto;
  height: auto;
  margin-left: 10px;
}
main.product-details .top-container.sticky .statistics .variable-rate .rate-container,
main.product-details .top-container.sticky .statistics .comparison-rate .rate-container,
main.product-details .top-container.sticky .statistics .repayment .rate-container,
main.product-details .top-container.sticky .statistics .loan-amount .rate-container {
  position: inherit;
  transform: none;
  background-image: none;
  top: inherit;
  padding: 0;
  margin: 0;
}
main.product-details .top-container.sticky .statistics .variable-rate .rate-container .value,
main.product-details .top-container.sticky .statistics .comparison-rate .rate-container .value,
main.product-details .top-container.sticky .statistics .repayment .rate-container .value,
main.product-details .top-container.sticky .statistics .loan-amount .rate-container .value {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}
main.product-details .top-container.sticky .statistics .variable-rate .rate-container .value span,
main.product-details .top-container.sticky .statistics .comparison-rate .rate-container .value span,
main.product-details .top-container.sticky .statistics .repayment .rate-container .value span,
main.product-details .top-container.sticky .statistics .loan-amount .rate-container .value span {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 10px;
  margin-left: 4px;
}
main.product-details .top-container.sticky .statistics .variable-rate .rate-container .value-description,
main.product-details .top-container.sticky .statistics .comparison-rate .rate-container .value-description,
main.product-details .top-container.sticky .statistics .repayment .rate-container .value-description,
main.product-details .top-container.sticky .statistics .loan-amount .rate-container .value-description {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 10px;
  margin-top: 2px;
}
main.product-details .top-container.sticky .navigation {
  margin: 0;
  float: right;
}
main.product-details .top-container.sticky .navigation .get-advice,
main.product-details .top-container.sticky .navigation .additional-information {
  display: none;
}
main.product-details .top-container.sticky .navigation .left,
main.product-details .top-container.sticky .navigation .middle {
  display: none;
}
main.product-details .top-container.sticky .navigation .right {
  display: block;
  width: auto;
}
main.product-details .top-container.sticky .navigation .check-eligiblity {
  float: none;
}
main.product-details .top-container.sticky .navigation .check-eligiblity .check-eligiblity-button {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  padding: 9px 0;
  width: 120px;
  min-width: auto;
}
main.product-details .top-container.sticky .navigation .check-eligiblity .modal-a-link {
  display: none;
}
main.product-details .top-container.sticky .navigation .favourites {
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
}
main.product-details .content-wrapper {
  max-width: 1100px;
  margin: 20px auto 40px auto;
}
@media (max-width: 1210px) {
  main.product-details .content-wrapper {
    margin: 40px;
  }
}
main.product-details .content {
  background-color: #fff;
  width: 100%;
  border: 1px solid #D8DCE7;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  color: #223241;
}
main.product-details .content h3 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  color: #101922;
  margin-bottom: 20px;
}
main.product-details .content .content-toggle-box {
  margin-top: 30px;
}
main.product-details .content p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  line-height: 1.4;
}
main.product-details .content p strong {
  font-weight: bolder;
}
main.product-details .content .key-details,
main.product-details .content .features,
main.product-details .content .loan-types,
main.product-details .content .fee-detail,
main.product-details .content .loan-amounts-and-lending-ratios,
main.product-details .content .account-access-methods,
main.product-details .content .repayment-frequency,
main.product-details .content .key-notes,
main.product-details .content .about,
main.product-details .content .navigation {
  border-bottom: solid 1px #E9EBF1;
  padding: 40px;
}
main.product-details .content .left-side,
main.product-details .content .right-side {
  width: Calc(50% - 50px);
  display: inline-block;
  vertical-align: top;
}
@media (max-width: 1210px) {
  main.product-details .content .left-side,
  main.product-details .content .right-side {
    width: 100%;
  }
}
main.product-details .content .left-side {
  margin-right: 100px;
}
main.product-details .content .key-details .bank {
  margin-bottom: 10px;
}
main.product-details .content .key-details .bank img,
main.product-details .content .key-details .bank p {
  display: inline-block;
  vertical-align: top;
}
main.product-details .content .key-details .bank img {
  max-width: 135px;
  width: 135px;
  max-height: 60px;
  object-fit: contain;
}
main.product-details .content .key-details .list-details {
  width: Calc(100% - 135px);
  display: inline-block;
  vertical-align: top;
}
main.product-details .content .key-details .list-details li {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  padding: 0 5px;
}
main.product-details .content .key-details .list-details li span {
  display: block;
}
main.product-details .content .key-details .list-details li .title {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #727C8F;
  margin-bottom: 10px;
}
main.product-details .content .key-details .list-details li .value {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
}
main.product-details .content .key-details .list-details li .subtitle {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  margin-top: 5px;
}
main.product-details .content .key-details .list-details.five li {
  width: 20%;
  min-height: 80px;
}
@media (max-width: 1210px) {
  main.product-details .content .key-details .list-details.five li {
    width: 18%;
    padding-bottom: 25px;
  }
}
@media (max-width: 1210px) {
  main.product-details .content .key-details .list-details.five li:nth-of-type(1),
  main.product-details .content .key-details .list-details.five li:nth-of-type(2),
  main.product-details .content .key-details .list-details.five li:nth-of-type(3),
  main.product-details .content .key-details .list-details.five li:nth-of-type(4) {
    width: 20%;
  }
}
main.product-details .content .key-details .list-details.four li {
  width: 25%;
  min-height: 80px;
}
main.product-details .content .key-details .list-details + h3 {
  margin-top: 30px;
}
main.product-details .content .key-details .lender-description {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}
@media (max-width: 1210px) {
  main.product-details .content .features .left-side,
  main.product-details .content .loan-types .left-side,
  main.product-details .content .repayment-frequency .left-side {
    margin-bottom: 40px;
  }
}
main.product-details .content .features ul,
main.product-details .content .loan-types ul,
main.product-details .content .repayment-frequency ul {
  margin-top: 30px;
}
main.product-details .content .features ul li,
main.product-details .content .loan-types ul li,
main.product-details .content .repayment-frequency ul li {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  margin-bottom: 30px;
  padding-left: 30px;
  background-repeat: no-repeat;
  background-position: 0% 50%;
  background-size: 12px 12px;
  background-image: url(img/elements/cross-light-blue.png);
  color: #D8DCE7;
}
main.product-details .content .features ul li:last-of-type,
main.product-details .content .loan-types ul li:last-of-type,
main.product-details .content .repayment-frequency ul li:last-of-type {
  margin-bottom: 0;
}
main.product-details .content .features ul li.available,
main.product-details .content .loan-types ul li.available,
main.product-details .content .repayment-frequency ul li.available {
  background-size: 16px 12px;
  background-image: url(img/elements/check-fancy-green.png);
  color: #101922;
  text-decoration: none;
}
main.product-details .content .features ul li.additional,
main.product-details .content .loan-types ul li.additional,
main.product-details .content .repayment-frequency ul li.additional {
  color: #101922;
  text-decoration: none;
  background-image: url(img/elements/money_blue.png);
  background-size: 10px 17px;
  background-position: 0px 50%;
}
main.product-details .content .features .column-1,
main.product-details .content .loan-types .column-1,
main.product-details .content .repayment-frequency .column-1,
main.product-details .content .features .column-2,
main.product-details .content .loan-types .column-2,
main.product-details .content .repayment-frequency .column-2 {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}
main.product-details .content .fee-detail p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  margin-bottom: 20px;
}
@media (max-width: 1210px) {
  main.product-details .content .fee-detail .upfront-fees,
  main.product-details .content .fee-detail .ongoing-fees,
  main.product-details .content .fee-detail .exit-fees {
    margin-bottom: 30px;
  }
}
main.product-details .content .fee-detail .exit-fees {
  margin-bottom: 0;
}
main.product-details .content .fee-detail h4 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
}
main.product-details .content .fee-detail .fees-calculations {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
}
main.product-details .content .fee-detail .fees-calculations ul {
  background-color: #F6F7FB;
  padding: 10px 20px;
}
main.product-details .content .fee-detail .fees-calculations ul li {
  border-bottom: solid 1px #E9EBF1;
  padding: 9px 0;
}
main.product-details .content .fee-detail .fees-calculations ul li:last-of-type {
  border-bottom: none;
}
main.product-details .content .fee-detail .fees-calculations span {
  float: right;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
}
main.product-details .content .fee-detail .fees-calculations p {
  margin-top: 15px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #575E6C;
}
main.product-details .content .fee-detail .total {
  margin-top: 20px;
  text-align: right;
}
main.product-details .content .loan-amounts-and-lending-ratios h4 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
}
main.product-details .content .loan-amounts-and-lending-ratios dl {
  align-items: center;
  flex-flow: row wrap;
  margin-bottom: 7px;
  color: #223241;
}
main.product-details .content .loan-amounts-and-lending-ratios dl dt {
  display: table-cell;
  min-width: 180px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
main.product-details .content .loan-amounts-and-lending-ratios dl dd {
  display: table-cell;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
main.product-details .content .loan-amounts-and-lending-ratios .loan-amounts,
main.product-details .content .loan-amounts-and-lending-ratios .loan-to-value-ratio {
  display: inline-block;
  vertical-align: top;
}
@media (max-width: 1210px) {
  main.product-details .content .loan-amounts-and-lending-ratios .loan-amounts,
  main.product-details .content .loan-amounts-and-lending-ratios .loan-to-value-ratio {
    margin-bottom: 30px;
  }
}
main.product-details .content .loan-amounts-and-lending-ratios .loan-to-value-ratio {
  margin-bottom: 0;
}
main.product-details .content .loan-amounts-and-lending-ratios .loan-to-value-ratio p {
  margin-top: 10px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #575E6C;
}
main.product-details .content .loan-amounts-and-lending-ratios .column-1,
main.product-details .content .loan-amounts-and-lending-ratios .column-2 {
  display: inline-block;
  vertical-align: top;
}
main.product-details .content .loan-amounts-and-lending-ratios .column-1 dl dt {
  min-width: 140px;
}
main.product-details .content .loan-amounts-and-lending-ratios .column-2 {
  margin-left: 40px;
}
main.product-details .content .loan-amounts-and-lending-ratios .column-2 dl dt {
  min-width: 170px;
}
main.product-details .content .navigation .get-advice {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}
main.product-details .content .navigation .get-advice:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
main.product-details .content .navigation .get-advice:active,
main.product-details .content .navigation .get-advice:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
main.product-details .content .navigation .get-advice span {
  background-image: url(img/button/right-arrow-short-blue.png);
  background-size: 7px 12px;
  background-repeat: no-repeat;
  background-position: 100% 50%;
  padding-right: 25px;
}
main.product-details .content .navigation .check-eligiblity {
  float: right;
}
main.product-details .content .navigation .check-eligiblity .check-eligiblity-button {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  padding: 14px 0;
  width: 180px;
}
main.product-details .content .navigation .check-eligiblity .modal-a-link {
  margin-top: 10px;
  display: block;
  text-align: center;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #2291FF;
  cursor: pointer;
}
main.product-details .content ul > li.invalid {
  text-decoration: line-through;
  color: #D8DCE7;
  background-image: url(img/elements/cross-light-blue.png);
  background-size: 12px 12px;
  background-position: 2px 50%;
}
main.product-details .references {
  max-width: 1100px;
  margin: 40px auto;
  margin-bottom: 50px;
}
@media (max-width: 1210px) {
  main.product-details .references {
    margin: 40px;
  }
}
main.product-details .references ol {
  border-top: solid 1px #E9EBF1;
  margin-top: 30px;
  padding-top: 10px;
}
main.product-details .references ol li {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #727C8F;
  margin-top: 8px;
  line-height: 1.2;
}
main.product-details .references strong {
  font-weight: bolder;
}
main.user-authentication {
  left: 0;
  overflow: auto;
}
@media (max-width: 480px) {
  main.user-authentication {
    padding: 20px 10px 100px 10px;
  }
}
main.user-authentication .modal {
  margin: 0 auto;
  background: #FFFFFF;
  border: 1px solid #D8DCE7;
  width: 800px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  position: relative;
  display: table;
}
@media (max-width: 840px) {
  main.user-authentication .modal {
    width: 100%;
  }
}
main.user-authentication .modal .loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 5;
}
main.user-authentication .modal .loading img {
  display: block;
  height: 40px;
  width: 40px;
  margin: 0 auto;
  top: 50%;
  position: relative;
  transform: translate3d(0, -50%, 0);
}
main.user-authentication .modal .loading .loader2 {
  position: relative;
  top: calc(50% - 30px);
}
main.user-authentication .modal .loading.show {
  display: block;
}
main.user-authentication .modal .left-image,
main.user-authentication .modal .right-content {
  display: table-cell;
  vertical-align: top;
}
main.user-authentication .modal .left-image {
  position: relative;
  width: 42.5%;
  height: 100%;
  background-image: url(img/temp/photo-1519159407671-cecc744eb049.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
@media (max-width: 840px) {
  main.user-authentication .modal .left-image {
    display: none;
  }
}
main.user-authentication .modal .left-image .quote {
  position: absolute;
  bottom: 0;
  padding: 0 25px 50px 25px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-style: italic;
  line-height: 1.5;
  color: #fff;
}
main.user-authentication .modal .left-image .quote .quote-symbol {
  display: inline-block;
  vertical-align: top;
  width: 10%;
  font-size: 44px;
  margin-top: -13px;
}
main.user-authentication .modal .left-image .quote .quote-content {
  display: inline-block;
  vertical-align: top;
  width: 90%;
}
main.user-authentication .modal .left-image .quote span {
  margin-top: 15px;
  display: block;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
main.user-authentication .modal .left-image .quote strong {
  font-weight: bolder;
}
main.user-authentication .modal .right-content {
  padding: 40px;
  width: 57.5%;
}
@media (max-width: 840px) {
  main.user-authentication .modal .right-content {
    width: 100%;
  }
}
@media (max-width: 480px) {
  main.user-authentication .modal .right-content {
    padding: 30px 20px;
  }
}
main.user-authentication .modal .right-content .alternative-signin .google,
main.user-authentication .modal .right-content .alternative-signin .facebook {
  width: Calc(50% - 10px);
  display: inline-block;
}
@media (max-width: 480px) {
  main.user-authentication .modal .right-content .alternative-signin .google,
  main.user-authentication .modal .right-content .alternative-signin .facebook {
    width: 100%;
    margin: 0 0 10px 0;
  }
}
main.user-authentication .modal .right-content .alternative-signin .google {
  margin-right: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  padding: 13px 15px 13px 40px;
  background-color: #DD4A40;
  background-image: url(img/button/logo_google.png);
  background-repeat: no-repeat;
  background-size: 14px 14px;
  background-position: 20px 50%;
}
main.user-authentication .modal .right-content .alternative-signin .google:hover {
  background-color: #46A3FF;
}
main.user-authentication .modal .right-content .alternative-signin .google:active,
main.user-authentication .modal .right-content .alternative-signin .google:focus {
  background-color: #1C83EA;
}
main.user-authentication .modal .right-content .alternative-signin .google:disabled,
main.user-authentication .modal .right-content .alternative-signin .google.disabled {
  background: #909090;
}
main.user-authentication .modal .right-content .alternative-signin .google:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #E6574D;
}
main.user-authentication .modal .right-content .alternative-signin .google:active,
main.user-authentication .modal .right-content .alternative-signin .google:focus {
  background-color: #C63C32;
}
main.user-authentication .modal .right-content .alternative-signin .facebook {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  padding: 13px 15px 13px 40px;
  background-color: #4267B2;
  background-image: url(img/button/logo_fb.png);
  background-repeat: no-repeat;
  background-size: 9px 17px;
  background-position: 20px 50%;
}
main.user-authentication .modal .right-content .alternative-signin .facebook:hover {
  background-color: #46A3FF;
}
main.user-authentication .modal .right-content .alternative-signin .facebook:active,
main.user-authentication .modal .right-content .alternative-signin .facebook:focus {
  background-color: #1C83EA;
}
main.user-authentication .modal .right-content .alternative-signin .facebook:disabled,
main.user-authentication .modal .right-content .alternative-signin .facebook.disabled {
  background: #909090;
}
main.user-authentication .modal .right-content .alternative-signin .facebook:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #5077C5;
}
main.user-authentication .modal .right-content .alternative-signin .facebook:active,
main.user-authentication .modal .right-content .alternative-signin .facebook:focus {
  background-color: #36589D;
}
@media (max-width: 480px) {
  main.user-authentication .modal .right-content .alternative-signin .facebook {
    width: 100%;
    margin: 0;
  }
}
main.user-authentication .modal .right-content .forgot-password {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #727C8F;
  display: inline-block;
  float: right;
  vertical-align: top;
  margin-bottom: 0;
  margin-top: -15px;
  position: relative;
  bottom: 6px;
}
main.user-authentication .modal .right-content .forgot-password:hover {
  color: #A3A8B2;
  text-decoration: underline;
}
main.user-authentication .modal .right-content .forgot-password:active,
main.user-authentication .modal .right-content .forgot-password:focus {
  color: #575E6C;
}
main.user-authentication .modal .skip-password .skip-password-text,
main.user-authentication .modal .access-code .skip-password-text,
main.user-authentication .modal .skip-password .email-secure-link,
main.user-authentication .modal .access-code .email-secure-link,
main.user-authentication .modal .skip-password .access-code-text,
main.user-authentication .modal .access-code .access-code-text,
main.user-authentication .modal .skip-password .access-link,
main.user-authentication .modal .access-code .access-link {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}
@media (max-width: 480px) {
  main.user-authentication .modal .skip-password .skip-password-text,
  main.user-authentication .modal .access-code .skip-password-text,
  main.user-authentication .modal .skip-password .email-secure-link,
  main.user-authentication .modal .access-code .email-secure-link,
  main.user-authentication .modal .skip-password .access-code-text,
  main.user-authentication .modal .access-code .access-code-text,
  main.user-authentication .modal .skip-password .access-link,
  main.user-authentication .modal .access-code .access-link {
    width: 100%;
  }
}
main.user-authentication .modal .skip-password .skip-password-text,
main.user-authentication .modal .access-code .skip-password-text,
main.user-authentication .modal .skip-password .access-code-text,
main.user-authentication .modal .access-code .access-code-text {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  line-height: 1.4;
}
@media (max-width: 480px) {
  main.user-authentication .modal .skip-password .skip-password-text,
  main.user-authentication .modal .access-code .skip-password-text,
  main.user-authentication .modal .skip-password .access-code-text,
  main.user-authentication .modal .access-code .access-code-text {
    text-align: center;
    margin-bottom: 10px;
  }
}
main.user-authentication .modal .skip-password .skip-password-text span,
main.user-authentication .modal .access-code .skip-password-text span,
main.user-authentication .modal .skip-password .access-code-text span,
main.user-authentication .modal .access-code .access-code-text span {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  display: block;
}
main.user-authentication .modal .skip-password .access-code-text span,
main.user-authentication .modal .access-code .access-code-text span {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}
main.user-authentication .modal .skip-password .email-secure-link,
main.user-authentication .modal .access-code .email-secure-link {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  padding: 12px 21px;
  text-align: center;
}
main.user-authentication .modal .skip-password .email-secure-link:hover,
main.user-authentication .modal .access-code .email-secure-link:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
main.user-authentication .modal .skip-password .email-secure-link:active,
main.user-authentication .modal .access-code .email-secure-link:active,
main.user-authentication .modal .skip-password .email-secure-link:focus,
main.user-authentication .modal .access-code .email-secure-link:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
main.user-authentication .modal .skip-password .email-secure-link span,
main.user-authentication .modal .access-code .email-secure-link span {
  background-image: url(img/button/magic_black.png);
  background-repeat: no-repeat;
  background-position: 0% 50%;
  background-size: 12px 12px;
  padding-left: 20px;
}
main.user-authentication .modal .skip-password .access-link,
main.user-authentication .modal .access-code .access-link {
  float: right;
  width: 40%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 14px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  background-color: #26BD27;
  padding: 10px 10px 10px 10px;
  color: #fff;
  background-color: #727C8F;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  padding: 10px 20px;
  margin-top: 25px;
}
main.user-authentication .modal .skip-password .access-link:hover,
main.user-authentication .modal .access-code .access-link:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #29CA2A;
}
main.user-authentication .modal .skip-password .access-link:active,
main.user-authentication .modal .access-code .access-link:active,
main.user-authentication .modal .skip-password .access-link:focus,
main.user-authentication .modal .access-code .access-link:focus {
  background-color: #23B024;
}
main.user-authentication .modal .skip-password .access-link:hover,
main.user-authentication .modal .access-code .access-link:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #A3A8B2;
}
main.user-authentication .modal .skip-password .access-link:active,
main.user-authentication .modal .access-code .access-link:active,
main.user-authentication .modal .skip-password .access-link:focus,
main.user-authentication .modal .access-code .access-link:focus {
  background-color: #575E6C;
}
main.user-authentication .modal .or-divider {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #B3B7C2;
  text-align: center;
  margin: 25px 0;
  font-style: italic;
}
main.user-authentication .modal .or-divider span {
  background-color: #fff;
  width: 100px;
  display: inline-block;
}
main.user-authentication .modal .or-divider hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #B3B7C2;
  padding: 0;
  margin-top: -8px;
}
main.user-authentication .modal .navigation {
  color: #727C8F;
  text-align: center;
  margin-top: 20px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  line-height: 1.4;
}
main.user-authentication .modal .navigation a {
  color: #2291FF;
  cursor: pointer;
}
main.user-authentication .modal .navigation a:hover {
  color: #46A3FF;
}
main.user-authentication .modal .navigation a:active,
main.user-authentication .modal .navigation a:focus {
  color: #1C83EA;
}
main.user-authentication .modal .navigation.back a {
  color: #727C8F;
}
main.user-authentication .modal .navigation.back a:hover {
  color: #798DA0;
}
main.user-authentication .modal .navigation.back a:active,
main.user-authentication .modal .navigation.back a:focus {
  color: #2291FF;
}
main.user-authentication .modal h2 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 22px;
}
main.user-authentication .modal h4 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #727C8F;
  margin-top: 15px;
  line-height: 1.3;
}
main.user-authentication .modal h4 span {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}
main.user-authentication .modal form {
  margin-top: 25px;
}
main.user-authentication .modal form .w100p,
main.user-authentication .modal form .w50p {
  margin: 10px 0px 10px 0px;
}
main.user-authentication .modal form label {
  display: block;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  margin-bottom: 10px;
}
main.user-authentication .modal form input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border: 1px solid #D8DCE7;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  padding-left: 10px;
}
main.user-authentication .modal form button[type=submit],
main.user-authentication .modal form .full-width-button {
  margin-top: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  width: 100%;
  padding: 10px 50px 10px 50px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
main.user-authentication .modal form button[type=submit]:hover,
main.user-authentication .modal form .full-width-button:hover {
  background-color: #46A3FF;
}
main.user-authentication .modal form button[type=submit]:active,
main.user-authentication .modal form .full-width-button:active,
main.user-authentication .modal form button[type=submit]:focus,
main.user-authentication .modal form .full-width-button:focus {
  background-color: #1C83EA;
}
main.user-authentication .modal form button[type=submit]:disabled,
main.user-authentication .modal form .full-width-button:disabled,
main.user-authentication .modal form button[type=submit].disabled,
main.user-authentication .modal form .full-width-button.disabled {
  background: #909090;
}
@media (max-width: 480px) {
  main.user-authentication .modal form button[type=submit],
  main.user-authentication .modal form .full-width-button {
    padding: 10px;
  }
}
main.user-authentication .modal .validation {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: red;
  margin-top: 8px;
}
main.user-authentication .modal .validation a {
  color: red;
}
main.user-authentication .modal .validation a:hover {
  text-decoration: underline;
}
main.user-authentication .modal hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #E9EBF1;
  padding: 0;
  margin: 20px 0;
}
main.user-authentication .single {
  padding: 40px;
  width: 460px;
  height: auto;
}
@media (max-width: 500px) {
  main.user-authentication .single {
    width: 100%;
    padding: 30px 20px;
  }
}
main.user-authentication .single h2 {
  text-align: center;
}
main.user-authentication .single h4 {
  text-align: center;
}
main.user-authentication.sign-up .modal {
  height: 700px;
}
main.user-authentication.sign-up .modal .left-image {
  background-size: 115%;
}
main.user-authentication.sign-up form .user-firstName {
  width: Calc(50% - 10px);
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
}
@media (max-width: 480px) {
  main.user-authentication.sign-up form .user-firstName {
    width: 100%;
    margin-right: 0;
  }
}
main.user-authentication.sign-up form .user-lastName {
  width: Calc(50% - 10px);
  display: inline-block;
  vertical-align: top;
}
@media (max-width: 480px) {
  main.user-authentication.sign-up form .user-lastName {
    width: 100%;
  }
}
main.check-eligiblity p {
  color: #606877;
}
main.check-eligiblity p.under-heading {
  margin-top: -30px;
  margin-bottom: 30px;
}
main.check-eligiblity .bottom {
  color: #606877;
}
main.check-eligiblity .text-area {
  margin-bottom: 35px;
}
main.check-eligiblity .extra-margin {
  height: 13px;
  width: 500px;
}
main.check-eligiblity .w280 {
  margin-bottom: 20px !important;
  margin-top: 0 !important;
}
main.check-eligiblity .w580px {
  margin-bottom: 20px !important;
  margin-top: 0 !important;
}
main.check-eligiblity .w580 {
  margin-bottom: 20px !important;
  margin-top: 0 !important;
}
main.check-eligiblity .box {
  margin-bottom: 20px !important;
}
main.check-eligiblity .form-h3 {
  margin: 15px 0 15px 0 !important;
}
main.check-eligiblity .form-h4 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
main.check-eligiblity .form-h4 h4 {
  font-size: 17px;
}
main.check-eligiblity .bottom {
  margin-bottom: 0;
  margin-top: 0 !important;
  padding-top: 8px;
}
main.check-eligiblity .form-navigation {
  margin-top: 35px !important;
  margin-bottom: 40px;
}
main.check-eligiblity .fade-in {
  width: 100%;
}
main.check-eligiblity .four-box {
  padding-top: 10px;
  padding-bottom: 10px;
}
main.check-eligiblity .four-box .box:nth-of-type(3),
main.check-eligiblity .four-box .box:nth-of-type(1) {
  margin-right: 20px;
}
main.check-eligiblity .four-box .field-error-message {
  margin-top: -20px;
}
main.check-eligiblity .form-introduction {
  background-color: #fff;
  border: 1px solid #D8DCE7;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 40px;
}
main.check-eligiblity .form-introduction h1 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  color: #101922;
  margin-bottom: 20px;
}
main.check-eligiblity .form-introduction .details {
  border-bottom: 1px solid #D8DCE7;
  padding-bottom: 40px;
}
main.check-eligiblity .form-introduction .details .eligibility-assessment {
  background-size: 30px 30px;
  background-repeat: no-repeat;
  background-image: url(img/elements/eligibility-badge.png);
  background-position: 0% 50%;
  padding-left: 60px;
  line-height: 30px;
  margin-bottom: 20px;
}
main.check-eligiblity .form-introduction .details .details-content,
main.check-eligiblity .form-introduction .details .quick-details {
  vertical-align: top;
  display: inline-block;
}
@media (max-width: 1210px) {
  main.check-eligiblity .form-introduction .details .details-content,
  main.check-eligiblity .form-introduction .details .quick-details {
    display: block;
  }
}
main.check-eligiblity .form-introduction .details .details-content {
  width: Calc(100% - 390px);
}
@media (max-width: 1210px) {
  main.check-eligiblity .form-introduction .details .details-content {
    width: 100%;
  }
}
main.check-eligiblity .form-introduction .details .details-content p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  line-height: 1.6;
  color: #223241;
  margin-bottom: 20px;
}
main.check-eligiblity .form-introduction .details .details-content p strong {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
main.check-eligiblity .form-introduction .details .quick-details {
  width: 290px;
  margin-left: 100px;
}
@media (max-width: 1210px) {
  main.check-eligiblity .form-introduction .details .quick-details {
    width: 100%;
    margin-left: 0;
  }
}
main.check-eligiblity .form-introduction .details .quick-details .time,
main.check-eligiblity .form-introduction .details .quick-details .need,
main.check-eligiblity .form-introduction .details .quick-details .security {
  margin-bottom: 25px;
}
main.check-eligiblity .form-introduction .details .quick-details .time .quick-details-content,
main.check-eligiblity .form-introduction .details .quick-details .need .quick-details-content,
main.check-eligiblity .form-introduction .details .quick-details .security .quick-details-content {
  background-size: 20px 20px;
  background-position: 0% 0%;
  background-repeat: no-repeat;
  padding-left: 40px;
}
main.check-eligiblity .form-introduction .details .quick-details .time .quick-details-content h3,
main.check-eligiblity .form-introduction .details .quick-details .need .quick-details-content h3,
main.check-eligiblity .form-introduction .details .quick-details .security .quick-details-content h3 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #223241;
  margin-bottom: 5px;
}
main.check-eligiblity .form-introduction .details .quick-details .time .quick-details-content p,
main.check-eligiblity .form-introduction .details .quick-details .need .quick-details-content p,
main.check-eligiblity .form-introduction .details .quick-details .security .quick-details-content p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #223241;
  line-height: 1.4;
}
main.check-eligiblity .form-introduction .details .quick-details .time .quick-details-content {
  background-image: url(img/icons/eligibility-clock.png);
}
main.check-eligiblity .form-introduction .details .quick-details .need .quick-details-content {
  background-size: 18px 20px;
  background-image: url(img/icons/eligibility-file.png);
}
main.check-eligiblity .form-introduction .details .quick-details .security .quick-details-content {
  background-size: 18px 27px;
  background-image: url(img/icons/eligibility-lock.png);
}
main.check-eligiblity .form-introduction .details .quick-details .security {
  margin-bottom: 0;
}
main.check-eligiblity .form-introduction .details .details-navigation {
  max-width: Calc(100% - 390px);
}
@media (max-width: 1210px) {
  main.check-eligiblity .form-introduction .details .details-navigation {
    margin-top: 40px;
    max-width: none;
  }
}
main.check-eligiblity .form-introduction .details .details-navigation .do-it,
main.check-eligiblity .form-introduction .details .details-navigation .assistance {
  vertical-align: top;
  display: inline-block;
  width: Calc(50% - 10px);
}
main.check-eligiblity .form-introduction .details .details-navigation .do-it {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  background-color: #26BD27;
  padding: 10px 10px 10px 10px;
  color: #fff;
  margin-right: 20px;
  padding: 10px 0;
}
main.check-eligiblity .form-introduction .details .details-navigation .do-it:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #29CA2A;
}
main.check-eligiblity .form-introduction .details .details-navigation .do-it:active,
main.check-eligiblity .form-introduction .details .details-navigation .do-it:focus {
  background-color: #23B024;
}
main.check-eligiblity .form-introduction .details .details-navigation .assistance {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 25px 13px 25px;
  color: #26BD27;
  background-color: Transparent;
  border: solid 1px #26BD27;
  padding: 10px 0;
}
main.check-eligiblity .form-introduction .details .details-navigation .assistance:hover {
  color: #29CA2A;
  border: solid 1px #29CA2A;
}
main.check-eligiblity .form-introduction .details .details-navigation .assistance:active,
main.check-eligiblity .form-introduction .details .details-navigation .assistance:focus {
  border: solid 1px #23B024;
}
main.check-eligiblity .form-introduction .selected-products {
  padding: 40px 0 0 0;
  border-bottom: 1px solid #D8DCE7;
}
main.check-eligiblity .form-introduction .selected-products .selected-products-content p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  line-height: 1.6;
  color: #223241;
  margin-bottom: 20px;
  max-width: 580px;
}
main.check-eligiblity .form-introduction .selected-products .selected-products-content p strong {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
main.check-eligiblity .form-introduction .selected-products .products-content .product-select {
  margin-right: 40px;
  margin-bottom: 40px;
}
main.check-eligiblity .form-introduction .selected-products .products-content .product-select:after {
  position: absolute;
  top: -10px;
  right: -10px;
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #26BD27;
  background-image: url(img/elements/check-white.png);
  background-size: 10px 8px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  z-index: 20;
}
main.check-eligiblity .form-introduction .selected-products .products-content .product-select.last-of-type {
  margin-right: 0;
}
main.check-eligiblity .form-introduction .steps {
  padding-top: 40px;
}
main.check-eligiblity .form-introduction .steps h1 {
  margin-bottom: 30px;
}
main.check-eligiblity .form-introduction .steps .confirm-eligibility,
main.check-eligiblity .form-introduction .steps .confirm-apply,
main.check-eligiblity .form-introduction .steps .settlement {
  display: inline-block;
  vertical-align: top;
  width: Calc(33.33% - 12px);
  margin-right: 18px;
  background-position: 0% 0%;
  background-repeat: no-repeat;
  padding-top: 70px;
}
main.check-eligiblity .form-introduction .steps .confirm-eligibility:last-of-type,
main.check-eligiblity .form-introduction .steps .confirm-apply:last-of-type,
main.check-eligiblity .form-introduction .steps .settlement:last-of-type {
  margin-right: 0;
}
main.check-eligiblity .form-introduction .steps .confirm-eligibility h2,
main.check-eligiblity .form-introduction .steps .confirm-apply h2,
main.check-eligiblity .form-introduction .steps .settlement h2 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #223241;
  margin-bottom: 10px;
}
main.check-eligiblity .form-introduction .steps .confirm-eligibility p,
main.check-eligiblity .form-introduction .steps .confirm-apply p,
main.check-eligiblity .form-introduction .steps .settlement p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #223241;
  line-height: 1.4;
}
main.check-eligiblity .form-introduction .steps .confirm-eligibility {
  background-size: 330px 48px;
  background-image: url(img/elements/eligibility-confirm.png);
}
main.check-eligiblity .form-introduction .steps .confirm-apply {
  background-size: 330px 48px;
  background-image: url(img/elements/eligibility-apply.png);
}
main.check-eligiblity .form-introduction .steps .settlement {
  background-size: 48px 48px;
  background-image: url(img/elements/eligibility-settlement.png);
}
main.check-eligiblity .your-details .left,
main.check-eligiblity .your-details .right {
  display: inline-block;
  vertical-align: top;
  margin-top: 40px;
}
@media (max-width: 1210px) {
  main.check-eligiblity .your-details .left,
  main.check-eligiblity .your-details .right {
    display: block;
  }
}
main.check-eligiblity .your-details .left {
  width: 665px;
  margin-right: 40px;
}
@media (max-width: 1210px) {
  main.check-eligiblity .your-details .left {
    width: 100%;
  }
}
main.check-eligiblity .your-details .right {
  width: Calc(100% - 705px);
}
@media (max-width: 1210px) {
  main.check-eligiblity .your-details .right {
    width: 100%;
  }
}
@media (max-width: 1210px) {
  main.check-eligiblity .your-details .intro-text {
    width: 100%;
  }
}
main.check-eligiblity .your-details .intro-text h1 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 22px;
  margin-bottom: 10px;
}
main.check-eligiblity .your-details .intro-text p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #727C8F;
  line-height: 1.3;
}
main.check-eligiblity .your-details .security-box {
  background-color: #fff;
  border: 1px solid #D8DCE7;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}
main.check-eligiblity .your-details .security-box p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  line-height: 1.2;
  color: #223241;
  background-image: url(img/elements/lock-yellow.png);
  background-size: 16px 24px;
  background-position: 20px 50%;
  padding-left: 40px;
  background-repeat: no-repeat;
  padding: 20px 20px 20px 60px;
}
main.check-eligiblity .your-details .security-box p span {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #2291FF;
}
main.check-eligiblity .your-details .content {
  background-color: #fff;
  padding: 40px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border: solid 1px #E9EBF1;
  width: 100%;
}
main.check-eligiblity .your-details .content .update-start {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  width: 100%;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
main.check-eligiblity .your-details .content .update-start:hover {
  background-color: #46A3FF;
}
main.check-eligiblity .your-details .content .update-start:active,
main.check-eligiblity .your-details .content .update-start:focus {
  background-color: #1C83EA;
}
main.check-eligiblity .your-details .content .update-start:disabled,
main.check-eligiblity .your-details .content .update-start.disabled {
  background: #909090;
}
main.check-eligiblity .your-details .content hr {
  margin-top: 20px;
}
main.check-eligiblity .your-details img {
  object-fit: contain;
  width: 100%;
  margin-top: 40px;
}
@media (max-width: 1210px) {
  main.check-eligiblity .your-details img {
    width: 300px;
    margin: 40px auto 0 auto;
    display: block;
  }
}
main.check-eligiblity .your-details .alternative-signin {
  margin-top: 20px;
}
main.check-eligiblity .your-details .alternative-signin .social-media {
  margin: 0 auto;
  width: 365px;
}
main.check-eligiblity .your-details .alternative-signin .social-media .google,
main.check-eligiblity .your-details .alternative-signin .social-media .facebook {
  display: inline-block;
}
main.check-eligiblity .your-details .alternative-signin .social-media .google {
  margin-right: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  padding: 13px 15px 13px 40px;
  background-color: #DD4A40;
  background-image: url(img/button/logo_google.png);
  background-repeat: no-repeat;
  background-size: 14px 14px;
  background-position: 20px 50%;
  width: 165px;
}
main.check-eligiblity .your-details .alternative-signin .social-media .google:hover {
  background-color: #46A3FF;
}
main.check-eligiblity .your-details .alternative-signin .social-media .google:active,
main.check-eligiblity .your-details .alternative-signin .social-media .google:focus {
  background-color: #1C83EA;
}
main.check-eligiblity .your-details .alternative-signin .social-media .google:disabled,
main.check-eligiblity .your-details .alternative-signin .social-media .google.disabled {
  background: #909090;
}
main.check-eligiblity .your-details .alternative-signin .social-media .google:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #E6574D;
}
main.check-eligiblity .your-details .alternative-signin .social-media .google:active,
main.check-eligiblity .your-details .alternative-signin .social-media .google:focus {
  background-color: #C63C32;
}
main.check-eligiblity .your-details .alternative-signin .social-media .facebook {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  padding: 13px 15px 13px 40px;
  background-color: #4267B2;
  background-image: url(img/button/logo_fb.png);
  background-repeat: no-repeat;
  background-size: 9px 17px;
  background-position: 20px 50%;
  width: 180px;
}
main.check-eligiblity .your-details .alternative-signin .social-media .facebook:hover {
  background-color: #46A3FF;
}
main.check-eligiblity .your-details .alternative-signin .social-media .facebook:active,
main.check-eligiblity .your-details .alternative-signin .social-media .facebook:focus {
  background-color: #1C83EA;
}
main.check-eligiblity .your-details .alternative-signin .social-media .facebook:disabled,
main.check-eligiblity .your-details .alternative-signin .social-media .facebook.disabled {
  background: #909090;
}
main.check-eligiblity .your-details .alternative-signin .social-media .facebook:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #5077C5;
}
main.check-eligiblity .your-details .alternative-signin .social-media .facebook:active,
main.check-eligiblity .your-details .alternative-signin .social-media .facebook:focus {
  background-color: #36589D;
}
main.check-eligiblity .your-details .alternative-signin .or-divider {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #B3B7C2;
  text-align: center;
  margin: 25px 0;
  font-style: italic;
}
main.check-eligiblity .your-details .alternative-signin .or-divider span {
  background-color: #fff;
  width: 100px;
  display: inline-block;
}
main.check-eligiblity .your-details .alternative-signin .or-divider hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #B3B7C2;
  padding: 0;
  margin-top: -8px;
}
main.check-eligiblity .total-monthly-expenses h4 {
  display: inline;
}
main.check-eligiblity .total-monthly-expenses .totals {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  float: right;
}
main.check-eligiblity .form-navigation {
  margin-top: 40px;
  padding-top: 20px;
  max-width: 580px;
  border-top: 1px solid #D8DCE7;
}
@media (max-width: 1210px) {
  main.check-eligiblity .form-navigation {
    max-width: none;
    width: 100%;
  }
}
main.check-eligiblity .form-navigation .previous-step,
main.check-eligiblity .form-navigation .next-step {
  display: inline-block;
  vertical-align: top;
  width: 280px;
  margin-top: 20px;
}
main.check-eligiblity .form-navigation .next-step {
  float: right;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  padding: 10px 50px 10px 50px;
}
main.check-eligiblity .form-navigation .next-step:hover {
  background-color: #46A3FF;
}
main.check-eligiblity .form-navigation .next-step:active,
main.check-eligiblity .form-navigation .next-step:focus {
  background-color: #1C83EA;
}
main.check-eligiblity .form-navigation .next-step:disabled,
main.check-eligiblity .form-navigation .next-step.disabled {
  background: #909090;
}
main.check-eligiblity .form-navigation .previous-step {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  background-color: #fff;
  color: #575E6C;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  padding: 10px 50px 10px 50px;
  border: 1px solid #fff;
}
main.check-eligiblity .form-navigation .previous-step:hover {
  background-color: #46A3FF;
}
main.check-eligiblity .form-navigation .previous-step:active,
main.check-eligiblity .form-navigation .previous-step:focus {
  background-color: #1C83EA;
}
main.check-eligiblity .form-navigation .previous-step:disabled,
main.check-eligiblity .form-navigation .previous-step.disabled {
  background: #909090;
}
main.check-eligiblity .form-navigation .previous-step:hover {
  color: #6e7788;
  background-color: #fff;
  border: 1px solid #fff;
}
main.check-eligiblity .form-navigation .previous-step:active,
main.check-eligiblity .form-navigation .previous-step:focus {
  color: #727C8F;
  background-color: #fff;
}
main.check-eligiblity .loan-details {
  max-width: 700px;
}
main.check-eligiblity .loan-details .summary-results {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #606877;
  margin-top: -20px;
  margin-left: 40px;
  margin-bottom: 40px;
}
main.check-eligiblity .loan-details .summary-results li {
  list-style: disc;
  margin-bottom: 6px;
  line-height: 1.2;
}
main.check-eligiblity .loan-details .summary-results strong {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #727C8F;
}
main.check-eligiblity .loan-details .selection {
  margin-bottom: 40px;
}
main.check-eligiblity .loan-details .selection p {
  margin-bottom: 30px;
}
main.check-eligiblity .loan-details .selection .product-container {
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
  margin-bottom: 20px;
  position: relative;
}
main.check-eligiblity .loan-details .selection .product-container:last-of-type {
  margin-right: 0;
}
main.check-eligiblity .loan-details .selection .product-container .product-select {
  width: 220px;
  position: relative;
  display: block;
  margin-right: 0;
}
main.check-eligiblity .loan-details .selection .product-container .product-select h6 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
main.check-eligiblity .loan-details .selection .product-container .edit {
  position: absolute;
  top: -10px;
  right: -10px;
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-image: url(img/button/tick-circle-green.png);
  background-color: #26BD27;
  background-position: 50% 50%;
  background-size: 24px 24px;
  background-repeat: no-repeat;
  z-index: 20;
  cursor: pointer;
}
main.check-eligiblity .loan-details .selection .product-container .edit:hover {
  background-image: url(img/button/cross-circle-red-2.png);
  background-color: #FF4E4C;
}
main.check-eligiblity .loan-details .selection .product-container .edit.on {
  background-image: url(img/button/cross-circle-red-2.png);
  background-color: #FF4E4C;
}
main.check-eligiblity .loan-details .selection .product-container .confirm-removal {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  position: absolute;
  background-color: #fff;
  top: 0;
  right: 0;
  width: 125px;
  height: 37px;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 6px 0 rgba(216, 220, 231, 0.65);
  border-radius: 4px;
  line-height: 37px;
  color: #FF4E4C;
  text-align: center;
  cursor: pointer;
}
main.check-eligiblity .loan-details .selection .product-container .confirm-removal:hover {
  color: #CD1816;
}
main.check-eligiblity .loan-details .selection .more-products {
  width: 220px;
  height: 408px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border: solid 1px #E9EBF1;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  padding: 40px 20px;
  position: relative;
}
main.check-eligiblity .loan-details .selection .more-products .add-product {
  position: absolute;
  left: 0;
  right: 0;
  top: 150px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #727C8F;
  line-height: 1.2;
  background-image: url(img/elements/question.png);
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position: 50% 0;
  padding-top: 40px;
  margin: 0;
}
main.check-eligiblity .loan-details .selection .more-products .product-shortlist {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
main.check-eligiblity .loan-details .selection .more-products .product-shortlist .shortlist {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  border: 1px solid #D8DCE7;
  background-color: #fff;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  height: 36px;
  width: 36px;
  background-size: 15px 15px;
  box-shadow: 0 1px 0 0 #E9EBF1;
  background-image: url(img/button/burger_grey.png);
  background-size: 16px 14px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
main.check-eligiblity .loan-details .selection .more-products .product-shortlist .shortlist:hover {
  border: 1px solid #C8CCD7;
}
main.check-eligiblity .loan-details .selection .more-products .product-shortlist p {
  margin-top: 5px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 11px;
  color: #727C8F;
  line-height: 1.2;
}
main.check-eligiblity .finish {
  max-width: 645px;
}
main.check-eligiblity .finish h3 {
  margin-top: 45px;
  margin-bottom: 20px;
}
main.check-eligiblity .finish .summary-results {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #575E6C;
  margin-left: 40px;
}
main.check-eligiblity .finish .summary-results li {
  list-style: disc;
  margin-bottom: 6px;
  line-height: 1.2;
}
main.check-eligiblity .finish strong {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #101922;
}
main.check-eligiblity .finish .product-select {
  width: 200px;
}
main.check-eligiblity .success img {
  width: 100%;
  object-fit: contain;
  margin-bottom: 10px;
}
main.check-eligiblity .success h3 {
  margin-top: 45px;
  margin-bottom: 20px;
}
main.check-eligiblity .success h3 strong {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #101922;
}
main.check-eligiblity .success p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #223241;
}
main.check-eligiblity .success p strong {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}
main.check-eligiblity .success h4 {
  margin-top: 20px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #727C8F;
}
main.check-eligiblity .success h4 a {
  color: #2291FF;
}
main.check-eligiblity .success .upload-container {
  margin-top: 20px;
}
main.check-eligiblity .success .upload-container > p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #727C8F;
  position: absolute;
  left: 30px;
  top: 20px;
}
main.check-eligiblity .success .upload-container h2 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 22px;
  margin-top: 0;
  margin-bottom: 0;
}
main.check-eligiblity .success .upload-container p {
  margin-top: 10px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
}
main.check-eligiblity .success .upload-container span {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #2291FF;
}
main.check-eligiblity .success .payslips {
  margin-top: 20px;
  border: 1px solid #D8DCE7;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}
main.check-eligiblity .success .payslips .payslips-header {
  height: 85px;
  display: block;
  border-bottom: solid 1px #E9EBF1;
  padding: 20px;
}
main.check-eligiblity .success .payslips .payslips-header h2,
main.check-eligiblity .success .payslips .payslips-header button {
  vertical-align: top;
  display: inline-block;
}
main.check-eligiblity .success .payslips .payslips-header h2 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  margin: 5px 0 0 0;
}
main.check-eligiblity .success .payslips .payslips-header button {
  float: right;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  background-color: #26BD27;
  padding: 10px 10px 10px 10px;
  color: #fff;
  background-image: url(img/button/upload-cloud-green.png);
  background-repeat: no-repeat;
  background-size: 16px 13px;
  background-position: 10px 50%;
  padding-left: 35px;
}
main.check-eligiblity .success .payslips .payslips-header button:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #29CA2A;
}
main.check-eligiblity .success .payslips .payslips-header button:active,
main.check-eligiblity .success .payslips .payslips-header button:focus {
  background-color: #23B024;
}
main.check-eligiblity .success .payslips .payslips-titles {
  border-bottom: solid 1px #E9EBF1;
  background-color: #F6F7FB;
  padding: 20px;
}
main.check-eligiblity .success .payslips .payslips-titles li {
  display: inline-block;
  vertical-align: top;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 11px;
  color: #575E6C;
}
main.check-eligiblity .success .payslips .payslips-titles li:nth-of-type(1) {
  width: 30%;
}
main.check-eligiblity .success .payslips .payslips-titles li:nth-of-type(2) {
  width: 70%;
}
main.check-eligiblity .success .payslips .uploaded-payslips .payslip {
  padding: 15px 20px;
}
main.check-eligiblity .success .payslips .uploaded-payslips .payslip .file-name,
main.check-eligiblity .success .payslips .uploaded-payslips .payslip .file-details {
  display: inline-block;
  vertical-align: top;
}
main.check-eligiblity .success .payslips .uploaded-payslips .payslip .file-name {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #575E6C;
  width: Calc(100% - 378px);
  word-wrap: break-word;
  padding-right: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 8px;
}
main.check-eligiblity .success .payslips .uploaded-payslips .payslip .file-details {
  width: 377px;
}
main.check-eligiblity .success .payslips .uploaded-payslips .payslip .file-details .applicant,
main.check-eligiblity .success .payslips .uploaded-payslips .payslip .file-details .properties {
  display: inline-block;
  vertical-align: center;
}
main.check-eligiblity .success .payslips .uploaded-payslips .payslip .file-details .applicant {
  width: 170px;
}
main.check-eligiblity .success .payslips .uploaded-payslips .payslip .file-details .applicant .w280 {
  margin: 0 !important;
  width: 280px;
}
main.check-eligiblity .success .payslips .uploaded-payslips .payslip .file-details .applicant .w280 label {
  display: none;
}
main.check-eligiblity .success .payslips .uploaded-payslips .payslip .file-details .applicant select {
  height: 32px;
}
main.check-eligiblity .success .payslips .uploaded-payslips .payslip .file-details .properties {
  float: right;
}
main.check-eligiblity .success .payslips .uploaded-payslips .payslip .file-details .properties .comment,
main.check-eligiblity .success .payslips .uploaded-payslips .payslip .file-details .properties .delete {
  background-position: 50% 50%;
  background-size: 16px 16px;
  background-repeat: no-repeat;
}
main.check-eligiblity .success .payslips .uploaded-payslips .payslip .file-details .properties .comment {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  border: 1px solid #D8DCE7;
  background-color: #fff;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  height: 36px;
  width: 36px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 15px 15px;
  box-shadow: 0 1px 0 0 #E9EBF1;
  width: 32px;
  height: 32px;
  background-image: url(img/button/comment-grey.png);
}
main.check-eligiblity .success .payslips .uploaded-payslips .payslip .file-details .properties .comment:hover {
  border: 1px solid #C8CCD7;
}
main.check-eligiblity .success .payslips .uploaded-payslips .payslip .file-details .properties .delete {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  border: 1px solid #D8DCE7;
  background-color: #fff;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  height: 36px;
  width: 36px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 15px 15px;
  box-shadow: 0 1px 0 0 #E9EBF1;
  width: 32px;
  height: 32px;
  margin-left: 10px;
  background-image: url(img/button/trash-red.png);
}
main.check-eligiblity .success .payslips .uploaded-payslips .payslip .file-details .properties .delete:hover {
  border: 1px solid #C8CCD7;
}
main.check-eligiblity .success .payslips .uploaded-payslips .payslip .add-comment {
  margin: 10px 0;
}
main.check-eligiblity .success .payslips .uploaded-payslips .payslip .add-comment p {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 10px;
  width: 100%;
  display: block;
  color: #575E6C;
}
main.check-eligiblity .success .payslips .uploaded-payslips .payslip .add-comment input,
main.check-eligiblity .success .payslips .uploaded-payslips .payslip .add-comment .save-comment {
  display: inline-block;
  vertical-align: top;
}
main.check-eligiblity .success .payslips .uploaded-payslips .payslip .add-comment input {
  width: Calc(100% - 110px);
  margin-right: 10px;
}
main.check-eligiblity .success .payslips .uploaded-payslips .payslip .add-comment .save-comment {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  width: 100px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  padding: 12px 0;
}
main.check-eligiblity .success .payslips .uploaded-payslips .payslip .add-comment .save-comment:hover {
  background-color: #46A3FF;
}
main.check-eligiblity .success .payslips .uploaded-payslips .payslip .add-comment .save-comment:active,
main.check-eligiblity .success .payslips .uploaded-payslips .payslip .add-comment .save-comment:focus {
  background-color: #1C83EA;
}
main.check-eligiblity .success .payslips .uploaded-payslips .payslip .add-comment .save-comment:disabled,
main.check-eligiblity .success .payslips .uploaded-payslips .payslip .add-comment .save-comment.disabled {
  background: #909090;
}
main.check-eligiblity .success .payslips .uploaded-payslips .payslip:nth-of-type(2n) {
  background-color: #F6F7FB;
}
main.check-eligiblity .success .payslips .uploaded-payslips .payslip .upload-status {
  padding: 25px;
  border-bottom: 1px solid #D8DCE7;
}
main.check-eligiblity .success .payslips .uploaded-payslips .payslip .upload-status .upload-progress-bar {
  display: inline-block;
  vertical-align: top;
  width: Calc(100% - 52px);
}
main.check-eligiblity .success .payslips .uploaded-payslips .payslip .upload-status .upload-progress-bar .file-name {
  display: inline-block;
  vertical-align: bottom;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #575E6C;
  width: 100%;
}
main.check-eligiblity .success .payslips .uploaded-payslips .payslip .upload-status .upload-progress-bar .upload-speed {
  display: inline-block;
  vertical-align: bottom;
  float: right;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #A3A8B2;
}
main.check-eligiblity .success .payslips .uploaded-payslips .payslip .upload-status .upload-progress-bar .progress-bar-container {
  margin-top: 10px;
  background-color: #F6F7FB;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  height: 8px;
  width: 100%;
}
main.check-eligiblity .success .payslips .uploaded-payslips .payslip .upload-status .upload-progress-bar .progress-bar-container .progress-bar-indicator {
  background-color: #26BD27;
  height: 8px;
  width: 50%;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}
main.check-eligiblity .success .payslips .uploaded-payslips .payslip .upload-status .delete {
  display: inline-block;
  vertical-align: top;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  border: 1px solid #D8DCE7;
  background-color: #fff;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  height: 36px;
  width: 36px;
  background-size: 15px 15px;
  box-shadow: 0 1px 0 0 #E9EBF1;
  width: 32px;
  height: 32px;
  margin-left: 20px;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: url(img/button/trash-red.png);
}
main.check-eligiblity .success .payslips .uploaded-payslips .payslip .upload-status .delete:hover {
  border: 1px solid #C8CCD7;
}
main.check-eligiblity .s2,
main.check-eligiblity .s3 {
  opacity: 0;
  -webkit-transition: opacity 1s;
  -moz-transition: opacity 1s;
  -ms-transition: opacity 1s;
  -o-transition: opacity 1s;
  transition: opacity 1s;
}
main.check-eligiblity .s2.active,
main.check-eligiblity .s3.active {
  opacity: 1;
}
main.help .content-header .heading,
main.help .content-header .search {
  display: inline-block;
  vertical-align: top;
}
main.help .content-header .search {
  float: right;
}
main.help .content-header .search input[type=text] {
  -webkit-appearance: none;
  appearance: none;
  height: 40px;
  border: 1px solid #D8DCE7;
  padding: 0 15px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  width: 100%;
  width: 320px;
  background-image: url(img/button/search-blue.png);
  background-size: 16px 16px;
  padding-left: 40px;
  background-repeat: no-repeat;
  background-position: 10px 50%;
}
main.help .content-header .search input[type=text]:focus {
  box-shadow: 0 0 10px rgba(34, 145, 255, 0.15);
  border: 1px solid #2291FF;
  border-radius: 4px;
}
main.help .content-header .search input[type=text]:hover {
  border: 1px solid #C8CCD7;
  box-shadow: 0 0 10px rgba(34, 145, 255, 0.15);
  border: 1px solid #2291FF;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}
main.help .content-header .search label {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #223241;
  margin-right: 10px;
}
main.help .content-header .heading {
  max-width: 60%;
}
main.help .content-header .heading h1 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 22px;
  margin-bottom: 10px;
}
main.help .content-header .heading p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #727C8F;
}
main.help .content-header .heading .breadcrumbs {
  margin-bottom: 20px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #727C8F;
}
main.help .featured-content {
  margin-top: 50px;
  background-color: #fff;
  border: solid 1px #E9EBF1;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 40px;
}
main.help .featured-content .column-1,
main.help .featured-content .column-2 {
  width: Calc(50% - 20px);
  display: inline-block;
  vertical-align: top;
}
main.help .featured-content .column-1 {
  margin-right: 40px;
}
main.help .featured-content h2 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  margin-bottom: 30px;
}
main.help .featured-content h3 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  margin-bottom: 20px;
}
main.help .featured-content p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #727C8F;
  line-height: 1.2;
  margin-bottom: 20px;
}
main.help .featured-content .quick-links {
  background-color: #F6F7FB;
  margin-top: 20px;
  padding: 20px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}
main.help .featured-content .quick-links ul li {
  margin-bottom: 12px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}
main.help .featured-content .quick-links ul li a {
  color: #2291FF;
  background-image: url(img/button/long-right-black.png);
  background-position: 0% 60%;
  background-repeat: no-repeat;
  background-size: 16px 10px;
  display: block;
  width: 100%;
  padding-left: 30px;
}
main.help .default .content {
  margin-top: 40px;
}
main.help .default .content .box {
  background-color: #fff;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border: solid 1px #E9EBF1;
  width: Calc(50% - 20px);
  display: inline-block;
  vertical-align: top;
  padding: 40px 40px 30px 40px;
}
main.help .default .content .box:nth-child(even) {
  margin-left: 40px;
}
main.help .default .content .box:nth-of-type(3),
main.help .default .content .box:nth-of-type(4) {
  margin-top: 40px;
}
main.help .default .content .box h2 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
}
main.help .default .content .box ul {
  margin-top: 25px;
  margin-bottom: 10px;
}
main.help .default .content .box ul li {
  border-bottom: solid 1px #E9EBF1;
  margin-top: 15px;
  padding-bottom: 15px;
}
main.help .default .content .box ul li a {
  color: #575E6C;
  width: 100%;
  display: block;
  background-image: url(img/button/small-right-grey.png);
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-size: 8px 12px;
  line-height: 1.2;
}
main.help .default .content .box ul li a:hover {
  color: #101922;
  background-image: url(img/button/small-right-blue.png);
}
main.help .default .content .box button {
  width: 100%;
  height: 40px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  background-color: #fff;
  color: #2291FF;
  background-image: url(img/button/right-arrow-blue.png);
  background-size: 13px 7px;
  background-position: Calc(100% - 185px) 59%;
  background-repeat: no-repeat;
}
main.help .default .content .box button:hover {
  color: #46A3FF;
}
main.help .help-content {
  margin-top: 40px;
}
main.help .help-content .content-box {
  display: inline-block;
  vertical-align: top;
  background-color: #fff;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 20px 40px;
  width: Calc(60% - 20px);
  border: solid 1px #E9EBF1;
}
main.help .help-content.categories {
  margin-top: 20px;
}
main.help .help-content.categories ul li {
  border-bottom: solid 1px #E9EBF1;
  margin-top: 15px;
  padding-bottom: 15px;
}
main.help .help-content.categories ul li a {
  color: #575E6C;
  width: 100%;
  display: block;
  background-image: url(img/button/small-right-grey.png);
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-size: 8px 12px;
  line-height: 1.2;
}
main.help .help-content.categories ul li a:hover {
  color: #101922;
  background-image: url(img/button/small-right-blue.png);
}
main.help .help-content.categories ul li:last-of-type {
  border-bottom: none;
}
main.help .help-content.body {
  margin-top: 20px;
}
main.help .help-content.body .content-box {
  padding: 40px;
  line-height: 1.8;
  color: #101922;
}
main.help .help-content.body .content-box p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  margin-bottom: 15px;
}
main.help .help-content.body .content-box ul {
  margin-bottom: 15px;
  margin-left: 30px;
}
main.help .help-content.body .content-box ul li {
  list-style: disc;
}
main.help .help-content.body .content-box ol {
  margin-bottom: 15px;
  margin-left: 30px;
}
main.help .help-content.body .content-box ol li {
  list-style: decimal;
}
main.help .help-content.body .content-box strong {
  font-weight: bolder;
}
main.help .help-content.body .content-box em {
  font-style: italic;
}
main.help .help-content.body .content-box a {
  color: #2291FF;
}
main.help .help-content.body .content-box a:hover {
  color: #46A3FF;
}
main.help .help-content.body .content-box a:active,
main.help .help-content.body .content-box a:focus {
  color: #1C83EA;
}
main.help .help-content.body .content-box img {
  width: 100%;
  height: auto;
  margin: 10px 0;
}
main.help .related-topics {
  display: inline-block;
  vertical-align: top;
  background-color: #fff;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 40px 40px 20px 40px;
  width: Calc(40% - 20px);
  margin-left: 40px;
  border: solid 1px #E9EBF1;
}
main.help .related-topics h2 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
}
main.help .related-topics ul {
  margin-top: 25px;
  margin-bottom: 10px;
}
main.help .related-topics ul li {
  border-bottom: solid 1px #E9EBF1;
  margin-top: 15px;
  padding-bottom: 15px;
}
main.help .related-topics ul li a {
  color: #575E6C;
  width: 100%;
  display: block;
  background-image: url(img/button/small-right-grey.png);
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-size: 8px 12px;
  line-height: 1.2;
}
main.help .related-topics ul li a:hover {
  color: #101922;
  background-image: url(img/button/small-right-blue.png);
}
main.help .related-topics ul li:last-of-type {
  border-bottom: none;
}
main.my-profile h1 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 22px;
}
main.my-profile .new-email-box {
  background-color: #fff;
  border: solid 1px #E9EBF1;
  width: 665px;
  padding: 40px;
  border-radius: 4px;
  margin-top: 30px;
}
main.my-profile .new-email-box h2 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  margin-bottom: 15px;
}
main.my-profile .new-email-box p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  line-height: 1.4;
}
main.my-profile .new-email-box p .underline {
  text-decoration: underline;
}
main.my-profile .new-email-box .new-email-navigation {
  margin-top: 30px;
}
main.my-profile .new-email-box .new-email-navigation .cancel-update,
main.my-profile .new-email-box .new-email-navigation .resend {
  width: Calc(50% - 10px);
}
main.my-profile .new-email-box .new-email-navigation .cancel-update {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
main.my-profile .new-email-box .new-email-navigation .cancel-update:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
main.my-profile .new-email-box .new-email-navigation .cancel-update:active,
main.my-profile .new-email-box .new-email-navigation .cancel-update:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
main.my-profile .new-email-box .new-email-navigation .resend {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  margin-right: 20px;
}
main.my-profile .new-email-box .new-email-navigation .resend:hover {
  background-color: #46A3FF;
}
main.my-profile .new-email-box .new-email-navigation .resend:active,
main.my-profile .new-email-box .new-email-navigation .resend:focus {
  background-color: #1C83EA;
}
main.my-profile .new-email-box .new-email-navigation .resend:disabled,
main.my-profile .new-email-box .new-email-navigation .resend.disabled {
  background: #909090;
}
main.my-profile .setting {
  margin-top: 30px;
  width: 665px;
  background-color: #fff;
  border: solid 1px #E9EBF1;
  padding: 40px;
  border-radius: 4px;
  position: relative;
}
main.my-profile .setting .avatar {
  border-bottom: solid 1px #E9EBF1;
  padding-bottom: 40px;
}
main.my-profile .setting .avatar .avatar-description {
  display: inline-block;
  vertical-align: top;
  width: Calc(100% - 290px);
  margin-left: 20px;
}
main.my-profile .setting .avatar .avatar-description h3 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  margin-bottom: 15px;
}
main.my-profile .setting .avatar .avatar-pic {
  display: inline-block;
  vertical-align: top;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
main.my-profile .setting .avatar .avatar-pic.empty {
  line-height: 60px;
  color: #fff;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  text-align: center;
  background-color: #2291FF;
}
main.my-profile .setting .avatar .social-media {
  display: inline-block;
  vertical-align: top;
  margin-left: 20px;
  pointer-events: none;
}
main.my-profile .setting .avatar .social-media .google {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  font-weight: 600;
  padding: 13px 15px 13px 40px;
  background-color: #DD4A40;
  background-image: url(img/button/logo_google.png);
  background-repeat: no-repeat;
  background-size: 14px 14px;
  background-position: 20px 50%;
  width: 190px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-position: 18px 50%;
  margin-bottom: 10px;
}
main.my-profile .setting .avatar .social-media .google:hover {
  background-color: #46A3FF;
}
main.my-profile .setting .avatar .social-media .google:active,
main.my-profile .setting .avatar .social-media .google:focus {
  background-color: #1C83EA;
}
main.my-profile .setting .avatar .social-media .google:disabled,
main.my-profile .setting .avatar .social-media .google.disabled {
  background: #909090;
}
main.my-profile .setting .avatar .social-media .google:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #E6574D;
}
main.my-profile .setting .avatar .social-media .google:active,
main.my-profile .setting .avatar .social-media .google:focus {
  background-color: #C63C32;
}
main.my-profile .setting .avatar .social-media .facebook {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  font-weight: 600;
  padding: 13px 15px 13px 40px;
  background-color: #4267B2;
  background-image: url(img/button/logo_fb.png);
  background-repeat: no-repeat;
  background-size: 9px 17px;
  background-position: 20px 50%;
  width: 190px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
}
main.my-profile .setting .avatar .social-media .facebook:hover {
  background-color: #46A3FF;
}
main.my-profile .setting .avatar .social-media .facebook:active,
main.my-profile .setting .avatar .social-media .facebook:focus {
  background-color: #1C83EA;
}
main.my-profile .setting .avatar .social-media .facebook:disabled,
main.my-profile .setting .avatar .social-media .facebook.disabled {
  background: #909090;
}
main.my-profile .setting .avatar .social-media .facebook:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #5077C5;
}
main.my-profile .setting .avatar .social-media .facebook:active,
main.my-profile .setting .avatar .social-media .facebook:focus {
  background-color: #36589D;
}
main.my-profile .setting .avatar .avatar-selector {
  position: relative;
}
main.my-profile .setting .avatar .avatar-selector .pick-avatar {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  padding: 6px 15px 6px 15px;
}
main.my-profile .setting .avatar .avatar-selector .pick-avatar:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
main.my-profile .setting .avatar .avatar-selector .pick-avatar:active,
main.my-profile .setting .avatar .avatar-selector .pick-avatar:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
main.my-profile .setting .avatar .avatar-selector .pop-up {
  z-index: 10;
  position: absolute;
  display: block;
  width: 265px;
  left: 60px;
  margin-left: -140px;
  top: 100%;
  padding-top: 20px;
}
main.my-profile .setting .avatar .avatar-selector .pop-up .content {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 6px 0 rgba(216, 220, 231, 0.65);
  border-radius: 4px;
  position: relative;
}
main.my-profile .setting .avatar .avatar-selector .pop-up .content:after,
main.my-profile .setting .avatar .avatar-selector .pop-up .content:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
main.my-profile .setting .avatar .avatar-selector .pop-up .content:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}
main.my-profile .setting .avatar .avatar-selector .pop-up .content:before {
  border-color: rgba(216, 220, 231, 0);
  border-bottom-color: #D8DCE7;
  border-width: 12px;
  margin-left: -12px;
}
main.my-profile .setting .avatar .avatar-selector .pop-up .content .row {
  width: 100%;
  display: block;
  margin-bottom: 20px;
}
main.my-profile .setting .avatar .avatar-selector .pop-up .content .row:last-of-type {
  margin-bottom: 0;
}
main.my-profile .setting .avatar .avatar-selector .pop-up .content .row .avatar-pic {
  display: inline-block;
  vertical-align: top;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: none;
  margin-right: 20px;
  cursor: pointer;
  background-position: 50% 50%;
  background-size: auto 60px;
  background-repeat: no-repeat;
  position: relative;
}
main.my-profile .setting .avatar .avatar-selector .pop-up .content .row .avatar-pic:last-of-type {
  margin-right: 0;
}
main.my-profile .setting .avatar .avatar-selector .pop-up .content .row .avatar-pic:hover:before {
  content: "";
  position: absolute;
  display: block;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  border: 4px solid #D8DCE7;
  border-radius: 50%;
  z-index: 10;
}
main.my-profile .setting .avatar .avatar-selector .pop-up .content .row .avatar-pic.selected:before {
  content: "";
  position: absolute;
  display: block;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  border: 4px solid #2291FF;
  border-radius: 50%;
  z-index: 10;
}
main.my-profile .setting .avatar .avatar-selector .pop-up .content .row .avatar-pic.selected:after {
  position: absolute;
  top: -5px;
  right: -5px;
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #2291FF;
  background-image: url(img/elements/check-white.png);
  background-size: 10px 8px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  z-index: 20;
}
main.my-profile .setting .details-section {
  border-bottom: solid 1px #E9EBF1;
  margin-top: 40px;
  padding-bottom: 20px;
}
main.my-profile .setting .details-section button {
  margin-top: 37px;
  width: calc(50% - 10px);
  padding: 11px 25px 10px 25px;
}
main.my-profile .setting .password-section {
  border-bottom: solid 1px #E9EBF1;
  margin-top: 40px;
  padding-bottom: 20px;
}
main.my-profile .setting .tooltips {
  border-bottom: solid 1px #E9EBF1;
  padding: 40px 0;
}
main.my-profile .setting .tooltips .content,
main.my-profile .setting .tooltips .switch {
  display: inline-block;
}
main.my-profile .setting .tooltips .content {
  vertical-align: top;
  width: Calc(100% - 100px);
  margin-right: 40px;
}
main.my-profile .setting .tooltips .content h3 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  margin-bottom: 10px;
}
main.my-profile .setting .tooltips .content p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
}
main.my-profile .setting .tooltips .switch {
  vertical-align: top;
  width: 60px;
}
main.my-profile .setting .update {
  margin-top: 40px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  width: 100%;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
main.my-profile .setting .update:hover {
  background-color: #46A3FF;
}
main.my-profile .setting .update:active,
main.my-profile .setting .update:focus {
  background-color: #1C83EA;
}
main.my-profile .setting .update:disabled,
main.my-profile .setting .update.disabled {
  background: #909090;
}
main.my-profile .info {
  text-align: center;
  background-color: #F6F7FB;
  padding: 16px 20px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 280px;
  display: inline-block;
  vertical-align: top;
  margin-left: 20px;
}
main.my-profile .info span.bank {
  max-width: 380px;
  text-align: left;
  display: inline-block;
  line-height: 16px;
  padding-left: 35px;
  background-image: url(img/elements/lock-yellow.png);
  background-size: 16px 24px;
  background-position: 0% 50%;
  background-repeat: no-repeat;
  color: #223241;
}
main.my-profile form .w280 {
  margin-top: 10px;
  margin-bottom: 15px;
}
main.my-profile-confirmation {
  text-align: center;
}
main.my-profile-confirmation .confirmation {
  margin-top: 40px;
}
main.my-profile-confirmation .confirmation h2 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 22px;
  color: #223241;
  margin-bottom: 10px;
  background-image: url(img/elements/confirmed_green.png);
  background-repeat: no-repeat;
  background-size: 56px 54px;
  background-position: 50% 0;
  padding-top: 80px;
}
main.my-profile-confirmation .confirmation p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  line-height: 1.4;
}
main.my-profile-confirmation .confirmation .navigation {
  margin-top: 40px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
main.my-profile-confirmation .confirmation .navigation:hover {
  background-color: #46A3FF;
}
main.my-profile-confirmation .confirmation .navigation:active,
main.my-profile-confirmation .confirmation .navigation:focus {
  background-color: #1C83EA;
}
main.my-profile-confirmation .confirmation .navigation:disabled,
main.my-profile-confirmation .confirmation .navigation.disabled {
  background: #909090;
}
main.calculators {
  overflow-x: hidden;
  overflow-y: auto;
}
main.calculators .calculator-home {
  max-width: 1100px;
  margin: 0 auto;
}
main.calculators .borrowing-power,
main.calculators .funds-required,
main.calculators .refinancing,
main.calculators .stamp-duty {
  max-width: 1100px;
  margin: 0 auto;
}
main.calculators .borrowing-power h1,
main.calculators .funds-required h1,
main.calculators .refinancing h1,
main.calculators .stamp-duty h1 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 22px;
  color: #101922;
}
main.calculators .borrowing-power h2,
main.calculators .funds-required h2,
main.calculators .refinancing h2,
main.calculators .stamp-duty h2 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #727C8F;
  margin-top: 10px;
}
main.calculators .borrowing-power .content,
main.calculators .funds-required .content,
main.calculators .refinancing .content,
main.calculators .stamp-duty .content {
  margin-top: 25px;
}
main.calculators .borrowing-power .content .left-content,
main.calculators .funds-required .content .left-content,
main.calculators .refinancing .content .left-content,
main.calculators .stamp-duty .content .left-content,
main.calculators .borrowing-power .content .right-content,
main.calculators .funds-required .content .right-content,
main.calculators .refinancing .content .right-content,
main.calculators .stamp-duty .content .right-content {
  display: inline-block;
  vertical-align: top;
}
@media (max-width: 1210px) {
  main.calculators .borrowing-power .content .left-content,
  main.calculators .funds-required .content .left-content,
  main.calculators .refinancing .content .left-content,
  main.calculators .stamp-duty .content .left-content,
  main.calculators .borrowing-power .content .right-content,
  main.calculators .funds-required .content .right-content,
  main.calculators .refinancing .content .right-content,
  main.calculators .stamp-duty .content .right-content {
    display: block;
  }
}
main.calculators .borrowing-power .content .left-content,
main.calculators .funds-required .content .left-content,
main.calculators .refinancing .content .left-content,
main.calculators .stamp-duty .content .left-content {
  width: 665px;
}
@media (max-width: 1210px) {
  main.calculators .borrowing-power .content .left-content,
  main.calculators .funds-required .content .left-content,
  main.calculators .refinancing .content .left-content,
  main.calculators .stamp-duty .content .left-content {
    width: 100%;
  }
}
main.calculators .borrowing-power .content .right-content,
main.calculators .funds-required .content .right-content,
main.calculators .refinancing .content .right-content,
main.calculators .stamp-duty .content .right-content {
  width: Calc(100% - 665px - 40px);
  margin-left: 40px;
}
@media (max-width: 1210px) {
  main.calculators .borrowing-power .content .right-content,
  main.calculators .funds-required .content .right-content,
  main.calculators .refinancing .content .right-content,
  main.calculators .stamp-duty .content .right-content {
    margin-top: 40px;
    margin-left: 0;
    width: 100%;
  }
}
main.calculators .borrowing-power .content .module,
main.calculators .funds-required .content .module,
main.calculators .refinancing .content .module,
main.calculators .stamp-duty .content .module {
  padding: 40px;
  background-color: #fff;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border: solid 1px #E9EBF1;
}
main.calculators .borrowing-power .content .module.borrowing-power-result .result,
main.calculators .funds-required .content .module.borrowing-power-result .result,
main.calculators .refinancing .content .module.borrowing-power-result .result,
main.calculators .stamp-duty .content .module.borrowing-power-result .result,
main.calculators .borrowing-power .content .module.funds-required-result .result,
main.calculators .funds-required .content .module.funds-required-result .result,
main.calculators .refinancing .content .module.funds-required-result .result,
main.calculators .stamp-duty .content .module.funds-required-result .result,
main.calculators .borrowing-power .content .module.stamp-duty-result .result,
main.calculators .funds-required .content .module.stamp-duty-result .result,
main.calculators .refinancing .content .module.stamp-duty-result .result,
main.calculators .stamp-duty .content .module.stamp-duty-result .result {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 26px;
  font-size: 32px;
  color: #2291FF;
  text-align: center;
}
main.calculators .borrowing-power .content .module.borrowing-power-result .result span,
main.calculators .funds-required .content .module.borrowing-power-result .result span,
main.calculators .refinancing .content .module.borrowing-power-result .result span,
main.calculators .stamp-duty .content .module.borrowing-power-result .result span,
main.calculators .borrowing-power .content .module.funds-required-result .result span,
main.calculators .funds-required .content .module.funds-required-result .result span,
main.calculators .refinancing .content .module.funds-required-result .result span,
main.calculators .stamp-duty .content .module.funds-required-result .result span,
main.calculators .borrowing-power .content .module.stamp-duty-result .result span,
main.calculators .funds-required .content .module.stamp-duty-result .result span,
main.calculators .refinancing .content .module.stamp-duty-result .result span,
main.calculators .stamp-duty .content .module.stamp-duty-result .result span {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #223241;
  display: inline-block;
  margin: 0 20px;
}
main.calculators .borrowing-power .content .module.borrowing-power-result .result-subtitle,
main.calculators .funds-required .content .module.borrowing-power-result .result-subtitle,
main.calculators .refinancing .content .module.borrowing-power-result .result-subtitle,
main.calculators .stamp-duty .content .module.borrowing-power-result .result-subtitle,
main.calculators .borrowing-power .content .module.funds-required-result .result-subtitle,
main.calculators .funds-required .content .module.funds-required-result .result-subtitle,
main.calculators .refinancing .content .module.funds-required-result .result-subtitle,
main.calculators .stamp-duty .content .module.funds-required-result .result-subtitle,
main.calculators .borrowing-power .content .module.stamp-duty-result .result-subtitle,
main.calculators .funds-required .content .module.stamp-duty-result .result-subtitle,
main.calculators .refinancing .content .module.stamp-duty-result .result-subtitle,
main.calculators .stamp-duty .content .module.stamp-duty-result .result-subtitle {
  text-align: center;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 22px;
  color: #101922;
  margin-top: 15px;
}
main.calculators .borrowing-power .content .module.borrowing-power-result .result-subtitle sup,
main.calculators .funds-required .content .module.borrowing-power-result .result-subtitle sup,
main.calculators .refinancing .content .module.borrowing-power-result .result-subtitle sup,
main.calculators .stamp-duty .content .module.borrowing-power-result .result-subtitle sup,
main.calculators .borrowing-power .content .module.funds-required-result .result-subtitle sup,
main.calculators .funds-required .content .module.funds-required-result .result-subtitle sup,
main.calculators .refinancing .content .module.funds-required-result .result-subtitle sup,
main.calculators .stamp-duty .content .module.funds-required-result .result-subtitle sup,
main.calculators .borrowing-power .content .module.stamp-duty-result .result-subtitle sup,
main.calculators .funds-required .content .module.stamp-duty-result .result-subtitle sup,
main.calculators .refinancing .content .module.stamp-duty-result .result-subtitle sup,
main.calculators .stamp-duty .content .module.stamp-duty-result .result-subtitle sup {
  vertical-align: super;
  font-size: 50%;
  margin: 0.2em;
}
main.calculators .borrowing-power .content .module.funds-required-result .result-left,
main.calculators .funds-required .content .module.funds-required-result .result-left,
main.calculators .refinancing .content .module.funds-required-result .result-left,
main.calculators .stamp-duty .content .module.funds-required-result .result-left,
main.calculators .borrowing-power .content .module.funds-required-result .result-right,
main.calculators .funds-required .content .module.funds-required-result .result-right,
main.calculators .refinancing .content .module.funds-required-result .result-right,
main.calculators .stamp-duty .content .module.funds-required-result .result-right {
  vertical-align: top;
  display: inline-block;
  width: 50%;
}
main.calculators .borrowing-power .content .module.funds-required-result .result-left:first-of-type,
main.calculators .funds-required .content .module.funds-required-result .result-left:first-of-type,
main.calculators .refinancing .content .module.funds-required-result .result-left:first-of-type,
main.calculators .stamp-duty .content .module.funds-required-result .result-left:first-of-type,
main.calculators .borrowing-power .content .module.funds-required-result .result-right:first-of-type,
main.calculators .funds-required .content .module.funds-required-result .result-right:first-of-type,
main.calculators .refinancing .content .module.funds-required-result .result-right:first-of-type,
main.calculators .stamp-duty .content .module.funds-required-result .result-right:first-of-type {
  border-right: solid 1px #E9EBF1;
}
main.calculators .borrowing-power .content .module.funds-required-result .result-subtitle,
main.calculators .funds-required .content .module.funds-required-result .result-subtitle,
main.calculators .refinancing .content .module.funds-required-result .result-subtitle,
main.calculators .stamp-duty .content .module.funds-required-result .result-subtitle {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  margin-top: 10px;
}
main.calculators .borrowing-power .content .module.stamp-duty-result,
main.calculators .funds-required .content .module.stamp-duty-result,
main.calculators .refinancing .content .module.stamp-duty-result,
main.calculators .stamp-duty .content .module.stamp-duty-result {
  text-align: center;
}
main.calculators .borrowing-power .content .module.stamp-duty-result .result-subtitle,
main.calculators .funds-required .content .module.stamp-duty-result .result-subtitle,
main.calculators .refinancing .content .module.stamp-duty-result .result-subtitle,
main.calculators .stamp-duty .content .module.stamp-duty-result .result-subtitle {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  margin-top: 10px;
}
main.calculators .borrowing-power .content .module.stamp-duty-result .result-calculations,
main.calculators .funds-required .content .module.stamp-duty-result .result-calculations,
main.calculators .refinancing .content .module.stamp-duty-result .result-calculations,
main.calculators .stamp-duty .content .module.stamp-duty-result .result-calculations {
  text-align: left;
  margin: 15px 0;
}
main.calculators .borrowing-power .content .module.stamp-duty-result .result-calculations h3,
main.calculators .funds-required .content .module.stamp-duty-result .result-calculations h3,
main.calculators .refinancing .content .module.stamp-duty-result .result-calculations h3,
main.calculators .stamp-duty .content .module.stamp-duty-result .result-calculations h3 {
  display: block;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  margin-top: 30px;
}
main.calculators .borrowing-power .content .module.stamp-duty-result .result-calculations h3:first-of-type,
main.calculators .funds-required .content .module.stamp-duty-result .result-calculations h3:first-of-type,
main.calculators .refinancing .content .module.stamp-duty-result .result-calculations h3:first-of-type,
main.calculators .stamp-duty .content .module.stamp-duty-result .result-calculations h3:first-of-type {
  margin-top: 0;
}
main.calculators .borrowing-power .content .module.stamp-duty-result .result-calculations ul,
main.calculators .funds-required .content .module.stamp-duty-result .result-calculations ul,
main.calculators .refinancing .content .module.stamp-duty-result .result-calculations ul,
main.calculators .stamp-duty .content .module.stamp-duty-result .result-calculations ul {
  margin: 15px 0;
}
main.calculators .borrowing-power .content .module.stamp-duty-result .result-calculations ul li,
main.calculators .funds-required .content .module.stamp-duty-result .result-calculations ul li,
main.calculators .refinancing .content .module.stamp-duty-result .result-calculations ul li,
main.calculators .stamp-duty .content .module.stamp-duty-result .result-calculations ul li {
  color: #575E6C;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  padding: 3px 0;
}
main.calculators .borrowing-power .content .module.stamp-duty-result .result-calculations ul li span,
main.calculators .funds-required .content .module.stamp-duty-result .result-calculations ul li span,
main.calculators .refinancing .content .module.stamp-duty-result .result-calculations ul li span,
main.calculators .stamp-duty .content .module.stamp-duty-result .result-calculations ul li span {
  float: right;
}
main.calculators .borrowing-power .content .module.stamp-duty-result .result-calculations ul li:last-of-type,
main.calculators .funds-required .content .module.stamp-duty-result .result-calculations ul li:last-of-type,
main.calculators .refinancing .content .module.stamp-duty-result .result-calculations ul li:last-of-type,
main.calculators .stamp-duty .content .module.stamp-duty-result .result-calculations ul li:last-of-type {
  margin-top: 10px;
  padding-top: 12px;
  border-top: 1px solid #E9EBF1;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #101922;
}
main.calculators .borrowing-power .content .module.your-results,
main.calculators .funds-required .content .module.your-results,
main.calculators .refinancing .content .module.your-results,
main.calculators .stamp-duty .content .module.your-results {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding-bottom: 20px;
}
main.calculators .borrowing-power .content .module.your-results.collapsed,
main.calculators .funds-required .content .module.your-results.collapsed,
main.calculators .refinancing .content .module.your-results.collapsed,
main.calculators .stamp-duty .content .module.your-results.collapsed {
  padding: 0;
  opacity: 0;
  font-size: 0;
}
main.calculators .borrowing-power .content .module.your-results.collapsed .results,
main.calculators .funds-required .content .module.your-results.collapsed .results,
main.calculators .refinancing .content .module.your-results.collapsed .results,
main.calculators .stamp-duty .content .module.your-results.collapsed .results,
main.calculators .borrowing-power .content .module.your-results.collapsed .chart,
main.calculators .funds-required .content .module.your-results.collapsed .chart,
main.calculators .refinancing .content .module.your-results.collapsed .chart,
main.calculators .stamp-duty .content .module.your-results.collapsed .chart {
  height: 0;
  opacity: 0;
}
main.calculators .borrowing-power .content .module.your-results .results,
main.calculators .funds-required .content .module.your-results .results,
main.calculators .refinancing .content .module.your-results .results,
main.calculators .stamp-duty .content .module.your-results .results,
main.calculators .borrowing-power .content .module.your-results .chart,
main.calculators .funds-required .content .module.your-results .chart,
main.calculators .refinancing .content .module.your-results .chart,
main.calculators .stamp-duty .content .module.your-results .chart {
  height: 280px;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  transition: all 0.5s;
}
main.calculators .borrowing-power .content .module.your-results .results,
main.calculators .funds-required .content .module.your-results .results,
main.calculators .refinancing .content .module.your-results .results,
main.calculators .stamp-duty .content .module.your-results .results {
  width: Calc(40% - 10px);
}
main.calculators .borrowing-power .content .module.your-results .chart,
main.calculators .funds-required .content .module.your-results .chart,
main.calculators .refinancing .content .module.your-results .chart,
main.calculators .stamp-duty .content .module.your-results .chart {
  width: Calc(60% - 10px);
}
main.calculators .borrowing-power .content .module.your-results .chart,
main.calculators .funds-required .content .module.your-results .chart,
main.calculators .refinancing .content .module.your-results .chart,
main.calculators .stamp-duty .content .module.your-results .chart {
  height: 280px;
  position: relative;
}
main.calculators .borrowing-power .content .module.your-results .chart .potential-savings,
main.calculators .funds-required .content .module.your-results .chart .potential-savings,
main.calculators .refinancing .content .module.your-results .chart .potential-savings,
main.calculators .stamp-duty .content .module.your-results .chart .potential-savings {
  display: block;
  position: absolute;
  right: 30px;
  text-align: right;
}
main.calculators .borrowing-power .content .module.your-results .chart .potential-savings h2,
main.calculators .funds-required .content .module.your-results .chart .potential-savings h2,
main.calculators .refinancing .content .module.your-results .chart .potential-savings h2,
main.calculators .stamp-duty .content .module.your-results .chart .potential-savings h2 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 22px;
  color: #101922;
  margin-bottom: 10px;
}
main.calculators .borrowing-power .content .module.your-results .chart .potential-savings p,
main.calculators .funds-required .content .module.your-results .chart .potential-savings p,
main.calculators .refinancing .content .module.your-results .chart .potential-savings p,
main.calculators .stamp-duty .content .module.your-results .chart .potential-savings p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #575E6C;
  line-height: 1.2;
}
main.calculators .borrowing-power .content .module.your-results .chart .potential-savings.positive,
main.calculators .funds-required .content .module.your-results .chart .potential-savings.positive,
main.calculators .refinancing .content .module.your-results .chart .potential-savings.positive,
main.calculators .stamp-duty .content .module.your-results .chart .potential-savings.positive {
  bottom: 60px;
}
main.calculators .borrowing-power .content .module.your-results .chart .potential-savings.negative,
main.calculators .funds-required .content .module.your-results .chart .potential-savings.negative,
main.calculators .refinancing .content .module.your-results .chart .potential-savings.negative,
main.calculators .stamp-duty .content .module.your-results .chart .potential-savings.negative {
  top: 30px;
}
main.calculators .borrowing-power .content .module.your-results .results,
main.calculators .funds-required .content .module.your-results .results,
main.calculators .refinancing .content .module.your-results .results,
main.calculators .stamp-duty .content .module.your-results .results {
  margin-right: 20px;
}
main.calculators .borrowing-power .content .module.your-results .results h2,
main.calculators .funds-required .content .module.your-results .results h2,
main.calculators .refinancing .content .module.your-results .results h2,
main.calculators .stamp-duty .content .module.your-results .results h2 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  color: #101922;
  margin: 0 0 5px 0;
}
main.calculators .borrowing-power .content .module.your-results .results .total-savings,
main.calculators .funds-required .content .module.your-results .results .total-savings,
main.calculators .refinancing .content .module.your-results .results .total-savings,
main.calculators .stamp-duty .content .module.your-results .results .total-savings,
main.calculators .borrowing-power .content .module.your-results .results .monthly-savings,
main.calculators .funds-required .content .module.your-results .results .monthly-savings,
main.calculators .refinancing .content .module.your-results .results .monthly-savings,
main.calculators .stamp-duty .content .module.your-results .results .monthly-savings,
main.calculators .borrowing-power .content .module.your-results .results .loan-term,
main.calculators .funds-required .content .module.your-results .results .loan-term,
main.calculators .refinancing .content .module.your-results .results .loan-term,
main.calculators .stamp-duty .content .module.your-results .results .loan-term {
  text-align: center;
  padding: 17px 0;
  border-bottom: 1px solid #E9EBF1;
}
main.calculators .borrowing-power .content .module.your-results .results .total-savings h3,
main.calculators .funds-required .content .module.your-results .results .total-savings h3,
main.calculators .refinancing .content .module.your-results .results .total-savings h3,
main.calculators .stamp-duty .content .module.your-results .results .total-savings h3,
main.calculators .borrowing-power .content .module.your-results .results .monthly-savings h3,
main.calculators .funds-required .content .module.your-results .results .monthly-savings h3,
main.calculators .refinancing .content .module.your-results .results .monthly-savings h3,
main.calculators .stamp-duty .content .module.your-results .results .monthly-savings h3,
main.calculators .borrowing-power .content .module.your-results .results .loan-term h3,
main.calculators .funds-required .content .module.your-results .results .loan-term h3,
main.calculators .refinancing .content .module.your-results .results .loan-term h3,
main.calculators .stamp-duty .content .module.your-results .results .loan-term h3 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 22px;
  color: #101922;
}
main.calculators .borrowing-power .content .module.your-results .results .total-savings p,
main.calculators .funds-required .content .module.your-results .results .total-savings p,
main.calculators .refinancing .content .module.your-results .results .total-savings p,
main.calculators .stamp-duty .content .module.your-results .results .total-savings p,
main.calculators .borrowing-power .content .module.your-results .results .monthly-savings p,
main.calculators .funds-required .content .module.your-results .results .monthly-savings p,
main.calculators .refinancing .content .module.your-results .results .monthly-savings p,
main.calculators .stamp-duty .content .module.your-results .results .monthly-savings p,
main.calculators .borrowing-power .content .module.your-results .results .loan-term p,
main.calculators .funds-required .content .module.your-results .results .loan-term p,
main.calculators .refinancing .content .module.your-results .results .loan-term p,
main.calculators .stamp-duty .content .module.your-results .results .loan-term p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #101922;
  margin-top: 10px;
}
main.calculators .borrowing-power .content .module.your-results .results .loan-term,
main.calculators .funds-required .content .module.your-results .results .loan-term,
main.calculators .refinancing .content .module.your-results .results .loan-term,
main.calculators .stamp-duty .content .module.your-results .results .loan-term {
  border-bottom: none;
}
main.calculators .borrowing-power .content .module.your-results .search-loans,
main.calculators .funds-required .content .module.your-results .search-loans,
main.calculators .refinancing .content .module.your-results .search-loans,
main.calculators .stamp-duty .content .module.your-results .search-loans {
  margin-top: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 14px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  background-color: #26BD27;
  padding: 10px 10px 10px 10px;
  color: #fff;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  width: 100%;
  padding: 14px 0;
}
main.calculators .borrowing-power .content .module.your-results .search-loans:hover,
main.calculators .funds-required .content .module.your-results .search-loans:hover,
main.calculators .refinancing .content .module.your-results .search-loans:hover,
main.calculators .stamp-duty .content .module.your-results .search-loans:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #29CA2A;
}
main.calculators .borrowing-power .content .module.your-results .search-loans:active,
main.calculators .funds-required .content .module.your-results .search-loans:active,
main.calculators .refinancing .content .module.your-results .search-loans:active,
main.calculators .stamp-duty .content .module.your-results .search-loans:active,
main.calculators .borrowing-power .content .module.your-results .search-loans:focus,
main.calculators .funds-required .content .module.your-results .search-loans:focus,
main.calculators .refinancing .content .module.your-results .search-loans:focus,
main.calculators .stamp-duty .content .module.your-results .search-loans:focus {
  background-color: #23B024;
}
main.calculators .borrowing-power .content .module.your-results .search-loan-description,
main.calculators .funds-required .content .module.your-results .search-loan-description,
main.calculators .refinancing .content .module.your-results .search-loan-description,
main.calculators .stamp-duty .content .module.your-results .search-loan-description {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #223241;
  text-align: center;
  margin-top: 15px;
}
main.calculators .borrowing-power .content .tabs,
main.calculators .funds-required .content .tabs,
main.calculators .refinancing .content .tabs,
main.calculators .stamp-duty .content .tabs {
  padding: 0;
  background-color: transparent;
  border: none;
  margin-top: 20px;
}
main.calculators .borrowing-power .content .tabs .tab,
main.calculators .funds-required .content .tabs .tab,
main.calculators .refinancing .content .tabs .tab,
main.calculators .stamp-duty .content .tabs .tab {
  padding: 40px;
  background-color: #fff;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border: 1px solid #D8DCE7;
  display: none;
}
main.calculators .borrowing-power .content .tabs .tab:first-of-type,
main.calculators .funds-required .content .tabs .tab:first-of-type,
main.calculators .refinancing .content .tabs .tab:first-of-type,
main.calculators .stamp-duty .content .tabs .tab:first-of-type {
  display: block;
}
main.calculators .borrowing-power .content .tabs .navigation .tab-navigation,
main.calculators .funds-required .content .tabs .navigation .tab-navigation,
main.calculators .refinancing .content .tabs .navigation .tab-navigation,
main.calculators .stamp-duty .content .tabs .navigation .tab-navigation {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  padding: 18px 40px;
  display: inline-block;
  margin-left: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #223241;
  background-color: #E9EBF1;
  border: 1px solid #D8DCE7;
  border-bottom: none;
}
main.calculators .borrowing-power .content .tabs .navigation .tab-navigation:first-of-type,
main.calculators .funds-required .content .tabs .navigation .tab-navigation:first-of-type,
main.calculators .refinancing .content .tabs .navigation .tab-navigation:first-of-type,
main.calculators .stamp-duty .content .tabs .navigation .tab-navigation:first-of-type {
  margin-left: 0;
}
main.calculators .borrowing-power .content .tabs .navigation .tab-navigation.tab-link-active,
main.calculators .funds-required .content .tabs .navigation .tab-navigation.tab-link-active,
main.calculators .refinancing .content .tabs .navigation .tab-navigation.tab-link-active,
main.calculators .stamp-duty .content .tabs .navigation .tab-navigation.tab-link-active {
  background-color: #fff;
  border: 1px solid #D8DCE7;
  border-bottom: none;
}
main.calculators .borrowing-power .content .tabs .tab-content,
main.calculators .funds-required .content .tabs .tab-content,
main.calculators .refinancing .content .tabs .tab-content,
main.calculators .stamp-duty .content .tabs .tab-content {
  margin-top: -1px;
}
main.calculators .borrowing-power .content .references,
main.calculators .funds-required .content .references,
main.calculators .refinancing .content .references,
main.calculators .stamp-duty .content .references {
  margin-top: 20px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  line-height: 1.2;
  color: #727C8F;
  text-align: left;
}
main.calculators .borrowing-power .content .references sup,
main.calculators .funds-required .content .references sup,
main.calculators .refinancing .content .references sup,
main.calculators .stamp-duty .content .references sup {
  vertical-align: super;
  font-size: smaller;
}
main.calculators .borrowing-power .content .help,
main.calculators .funds-required .content .help,
main.calculators .refinancing .content .help,
main.calculators .stamp-duty .content .help {
  padding: 25px 0;
  margin: 20px 0;
  border-top: 1px solid #D8DCE7;
}
main.calculators .borrowing-power .content .help h3,
main.calculators .funds-required .content .help h3,
main.calculators .refinancing .content .help h3,
main.calculators .stamp-duty .content .help h3 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  color: #223241;
  margin-bottom: 15px;
}
main.calculators .borrowing-power .content .help p,
main.calculators .funds-required .content .help p,
main.calculators .refinancing .content .help p,
main.calculators .stamp-duty .content .help p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #575E6C;
  line-height: 1.3;
}
main.calculators .borrowing-power .content .help .navigation,
main.calculators .funds-required .content .help .navigation,
main.calculators .refinancing .content .help .navigation,
main.calculators .stamp-duty .content .help .navigation {
  margin: 20px 0 15px;
}
main.calculators .borrowing-power .content .help .navigation .get-advice,
main.calculators .funds-required .content .help .navigation .get-advice,
main.calculators .refinancing .content .help .navigation .get-advice,
main.calculators .stamp-duty .content .help .navigation .get-advice,
main.calculators .borrowing-power .content .help .navigation .lets-chat,
main.calculators .funds-required .content .help .navigation .lets-chat,
main.calculators .refinancing .content .help .navigation .lets-chat,
main.calculators .stamp-duty .content .help .navigation .lets-chat {
  display: inline-block;
  vertical-align: top;
  width: Calc(50% - 10px);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
  padding: 8px 25px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
}
main.calculators .borrowing-power .content .help .navigation .get-advice:hover,
main.calculators .funds-required .content .help .navigation .get-advice:hover,
main.calculators .refinancing .content .help .navigation .get-advice:hover,
main.calculators .stamp-duty .content .help .navigation .get-advice:hover,
main.calculators .borrowing-power .content .help .navigation .lets-chat:hover,
main.calculators .funds-required .content .help .navigation .lets-chat:hover,
main.calculators .refinancing .content .help .navigation .lets-chat:hover,
main.calculators .stamp-duty .content .help .navigation .lets-chat:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
main.calculators .borrowing-power .content .help .navigation .get-advice:active,
main.calculators .funds-required .content .help .navigation .get-advice:active,
main.calculators .refinancing .content .help .navigation .get-advice:active,
main.calculators .stamp-duty .content .help .navigation .get-advice:active,
main.calculators .borrowing-power .content .help .navigation .lets-chat:active,
main.calculators .funds-required .content .help .navigation .lets-chat:active,
main.calculators .refinancing .content .help .navigation .lets-chat:active,
main.calculators .stamp-duty .content .help .navigation .lets-chat:active,
main.calculators .borrowing-power .content .help .navigation .get-advice:focus,
main.calculators .funds-required .content .help .navigation .get-advice:focus,
main.calculators .refinancing .content .help .navigation .get-advice:focus,
main.calculators .stamp-duty .content .help .navigation .get-advice:focus,
main.calculators .borrowing-power .content .help .navigation .lets-chat:focus,
main.calculators .funds-required .content .help .navigation .lets-chat:focus,
main.calculators .refinancing .content .help .navigation .lets-chat:focus,
main.calculators .stamp-duty .content .help .navigation .lets-chat:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
main.calculators .borrowing-power .content .help .navigation .lets-chat,
main.calculators .funds-required .content .help .navigation .lets-chat,
main.calculators .refinancing .content .help .navigation .lets-chat,
main.calculators .stamp-duty .content .help .navigation .lets-chat {
  margin-left: 20px;
}
main.calculators .borrowing-power .content .help .navigation .lets-chat span,
main.calculators .funds-required .content .help .navigation .lets-chat span,
main.calculators .refinancing .content .help .navigation .lets-chat span,
main.calculators .stamp-duty .content .help .navigation .lets-chat span {
  background-image: url(img/button/chat-blue.png);
  background-repeat: no-repeat;
  background-position: 0% 50%;
  background-size: 12px 12px;
  padding-left: 20px;
}
main.calculators .borrowing-power .content .help .search-loans,
main.calculators .funds-required .content .help .search-loans,
main.calculators .refinancing .content .help .search-loans,
main.calculators .stamp-duty .content .help .search-loans {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  width: 100%;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  padding: 10px 25px;
}
main.calculators .borrowing-power .content .help .search-loans:hover,
main.calculators .funds-required .content .help .search-loans:hover,
main.calculators .refinancing .content .help .search-loans:hover,
main.calculators .stamp-duty .content .help .search-loans:hover {
  background-color: #46A3FF;
}
main.calculators .borrowing-power .content .help .search-loans:active,
main.calculators .funds-required .content .help .search-loans:active,
main.calculators .refinancing .content .help .search-loans:active,
main.calculators .stamp-duty .content .help .search-loans:active,
main.calculators .borrowing-power .content .help .search-loans:focus,
main.calculators .funds-required .content .help .search-loans:focus,
main.calculators .refinancing .content .help .search-loans:focus,
main.calculators .stamp-duty .content .help .search-loans:focus {
  background-color: #1C83EA;
}
main.calculators .borrowing-power .content .help .search-loans:disabled,
main.calculators .funds-required .content .help .search-loans:disabled,
main.calculators .refinancing .content .help .search-loans:disabled,
main.calculators .stamp-duty .content .help .search-loans:disabled,
main.calculators .borrowing-power .content .help .search-loans.disabled,
main.calculators .funds-required .content .help .search-loans.disabled,
main.calculators .refinancing .content .help .search-loans.disabled,
main.calculators .stamp-duty .content .help .search-loans.disabled {
  background: #909090;
}
main.calculators .borrowing-power .content .temp-image,
main.calculators .funds-required .content .temp-image,
main.calculators .refinancing .content .temp-image,
main.calculators .stamp-duty .content .temp-image {
  width: 100%;
  height: 300px;
  background-color: #E9EBF1;
}
main.calculators .borrowing-power .half-column .w280,
main.calculators .funds-required .half-column .w280 {
  width: 100%;
}
main.calculators .borrowing-power .image,
main.calculators .funds-required .image {
  background-image: url(img/page_illustrations/calculators_borrowing.png);
  width: 100%;
  height: 375px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
@media (max-width: 1210px) {
  main.calculators .borrowing-power .image,
  main.calculators .funds-required .image {
    height: 250px;
  }
}
main.calculators .stamp-duty .image {
  background-image: url(img/page_illustrations/calculators_stamp_duty.png);
  width: 100%;
  height: 275px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  margin-bottom: -40px;
  margin-top: 40px;
}
@media (max-width: 1210px) {
  main.calculators .stamp-duty .image {
    height: 200px;
  }
}
main.calculators .refinancing .image {
  background-image: url(img/page_illustrations/calculators_refinance.png);
  width: 100%;
  height: 495px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  margin-top: 40px;
  margin-bottom: -20px;
}
@media (max-width: 1210px) {
  main.calculators .refinancing .image {
    background-image: url(img/page_illustrations/success_2.png);
    background-size: 320px auto;
    height: 250px;
  }
}
main.contact .contact-box-container {
  margin-bottom: 50px;
}
main.contact .contact-box-container .contact-box {
  display: inline-block;
  vertical-align: top;
  width: Calc(100% / 3 - 15px);
  background: #FFFFFF;
  border: 1px solid #D8DCE7;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 40px 60px;
  text-align: center;
  margin-right: 20px;
}
main.contact .contact-box-container .contact-box:last-of-type {
  margin-right: 0;
}
main.contact .contact-box-container .contact-box h4 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  color: #223241;
  margin-bottom: 10px;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  padding-top: 70px;
  background-size: 45px;
}
main.contact .contact-box-container .contact-box p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #223241;
  margin-bottom: 20px;
  line-height: 1.4;
}
main.contact .contact-box-container .contact-box .contact-button {
  display: block;
  margin: 0 auto;
  line-height: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  padding: 12px 60px;
}
main.contact .contact-box-container .contact-box .contact-button:hover {
  background-color: #46A3FF;
}
main.contact .contact-box-container .contact-box .contact-button:active,
main.contact .contact-box-container .contact-box .contact-button:focus {
  background-color: #1C83EA;
}
main.contact .contact-box-container .contact-box .contact-button:disabled,
main.contact .contact-box-container .contact-box .contact-button.disabled {
  background: #909090;
}
main.contact .contact-box-container .contact-box.email-us h4 {
  background-image: url(img/icons/contact-email.png);
}
main.contact .contact-box-container .contact-box.chat h4 {
  background-image: url(img/icons/contact-chat.png);
}
main.contact .contact-box-container .contact-box.call-us h4 {
  background-image: url(img/icons/contact-phone.png);
}
main.contact .request-callback {
  display: block;
  margin: 0 auto;
  text-align: center;
  width: Calc(100% / 3 - 15px);
}
main.contact .request-callback h4 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  color: #223241;
}
main.contact .request-callback p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #223241;
  margin-top: 10px;
  margin-bottom: 30px;
}
main.contact .request-callback .form {
  text-align: left;
}
main.contact .request-callback .form .w280 {
  width: 100%;
}
main.contact .request-callback .form .submit {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  width: 100%;
}
main.contact-initial {
  left: 0;
}
main.upload {
  background-color: #fff;
}
main.upload .main-container {
  max-width: 820px;
}
main.upload .intro-text {
  margin-bottom: 40px;
}
main.upload .intro-text h1 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 22px;
  color: #101922;
}
main.upload .intro-text p {
  margin-top: 10px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #727C8F;
}
main.upload .intro-text ul.instructions {
  margin-top: 40px;
  color: #223241;
  background-image: url(img/elements/cloud-upload.png);
  background-size: 48px 48px;
  background-position: 0 50%;
  background-repeat: no-repeat;
  min-height: 48px;
  padding-left: 80px;
  padding-top: 3px;
}
main.upload .intro-text ul.instructions li {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  list-style: disc;
  margin-bottom: 5px;
}
main.upload .intro-text ul.instructions strong {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}
main.upload .intro-text ul.instructions a {
  color: #2291FF;
  cursor: pointer;
}
main.upload .intro-text ul.instructions a:hover {
  color: #46A3FF;
}
main.upload .intro-text ul.instructions a:active,
main.upload .intro-text ul.instructions a:focus {
  color: #1C83EA;
}
main.upload .file-uploader {
  background: #FFFFFF;
  border: 1px solid #D8DCE7;
  border-bottom: none;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}
main.upload .file-uploader .file-uploader-header {
  border-bottom: 1px solid #D8DCE7;
}
main.upload .file-uploader .file-uploader-header ul li {
  padding: 20px 20px;
  display: inline-block;
  vertical-align: bottom;
  color: #223241;
}
main.upload .file-uploader .file-uploader-header ul li:nth-of-type(1) {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  width: 38%;
}
main.upload .file-uploader .file-uploader-header ul li:nth-of-type(2) {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 11px;
  color: #575E6C;
  width: 24%;
}
main.upload .file-uploader .file-uploader-header ul li:nth-of-type(3) {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 11px;
  color: #575E6C;
  width: 38%;
}
main.upload .file-uploader .uploading-file .upload-status {
  padding: 25px;
  border-bottom: 1px solid #D8DCE7;
}
main.upload .file-uploader .uploading-file .upload-status .upload-progress-bar {
  display: inline-block;
  vertical-align: top;
  width: Calc(100% - 52px);
}
main.upload .file-uploader .uploading-file .upload-status .upload-progress-bar .file-name {
  display: inline-block;
  vertical-align: bottom;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #575E6C;
}
main.upload .file-uploader .uploading-file .upload-status .upload-progress-bar .upload-speed {
  display: inline-block;
  vertical-align: bottom;
  float: right;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #A3A8B2;
}
main.upload .file-uploader .uploading-file .upload-status .upload-progress-bar .progress-bar-container {
  margin-top: 10px;
  background-color: #F6F7FB;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  height: 8px;
  width: 100%;
}
main.upload .file-uploader .uploading-file .upload-status .upload-progress-bar .progress-bar-container .progress-bar-indicator {
  background-color: #26BD27;
  height: 8px;
  width: 50%;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}
main.upload .file-uploader .uploading-file .upload-status .delete {
  display: inline-block;
  vertical-align: top;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  border: 1px solid #D8DCE7;
  background-color: #fff;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  height: 36px;
  width: 36px;
  background-size: 15px 15px;
  box-shadow: 0 1px 0 0 #E9EBF1;
  width: 32px;
  height: 32px;
  margin-left: 20px;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: url(img/button/trash-red.png);
}
main.upload .file-uploader .uploading-file .upload-status .delete:hover {
  border: 1px solid #C8CCD7;
}
main.upload .file-uploader .uploading-file .assign-upload {
  padding: 25px;
  border-bottom: 1px solid #D8DCE7;
  min-height: 83px;
}
main.upload .file-uploader .uploading-file .assign-upload p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  display: inline-block;
  vertical-align: middle;
  width: Calc(100% - 473px);
}
main.upload .file-uploader .uploading-file .assign-upload .assign-settings {
  display: inline-block;
  vertical-align: middle;
}
main.upload .file-uploader .uploading-file .assign-upload .assign-settings .document-type,
main.upload .file-uploader .uploading-file .assign-upload .assign-settings .document-applicant {
  -webkit-appearance: none;
  appearance: none;
  height: 32px;
  width: 168px;
  border: 1px solid #D8DCE7;
  padding-left: 15px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  background-image: url(img/button/down-blue.png);
  background-size: 10px 6px;
  background-position: Calc(100% - 15px) 50%;
  background-repeat: no-repeat;
  background-color: #fff;
}
main.upload .file-uploader .uploading-file .assign-upload .assign-settings .document-type:focus,
main.upload .file-uploader .uploading-file .assign-upload .assign-settings .document-applicant:focus {
  box-shadow: 0 0 10px rgba(34, 145, 255, 0.15);
  border: 1px solid #2291FF;
  border-radius: 4px;
}
main.upload .file-uploader .uploading-file .assign-upload .assign-settings .document-type:hover,
main.upload .file-uploader .uploading-file .assign-upload .assign-settings .document-applicant:hover {
  border: 1px solid #C8CCD7;
  box-shadow: 0 0 10px rgba(34, 145, 255, 0.15);
  border: 1px solid #2291FF;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}
main.upload .file-uploader .uploading-file .assign-upload .assign-settings .document-applicant {
  margin-left: 10px;
}
main.upload .file-uploader .uploading-file .assign-upload .assign-settings .comment,
main.upload .file-uploader .uploading-file .assign-upload .assign-settings .view,
main.upload .file-uploader .uploading-file .assign-upload .assign-settings .delete {
  display: inline-block;
  vertical-align: top;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  border: 1px solid #D8DCE7;
  background-color: #fff;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  height: 36px;
  width: 36px;
  background-size: 15px 15px;
  box-shadow: 0 1px 0 0 #E9EBF1;
  width: 32px;
  height: 32px;
  margin-left: 10px;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
main.upload .file-uploader .uploading-file .assign-upload .assign-settings .comment:hover,
main.upload .file-uploader .uploading-file .assign-upload .assign-settings .view:hover,
main.upload .file-uploader .uploading-file .assign-upload .assign-settings .delete:hover {
  border: 1px solid #C8CCD7;
}
main.upload .file-uploader .uploading-file .assign-upload .assign-settings .comment {
  background-image: url(img/button/comment-grey.png);
}
main.upload .file-uploader .uploading-file .assign-upload .assign-settings .view {
  background-image: url(img/button/view-grey.png);
  background-size: 16px 12px;
}
main.upload .file-uploader .uploading-file .assign-upload .assign-settings .delete {
  background-image: url(img/button/trash-red.png);
}
main.upload .file-uploader .file-submit {
  padding: 25px;
  text-align: center;
}
main.upload .file-uploader .file-submit p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #101922;
  line-height: 1.4;
  margin-bottom: 20px;
}
main.upload .file-uploader .file-submit p strong {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
main.upload .file-uploader .file-submit .submit {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  width: 100%;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  padding: 10px 0px;
}
main.upload .file-uploader .file-submit .submit:hover {
  background-color: #46A3FF;
}
main.upload .file-uploader .file-submit .submit:active,
main.upload .file-uploader .file-submit .submit:focus {
  background-color: #1C83EA;
}
main.upload .file-uploader .file-submit .submit:disabled,
main.upload .file-uploader .file-submit .submit.disabled {
  background: #909090;
}
main.upload .required-doc {
  margin-top: 40px;
}
main.upload .required-doc .upload-count {
  float: right;
  padding: 15px;
  background: #FFFFFF;
  border: 1px solid #E9EBF1;
  display: inline-block;
}
main.upload .required-doc .upload-count span {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  line-height: 16px;
  padding-left: 25px;
  color: #101922;
  display: block;
  background-image: url(img/button/excl-grey.png);
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: 0% 50%;
}
main.upload .required-doc .upload-count.correct span {
  background-image: url(img/button/tick-circle-green.png);
}
main.upload .required-doc .required-doc-header {
  margin-bottom: 20px;
  display: inline-block;
}
main.upload .required-doc .required-doc-header h1 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  color: #101922;
}
main.upload .required-doc .required-doc-header p {
  margin-top: 10px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #727C8F;
}
main.upload .required-doc .document-table {
  border: 1px solid #D8DCE7;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}
main.upload .required-doc .document-table table {
  width: 100%;
}
main.upload .required-doc .document-table tbody th,
main.upload .required-doc .document-table tbody td {
  border-collapse: collapse;
  vertical-align: top;
  table-layout: fixed;
}
main.upload .required-doc .document-table tbody th:nth-of-type(1),
main.upload .required-doc .document-table tbody td:nth-of-type(1) {
  width: 30%;
}
main.upload .required-doc .document-table tbody th:nth-of-type(2),
main.upload .required-doc .document-table tbody td:nth-of-type(2) {
  width: 18%;
}
main.upload .required-doc .document-table tbody th:nth-of-type(3),
main.upload .required-doc .document-table tbody td:nth-of-type(3) {
  width: 30%;
}
main.upload .required-doc .document-table tbody th:nth-of-type(4),
main.upload .required-doc .document-table tbody td:nth-of-type(4) {
  width: 22%;
}
main.upload .required-doc .document-table tbody tr:first-of-type {
  background-color: #F6F7FB;
  text-align: left;
  border-bottom: 1px solid #D8DCE7;
}
main.upload .required-doc .document-table tbody tr:first-of-type th {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 11px;
  color: #575E6C;
  padding: 12px 20px;
}
main.upload .required-doc .document-table tbody tr td {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  color: #575E6C;
  padding: 20px 20px;
}
main.upload .required-doc .document-table tbody tr td:first-of-type {
  color: #101922;
}
main.upload .required-doc .document-table tbody tr td.submitted,
main.upload .required-doc .document-table tbody tr td.approved,
main.upload .required-doc .document-table tbody tr td.rejected,
main.upload .required-doc .document-table tbody tr td.upload {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: 20px 50%;
  padding: 20px 20px 20px 45px;
}
main.upload .required-doc .document-table tbody tr td.submitted {
  background-image: url(img/elements/tick-circle-blue.png);
  color: #2291FF;
}
main.upload .required-doc .document-table tbody tr td.approved {
  background-image: url(img/elements/tick-circle-green.png);
  color: #26BD27;
}
main.upload .required-doc .document-table tbody tr td.rejected {
  background-image: url(img/elements/exc-circle-red.png);
  color: #FF4E4C;
  position: relative;
}
main.upload .required-doc .document-table tbody tr td.rejected .pop-up {
  display: none;
}
main.upload .required-doc .document-table tbody tr td.rejected:hover .pop-up {
  z-index: 10;
  position: absolute;
  display: block;
  width: 265px;
  min-height: 50px;
  left: 35px;
  margin-left: -140px;
  bottom: 60%;
  padding-bottom: 20px;
}
main.upload .required-doc .document-table tbody tr td.rejected:hover .pop-up .content {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 6px 0 rgba(216, 220, 231, 0.65);
  border-radius: 4px;
  position: relative;
}
main.upload .required-doc .document-table tbody tr td.rejected:hover .pop-up .content h4 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  color: #FF4E4C;
  margin-bottom: 10px;
}
main.upload .required-doc .document-table tbody tr td.rejected:hover .pop-up .content p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  color: #575E6C;
  line-height: 1.4;
  margin-bottom: 10px;
}
main.upload .required-doc .document-table tbody tr td.rejected:hover .pop-up .content button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
  width: 100%;
  padding: 8px 0;
}
main.upload .required-doc .document-table tbody tr td.rejected:hover .pop-up .content button:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
main.upload .required-doc .document-table tbody tr td.rejected:hover .pop-up .content button:active,
main.upload .required-doc .document-table tbody tr td.rejected:hover .pop-up .content button:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
main.upload .required-doc .document-table tbody tr td.rejected:hover .pop-up .content:after,
main.upload .required-doc .document-table tbody tr td.rejected:hover .pop-up .content:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
main.upload .required-doc .document-table tbody tr td.rejected:hover .pop-up .content:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}
main.upload .required-doc .document-table tbody tr td.rejected:hover .pop-up .content:before {
  border-color: rgba(216, 220, 231, 0);
  border-top-color: #D8DCE7;
  border-width: 12px;
  margin-left: -12px;
}
main.upload .required-doc .document-table tbody tr td.upload {
  background-image: url(img/elements/exc-circle-grey.png);
  color: #575E6C;
}
main.upload .required-doc .document-table tbody tr:nth-of-type(odd) {
  background-color: #F6F7FB;
}
main.upload .required-doc .document-table tbody tr.rejected td:first-of-type {
  color: #FF4E4C;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
}
main.upload .security-box {
  margin-top: 40px;
  display: block;
  background-color: #fff;
  width: 100%;
  border: 1px solid #D8DCE7;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}
main.upload .security-box p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  line-height: 1.2;
  color: #223241;
  background-image: url(img/elements/lock-yellow.png);
  background-size: 16px 24px;
  background-position: 20px 50%;
  padding: 20px 20px 20px 60px;
  background-repeat: no-repeat;
}
main.upload .security-box p span {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #2291FF;
}
main.user-authentication.securelink .confirmation button {
  margin-top: 30px;
  margin-bottom: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  width: 100%;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  padding: 10px 0;
}
main.user-authentication.securelink .confirmation button:hover {
  background-color: #46A3FF;
}
main.user-authentication.securelink .confirmation button:active,
main.user-authentication.securelink .confirmation button:focus {
  background-color: #1C83EA;
}
main.user-authentication.securelink .confirmation button:disabled,
main.user-authentication.securelink .confirmation button.disabled {
  background: #909090;
}
main.user-authentication.securelink .confirmation button span {
  background-image: url(img/button/magic.png);
  background-repeat: no-repeat;
  background-position: 0% 50%;
  background-size: 12px 12px;
  padding-left: 20px;
}
main.user-authentication.securelink .magic form {
  margin-top: 25px;
}
main.user-authentication.securelink .magic form label {
  display: none;
}
main.user-authentication.securelink .magic form button[type=submit] {
  margin-top: 30px;
}
main.user-authentication.securelink .magic form button[type=submit] span {
  background-image: url(img/button/magic.png);
  background-repeat: no-repeat;
  background-position: 0% 50%;
  background-size: 12px 12px;
  padding-left: 20px;
}
main.verify .main-container {
  background: #FFFFFF;
  border: 1px solid #D8DCE7;
  border-radius: 4px;
  padding: 40px;
  width: 460px;
  height: auto;
  margin: 0 auto;
  text-align: center;
}
main.verify .main-container h1 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  margin-bottom: 20px;
}
main.verify .main-container p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #727C8F;
  line-height: 1.4;
  margin-bottom: 20px;
}
main.verify .main-container p:last-of-type {
  margin-bottom: 0;
}
main.verify .main-container p .underline {
  text-decoration: underline;
}
main.verify .main-container .button {
  margin-top: 30px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
main.verify .main-container .button:hover {
  background-color: #46A3FF;
}
main.verify .main-container .button:active,
main.verify .main-container .button:focus {
  background-color: #1C83EA;
}
main.verify .main-container .button:disabled,
main.verify .main-container .button.disabled {
  background: #909090;
}
main.verify .main-container img {
  margin-top: 40px;
  width: 245px;
  margin-bottom: -42px;
  object-fit: contain;
}
.check-eligibility-container {
  vertical-align: top;
  display: inline-block;
}
.check-eligibility-container .how-does-this-work {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 11px;
  color: #2291FF;
  text-align: center;
  margin-top: 7px;
  margin-right: 5px;
  display: block;
  cursor: pointer;
}
.check-eligiblity-button {
  vertical-align: top;
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  padding: 9px 0;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  margin-right: 5px;
  width: 110px;
  transition: background color 0.3s;
  box-sizing: border-box;
  border: 1px solid #1C83EA;
}
.check-eligiblity-button:hover {
  background-color: #46A3FF;
}
.check-eligiblity-button:active,
.check-eligiblity-button:focus {
  background-color: #1C83EA;
}
.check-eligiblity-button:disabled,
.check-eligiblity-button.disabled {
  background: #909090;
}
.check-eligiblity-button.active {
  background: rgba(38, 189, 39, 0.07);
  border: 1px solid #D8DCE7;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  color: #26BD27;
  box-shadow: 0 1px 0 0 #E9EBF1;
}
.check-eligiblity-button.active span {
  background-image: url(img/elements/tick-circle-green.png);
  background-size: 16px 16px;
  background-position: 0% 50%;
  background-repeat: no-repeat;
  padding-left: 25px;
}
.check-eligiblity-button.active.remove-loan {
  background: #f8e9e9;
  color: red;
}
.check-eligiblity-button.active.remove-loan span {
  background-image: url(img/button/cross-circle-red.png);
}
.refinance-analysis-box {
  margin: 60px 0;
}
.refinance-analysis-box .refinance-title {
  width: 561px;
  height: 60px;
}
.refinance-analysis-box .refinance-title .refinance-title-container {
  display: inline-block;
  vertical-align: top;
  height: 60px;
  width: 501px;
  border-top-right-radius: 4px;
  background-color: #fff;
  border-top: 1px solid #D8DCE7;
  border-right: 1px solid #D8DCE7;
  border-bottom: none;
}
.refinance-analysis-box .refinance-title button {
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  background-color: #fff;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  height: 36px;
  width: 36px;
  box-shadow: 0 1px 0 0 #E9EBF1;
  background-image: url(img/button/modal-close.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 15px 15px;
  border: 1px solid #D8DCE7;
  height: 60px;
  width: 60px;
  background-color: #FDFDFD;
  border-top-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom: none;
}
.refinance-analysis-box .refinance-title button:hover {
  border: 1px solid #C8CCD7;
}
.refinance-analysis-box .refinance-title button:hover {
  border: 1px solid #C8CCD7;
}
.refinance-analysis-box .refinance-title h2 {
  top: 50%;
  position: relative;
  transform: translate3d(0, -50%, 0);
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  text-align: center;
  width: Calc(100% - 60px);
}
.refinance-analysis-box .refinance-title h2 span {
  background-image: url(img/button/stats-blue-large.png);
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: 20px 20px;
  padding-left: 30px;
}
.refinance-content {
  background-color: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: table;
}
.refinance-content tr {
  border-top: solid 1px #E9EBF1;
  background-color: rgba(233, 235, 241, 0.2);
}
.refinance-content tr:first-of-type {
  border-top: none;
  background-color: rgba(0, 0, 0, 0);
}
.refinance-content tr td {
  vertical-align: top;
}
.refinance-content tr td:first-of-type {
  width: 560px;
}
@media (max-width: 1210px) {
  .refinance-content tr td:first-of-type {
    width: 50%;
  }
}
.refinance-content .left-side .current-loan,
.refinance-content .left-side .new-loan {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  text-align: center;
}
.refinance-content .left-side .current-loan .loan,
.refinance-content .left-side .new-loan .loan {
  border-bottom: 1px solid #E9EBF1;
  padding: 20px 0 0 0;
}
.refinance-content .left-side .current-loan .loan .logo-container,
.refinance-content .left-side .new-loan .loan .logo-container {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.refinance-content .left-side .current-loan .loan h4,
.refinance-content .left-side .new-loan .loan h4 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
.refinance-content .left-side .current-loan .loan h6,
.refinance-content .left-side .new-loan .loan h6 {
  height: 32px;
}
.refinance-content .left-side .current-loan .loan img,
.refinance-content .left-side .new-loan .loan img {
  max-width: 100px;
  max-height: 30px;
  object-fit: contain;
}
.refinance-content .left-side .current-loan .loan .company-text,
.refinance-content .left-side .new-loan .loan .company-text {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #727C8F;
  line-height: 30px;
}
.refinance-content .left-side .current-loan .rates,
.refinance-content .left-side .new-loan .rates {
  border-bottom: 1px solid #E9EBF1;
  padding: 26px 0;
}
.refinance-content .left-side .current-loan .rates .variable-rate .rate,
.refinance-content .left-side .new-loan .rates .variable-rate .rate,
.refinance-content .left-side .current-loan .rates .comparison-rate .rate,
.refinance-content .left-side .new-loan .rates .comparison-rate .rate {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 24px;
}
.refinance-content .left-side .current-loan .rates .variable-rate .rate span,
.refinance-content .left-side .new-loan .rates .variable-rate .rate span,
.refinance-content .left-side .current-loan .rates .comparison-rate .rate span,
.refinance-content .left-side .new-loan .rates .comparison-rate .rate span {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #727C8F;
}
.refinance-content .left-side .current-loan .rates .variable-rate .rate-description,
.refinance-content .left-side .new-loan .rates .variable-rate .rate-description,
.refinance-content .left-side .current-loan .rates .comparison-rate .rate-description,
.refinance-content .left-side .new-loan .rates .comparison-rate .rate-description {
  margin-top: 5px;
  text-align: center;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #727C8F;
}
.refinance-content .left-side .current-loan .rates a,
.refinance-content .left-side .new-loan .rates a {
  margin-top: 15px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #2291FF;
  cursor: pointer;
  display: block;
  height: 14px;
}
.refinance-content .left-side .current-loan .rates a:hover,
.refinance-content .left-side .new-loan .rates a:hover {
  color: #46A3FF;
}
.refinance-content .left-side .current-loan .rates a:active,
.refinance-content .left-side .new-loan .rates a:active,
.refinance-content .left-side .current-loan .rates a:focus,
.refinance-content .left-side .new-loan .rates a:focus {
  color: #1C83EA;
}
.refinance-content .left-side .current-loan .rates p,
.refinance-content .left-side .new-loan .rates p {
  margin-top: 15px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 10px;
}
.refinance-content .left-side .current-loan .type,
.refinance-content .left-side .new-loan .type {
  border-bottom: 1px solid #E9EBF1;
  padding: 20px 0;
}
.refinance-content .left-side .current-loan .type .principal-interest h5,
.refinance-content .left-side .new-loan .type .principal-interest h5,
.refinance-content .left-side .current-loan .type .loan-amount h5,
.refinance-content .left-side .new-loan .type .loan-amount h5 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  color: #101922;
}
.refinance-content .left-side .current-loan .type .principal-interest .description,
.refinance-content .left-side .new-loan .type .principal-interest .description,
.refinance-content .left-side .current-loan .type .loan-amount .description,
.refinance-content .left-side .new-loan .type .loan-amount .description {
  margin-top: 5px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 10px;
  color: #727C8F;
}
.refinance-content .left-side .current-loan .type .principal-interest,
.refinance-content .left-side .new-loan .type .principal-interest {
  margin-bottom: 15px;
}
.refinance-content .left-side .current-loan .repayments,
.refinance-content .left-side .new-loan .repayments {
  padding: 20px 0;
}
.refinance-content .left-side .current-loan .repayments .indicator,
.refinance-content .left-side .new-loan .repayments .indicator {
  display: block;
  margin: 0 auto;
  width: Calc(100% - 40px);
  height: 24px;
  border-radius: 12px;
  background-color: #E9EBF1;
  margin-bottom: 20px;
}
.refinance-content .left-side .current-loan .repayments .indicator .indicator-value,
.refinance-content .left-side .new-loan .repayments .indicator .indicator-value {
  background-color: #727C8F;
  height: 24px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #fff;
  border-radius: 12px;
  line-height: 24px;
  width: 100%;
}
.refinance-content .left-side .current-loan .repayments h2,
.refinance-content .left-side .new-loan .repayments h2 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 22px;
  color: #727C8F;
}
.refinance-content .left-side .current-loan {
  border-right: 1px solid #E9EBF1;
}
.refinance-content .left-side .new-loan .rates .variable-rate,
.refinance-content .left-side .new-loan .rates .comparison-rate {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}
.refinance-content .left-side .new-loan .rates p {
  display: block;
  font-size: 12px;
  height: 14px;
  color: #23B024;
  text-transform: uppercase;
  margin-bottom: 0 !important;
}
.refinance-content .left-side .new-loan .repayments .indicator .indicator-value {
  background-color: #2291FF;
  width: 85%;
}
.refinance-content .left-side .new-loan .repayments h2 {
  color: #2291FF;
}
.refinance-content .right-cell {
  border-left: solid 1px #E9EBF1;
}
.refinance-content .refinance-details {
  padding: 20px;
}
.refinance-content .refinance-details p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #575E6C;
  line-height: 120%;
}
.refinance-content .refinance-details p strong {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
}
.refinance-content .refinance-details .navigation {
  margin-top: 20px;
}
.refinance-content .refinance-details .navigation .update-loan {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  padding: 12px 40px;
}
.refinance-content .refinance-details .navigation .update-loan:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
.refinance-content .refinance-details .navigation .update-loan:active,
.refinance-content .refinance-details .navigation .update-loan:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
@media (max-width: 1210px) {
  .refinance-content .refinance-details .navigation .update-loan {
    width: 100%;
  }
}
.refinance-content .show-repayments {
  float: right;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
@media (max-width: 1210px) {
  .refinance-content .show-repayments {
    text-align: center;
    float: none;
    margin-top: 10px;
  }
}
.refinance-content .show-repayments span {
  margin-right: 10px;
}
.refinance-content .show-repayments select {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  height: 40px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  width: 110px;
  background-image: url(img/button/down-blue.png);
  background-size: 10px 6px;
  background-position: Calc(100% - 15px) 50%;
  background-repeat: no-repeat;
  background-color: transparent;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
  border-radius: 4px;
  border: 1px solid rgba(233, 235, 241, 0.2);
}
.refinance-content .show-repayments:hover select {
  margin-left: 5px;
  width: 110px;
  -webkit-appearance: none;
  appearance: none;
  height: 40px;
  border: 1px solid #D8DCE7;
  padding-left: 15px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  background-image: url(img/button/down-blue.png);
  background-size: 10px 6px;
  background-position: Calc(100% - 15px) 50%;
  background-repeat: no-repeat;
  background-color: transparent;
  border-radius: 4px;
}
.refinance-content .show-repayments:hover select:focus {
  box-shadow: 0 0 10px rgba(34, 145, 255, 0.15);
  border: 1px solid #2291FF;
  border-radius: 4px;
}
.refinance-content .show-repayments:hover select:hover {
  border: 1px solid #C8CCD7;
  box-shadow: 0 0 10px rgba(34, 145, 255, 0.15);
  border: 1px solid #2291FF;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}
.refinance-content .show-repayments:hover select:disabled {
  color: #D8DCE7;
}
.refinance-content .next-steps {
  padding: 20px;
}
.refinance-content .next-steps p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  line-height: 1.6;
  color: #575E6C;
}
.refinance-content .next-steps p strong {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
}
.refinance-content .next-steps .navigation {
  margin-top: 20px;
  text-align: left;
}
.refinance-content .next-steps .navigation .get-advice {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  width: 200px;
}
.refinance-content .next-steps .navigation .get-advice:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
.refinance-content .next-steps .navigation .get-advice:active,
.refinance-content .next-steps .navigation .get-advice:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
.refinance-content .next-steps .navigation .get-advice span {
  background-image: url(img/button/right-arrow-short-blue.png);
  background-size: 7px 12px;
  background-repeat: no-repeat;
  background-position: 100% 50%;
  padding-right: 25px;
}
@media (max-width: 1210px) {
  .refinance-content .next-steps .navigation .get-advice {
    width: 100%;
  }
}
.refinance-content .next-steps .navigation .check-eligibility-container {
  float: right;
}
.refinance-content .next-steps .navigation .check-eligibility-container .check-eligiblity-button {
  width: 200px;
  padding: 12px 0;
  float: right;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}
@media (max-width: 1210px) {
  .refinance-content .next-steps .navigation .check-eligibility-container .check-eligiblity-button {
    width: 100%;
    float: none;
  }
}
@media (max-width: 1210px) {
  .refinance-content .next-steps .navigation .check-eligibility-container {
    width: 100%;
    float: none;
    margin-top: 15px;
  }
}
.refinance-content .right-side {
  position: relative;
  pointer-events: none;
}
.refinance-content .right-side > * {
  pointer-events: auto;
}
.refinance-content .right-side .top {
  padding: 20px 20px 0 20px;
  pointer-events: none;
}
.refinance-content .right-side .top > * {
  pointer-events: auto;
}
.refinance-content .right-side .top .toggle-details,
.refinance-content .right-side .top .shortlist {
  vertical-align: top;
  display: inline-block;
}
.refinance-content .right-side .top .toggle-details {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
  height: 36px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  padding: 0 35px 0 15px;
  background-position: Calc(100% - 15px) 50%;
  background-size: 10px 10px;
  background-image: url(img/button/thin-burger.png);
  background-repeat: no-repeat;
}
.refinance-content .right-side .top .toggle-details:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
.refinance-content .right-side .top .toggle-details:active,
.refinance-content .right-side .top .toggle-details:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
.refinance-content .right-side .top .toggle-details.product-details-view {
  background-image: url(img/button/thin-burger.png);
}
.refinance-content .right-side .top .toggle-details.chart-view {
  background-image: url(img/button/stats-blue.png);
}
.refinance-content .right-side .top .shortlist {
  float: right;
}
.refinance-content .right-side .top .shortlist p,
.refinance-content .right-side .top .shortlist .favourites {
  display: inline-block;
  vertical-align: middle;
}
.refinance-content .right-side .top .shortlist p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 10px;
  text-align: right;
  margin-right: 10px;
}
@media (max-width: 1210px) {
  .refinance-content .right-side .top .shortlist p {
    display: none;
  }
}
.refinance-content .right-side .top .shortlist .favourites {
  display: inline-block;
  vertical-align: middle;
}
.refinance-content .right-side .product-details .product-content {
  padding: 22px 20px 20px 20px;
  min-height: 350px;
}
.refinance-content .right-side .product-details .product-content h4 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.4;
}
.refinance-content .right-side .product-details .product-content p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  line-height: 1.4;
  color: black !important;
  margin-bottom: 0 !important;
}
.refinance-content .right-side .product-details .product-content .product-name {
  display: block;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  margin-bottom: 20px;
  font-style: italic;
}
.refinance-content .right-side .product-details .product-content .details {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  margin-bottom: 15px;
}
@media (max-width: 1210px) {
  .refinance-content .right-side .product-details .product-content .details {
    width: 100%;
  }
}
.refinance-content .right-side .product-details .product-content .details h5 {
  color: #727C8F;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 10px;
  margin-bottom: 8px;
}
.refinance-content .right-side .product-details .product-content .details p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  margin-top: 5px;
}
.refinance-content .right-side .product-details .product-content .details .ongoing,
.refinance-content .right-side .product-details .product-content .details .upfront {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}
.refinance-content .right-side .product-details .product-content .details ul.statistics {
  display: block;
}
.refinance-content .right-side .product-details .product-content .details ul.statistics li {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0% 50%;
  background-size: 10px 10px;
  padding-left: 15px;
  margin-left: 10px;
}
.refinance-content .right-side .product-details .product-content .details ul.statistics li.true,
.refinance-content .right-side .product-details .product-content .details ul.statistics li.Y {
  background-image: url(img/elements/check-green.png);
  color: #26BD27;
}
.refinance-content .right-side .product-details .product-content .details ul.statistics li.false,
.refinance-content .right-side .product-details .product-content .details ul.statistics li.F,
.refinance-content .right-side .product-details .product-content .details ul.statistics li.N {
  background-image: url(img/elements/cross-red.png);
  color: #FF4E4C;
}
.refinance-content .right-side .product-details .product-content .details ul.statistics li.additional {
  background-image: url(img/elements/money_blue.png);
  color: #2291FF;
  background-size: 8px 12px;
  background-position: 0px 50%;
}
.refinance-content .right-side .product-details .product-content .details ul.statistics li:first-of-type {
  margin-left: 0;
}
.refinance-content .right-side .product-details .product-content .key-details {
  display: block;
  height: 170px;
  margin-top: 13px;
  overflow: auto;
}
.refinance-content .right-side .product-details .product-content .key-details p {
  line-height: 2;
}
.refinance-content .right-side .product-details .product-content .key-details a {
  color: #2291FF;
  cursor: pointer;
}
.refinance-content .right-side .product-details .product-content .key-details a:hover {
  color: #46A3FF;
}
.refinance-content .right-side .product-details .product-content .key-details a:active,
.refinance-content .right-side .product-details .product-content .key-details a:focus {
  color: #1C83EA;
}
.refinance-content .right-side .product-details .product-content a {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  color: #2291FF;
  cursor: pointer;
}
.refinance-content .right-side .product-details .product-content a:hover {
  color: #46A3FF;
}
.refinance-content .right-side .product-details .product-content a:active,
.refinance-content .right-side .product-details .product-content a:focus {
  color: #1C83EA;
}
.refinance-content .right-side .view-chart .graph {
  width: Calc(100% - 40px);
  display: block;
  margin: 10px auto 0;
  margin-top: 20px;
  height: 267px;
  position: relative;
}
.refinance-content .right-side .view-chart .graph .potential-savings {
  display: block;
  position: absolute;
  right: 30px;
  text-align: right;
}
.refinance-content .right-side .view-chart .graph .potential-savings h2 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 22px;
  color: #101922;
  margin-bottom: 10px;
}
.refinance-content .right-side .view-chart .graph .potential-savings p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #575E6C;
  line-height: 1.2;
}
.refinance-content .right-side .view-chart .graph .potential-savings.positive {
  bottom: 60px;
}
.refinance-content .right-side .view-chart .graph .potential-savings.negative {
  top: 30px;
}
.refinance-content .right-side .view-chart .product-comparison {
  margin: 20px 0;
}
.refinance-content .right-side .view-chart .product-comparison .monthly-savings,
.refinance-content .right-side .view-chart .product-comparison .lvr,
.refinance-content .right-side .view-chart .product-comparison .borrowing-power {
  display: inline-block;
  vertical-align: top;
  width: 33%;
  text-align: center;
  padding: 0 10px;
}
.refinance-content .right-side .view-chart .product-comparison .monthly-savings .value,
.refinance-content .right-side .view-chart .product-comparison .lvr .value {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 22px;
  color: #101922;
}
@media (max-width: 1210px) {
  .refinance-content .right-side .view-chart .product-comparison .monthly-savings .value,
  .refinance-content .right-side .view-chart .product-comparison .lvr .value {
    font-weight: 600;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
  }
}
.refinance-content .right-side .view-chart .product-comparison .monthly-savings .value.red,
.refinance-content .right-side .view-chart .product-comparison .lvr .value.red {
  color: #FF4E4C;
}
.refinance-content .right-side .view-chart .product-comparison .monthly-savings .value.orange,
.refinance-content .right-side .view-chart .product-comparison .lvr .value.orange {
  color: #F5A623;
}
.refinance-content .right-side .view-chart .product-comparison .monthly-savings .value-description,
.refinance-content .right-side .view-chart .product-comparison .lvr .value-description {
  margin-top: 5px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #575E6C;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.refinance-content .right-side .view-chart .product-comparison .borrowing-power .borrowing-value {
  padding-left: 40px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 22px;
}
.refinance-content .right-side .view-chart .product-comparison .borrowing-power .borrowing-value.empty-value {
  padding-left: 0;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}
@media (max-width: 1210px) {
  .refinance-content .right-side .view-chart .product-comparison .borrowing-power .borrowing-value {
    font-weight: 600;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
  }
}
.refinance-content .right-side .view-chart .product-comparison .borrowing-power .description {
  margin-top: 5px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #575E6C;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.proposal-refinance {
  max-width: 920px;
  margin: 20px 0 25px 0 !important;
}
.proposal-refinance tr {
  background-color: transparent;
}
@media (min-width: 1210px) {
  .proposal-refinance tr:nth-of-type(2) td:first-of-type {
    border-right: solid 1px #E9EBF1;
  }
}
.proposal-refinance tr td {
  width: 58%;
}
@media (max-width: 480px) {
  .proposal-refinance tr td {
    border-top: solid 1px #E9EBF1;
  }
}
.proposal-refinance tr td:first-of-type {
  border: none;
  width: 42%;
}
.proposal-refinance .left-side,
.proposal-refinance .right-side {
  width: 100%;
}
.proposal-refinance .left-side {
  padding: 25px 0 25px 25px;
}
@media (max-width: 780px) {
  .proposal-refinance .left-side {
    padding: 25px 0 5px 25px;
  }
}
@media (max-width: 480px) {
  .proposal-refinance .left-side {
    padding: 25px 0 5px 20px;
  }
}
.proposal-refinance .td-product-comparison {
  vertical-align: middle;
}
@media (max-width: 480px) {
  .proposal-refinance .td-product-comparison {
    padding-right: 10px;
  }
}
@media (max-width: 780px) {
  .proposal-refinance .top-items {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    flex-wrap: wrap;
  }
}
.proposal-refinance .loan {
  text-align: center;
  margin-bottom: 25px;
}
@media (max-width: 780px) {
  .proposal-refinance .loan {
    margin-left: -25px;
  }
}
@media (max-width: 450px) {
  .proposal-refinance .loan {
    flex: 0 0 100%;
  }
}
.proposal-refinance .loan .logo-container {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.proposal-refinance .loan img {
  max-width: 100px;
  max-height: 40px;
  object-fit: contain;
}
.proposal-refinance .loan .loan-name {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
}
.proposal-refinance .type {
  margin-bottom: 30px;
}
@media (max-width: 780px) {
  .proposal-refinance .type {
    margin-bottom: 25px;
  }
}
@media (max-width: 450px) {
  .proposal-refinance .type {
    flex: 0 0 100%;
    margin-left: -25px;
  }
}
.proposal-refinance .type h5 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 26px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.proposal-refinance .type .description {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  margin-top: 5px;
  color: #727C8F;
  display: block;
  text-align: center;
}
.proposal-refinance .repayments .indicator {
  display: inline-block;
  vertical-align: top;
  width: Calc(100% - 120px);
  height: 24px;
  border-radius: 12px;
  background-color: #E9EBF1;
  margin-bottom: 20px;
}
.proposal-refinance .repayments .indicator .indicator-value {
  background-color: #727C8F;
  height: 24px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #fff;
  border-radius: 12px;
  line-height: 24px;
  width: 100%;
  text-align: center;
}
.proposal-refinance .repayments h2 {
  max-width: 120px;
  display: inline-block;
  vertical-align: top;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 22px;
  padding-left: 20px;
}
.proposal-refinance .repayments.new .indicator-value {
  background-color: #2291FF;
}
.proposal-refinance .repayments.new h2 {
  color: #2291FF;
}
@media (max-width: 780px) {
  .proposal-refinance .repayments.new .indicator {
    margin-bottom: 0;
  }
}
.proposal-refinance .refinance-details p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #575E6C;
  line-height: 120%;
}
.proposal-refinance .product-comparison {
  text-align: center;
  padding: 20px;
}
.proposal-refinance .product-comparison .monthly-savings,
.proposal-refinance .product-comparison .lvr,
.proposal-refinance .product-comparison .rebate-savings {
  width: 50%;
  vertical-align: top;
  display: inline-block;
}
@media (max-width: 480px) {
  .proposal-refinance .product-comparison .monthly-savings,
  .proposal-refinance .product-comparison .lvr,
  .proposal-refinance .product-comparison .rebate-savings {
    display: block;
    width: 100% !important;
  }
  .proposal-refinance .product-comparison .monthly-savings.monthly-savings,
  .proposal-refinance .product-comparison .lvr.monthly-savings,
  .proposal-refinance .product-comparison .rebate-savings.monthly-savings,
  .proposal-refinance .product-comparison .monthly-savings.lvr,
  .proposal-refinance .product-comparison .lvr.lvr,
  .proposal-refinance .product-comparison .rebate-savings.lvr {
    margin-bottom: 20px;
  }
  .proposal-refinance .product-comparison .monthly-savings.monthly-savings:last-of-type,
  .proposal-refinance .product-comparison .lvr.monthly-savings:last-of-type,
  .proposal-refinance .product-comparison .rebate-savings.monthly-savings:last-of-type,
  .proposal-refinance .product-comparison .monthly-savings.lvr:last-of-type,
  .proposal-refinance .product-comparison .lvr.lvr:last-of-type,
  .proposal-refinance .product-comparison .rebate-savings.lvr:last-of-type {
    margin-bottom: 0px;
  }
}
.proposal-refinance .product-comparison.three-items .monthly-savings,
.proposal-refinance .product-comparison.three-items .lvr,
.proposal-refinance .product-comparison.three-items .rebate-savings {
  width: 33%;
}
.proposal-refinance .product-comparison .value {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 22px;
  color: #101922;
}
.proposal-refinance .product-comparison .value.red {
  color: #FF4E4C;
}
.proposal-refinance .product-comparison .value.orange {
  color: #F5A623;
}
.proposal-refinance .product-comparison .value-description {
  margin-top: 5px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #575E6C;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.proposal-refinance .show-repayments {
  float: none;
  text-align: center;
}
@media (max-width: 640px) {
  .proposal-refinance .show-repayments {
    margin: 0 0 0 0;
  }
}
.proposal-refinance .graph {
  height: 290px !important;
}
.shortlist-result {
  margin-right: 20px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border: solid 1px #D8DCE7;
  display: inline-block;
  width: 280px;
  background-color: #fff;
  vertical-align: top;
  padding: 20px;
  margin-top: 25px;
  min-height: 739px;
  position: relative;
}
.shortlist-result .field-description {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 11px;
  margin-top: 5px;
  color: #727C8F;
  display: block;
  text-align: center;
}
.shortlist-result .field-description.true-rate {
  height: 30px;
}
.shortlist-result .lender {
  text-align: center;
}
.shortlist-result .lender h6 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  padding: 20px 0 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.shortlist-result .lender .favourites {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  border: 1px solid #D8DCE7;
  background-color: #fff;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  height: 36px;
  width: 36px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 15px 15px;
  box-shadow: 0 1px 0 0 #E9EBF1;
  background-image: url(img/button/star_grey.png);
  width: 32px;
  height: 32px;
  background-image: url(img/button/star_orange_filled.png);
  float: right;
  position: relative;
}
.shortlist-result .lender .favourites:hover {
  border: 1px solid #C8CCD7;
}
.shortlist-result .lender .favourites.active {
  border: 1px solid #F5A623;
  background-image: url(img/button/star_orange_filled.png);
}
.shortlist-result .lender .favourites .confirmFavourites {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #FF4E4C;
  position: absolute;
  top: 29px;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 6px 0 rgba(216, 220, 231, 0.65);
  border-radius: 4px 0 4px 4px;
  right: -1px;
  width: 130px;
  padding: 10px 5px;
  background-color: #fff;
}
.shortlist-result .lender .favourites .confirmFavourites::after {
  position: absolute;
  display: block;
  top: -2px;
  right: 0;
  content: "";
  background-color: #fff;
  width: 29px;
  height: 5px;
}
.shortlist-result .lender .favourites .confirmFavourites:hover {
  color: #EC2422;
}
.shortlist-result .lender .favourites:active,
.shortlist-result .lender .favourites:focus {
  background-image: url(img/button/star_orange_filled.png);
}
.shortlist-result .lender .refinance {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  border: 1px solid #D8DCE7;
  background-color: #fff;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  height: 36px;
  width: 36px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 15px 15px;
  box-shadow: 0 1px 0 0 #E9EBF1;
  background-image: url(img/button/stats-blue-large.png);
  float: left;
  width: 32px;
  height: 32px;
}
.shortlist-result .lender .refinance:hover {
  border: 1px solid #C8CCD7;
}
.shortlist-result .lender .refinance:hover {
  border: 1px solid #C8CCD7;
}
.shortlist-result .lender img {
  max-width: 100px;
  max-height: 30px;
  object-fit: contain;
}
.shortlist-result .lender .text-logo {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  line-height: 30px;
  text-align: center;
}
.shortlist-result .payments {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 26px;
  text-align: center;
  padding: 20px 0;
  border-top: solid 1px #E9EBF1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.shortlist-result .payments .form {
  display: inline;
}
.shortlist-result .payments form {
  display: inline;
}
.shortlist-result .payments form .form-container {
  display: inline-block;
}
.shortlist-result .payments form .form-container .select {
  margin: 0;
  padding: 0;
  width: auto;
  display: inline;
}
.shortlist-result .payments form .form-container .select .field label {
  margin: 0;
}
.shortlist-result .payments form .form-container .select .field select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 400;
  padding-left: 6px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 11px;
  color: #727C8F;
  height: 15px;
  border: none;
}
.shortlist-result .payments form .form-container .select .field select:hover {
  box-shadow: none;
  border: none;
}
.shortlist-result .interest-rate {
  width: 100%;
  padding: 20px 0;
  border-top: solid 1px #E9EBF1;
  max-height: 83px;
}
.shortlist-result .interest-rate .variable-rate,
.shortlist-result .interest-rate .comparison-rate {
  display: inline-block;
  width: 49%;
  vertical-align: top;
}
.shortlist-result .interest-rate .variable-rate .rate,
.shortlist-result .interest-rate .comparison-rate .rate {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 26px;
  text-align: center;
}
.shortlist-result .interest-rate .variable-rate .rate span,
.shortlist-result .interest-rate .comparison-rate .rate span {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #727C8F;
}
.shortlist-result .interest-rate .variable-rate .rate-description,
.shortlist-result .interest-rate .comparison-rate .rate-description {
  margin-top: 5px;
  text-align: center;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 10px;
  color: #727C8F;
}
.shortlist-result .total-cost {
  width: 100%;
  border-top: solid 1px #E9EBF1;
  text-align: center;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  padding: 20px 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.shortlist-result .borrowing-power {
  width: 100%;
  border-top: solid 1px #E9EBF1;
  text-align: center;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  padding: 20px 0px;
}
.shortlist-result .borrowing-power .borrowing-value {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}
.shortlist-result .borrowing-power .borrowing-value.empty-value {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
}
.shortlist-result .repayment-type {
  width: 100%;
  border-top: solid 1px #E9EBF1;
  text-align: center;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  padding: 20px 0px;
}
.shortlist-result .fees {
  padding: 20px 0;
  width: 100%;
  border-top: solid 1px #E9EBF1;
  text-align: center;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 20px;
}
.shortlist-result .fees .upfront-fee,
.shortlist-result .fees .ongoing-fee,
.shortlist-result .fees .exit-fee {
  display: inline-block;
  vertical-align: top;
  width: 33.3%;
}
.shortlist-result .fees span {
  margin-top: 5px;
  display: inline-block;
  width: 102%;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 10px;
  color: #727C8F;
}
.shortlist-result .fees span.value {
  display: inline;
  color: black;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 20px;
}
.shortlist-result .cost-reducers {
  padding: 20px 0;
  width: 100%;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  border-top: solid 1px #E9EBF1;
  border-bottom: solid 1px #E9EBF1;
}
.shortlist-result .cost-reducers ul {
  display: block;
}
.shortlist-result .cost-reducers ul li {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0% 50%;
  background-size: 10px 10px;
  padding-left: 15px;
  margin-left: 10px;
}
.shortlist-result .cost-reducers ul li.true,
.shortlist-result .cost-reducers ul li.Y {
  background-image: url(img/elements/check-green.png);
  color: #26BD27;
}
.shortlist-result .cost-reducers ul li.false,
.shortlist-result .cost-reducers ul li.N {
  background-image: url(img/elements/cross-red.png);
  color: #FF4E4C;
}
.shortlist-result .cost-reducers ul li.additional {
  background-image: url(img/elements/money_blue.png);
  color: #2291FF;
  background-size: 8px 12px;
  background-position: 0px 50%;
  padding-left: 13px;
}
.shortlist-result .cost-reducers ul li:first-of-type {
  margin-left: 0;
}
.shortlist-result .show-more {
  border-top: solid 1px #E9EBF1;
  border-bottom: solid 1px #E9EBF1;
}
.shortlist-result .show-more button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  background-color: #fff;
  width: 100%;
  height: 50px;
  text-align: left;
}
.shortlist-result .show-more button:hover {
  color: #337ab7;
  text-decoration: none;
}
.shortlist-result .show-more button span {
  display: block;
  width: 100%;
  background-image: url(img/button/down-blue.png);
  background-repeat: no-repeat;
  background-size: 10px 5px;
  background-position: 100% 50%;
  padding-right: 25px;
  text-align: left;
}
.shortlist-result .show-more.show {
  border-top: none;
}
.shortlist-result .show-more.show button span {
  background-image: url(img/button/up-blue.png);
}
.shortlist-result .more-info {
  width: 100%;
  margin-top: 20px;
}
.shortlist-result .more-info .details,
.shortlist-result .more-info .eligibility {
  width: Calc(50% - 12px);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  padding: 10px 2px 10px 2px;
  display: inline-block;
}
.shortlist-result .more-info .details:hover,
.shortlist-result .more-info .eligibility:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
.shortlist-result .more-info .details:active,
.shortlist-result .more-info .eligibility:active,
.shortlist-result .more-info .details:focus,
.shortlist-result .more-info .eligibility:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
.shortlist-result .more-info .details:first-of-type,
.shortlist-result .more-info .eligibility:first-of-type {
  margin-right: 20px;
}
.shortlist-result .more-info .check-eligiblity-button {
  padding: 10px 2px 10px 2px;
  margin-right: 0;
}
.shortlist-result .more-info .check-eligiblity-button:hover {
  padding: 10px 2px 10px 2px;
}
.shortlist-result small {
  margin-top: 10px;
  display: block;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 10px;
  text-align: center;
}
.shortlist-result.filtered {
  height: 739px;
  cursor: pointer;
}
.shortlist-result.filtered .content {
  text-align: center;
  line-height: 1.2;
  top: 50%;
  position: relative;
  transform: translate3d(0, -50%, 0);
}
.shortlist-result.filtered .content button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  background: #E9EBF1;
  height: 40px;
  width: 40px;
  background-image: url(img/button/burger_grey.png);
  background-position: 50% 50%;
  background-size: 16px 14px;
  background-repeat: no-repeat;
  position: relative;
}
.shortlist-result.filtered .content button .number {
  position: absolute;
  top: -7px;
  right: -7px;
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #FF4E4C;
  z-index: 20;
  line-height: 14px;
  text-align: center;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 10px;
  color: #fff;
}
.shortlist-result.filtered .content h2 {
  margin-top: 25px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #101922;
}
.shortlist-result.filtered .content h2 span {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}
.shortlist-result.filtered .content p {
  margin-top: 20px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #727C8F;
}
.proposal-product-box {
  min-height: 589px;
  margin-top: 0;
}
.proposal-product-box:last-of-type {
  margin-right: 0;
}
.shortlist-labels {
  vertical-align: top;
  display: inline-block;
  height: 650px;
  width: 230px;
  padding-right: 20px;
}
.shortlist-labels ul li {
  display: block;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  text-align: right;
  border-top: solid 1px #E9EBF1;
  padding-top: 10px;
  margin-bottom: 50px;
}
.shortlist-labels ul li.label-payments span {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
.shortlist-labels ul li.label-payments .form {
  display: inline-block;
  vertical-align: middle;
}
.shortlist-labels ul li.label-payments .form .w280 {
  margin-bottom: 0;
  width: 140px;
}
.shortlist-labels ul li.label-payments .form .field {
  margin-top: 0;
}
.shortlist-labels ul li:first-of-type {
  border-top: none;
}
.shortlist-labels ul li:nth-of-type(1) {
  margin-top: 25px;
  margin-bottom: 76px;
}
.shortlist-labels ul li:nth-of-type(2) {
  margin-bottom: 10px;
}
.shortlist-labels ul li:nth-of-type(2) .w280 {
  margin: 0;
  width: 120px !important;
}
.shortlist-labels ul li:nth-of-type(2) .w280 label {
  margin: 0;
  height: 2px;
}
.shortlist-labels ul li:nth-of-type(3) {
  margin-bottom: 57px;
}
.shortlist-labels ul .shortlist-additional-labels li {
  border-top: solid 1px #E9EBF1;
}
.shortlist-labels ul .shortlist-additional-labels li:nth-of-type(1) {
  margin-bottom: 38px;
}
.shortlist-labels ul .shortlist-additional-labels li:nth-of-type(2) {
  margin-bottom: 35px;
}
.shortlist-labels ul .shortlist-additional-labels li:nth-of-type(3) {
  margin-bottom: 35px;
}
.shortlist-labels ul .shortlist-additional-labels li:nth-of-type(4) {
  margin-bottom: 50px;
}
.shortlist-labels ul .shortlist-additional-labels li:nth-of-type(5) {
  margin-bottom: 48px;
}
.product-select {
  width: 230px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border: solid 1px #E9EBF1;
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
  text-align: center;
  padding: 20px;
  position: relative;
}
.product-select img {
  width: 100px;
  height: 30px;
  object-fit: contain;
}
.product-select h6 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  padding-bottom: 15px;
  margin-top: 20px;
  height: 32px;
}
.product-select .interest-rate {
  width: 100%;
  padding: 20px 0;
  border-top: solid 1px #E9EBF1;
}
.product-select .interest-rate .variable-rate,
.product-select .interest-rate .comparison-rate {
  display: inline-block;
  width: 49%;
}
.product-select .interest-rate .variable-rate .rate,
.product-select .interest-rate .comparison-rate .rate {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  text-align: center;
}
.product-select .interest-rate .variable-rate .rate span,
.product-select .interest-rate .comparison-rate .rate span {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 10px;
  color: #727C8F;
}
.product-select .interest-rate .variable-rate .rate-description,
.product-select .interest-rate .comparison-rate .rate-description {
  margin-top: 5px;
  text-align: center;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 9px;
  color: #727C8F;
}
.product-select .monthly-repayment .value {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  text-align: center;
}
.product-select .monthly-repayment .value span {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 10px;
  color: #727C8F;
}
.product-select .monthly-repayment .value-description {
  margin-top: 5px;
  text-align: center;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 9px;
  color: #727C8F;
}
.product-select .principal-interest {
  width: 100%;
  padding: 20px 0;
  border-top: solid 1px #E9EBF1;
}
.product-select .principal-interest h5 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  color: #101922;
}
.product-select .principal-interest .description {
  margin-top: 5px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 9px;
  color: #727C8F;
}
.product-select .statistics {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px 0;
  border-top: solid 1px #E9EBF1;
}
.product-select .statistics li {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #26BD27;
  margin-bottom: 10px;
  background-repeat: no-repeat;
  background-position: 0% 50%;
  background-size: 10px 10px;
  margin: 4px 7px;
}
.product-select .statistics li span:not(.content):not(.tooltip) {
  background-repeat: no-repeat;
  background-position: 0% 50%;
  background-size: 12px 12px;
  padding-left: 17px;
}
.product-select .statistics li.true span,
.product-select .statistics li.T span,
.product-select .statistics li.Y span {
  background-image: url(img/elements/check-green.png);
  color: #26BD27;
}
.product-select .statistics li.false span,
.product-select .statistics li.F span,
.product-select .statistics li.N span {
  background-image: url(img/elements/cross-red.png);
  color: #FF4E4C;
}
.product-select .statistics li.additional .content {
  background-image: url(img/elements/money_blue.png);
  color: #2291FF;
  background-size: 8px 12px;
  background-position: 0px 50%;
  background-repeat: no-repeat;
  padding-left: 17px;
}
.product-select .fees {
  width: 100%;
  padding: 20px 0;
  border-top: solid 1px #E9EBF1;
}
.product-select .fees .upfront-fee,
.product-select .fees .annual-fee {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  color: #101922;
  display: inline-block;
  width: 50%;
  text-align: center;
}
.product-select .fees .upfront-fee span,
.product-select .fees .annual-fee span {
  margin-top: 5px;
  display: block;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 9px;
  color: #727C8F;
}
.product-select:last-of-type {
  margin-right: 0;
}
.calculator-home .col-m1 {
  max-width: 577px;
  border: solid 1px #D8DCE7;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border: solid 1px #E9EBF1;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  background-color: #fff;
  margin-top: 24px;
}
.calculator-home .m-tlt .mod-title {
  border-bottom: solid 1px #E9EBF1;
  min-height: 62px;
  padding: 20px;
  overflow: hidden;
}
.calculator-home .m-tlt .mod-title h3 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
}
.calculator-home .mod-content {
  width: 100%;
  height: 100%;
}
.calculator-home .mod-content .animation-container {
  padding: 20px;
}
.calculator-home .mod-content.nb {
  padding: 0;
}
.calculator-home .mod-content .video {
  display: block;
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  margin: 0 auto;
}
.calculator-home .mod-content .col-c4 {
  display: inline-block;
  vertical-align: top;
  width: 24.5%;
}
.calculator-home .mod-content .col-c3 {
  display: inline-block;
  vertical-align: top;
  width: 25.5%;
}
.calculator-home .mod-content h3 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  line-height: 1.3;
}
.calculator-home .mod-content h4 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
}
.calculator-home .mod-content h6 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  margin-bottom: 10px;
}
.calculator-home .mod-content p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
.calculator-home .mod-content .btn-1 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
}
.calculator-home .mod-content .btn-1:hover {
  background-color: #46A3FF;
}
.calculator-home .mod-content .btn-1:active,
.calculator-home .mod-content .btn-1:focus {
  background-color: #1C83EA;
}
.calculator-home .mod-content .btn-1:disabled,
.calculator-home .mod-content .btn-1.disabled {
  background: #909090;
}
.calculator-home .mod-content .fw {
  width: 100%;
}
.calculator-home .calculate-box {
  display: inline-block;
  vertical-align: top;
  width: Calc(50% - 10px);
  margin-right: 20px;
  background-color: #F6F7FB;
  border: 1px solid #F6F7FB;
  padding: 20px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}
.calculator-home .calculate-box:nth-of-type(1),
.calculator-home .calculate-box:nth-of-type(2) {
  margin-bottom: 20px;
}
.calculator-home .calculate-box:nth-of-type(2),
.calculator-home .calculate-box:nth-of-type(4) {
  margin-right: 0;
}
.calculator-home .calculate-box h2 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-size: 28px;
  margin-top: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.calculator-home .calculate-box h3 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}
.calculator-home .calculate-box h4 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #575E6C;
}
.calculator-home .calculate-box h6 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  margin-top: 10px;
}
.calculator-home .calculate-box h7 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #575E6C;
  margin-bottom: 15px;
}
.calculator-home .calculate-box .result {
  border-bottom: 1px solid #E9EBF1;
  border-top: 1px solid #E9EBF1;
  padding: 20px 0;
  margin: 20px 0;
}
.calculator-home .calculate-box button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
  width: 100%;
  padding: 12px 25px 12px 25px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
.calculator-home .calculate-box button:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
.calculator-home .calculate-box button:active,
.calculator-home .calculate-box button:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
.calculator-home .calculate-box button.calculate {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  background-color: #26BD27;
  padding: 10px 10px 10px 10px;
  color: #fff;
  padding: 12px 25px 12px 25px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
.calculator-home .calculate-box button.calculate:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #29CA2A;
}
.calculator-home .calculate-box button.calculate:active,
.calculator-home .calculate-box button.calculate:focus {
  background-color: #23B024;
}
.calculator-home .calculate-box span.info {
  vertical-align: 10%;
}
.calculator-home .calculate-box:hover {
  background-color: #fff;
  border: 1px solid #E9EBF1;
}
.calculator-home .illustration {
  width: 670px;
  height: 329px;
  object-fit: contain;
  display: block;
  margin: 0 auto;
  margin-top: 40px;
}
.calculator-home .calculator-help {
  width: Calc(100% - 577px);
  padding: 0 0 0 40px;
  display: inline-block;
  vertical-align: top;
  text-align: center;
}
.calculator-home .calculator-help img {
  width: 100%;
  object-fit: contain;
}
.calculator-home .calculator-help h3 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #223241;
  margin-bottom: 15px;
}
.calculator-home .calculator-help p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  color: #575E6C;
  line-height: 1.3;
}
.calculator-home .calculator-help .navigation {
  margin: 20px 0 15px;
}
.calculator-home .calculator-help .navigation button {
  display: inline-block;
  vertical-align: top;
  width: Calc(50% - 10px);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
  padding: 8px 25px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
}
.calculator-home .calculator-help .navigation button:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
.calculator-home .calculator-help .navigation button:active,
.calculator-home .calculator-help .navigation button:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
.calculator-home .calculator-help .navigation button.lets-chat {
  margin-left: 20px;
}
.calculator-home .calculator-help .navigation button.lets-chat span {
  background-image: url(img/button/chat-blue.png);
  background-repeat: no-repeat;
  background-position: 0% 50%;
  background-size: 12px 12px;
  padding-left: 20px;
}
.calculator-home .calculator-help .search-loans {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  width: 100%;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  padding: 10px 25px;
}
.calculator-home .calculator-help .search-loans:hover {
  background-color: #46A3FF;
}
.calculator-home .calculator-help .search-loans:active,
.calculator-home .calculator-help .search-loans:focus {
  background-color: #1C83EA;
}
.calculator-home .calculator-help .search-loans:disabled,
.calculator-home .calculator-help .search-loans.disabled {
  background: #909090;
}
.calculator-home .calculator-help.dashboard-section {
  padding: 50px 0 0 40px;
}
@media (max-width: 1210px) {
  .calculator-home .calculator-help.dashboard-section {
    padding: 0 100px;
  }
}
.calculator-home .calculator-help.dashboard-section h3 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  margin-top: 50px;
}
@media (max-width: 1210px) {
  .calculator-home .calculator-help.dashboard-section h3 {
    margin-top: 20px;
  }
}
.calculator-home .calculator-help.dashboard-section p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
}
.calculator-home .calculator-help.dashboard-section .navigation button {
  vertical-align: top;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  padding: 13px 25px;
}
.calculator-home .calculator-help.dashboard-section .navigation button:nth-of-type(2) {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  padding: 13px 25px;
  margin-left: 20px;
}
.calculator-home .calculator-help.dashboard-section .navigation button:nth-of-type(2):hover {
  background-color: #46A3FF;
}
.calculator-home .calculator-help.dashboard-section .navigation button:nth-of-type(2):active,
.calculator-home .calculator-help.dashboard-section .navigation button:nth-of-type(2):focus {
  background-color: #1C83EA;
}
.calculator-home .calculator-help.dashboard-section .navigation button:nth-of-type(2):disabled,
.calculator-home .calculator-help.dashboard-section .navigation button:nth-of-type(2).disabled {
  background: #909090;
}
@media (max-width: 1210px) {
  .calculator-home .col-m1,
  .calculator-home .calculator-help {
    width: 100%;
    max-width: none;
  }
  .calculator-home .calculator-help {
    padding: 0 120px;
  }
}
.loan-shortlist-header h5 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #727C8F;
  display: block;
  margin-bottom: 10px;
}
.loan-shortlist-header .header-text {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  display: inline-block;
  margin-right: 0.3em;
  padding-bottom: 10px;
  color: #575E6C;
}
.loan-shortlist-header .header-text.hover {
  position: relative;
  cursor: pointer;
  border-bottom: solid 2px #F6F6FA;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  color: #223241;
}
.loan-shortlist-header .header-text.hover:hover {
  border-bottom: solid 2px #727C8F;
}
.loan-shortlist-header .header-text.hover.hovering {
  color: #2291FF;
  border-bottom: solid 2px #2291FF;
}
.loan-shortlist-header .header-text.hover.hovering .hover-border {
  background-color: rgba(0, 0, 0, 0);
  z-index: 9;
  position: absolute;
  display: block;
  left: 50%;
  margin-left: -140px;
  top: 100%;
  cursor: auto !important;
}
.loan-shortlist-header .header-text.hover.hovering .hover-border.leftmost {
  margin-left: -60px;
}
.loan-shortlist-header .header-text.hover.hovering .hover-border.w126 {
  margin-left: -63px;
}
.loan-shortlist-header .header-text.hover.hovering .hover-border.w170 {
  margin-left: -85px;
}
.loan-shortlist-header .header-text.hover.hovering .modal {
  z-index: 10;
  display: block;
  width: 280px;
  left: 30px;
  top: 100%;
  padding-top: 20px;
}
.loan-shortlist-header .header-text.hover.hovering .modal .content {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 6px 0 rgba(216, 220, 231, 0.65);
  border-radius: 4px;
  position: relative;
}
.loan-shortlist-header .header-text.hover.hovering .modal .content:after,
.loan-shortlist-header .header-text.hover.hovering .modal .content:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
@media (max-width: 950px) {
  .loan-shortlist-header .header-text.hover.hovering .modal .content:after,
  .loan-shortlist-header .header-text.hover.hovering .modal .content:before {
    display: none;
  }
}
.loan-shortlist-header .header-text.hover.hovering .modal .content:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}
.loan-shortlist-header .header-text.hover.hovering .modal .content:before {
  border-color: rgba(216, 220, 231, 0);
  border-bottom-color: #D8DCE7;
  border-width: 12px;
  margin-left: -12px;
}
.loan-shortlist-header .header-text.hover.hovering .modal.w126 {
  width: 126px;
}
.loan-shortlist-header .header-text.hover.hovering .modal.w170 {
  width: 170px;
}
.loan-shortlist-header .header-text.hover.hovering .modal .select {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #101922;
  margin: -20px;
  padding: 10px 0 0 0;
}
.loan-shortlist-header .header-text.hover.hovering .modal .select li label {
  display: block;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  padding: 15px 20px;
}
.loan-shortlist-header .header-text.hover.hovering .modal .select li:hover {
  background-color: #F6F7FB;
}
.loan-shortlist-header .header-text.hover.want-to-borrow .modal {
  left: 175%;
}
.loan-shortlist-header .header-text.hover.want-to-borrow .modal .content:before {
  margin-left: -94px;
}
.loan-shortlist-header .header-text.hover.want-to-borrow .modal .content:after {
  margin-left: -92px;
}
.loan-shortlist-header h4 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #101922;
  margin-bottom: 15px;
}
.loan-shortlist-header .modal {
  display: none;
}
.loan-shortlist-header .form {
  width: 100%;
  max-width: none;
}
.loan-shortlist-header .form .w580 {
  width: 100%;
  margin-bottom: 0;
}
.loan-shortlist-header .form label {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #101922;
}
.loan-shortlist-header .form label.bottom {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #727C8F;
  margin-top: 9px;
}
.loan-shortlist-header .form input[type=submit] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  width: 100%;
  padding: 10px 0;
}
.loan-shortlist-header .form input[type=submit]:hover {
  background-color: #46A3FF;
}
.loan-shortlist-header .form input[type=submit]:active,
.loan-shortlist-header .form input[type=submit]:focus {
  background-color: #1C83EA;
}
.loan-shortlist-header .form input[type=submit]:disabled,
.loan-shortlist-header .form input[type=submit].disabled {
  background: #909090;
}
.loan-shortlist-header .form .select label {
  cursor: pointer;
}
.loan-shortlist-header .form .select input {
  visibility: hidden;
  display: none;
}
.loan-shortlist-header .form .years label {
  margin-bottom: 20px;
  display: block;
}
.loan-shortlist-header .form .years .field {
  margin: 0 auto;
  width: 50px;
  text-align: center;
}
.loan-shortlist-header .form .years .field input {
  text-align: center;
}
.loan-shortlist-header .form .years .rc-slider {
  margin-top: 20px;
}
.loan-shortlist-header .form .years .rc-slider .rc-slider-rail {
  background-color: #E9EBF1;
}
.loan-shortlist-header .form .years .rc-slider .rc-slider-track {
  background-color: #2291FF;
}
.loan-shortlist-header .form .years .rc-slider .rc-slider-handle {
  background: #FFFFFF;
  border: 1px solid #C8CCD7;
  box-shadow: 0 1px 0 0 rgba(87, 94, 108, 0.15);
}
.loan-shortlist-header .form .years ul li {
  display: inline-block;
  width: 50%;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 10px;
  color: #101922;
}
.loan-shortlist-header .form .years ul li:first-of-type {
  text-align: left;
}
.loan-shortlist-header .form .years ul li:last-of-type {
  text-align: right;
}
.loan-search-header h5 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #727C8F;
  display: block;
  margin-bottom: 10px;
}
@media (max-width: 645px) {
  .loan-search-header h5 {
    font-weight: 400;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
  }
}
.loan-search-header .header-text {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  display: inline-block;
  margin-right: 0.3em;
  padding-bottom: 7px;
  color: #575E6C;
}
@media (max-width: 645px) {
  .loan-search-header .header-text {
    font-weight: 400;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
  }
}
.loan-search-header .header-text.hover {
  position: relative;
  cursor: pointer;
  border-bottom: solid 2px #F6F6FA;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  color: #223241;
}
@media (max-width: 645px) {
  .loan-search-header .header-text.hover {
    font-weight: 600;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
  }
}
.loan-search-header .header-text.hover:hover {
  border-bottom: solid 2px #727C8F;
}
.loan-search-header .header-text.hover.hovering {
  color: #2291FF;
  border-bottom: solid 2px #2291FF;
}
.loan-search-header .header-text.hover.hovering .hover-border {
  background-color: rgba(0, 0, 0, 0);
  z-index: 9;
  position: absolute;
  display: block;
  padding: 0 40px 40px 40px;
  left: 50%;
  margin-left: -180px;
  top: 100%;
  cursor: auto !important;
  transition: 0.2s left;
}
@media (max-width: 480px) {
  .loan-search-header .header-text.hover.hovering .hover-border {
    padding: 0 30px 30px 30px;
  }
}
.loan-search-header .header-text.hover.hovering .hover-border.leftmost {
  margin-left: -100px;
}
.loan-search-header .header-text.hover.hovering .hover-border.w126 {
  margin-left: -103px;
}
.loan-search-header .header-text.hover.hovering .hover-border.w170 {
  margin-left: -125px;
}
.loan-search-header .header-text.hover.hovering .modal {
  z-index: 10;
  display: block;
  width: 280px;
  padding-top: 20px;
  cursor: default !important;
}
.loan-search-header .header-text.hover.hovering .modal .content {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 6px 0 rgba(216, 220, 231, 0.65);
  border-radius: 4px;
  position: relative;
  cursor: default !important;
}
.loan-search-header .header-text.hover.hovering .modal .content:after,
.loan-search-header .header-text.hover.hovering .modal .content:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
@media (max-width: 950px) {
  .loan-search-header .header-text.hover.hovering .modal .content:after,
  .loan-search-header .header-text.hover.hovering .modal .content:before {
    display: none;
  }
}
.loan-search-header .header-text.hover.hovering .modal .content:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}
.loan-search-header .header-text.hover.hovering .modal .content:before {
  border-color: rgba(216, 220, 231, 0);
  border-bottom-color: #D8DCE7;
  border-width: 12px;
  margin-left: -12px;
}
.loan-search-header .header-text.hover.hovering .modal .content span {
  display: none;
}
.loan-search-header .header-text.hover.hovering .modal.w126 {
  width: 126px;
}
.loan-search-header .header-text.hover.hovering .modal.w170 {
  width: 170px;
}
.loan-search-header .header-text.hover.hovering .modal .select {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #101922;
  margin: -20px;
  padding: 10px 0 0 0;
}
.loan-search-header .header-text.hover.hovering .modal .select li label {
  display: block;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  padding: 15px 20px;
}
.loan-search-header .header-text.hover.hovering .modal .select li:hover {
  background-color: #F6F7FB;
}
.loan-search-header .header-text.hover.hovering.want-to-borrow .modal {
  left: 175%;
}
.loan-search-header .header-text.hover.hovering.want-to-borrow .content:before {
  margin-left: -94px;
}
.loan-search-header .header-text.hover.hovering.want-to-borrow .content:after {
  margin-left: -92px;
}
.loan-search-header h4 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #101922;
  margin-bottom: 15px;
}
.loan-search-header .modal {
  display: none;
}
.loan-search-header .form {
  width: 100%;
  max-width: none;
}
.loan-search-header .form .w580 {
  width: 100%;
  margin-bottom: 0;
}
.loan-search-header .form label {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #101922;
}
.loan-search-header .form label.bottom {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #727C8F;
  margin-top: 9px;
}
.loan-search-header .form input[type=submit] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  width: 100%;
  padding: 10px 0;
}
.loan-search-header .form input[type=submit]:hover {
  background-color: #46A3FF;
}
.loan-search-header .form input[type=submit]:active,
.loan-search-header .form input[type=submit]:focus {
  background-color: #1C83EA;
}
.loan-search-header .form input[type=submit]:disabled,
.loan-search-header .form input[type=submit].disabled {
  background: #909090;
}
.loan-search-header .form .select label {
  cursor: pointer;
}
.loan-search-header .form .select input {
  visibility: hidden;
  display: none;
}
.loan-search-header .form .years label {
  margin-bottom: 20px;
  display: block;
}
.loan-search-header .form .years .field {
  margin: 0 auto;
  width: 50px;
  text-align: center;
}
.loan-search-header .form .years .field input {
  text-align: center;
}
.loan-search-header .form .years .rc-slider {
  margin-top: 20px;
}
.loan-search-header .form .years .rc-slider .rc-slider-rail {
  background-color: #E9EBF1;
}
.loan-search-header .form .years .rc-slider .rc-slider-track {
  background-color: #2291FF;
}
.loan-search-header .form .years .rc-slider .rc-slider-handle {
  background: #FFFFFF;
  border: 1px solid #C8CCD7;
  box-shadow: 0 1px 0 0 rgba(87, 94, 108, 0.15);
}
.loan-search-header .form .years ul li {
  display: inline-block;
  width: 50%;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 10px;
  color: #101922;
}
.loan-search-header .form .years ul li:first-of-type {
  text-align: left;
}
.loan-search-header .form .years ul li:last-of-type {
  text-align: right;
}
.modal-background {
  z-index: 99;
  background-color: rgba(33, 61, 88, 0.9);
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.modal-background .modal-box {
  display: inline-block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 200px;
  height: 100px;
  margin: auto;
  background-color: #FFFFFF;
  width: 540px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}
.overlay-modal {
  z-index: 1210;
  background-color: rgba(33, 61, 88, 0.9);
  opacity: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.overlay-modal.smsverification {
  background-color: rgba(33, 61, 88, 0.8);
}
@media (max-width: 645px) {
  .overlay-modal.border {
    background-color: rgba(33, 61, 88, 0.9);
  }
}
.overlay-modal.border .modal-container {
  background: none;
}
.overlay-modal.border .modal-container .modal-box {
  margin: 15px;
}
.overlay-modal .modal-box {
  position: relative;
  margin: auto;
  background-color: #FFFFFF;
  width: 540px;
  margin: 40px;
  padding: 40px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}
@media (max-width: 645px) {
  .overlay-modal .modal-box {
    width: 100vw !important;
    margin: 10px;
    padding: 40px;
  }
}
@media (max-width: 480px) {
  .overlay-modal .modal-box {
    padding: 35px 15px;
  }
}
.overlay-modal .modal-box h2 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 22px;
}
.overlay-modal .modal-box h4 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #727C8F;
  margin-top: 15px;
  line-height: 1.3;
}
.overlay-modal .modal-box h4 span {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}
.overlay-modal .modal-box form {
  margin-top: 25px;
}
.overlay-modal .modal-box form .w100p,
.overlay-modal .modal-box form .w50p {
  margin: 10px 0px 10px 0px;
}
.overlay-modal .modal-box form label {
  display: block;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  margin-bottom: 10px;
}
.overlay-modal .modal-box form input {
  height: 40px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  padding-left: 10px;
}
.overlay-modal .modal-box form button[type=submit] {
  margin-top: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  width: 100%;
  padding: 10px 50px 10px 50px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
.overlay-modal .modal-box form button[type=submit]:hover {
  background-color: #46A3FF;
}
.overlay-modal .modal-box form button[type=submit]:active,
.overlay-modal .modal-box form button[type=submit]:focus {
  background-color: #1C83EA;
}
.overlay-modal .modal-box form button[type=submit]:disabled,
.overlay-modal .modal-box form button[type=submit].disabled {
  background: #909090;
}
.overlay-modal .modal-box.next-step {
  padding-top: 50px !important;
  width: 750px;
}
@media (max-width: 800px) {
  .overlay-modal .modal-box.next-step {
    width: 500px;
  }
}
.overlay-modal .modal-box.next-step .info .bank {
  max-width: 660px !important;
  padding: 5px 0 5px 30px !important;
}
.overlay-modal .modal-box .closeButton {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  background-image: url(img/button/modal-close.png);
  width: 14px;
  height: 14px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 14px 14px;
  float: right;
  margin-top: -20px;
  margin-right: -20px;
}
@media (max-width: 480px) {
  .overlay-modal .modal-box .closeButton {
    margin-right: 0;
  }
}
.overlay-modal .modal-box .modal-navigation {
  margin-top: 25px;
}
.overlay-modal .modal-box .modal-navigation .left.double,
.overlay-modal .modal-box .modal-navigation .right.double {
  display: inline-block;
  vertical-align: top;
  width: Calc(50% - 10px);
  padding-left: 0;
  padding-right: 0;
}
@media (max-width: 480px) {
  .overlay-modal .modal-box .modal-navigation .left.double,
  .overlay-modal .modal-box .modal-navigation .right.double {
    width: 100%;
    margin: 0 0 10px 0;
  }
}
.overlay-modal .modal-box .modal-navigation .right.double {
  margin-left: 20px;
}
@media (max-width: 480px) {
  .overlay-modal .modal-box .modal-navigation .right.double {
    margin-left: 0;
  }
}
.overlay-modal .modal-box .modal-navigation .single {
  width: 100%;
  padding: 13px 0;
}
.overlay-modal .modal-box .modal-content {
  text-align: center;
}
.overlay-modal .modal-box .modal-content h1 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  margin-bottom: 25px;
  line-height: 1.4;
}
.overlay-modal .modal-box .modal-content p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #575E6C;
  line-height: 1.4;
  margin-bottom: 10px;
}
.overlay-modal .modal-box .modal-content p:last-of-type {
  margin-bottom: 20px;
}
.overlay-modal .modal-box .modal-content p strong {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}
.overlay-modal .modal-box .modal-content .box-text {
  margin-top: 25px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  line-height: 1.4;
  background-color: #F6F7FB;
  padding: 15px;
  color: #575E6C;
}
.overlay-modal .modal-box .request-assistance {
  margin-top: 40px;
  background-color: #F6F7FB;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 20px;
}
.overlay-modal .modal-box .request-assistance .request-assistance-content,
.overlay-modal .modal-box .request-assistance button {
  display: inline-block;
  vertical-align: middle;
}
.overlay-modal .modal-box .request-assistance .request-assistance-content {
  width: Calc(100% - 170px);
  text-align: left;
  margin-right: 20px;
}
.overlay-modal .modal-box .request-assistance .request-assistance-content h4 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  color: #223241;
  margin-bottom: 10px;
}
.overlay-modal .modal-box .request-assistance .request-assistance-content p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  color: #575E6C;
  margin-bottom: 0;
}
.overlay-modal .modal-box .request-assistance button {
  width: 150px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
  padding: 10px 0;
}
.overlay-modal .modal-box .request-assistance button:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
.overlay-modal .modal-box .request-assistance button:active,
.overlay-modal .modal-box .request-assistance button:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
.overlay-modal .modal-box.backendDead button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
.overlay-modal .modal-box.backendDead button:hover {
  background-color: #46A3FF;
}
.overlay-modal .modal-box.backendDead button:active,
.overlay-modal .modal-box.backendDead button:focus {
  background-color: #1C83EA;
}
.overlay-modal .modal-box.backendDead button:disabled,
.overlay-modal .modal-box.backendDead button.disabled {
  background: #909090;
}
.overlay-modal .modal-box.backendDead .orange-excl {
  display: inline-block;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: #F5A623;
  margin-left: 10px;
  font-weight: 900;
  z-index: 99;
  margin-right: 10px;
  pointer-events: none;
  height: 22px;
  width: 22px;
  line-height: 22px;
  font-size: 12px;
  vertical-align: middle;
  transform: translateY(-3px);
}
.overlay-modal .modal-box.additional-info {
  padding-bottom: 0;
}
.overlay-modal .modal-box.additional-info .info {
  text-align: center;
  background-color: #F6F7FB;
  padding: 20px;
  margin-left: -40px;
  margin-right: -40px;
  margin-top: 40px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  border-top: 1px solid #D8DCE7;
  width: Calc(100% + 80px);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
@media (max-width: 480px) {
  .overlay-modal .modal-box.additional-info .info {
    margin-left: -15px;
    margin-right: -15px;
    width: Calc(100% + 30px);
  }
}
.overlay-modal .modal-box.additional-info .info span.warning {
  display: inline-block;
  line-height: 16px;
  padding-left: 25px;
  background-image: url(img/elements/alert-circle-red.png);
  background-size: 16px 16px;
  background-position: 0% 50%;
  background-repeat: no-repeat;
}
.overlay-modal .modal-box.additional-info .info span.bank {
  max-width: 380px;
  text-align: left;
  display: inline-block;
  line-height: 16px;
  padding-left: 40px;
  background-image: url(img/elements/lock-yellow.png);
  background-size: 16px 24px;
  background-position: 0% 50%;
  background-repeat: no-repeat;
  color: #223241;
}
.overlay-modal .modal-box.additional-info .info span.link {
  font-weight: bolder;
  color: #2291FF;
  cursor: pointer;
}
.overlay-modal .modal-box.additional-info .info span.link:hover {
  color: #46A3FF;
}
.overlay-modal .modal-box.additional-info .info span.link:active,
.overlay-modal .modal-box.additional-info .info span.link:focus {
  color: #1C83EA;
}
.overlay-modal .modal-box.smsverification {
  width: 755px;
}
.overlay-modal .modal-box.smsverification .closeButton {
  display: none;
}
.overlay-modal .modal-box.smsverification .left,
.overlay-modal .modal-box.smsverification .right {
  display: inline-block;
  vertical-align: top;
}
@media (max-width: 600px) {
  .overlay-modal .modal-box.smsverification h1 {
    font-size: 20px;
    margin-bottom: 10px;
  }
}
@media (max-width: 600px) {
  .overlay-modal .modal-box.smsverification p {
    font-size: 14px;
  }
}
.overlay-modal .modal-box.smsverification .left {
  width: 200px;
}
@media (max-width: 600px) {
  .overlay-modal .modal-box.smsverification .left {
    width: 100%;
  }
}
.overlay-modal .modal-box.smsverification .left img {
  object-fit: contain;
  width: 100%;
  margin-top: 30px;
}
@media (max-width: 600px) {
  .overlay-modal .modal-box.smsverification .left img {
    width: 30%;
    margin-bottom: 20px;
    margin-top: 0;
  }
}
.overlay-modal .modal-box.smsverification .right {
  width: Calc(100% - 260px);
  margin-left: 60px;
  text-align: left;
}
@media (max-width: 600px) {
  .overlay-modal .modal-box.smsverification .right {
    width: 100%;
    margin-left: 0;
  }
}
.overlay-modal .modal-box.smsverification .right h3 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  line-height: 1.4;
}
.overlay-modal .modal-box.smsverification .right .request-callback {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
}
.overlay-modal .modal-box.smsverification .right .request-callback:hover {
  background-color: #46A3FF;
}
.overlay-modal .modal-box.smsverification .right .request-callback:active,
.overlay-modal .modal-box.smsverification .right .request-callback:focus {
  background-color: #1C83EA;
}
.overlay-modal .modal-box.smsverification .right .request-callback:disabled,
.overlay-modal .modal-box.smsverification .right .request-callback.disabled {
  background: #909090;
}
.overlay-modal .modal-box.smsverification .right.trouble-validate {
  margin-top: 80px;
}
.overlay-modal .modal-box.smsverification .info {
  padding: 10px 20px;
}
@media (max-width: 600px) {
  .overlay-modal .modal-box.smsverification .info {
    margin-top: 20px;
  }
}
.overlay-modal .modal-box.smsverification .info span.bank {
  max-width: none;
  padding: 10px 30px;
}
.overlay-modal .modal-box.checkeligibilityhelp {
  width: 640px;
  padding: 35px;
}
@media (max-width: 480px) {
  .overlay-modal .modal-box.checkeligibilityhelp .closeButton {
    margin-right: 0;
  }
}
@media (max-width: 480px) {
  .overlay-modal .modal-box.checkeligibilityhelp {
    padding: 35px 15px;
  }
}
.overlay-modal .modal-box.checkeligibilityhelp .modal-content {
  text-align: center;
}
@media (max-width: 600px) {
  .overlay-modal .modal-box.checkeligibilityhelp .modal-content h1 {
    font-size: 20px;
    margin-bottom: 10px;
  }
}
.overlay-modal .modal-box.checkeligibilityhelp .modal-content p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #223241;
  line-height: 1.4;
  margin-bottom: 10px;
}
@media (max-width: 600px) {
  .overlay-modal .modal-box.checkeligibilityhelp .modal-content p {
    font-size: 14px;
  }
}
.overlay-modal .modal-box.checkeligibilityhelp .modal-content .small-text {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #223241;
  margin-top: 18px;
}
.overlay-modal .modal-box.checkeligibilityhelp .modal-navigation {
  margin-top: 25px;
}
.overlay-modal .modal-box.checkeligibilityhelp .modal-navigation .left,
.overlay-modal .modal-box.checkeligibilityhelp .modal-navigation .right {
  display: inline-block;
  width: Calc(50% - 5px);
}
.overlay-modal .modal-box.checkeligibilityhelp .modal-navigation .right {
  margin-left: 10px;
}
.overlay-modal .modal-box.checkeligibilityhelp .modal-navigation .button-1 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  padding: 10px 25px 10px 25px;
}
.overlay-modal .modal-box.checkeligibilityhelp .modal-navigation .button-1:hover {
  background-color: #46A3FF;
}
.overlay-modal .modal-box.checkeligibilityhelp .modal-navigation .button-1:active,
.overlay-modal .modal-box.checkeligibilityhelp .modal-navigation .button-1:focus {
  background-color: #1C83EA;
}
.overlay-modal .modal-box.checkeligibilityhelp .modal-navigation .button-1:disabled,
.overlay-modal .modal-box.checkeligibilityhelp .modal-navigation .button-1.disabled {
  background: #909090;
}
.overlay-modal .modal-box.checkeligibilityhelp .modal-navigation .button-5 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  padding: 10px 25px 10px 25px;
}
.overlay-modal .modal-box.checkeligibilityhelp .modal-navigation .button-5:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
.overlay-modal .modal-box.checkeligibilityhelp .modal-navigation .button-5:active,
.overlay-modal .modal-box.checkeligibilityhelp .modal-navigation .button-5:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .navigation {
  background-color: #F6F7FB;
  margin-left: -35px;
  margin-right: -35px;
  border-top: solid 1px #E9EBF1;
  border-bottom: solid 1px #E9EBF1;
  padding-top: 10px;
  padding-bottom: 10px;
}
@media (max-width: 480px) {
  .overlay-modal .modal-box.checkeligibilityhelp .tabs .navigation {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .navigation .tab-navigation {
  display: inline-block;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #575E6C;
  width: 160px;
  margin-right: 20px;
  padding: 12px 0;
  cursor: pointer;
}
@media (max-width: 600px) {
  .overlay-modal .modal-box.checkeligibilityhelp .tabs .navigation .tab-navigation {
    width: 130px;
  }
}
@media (max-width: 375px) {
  .overlay-modal .modal-box.checkeligibilityhelp .tabs .navigation .tab-navigation {
    margin-right: 0;
    width: 100%;
    margin-bottom: 10px;
  }
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .navigation .tab-navigation.call span,
.overlay-modal .modal-box.checkeligibilityhelp .tabs .navigation .tab-navigation.chat span,
.overlay-modal .modal-box.checkeligibilityhelp .tabs .navigation .tab-navigation.help span {
  display: inline-block;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: 0% 50%;
  padding-left: 25px;
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .navigation .tab-navigation.tab-link-active {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  width: 160px;
  padding: 12px 0;
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .navigation .tab-navigation.tab-link-active:hover {
  background-color: #46A3FF;
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .navigation .tab-navigation.tab-link-active:active,
.overlay-modal .modal-box.checkeligibilityhelp .tabs .navigation .tab-navigation.tab-link-active:focus {
  background-color: #1C83EA;
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .navigation .tab-navigation.tab-link-active:disabled,
.overlay-modal .modal-box.checkeligibilityhelp .tabs .navigation .tab-navigation.tab-link-active.disabled {
  background: #909090;
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .navigation .tab-navigation.tab-link-active.call span {
  background-image: url(img/button/help-phone-white.png);
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .navigation .tab-navigation.tab-link-active.chat span {
  background-image: url(img/button/help-message-white.png);
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .navigation .tab-navigation.tab-link-active.help span {
  background-image: url(img/button/help-question-white.png);
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .navigation .tab-navigation.tab-link-active:hover {
  color: #fff;
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .navigation .tab-navigation.call span {
  background-image: url(img/button/help-phone-grey.png);
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .navigation .tab-navigation.chat span {
  background-image: url(img/button/help-message-grey.png);
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .navigation .tab-navigation.help span {
  background-image: url(img/button/help-question-grey.png);
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .navigation .tab-navigation:last-of-type {
  margin-right: 0;
}
@media (max-width: 375px) {
  .overlay-modal .modal-box.checkeligibilityhelp .tabs .navigation .tab-navigation:last-of-type {
    margin-bottom: 0;
  }
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .navigation .tab-navigation:hover {
  color: #292d34;
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .tab-content {
  padding-top: 40px;
  min-height: 460px;
}
@media (max-width: 600px) {
  .overlay-modal .modal-box.checkeligibilityhelp .tabs .tab-content {
    padding-top: 20px;
  }
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .tab-content .call h3 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  margin-bottom: 13px;
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .tab-content .call h2 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 25px;
  margin-bottom: 15px;
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .tab-content .call p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  color: #223241;
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .tab-content .call .left,
.overlay-modal .modal-box.checkeligibilityhelp .tabs .tab-content .call .right {
  display: inline-block;
  vertical-align: top;
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .tab-content .call .left {
  width: Calc(100% - 240px);
  text-align: left;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  border-right: solid 1px #E9EBF1;
  margin-right: 40px;
  padding-right: 35px;
}
@media (max-width: 600px) {
  .overlay-modal .modal-box.checkeligibilityhelp .tabs .tab-content .call .left {
    width: 100%;
    border-right: none;
    margin-right: 0;
    padding-right: 0;
  }
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .tab-content .call .left .form {
  margin-top: 20px;
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .tab-content .call .left .form .w280 {
  width: 100%;
  margin: 0px 0 20px 0;
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .tab-content .call .left .form textarea {
  min-height: 69px;
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .tab-content .call .left .form button {
  margin-bottom: 0;
  margin-top: 0;
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .tab-content .call .left .center-content {
  padding-top: 160px;
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .tab-content .call .left .center-content strong {
  font-weight: 600;
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .tab-content .call .left .center-content p {
  text-align: center;
  font-size: 17px;
  margin-bottom: 3px;
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .tab-content .call .right {
  text-align: center;
  width: 200px;
  color: #223241;
}
@media (max-width: 600px) {
  .overlay-modal .modal-box.checkeligibilityhelp .tabs .tab-content .call .right {
    width: 100%;
    margin-top: 20px;
  }
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .tab-content .call .right img {
  width: 275px;
  height: 281px;
  object-fit: contain;
  margin-left: -40px;
  margin-right: -35px;
}
@media (max-width: 600px) {
  .overlay-modal .modal-box.checkeligibilityhelp .tabs .tab-content .call .right img {
    margin-top: 20px;
  }
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .tab-content .call .right h3 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  margin-top: 20px;
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .tab-content .call .right h2 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 25px;
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .tab-content .call .right p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #575E6C;
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .tab-content .chat img {
  width: 350px;
  height: 250px;
  object-fit: contain;
  margin-bottom: 40px;
}
@media (max-width: 600px) {
  .overlay-modal .modal-box.checkeligibilityhelp .tabs .tab-content .chat img {
    width: 80%;
  }
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .tab-content .chat button span {
  background-image: url(img/button/help-message-white.png);
  background-repeat: no-repeat;
  background-position: 0% 50%;
  background-size: 12px 12px;
  padding-left: 20px;
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .tab-content .chat p {
  margin-bottom: 0;
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .tab-content .help img {
  width: 350px;
  height: 245px;
  object-fit: contain;
  margin-bottom: 30px;
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .tab-content .help p {
  margin-bottom: 20px;
}
.overlay-modal .modal-box.checkeligibilityhelp .tabs .tab-content .help .small-text {
  margin-top: 0;
}
.overlay-modal .modal-box.checkeligibilityhelp .contact-me {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  background-color: #26BD27;
  padding: 10px 10px 10px 10px;
  color: #fff;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  width: 100%;
  padding: 13px 0;
  margin-bottom: 20px;
}
.overlay-modal .modal-box.checkeligibilityhelp .contact-me:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #29CA2A;
}
.overlay-modal .modal-box.checkeligibilityhelp .contact-me:active,
.overlay-modal .modal-box.checkeligibilityhelp .contact-me:focus {
  background-color: #23B024;
}
.overlay-modal .modal-box.checkeligibilityhelp .contact-me.chat {
  max-width: 280px;
}
.overlay-modal .modal-box.checkeligibilityhelp .contact-me.help {
  max-width: 280px;
}
.overlay-modal .modal-box.checkeligibilitygetadvice img {
  width: 186px;
  height: 186px;
}
.overlay-modal .modal-box.checkeligibilitygetadvice h1 {
  margin-top: 30px;
}
.overlay-modal .modal-box.checkeligibilitydelete {
  width: 600px;
}
.overlay-modal .modal-box.checkeligibilitydelete img {
  width: 350px;
  height: 247px;
  object-fit: contain;
}
.overlay-modal .modal-box.checkeligibilitydelete h1 {
  margin-top: 30px;
}
.overlay-modal .modal-box.checkeligibilitydelete .resume {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
}
.overlay-modal .modal-box.checkeligibilitydelete .resume:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
.overlay-modal .modal-box.checkeligibilitydelete .resume:active,
.overlay-modal .modal-box.checkeligibilitydelete .resume:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
.overlay-modal .modal-box.checkeligibilitydelete .delete {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #fff;
  background-color: #FF4E4C;
}
.overlay-modal .modal-box.checkeligibilitydelete .delete:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #FF4E4C;
}
.overlay-modal .modal-box.checkeligibilitydelete .delete:active,
.overlay-modal .modal-box.checkeligibilitydelete .delete:focus {
  background-color: #FF4E4C;
}
.overlay-modal .modal-box.checkeligibilityfull {
  padding: 60px;
  width: 600px;
}
.overlay-modal .modal-box.checkeligibilityfull .closeButton {
  margin-top: -30px;
  margin-right: -30px;
}
.overlay-modal .modal-box.signup-1,
.overlay-modal .modal-box.signup-2,
.overlay-modal .modal-box.signup-3 {
  padding: 40px 80px;
}
.overlay-modal .modal-box.signup-1 h1,
.overlay-modal .modal-box.signup-2 h1,
.overlay-modal .modal-box.signup-3 h1 {
  margin-bottom: 10px;
}
.overlay-modal .modal-box.signup-1 .closeButton,
.overlay-modal .modal-box.signup-2 .closeButton,
.overlay-modal .modal-box.signup-3 .closeButton {
  margin-top: -20px;
  margin-right: -60px;
}
.overlay-modal .modal-box.signup-1 .carousel.carousel-slider,
.overlay-modal .modal-box.signup-2 .carousel.carousel-slider,
.overlay-modal .modal-box.signup-3 .carousel.carousel-slider {
  display: block;
  margin: 0 auto;
  width: 380px;
  height: 250px;
  background-color: grey;
  margin-bottom: 20px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border: 1px solid #D8DCE7;
}
.overlay-modal .modal-box.signup-1 .control-prev.control-arrow:before,
.overlay-modal .modal-box.signup-2 .control-prev.control-arrow:before,
.overlay-modal .modal-box.signup-3 .control-prev.control-arrow:before {
  border-right: 8px solid #000;
}
.overlay-modal .modal-box.signup-1 .control-next.control-arrow:before,
.overlay-modal .modal-box.signup-2 .control-next.control-arrow:before,
.overlay-modal .modal-box.signup-3 .control-next.control-arrow:before {
  border-left: 8px solid #000;
}
.overlay-modal .modal-box.signup-1 .form,
.overlay-modal .modal-box.signup-2 .form,
.overlay-modal .modal-box.signup-3 .form {
  margin-top: 40px;
}
.overlay-modal .modal-box.signup-1 form,
.overlay-modal .modal-box.signup-2 form,
.overlay-modal .modal-box.signup-3 form {
  text-align: left;
}
.overlay-modal .modal-box.signup-1 form .user-password,
.overlay-modal .modal-box.signup-2 form .user-password,
.overlay-modal .modal-box.signup-3 form .user-password {
  margin-bottom: 25px;
}
.overlay-modal .modal-box.signup-1 form .w100p,
.overlay-modal .modal-box.signup-2 form .w100p,
.overlay-modal .modal-box.signup-3 form .w100p {
  margin: 0;
  margin-bottom: 20px;
}
.overlay-modal .modal-box.signup-1 .submit-button,
.overlay-modal .modal-box.signup-2 .submit-button,
.overlay-modal .modal-box.signup-3 .submit-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 14px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  background-color: #26BD27;
  padding: 10px 10px 10px 10px;
  color: #fff;
  width: 100%;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  padding: 13px 50px 13px 50px;
}
.overlay-modal .modal-box.signup-1 .submit-button:hover,
.overlay-modal .modal-box.signup-2 .submit-button:hover,
.overlay-modal .modal-box.signup-3 .submit-button:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #29CA2A;
}
.overlay-modal .modal-box.signup-1 .submit-button:active,
.overlay-modal .modal-box.signup-2 .submit-button:active,
.overlay-modal .modal-box.signup-3 .submit-button:active,
.overlay-modal .modal-box.signup-1 .submit-button:focus,
.overlay-modal .modal-box.signup-2 .submit-button:focus,
.overlay-modal .modal-box.signup-3 .submit-button:focus {
  background-color: #23B024;
}
.overlay-modal .modal-box.signup-1 .submit-button.create-account,
.overlay-modal .modal-box.signup-2 .submit-button.create-account,
.overlay-modal .modal-box.signup-3 .submit-button.create-account {
  margin-top: 20px;
}
.overlay-modal .modal-box.signup-1 .alternative-signin,
.overlay-modal .modal-box.signup-2 .alternative-signin,
.overlay-modal .modal-box.signup-3 .alternative-signin {
  margin-top: 20px;
}
.overlay-modal .modal-box.signup-1 .alternative-signin .google,
.overlay-modal .modal-box.signup-2 .alternative-signin .google,
.overlay-modal .modal-box.signup-3 .alternative-signin .google,
.overlay-modal .modal-box.signup-1 .alternative-signin .facebook,
.overlay-modal .modal-box.signup-2 .alternative-signin .facebook,
.overlay-modal .modal-box.signup-3 .alternative-signin .facebook {
  width: Calc(50% - 10px);
  display: inline-block;
}
.overlay-modal .modal-box.signup-1 .alternative-signin .google,
.overlay-modal .modal-box.signup-2 .alternative-signin .google,
.overlay-modal .modal-box.signup-3 .alternative-signin .google {
  margin-right: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  padding: 13px 15px 13px 40px;
  background-color: #DD4A40;
  background-image: url(img/button/logo_google.png);
  background-repeat: no-repeat;
  background-size: 14px 14px;
  background-position: 20px 50%;
}
.overlay-modal .modal-box.signup-1 .alternative-signin .google:hover,
.overlay-modal .modal-box.signup-2 .alternative-signin .google:hover,
.overlay-modal .modal-box.signup-3 .alternative-signin .google:hover {
  background-color: #46A3FF;
}
.overlay-modal .modal-box.signup-1 .alternative-signin .google:active,
.overlay-modal .modal-box.signup-2 .alternative-signin .google:active,
.overlay-modal .modal-box.signup-3 .alternative-signin .google:active,
.overlay-modal .modal-box.signup-1 .alternative-signin .google:focus,
.overlay-modal .modal-box.signup-2 .alternative-signin .google:focus,
.overlay-modal .modal-box.signup-3 .alternative-signin .google:focus {
  background-color: #1C83EA;
}
.overlay-modal .modal-box.signup-1 .alternative-signin .google:disabled,
.overlay-modal .modal-box.signup-2 .alternative-signin .google:disabled,
.overlay-modal .modal-box.signup-3 .alternative-signin .google:disabled,
.overlay-modal .modal-box.signup-1 .alternative-signin .google.disabled,
.overlay-modal .modal-box.signup-2 .alternative-signin .google.disabled,
.overlay-modal .modal-box.signup-3 .alternative-signin .google.disabled {
  background: #909090;
}
.overlay-modal .modal-box.signup-1 .alternative-signin .google:hover,
.overlay-modal .modal-box.signup-2 .alternative-signin .google:hover,
.overlay-modal .modal-box.signup-3 .alternative-signin .google:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #E6574D;
}
.overlay-modal .modal-box.signup-1 .alternative-signin .google:active,
.overlay-modal .modal-box.signup-2 .alternative-signin .google:active,
.overlay-modal .modal-box.signup-3 .alternative-signin .google:active,
.overlay-modal .modal-box.signup-1 .alternative-signin .google:focus,
.overlay-modal .modal-box.signup-2 .alternative-signin .google:focus,
.overlay-modal .modal-box.signup-3 .alternative-signin .google:focus {
  background-color: #C63C32;
}
.overlay-modal .modal-box.signup-1 .alternative-signin .facebook,
.overlay-modal .modal-box.signup-2 .alternative-signin .facebook,
.overlay-modal .modal-box.signup-3 .alternative-signin .facebook {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  padding: 13px 15px 13px 40px;
  background-color: #4267B2;
  background-image: url(img/button/logo_fb.png);
  background-repeat: no-repeat;
  background-size: 9px 17px;
  background-position: 20px 50%;
}
.overlay-modal .modal-box.signup-1 .alternative-signin .facebook:hover,
.overlay-modal .modal-box.signup-2 .alternative-signin .facebook:hover,
.overlay-modal .modal-box.signup-3 .alternative-signin .facebook:hover {
  background-color: #46A3FF;
}
.overlay-modal .modal-box.signup-1 .alternative-signin .facebook:active,
.overlay-modal .modal-box.signup-2 .alternative-signin .facebook:active,
.overlay-modal .modal-box.signup-3 .alternative-signin .facebook:active,
.overlay-modal .modal-box.signup-1 .alternative-signin .facebook:focus,
.overlay-modal .modal-box.signup-2 .alternative-signin .facebook:focus,
.overlay-modal .modal-box.signup-3 .alternative-signin .facebook:focus {
  background-color: #1C83EA;
}
.overlay-modal .modal-box.signup-1 .alternative-signin .facebook:disabled,
.overlay-modal .modal-box.signup-2 .alternative-signin .facebook:disabled,
.overlay-modal .modal-box.signup-3 .alternative-signin .facebook:disabled,
.overlay-modal .modal-box.signup-1 .alternative-signin .facebook.disabled,
.overlay-modal .modal-box.signup-2 .alternative-signin .facebook.disabled,
.overlay-modal .modal-box.signup-3 .alternative-signin .facebook.disabled {
  background: #909090;
}
.overlay-modal .modal-box.signup-1 .alternative-signin .facebook:hover,
.overlay-modal .modal-box.signup-2 .alternative-signin .facebook:hover,
.overlay-modal .modal-box.signup-3 .alternative-signin .facebook:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #5077C5;
}
.overlay-modal .modal-box.signup-1 .alternative-signin .facebook:active,
.overlay-modal .modal-box.signup-2 .alternative-signin .facebook:active,
.overlay-modal .modal-box.signup-3 .alternative-signin .facebook:active,
.overlay-modal .modal-box.signup-1 .alternative-signin .facebook:focus,
.overlay-modal .modal-box.signup-2 .alternative-signin .facebook:focus,
.overlay-modal .modal-box.signup-3 .alternative-signin .facebook:focus {
  background-color: #36589D;
}
.overlay-modal .modal-box.signup-1 .hide,
.overlay-modal .modal-box.signup-2 .hide,
.overlay-modal .modal-box.signup-3 .hide {
  display: none;
}
.overlay-modal .modal-box.signup-1 .login,
.overlay-modal .modal-box.signup-2 .login,
.overlay-modal .modal-box.signup-3 .login {
  margin-top: 20px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  display: block;
  color: #2291FF;
  cursor: pointer;
}
.overlay-modal .modal-box.signup-1 .login:hover,
.overlay-modal .modal-box.signup-2 .login:hover,
.overlay-modal .modal-box.signup-3 .login:hover {
  color: #46A3FF;
}
.overlay-modal .modal-box.signup-1 .login:active,
.overlay-modal .modal-box.signup-2 .login:active,
.overlay-modal .modal-box.signup-3 .login:active,
.overlay-modal .modal-box.signup-1 .login:focus,
.overlay-modal .modal-box.signup-2 .login:focus,
.overlay-modal .modal-box.signup-3 .login:focus {
  color: #1C83EA;
}
.overlay-modal .modal-box.signup-2 {
  padding-bottom: 0;
}
.overlay-modal .modal-box.signup-2 .info {
  margin-left: -80px;
  margin-right: -80px;
  width: Calc(100% + 160px);
}
.overlay-modal .modal-box.signup-2 .form .user-email {
  display: none;
}
.overlay-modal .modal-box.signup-2 .form .email,
.overlay-modal .modal-box.signup-2 .form .text,
.overlay-modal .modal-box.signup-2 .form .double-field {
  width: 100%;
}
.overlay-modal .modal-box.signup-2 .form .user-firstName {
  width: 180px;
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
}
.overlay-modal .modal-box.signup-2 .form .user-lastName {
  width: 180px;
  display: inline-block;
  vertical-align: top;
}
.overlay-modal .modal-box.signup-3 {
  padding: 40px 80px 0 80px;
}
.overlay-modal .modal-box.signup-3.additional-info .info {
  margin-left: -80px;
  margin-right: -80px;
  width: Calc(100% + 160px);
}
.overlay-modal .modal-box.signupfinish img {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 463px;
  height: 217px;
  object-fit: contain;
}
.overlay-modal .modal-box.signupfinish h1 {
  margin-bottom: 10px;
}
.overlay-modal .modal-box.signupfinish button.single {
  width: 380px;
}
.overlay-modal .modal-box.signin .modal-content {
  padding: 20px 40px;
}
.overlay-modal .modal-box.signin .form {
  margin-top: 40px;
}
.overlay-modal .modal-box.signin form {
  text-align: left;
}
.overlay-modal .modal-box.signin form input {
  width: 100%;
}
.overlay-modal .modal-box.signin .alternative-signin .google,
.overlay-modal .modal-box.signin .alternative-signin .facebook {
  width: Calc(50% - 10px);
  display: inline-block;
}
.overlay-modal .modal-box.signin .alternative-signin .google {
  margin-right: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  padding: 13px 15px 13px 40px;
  background-color: #DD4A40;
  background-image: url(img/button/logo_google.png);
  background-repeat: no-repeat;
  background-size: 14px 14px;
  background-position: 20px 50%;
}
.overlay-modal .modal-box.signin .alternative-signin .google:hover {
  background-color: #46A3FF;
}
.overlay-modal .modal-box.signin .alternative-signin .google:active,
.overlay-modal .modal-box.signin .alternative-signin .google:focus {
  background-color: #1C83EA;
}
.overlay-modal .modal-box.signin .alternative-signin .google:disabled,
.overlay-modal .modal-box.signin .alternative-signin .google.disabled {
  background: #909090;
}
.overlay-modal .modal-box.signin .alternative-signin .google:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #E6574D;
}
.overlay-modal .modal-box.signin .alternative-signin .google:active,
.overlay-modal .modal-box.signin .alternative-signin .google:focus {
  background-color: #C63C32;
}
.overlay-modal .modal-box.signin .alternative-signin .facebook {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  padding: 13px 15px 13px 40px;
  background-color: #4267B2;
  background-image: url(img/button/logo_fb.png);
  background-repeat: no-repeat;
  background-size: 9px 17px;
  background-position: 20px 50%;
}
.overlay-modal .modal-box.signin .alternative-signin .facebook:hover {
  background-color: #46A3FF;
}
.overlay-modal .modal-box.signin .alternative-signin .facebook:active,
.overlay-modal .modal-box.signin .alternative-signin .facebook:focus {
  background-color: #1C83EA;
}
.overlay-modal .modal-box.signin .alternative-signin .facebook:disabled,
.overlay-modal .modal-box.signin .alternative-signin .facebook.disabled {
  background: #909090;
}
.overlay-modal .modal-box.signin .alternative-signin .facebook:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #5077C5;
}
.overlay-modal .modal-box.signin .alternative-signin .facebook:active,
.overlay-modal .modal-box.signin .alternative-signin .facebook:focus {
  background-color: #36589D;
}
.overlay-modal .modal-box.signin .forgot-password {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #727C8F;
  display: inline-block;
  float: right;
  vertical-align: top;
  margin-bottom: 0;
  margin-top: -15px;
  position: relative;
  bottom: 6px;
  cursor: pointer;
}
.overlay-modal .modal-box.signin .forgot-password:hover {
  color: #A3A8B2;
  text-decoration: underline;
}
.overlay-modal .modal-box.signin .forgot-password:active,
.overlay-modal .modal-box.signin .forgot-password:focus {
  color: #575E6C;
}
.overlay-modal .modal-box .skip-password .skip-password-text,
.overlay-modal .modal-box .access-code .skip-password-text,
.overlay-modal .modal-box .skip-password .email-secure-link,
.overlay-modal .modal-box .access-code .email-secure-link,
.overlay-modal .modal-box .skip-password .access-code-text,
.overlay-modal .modal-box .access-code .access-code-text,
.overlay-modal .modal-box .skip-password .access-link,
.overlay-modal .modal-box .access-code .access-link {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}
.overlay-modal .modal-box .skip-password .skip-password-text,
.overlay-modal .modal-box .access-code .skip-password-text,
.overlay-modal .modal-box .skip-password .access-code-text,
.overlay-modal .modal-box .access-code .access-code-text {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  line-height: 1.4;
}
.overlay-modal .modal-box .skip-password .skip-password-text span,
.overlay-modal .modal-box .access-code .skip-password-text span,
.overlay-modal .modal-box .skip-password .access-code-text span,
.overlay-modal .modal-box .access-code .access-code-text span {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  display: block;
}
.overlay-modal .modal-box .skip-password .access-code-text span,
.overlay-modal .modal-box .access-code .access-code-text span {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}
.overlay-modal .modal-box .skip-password .email-secure-link,
.overlay-modal .modal-box .access-code .email-secure-link {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  padding: 12px 21px;
}
.overlay-modal .modal-box .skip-password .email-secure-link:hover,
.overlay-modal .modal-box .access-code .email-secure-link:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
.overlay-modal .modal-box .skip-password .email-secure-link:active,
.overlay-modal .modal-box .access-code .email-secure-link:active,
.overlay-modal .modal-box .skip-password .email-secure-link:focus,
.overlay-modal .modal-box .access-code .email-secure-link:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
.overlay-modal .modal-box .skip-password .email-secure-link span,
.overlay-modal .modal-box .access-code .email-secure-link span {
  background-image: url(img/button/magic_black.png);
  background-repeat: no-repeat;
  background-position: 0% 50%;
  background-size: 12px 12px;
  padding-left: 20px;
}
.overlay-modal .modal-box .skip-password .access-link,
.overlay-modal .modal-box .access-code .access-link {
  float: right;
  width: 40%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 14px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  background-color: #26BD27;
  padding: 10px 10px 10px 10px;
  color: #fff;
  background-color: #727C8F;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  padding: 10px 20px;
  margin-top: 25px;
}
.overlay-modal .modal-box .skip-password .access-link:hover,
.overlay-modal .modal-box .access-code .access-link:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #29CA2A;
}
.overlay-modal .modal-box .skip-password .access-link:active,
.overlay-modal .modal-box .access-code .access-link:active,
.overlay-modal .modal-box .skip-password .access-link:focus,
.overlay-modal .modal-box .access-code .access-link:focus {
  background-color: #23B024;
}
.overlay-modal .modal-box .skip-password .access-link:hover,
.overlay-modal .modal-box .access-code .access-link:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #A3A8B2;
}
.overlay-modal .modal-box .skip-password .access-link:active,
.overlay-modal .modal-box .access-code .access-link:active,
.overlay-modal .modal-box .skip-password .access-link:focus,
.overlay-modal .modal-box .access-code .access-link:focus {
  background-color: #575E6C;
}
.overlay-modal .modal-box .or-divider {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #B3B7C2;
  text-align: center;
  margin: 25px 0;
  font-style: italic;
}
.overlay-modal .modal-box .or-divider span {
  background-color: #fff;
  width: 100px;
  display: inline-block;
}
.overlay-modal .modal-box .or-divider hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #B3B7C2;
  padding: 0;
  margin-top: -8px;
}
.overlay-modal .modal-box .navigation {
  color: #727C8F;
  text-align: center;
  margin-top: 20px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
.overlay-modal .modal-box .navigation a {
  color: #2291FF;
  cursor: pointer;
}
.overlay-modal .modal-box .navigation a:hover {
  color: #46A3FF;
}
.overlay-modal .modal-box .navigation a:active,
.overlay-modal .modal-box .navigation a:focus {
  color: #1C83EA;
}
.overlay-modal .modal-box .navigation.back a {
  color: #727C8F;
}
.overlay-modal .modal-box .navigation.back a:hover {
  color: #798DA0;
}
.overlay-modal .modal-box .navigation.back a:active,
.overlay-modal .modal-box .navigation.back a:focus {
  color: #2291FF;
}
.overlay-modal .modal-box h2 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 22px;
}
.overlay-modal .modal-box h4 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #727C8F;
  margin-top: 15px;
  line-height: 1.3;
}
.overlay-modal .modal-box h4 span {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}
.overlay-modal .modal-box form {
  margin-top: 25px;
}
.overlay-modal .modal-box form .w100p,
.overlay-modal .modal-box form .w50p {
  margin: 10px 0px 10px 0px;
}
.overlay-modal .modal-box form label {
  display: block;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  margin-bottom: 10px;
}
.overlay-modal .modal-box form input {
  height: 40px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  padding-left: 10px;
}
.overlay-modal .modal-box form button[type=submit] {
  margin-top: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  width: 100%;
  padding: 10px 50px 10px 50px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
.overlay-modal .modal-box form button[type=submit]:hover {
  background-color: #46A3FF;
}
.overlay-modal .modal-box form button[type=submit]:active,
.overlay-modal .modal-box form button[type=submit]:focus {
  background-color: #1C83EA;
}
.overlay-modal .modal-box form button[type=submit]:disabled,
.overlay-modal .modal-box form button[type=submit].disabled {
  background: #909090;
}
.overlay-modal .modal-box .validation {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: red;
  margin-top: 8px;
}
.overlay-modal .modal-box .validation a {
  color: red;
}
.overlay-modal .modal-box .validation a:hover {
  text-decoration: underline;
}
.overlay-modal .modal-box hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #E9EBF1;
  padding: 0;
  margin: 20px 0;
}
.overlay-modal .modal-box .single {
  padding: 40px;
  width: 460px;
  height: auto;
}
.overlay-modal .modal-box .single h2 {
  text-align: center;
}
.overlay-modal .modal-box .single h4 {
  text-align: center;
}
.overlay-modal .modal-box.forgetpassword .modal-content {
  padding: 20px 40px;
}
.overlay-modal .modal-box.forgetpassword h2 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  line-height: 1.4;
  margin-bottom: 25px;
}
.overlay-modal .modal-box.forgetpassword h4 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #575E6C;
  line-height: 1.4;
  margin-bottom: 10px;
}
.overlay-modal .modal-box.forgetpassword form {
  text-align: center;
  margin: 0 auto;
  margin-top: 30px;
}
.overlay-modal .modal-box.forgetpassword form label {
  display: none;
}
.overlay-modal .modal-box.forgetpassword form input {
  -webkit-appearance: none;
  appearance: none;
  height: 40px;
  border: 1px solid #D8DCE7;
  padding: 0 15px;
  background-color: #fff;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  width: 100%;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}
.overlay-modal .modal-box.forgetpassword form input:focus {
  box-shadow: 0 0 10px rgba(34, 145, 255, 0.15);
  border: 1px solid #2291FF;
  border-radius: 4px;
}
.overlay-modal .modal-box.forgetpassword form input:hover {
  border: 1px solid #C8CCD7;
  box-shadow: 0 0 10px rgba(34, 145, 255, 0.15);
  border: 1px solid #2291FF;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}
.overlay-modal .modal-box.forgetpassword form button {
  margin-top: 40px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  padding: 10px 0;
  width: 100%;
}
.overlay-modal .modal-box.forgetpassword form button:hover {
  background-color: #46A3FF;
}
.overlay-modal .modal-box.forgetpassword form button:active,
.overlay-modal .modal-box.forgetpassword form button:focus {
  background-color: #1C83EA;
}
.overlay-modal .modal-box.forgetpassword form button:disabled,
.overlay-modal .modal-box.forgetpassword form button.disabled {
  background: #909090;
}
.overlay-modal .modal-box.forgetpassword .navigation {
  text-align: center;
  margin-top: 20px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
.overlay-modal .modal-box.forgetpassword .navigation a {
  color: #727C8F;
  cursor: pointer;
}
.overlay-modal .modal-box.forgetpassword .navigation a:hover {
  color: #798DA0;
}
.overlay-modal .modal-box.securelink .modal-content {
  padding: 20px 40px;
}
.overlay-modal .modal-box.securelink h2 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  line-height: 1.4;
  margin-bottom: 25px;
}
.overlay-modal .modal-box.securelink h4 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #575E6C;
  line-height: 1.4;
  margin-bottom: 10px;
}
.overlay-modal .modal-box.securelink form {
  text-align: center;
  margin: 0 auto;
  margin-top: 30px;
}
.overlay-modal .modal-box.securelink form label {
  display: none;
}
.overlay-modal .modal-box.securelink form input {
  -webkit-appearance: none;
  appearance: none;
  height: 40px;
  border: 1px solid #D8DCE7;
  padding: 0 15px;
  background-color: #fff;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  width: 100%;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}
.overlay-modal .modal-box.securelink form input:focus {
  box-shadow: 0 0 10px rgba(34, 145, 255, 0.15);
  border: 1px solid #2291FF;
  border-radius: 4px;
}
.overlay-modal .modal-box.securelink form input:hover {
  border: 1px solid #C8CCD7;
  box-shadow: 0 0 10px rgba(34, 145, 255, 0.15);
  border: 1px solid #2291FF;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}
.overlay-modal .modal-box.securelink form button {
  margin-top: 40px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  padding: 10px 0;
  width: 100%;
}
.overlay-modal .modal-box.securelink form button:hover {
  background-color: #46A3FF;
}
.overlay-modal .modal-box.securelink form button:active,
.overlay-modal .modal-box.securelink form button:focus {
  background-color: #1C83EA;
}
.overlay-modal .modal-box.securelink form button:disabled,
.overlay-modal .modal-box.securelink form button.disabled {
  background: #909090;
}
.overlay-modal .modal-box.securelink form button span {
  background-image: url(img/button/magic.png);
  background-repeat: no-repeat;
  background-position: 0% 50%;
  background-size: 12px 12px;
  padding-left: 20px;
}
.overlay-modal .modal-box.securelink .navigation {
  text-align: center;
  margin-top: 20px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
.overlay-modal .modal-box.securelink .navigation a {
  color: #727C8F;
  cursor: pointer;
}
.overlay-modal .modal-box.securelink .navigation a:hover {
  color: #798DA0;
}
.overlay-modal .modal-box.checkeligibilityview,
.overlay-modal .modal-box.shortlistfilterview,
.overlay-modal .modal-box.shortlistview,
.overlay-modal .modal-box.additionalloanproducts {
  width: 870px;
  padding: 20px;
}
.overlay-modal .modal-box.checkeligibilityview .closeButton,
.overlay-modal .modal-box.shortlistfilterview .closeButton,
.overlay-modal .modal-box.shortlistview .closeButton,
.overlay-modal .modal-box.additionalloanproducts .closeButton {
  margin-top: 0;
  margin-right: 0;
}
.overlay-modal .modal-box.checkeligibilityview .heading,
.overlay-modal .modal-box.shortlistfilterview .heading,
.overlay-modal .modal-box.shortlistview .heading,
.overlay-modal .modal-box.additionalloanproducts .heading {
  text-align: left;
}
.overlay-modal .modal-box.checkeligibilityview .heading h1,
.overlay-modal .modal-box.shortlistfilterview .heading h1,
.overlay-modal .modal-box.shortlistview .heading h1,
.overlay-modal .modal-box.additionalloanproducts .heading h1 {
  margin-top: 5px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  background-image: url(img/elements/eligibility-check.png);
  background-size: 24px 24px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  padding-left: 40px;
}
.overlay-modal .modal-box.checkeligibilityview .product-list,
.overlay-modal .modal-box.shortlistfilterview .product-list,
.overlay-modal .modal-box.shortlistview .product-list,
.overlay-modal .modal-box.additionalloanproducts .product-list {
  border-top: 1px solid #D8DCE7;
  border-bottom: 1px solid #D8DCE7;
  margin-left: -20px;
  margin-right: -20px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.overlay-modal .modal-box.checkeligibilityview .product-list.no-scroll .product,
.overlay-modal .modal-box.shortlistfilterview .product-list.no-scroll .product,
.overlay-modal .modal-box.shortlistview .product-list.no-scroll .product,
.overlay-modal .modal-box.additionalloanproducts .product-list.no-scroll .product {
  margin-bottom: 0;
  border-bottom: none;
}
.overlay-modal .modal-box.checkeligibilityview .product-list .product,
.overlay-modal .modal-box.shortlistfilterview .product-list .product,
.overlay-modal .modal-box.shortlistview .product-list .product,
.overlay-modal .modal-box.additionalloanproducts .product-list .product {
  display: inline-block;
  vertical-align: top;
  width: 290px;
  background-color: #F6F7FB;
  border-right: 1px solid #D8DCE7;
}
.overlay-modal .modal-box.checkeligibilityview .product-list .product strong,
.overlay-modal .modal-box.shortlistfilterview .product-list .product strong,
.overlay-modal .modal-box.shortlistview .product-list .product strong,
.overlay-modal .modal-box.additionalloanproducts .product-list .product strong {
  font-weight: bolder;
}
.overlay-modal .modal-box.checkeligibilityview .navigation,
.overlay-modal .modal-box.shortlistfilterview .navigation,
.overlay-modal .modal-box.shortlistview .navigation,
.overlay-modal .modal-box.additionalloanproducts .navigation {
  text-align: left;
  padding-top: 20px;
}
.overlay-modal .modal-box.checkeligibilityview .navigation .skip,
.overlay-modal .modal-box.shortlistfilterview .navigation .skip,
.overlay-modal .modal-box.shortlistview .navigation .skip,
.overlay-modal .modal-box.additionalloanproducts .navigation .skip {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  padding: 12px 25px 12px 25px;
}
.overlay-modal .modal-box.checkeligibilityview .navigation .skip:hover,
.overlay-modal .modal-box.shortlistfilterview .navigation .skip:hover,
.overlay-modal .modal-box.shortlistview .navigation .skip:hover,
.overlay-modal .modal-box.additionalloanproducts .navigation .skip:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
.overlay-modal .modal-box.checkeligibilityview .navigation .skip:active,
.overlay-modal .modal-box.shortlistfilterview .navigation .skip:active,
.overlay-modal .modal-box.shortlistview .navigation .skip:active,
.overlay-modal .modal-box.additionalloanproducts .navigation .skip:active,
.overlay-modal .modal-box.checkeligibilityview .navigation .skip:focus,
.overlay-modal .modal-box.shortlistfilterview .navigation .skip:focus,
.overlay-modal .modal-box.shortlistview .navigation .skip:focus,
.overlay-modal .modal-box.additionalloanproducts .navigation .skip:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
.overlay-modal .modal-box.checkeligibilityview .navigation .add,
.overlay-modal .modal-box.shortlistfilterview .navigation .add,
.overlay-modal .modal-box.shortlistview .navigation .add,
.overlay-modal .modal-box.additionalloanproducts .navigation .add {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  padding: 12px 25px 12px 25px;
  float: right;
}
.overlay-modal .modal-box.checkeligibilityview .navigation .add:hover,
.overlay-modal .modal-box.shortlistfilterview .navigation .add:hover,
.overlay-modal .modal-box.shortlistview .navigation .add:hover,
.overlay-modal .modal-box.additionalloanproducts .navigation .add:hover {
  background-color: #46A3FF;
}
.overlay-modal .modal-box.checkeligibilityview .navigation .add:active,
.overlay-modal .modal-box.shortlistfilterview .navigation .add:active,
.overlay-modal .modal-box.shortlistview .navigation .add:active,
.overlay-modal .modal-box.additionalloanproducts .navigation .add:active,
.overlay-modal .modal-box.checkeligibilityview .navigation .add:focus,
.overlay-modal .modal-box.shortlistfilterview .navigation .add:focus,
.overlay-modal .modal-box.shortlistview .navigation .add:focus,
.overlay-modal .modal-box.additionalloanproducts .navigation .add:focus {
  background-color: #1C83EA;
}
.overlay-modal .modal-box.checkeligibilityview .navigation .add:disabled,
.overlay-modal .modal-box.shortlistfilterview .navigation .add:disabled,
.overlay-modal .modal-box.shortlistview .navigation .add:disabled,
.overlay-modal .modal-box.additionalloanproducts .navigation .add:disabled,
.overlay-modal .modal-box.checkeligibilityview .navigation .add.disabled,
.overlay-modal .modal-box.shortlistfilterview .navigation .add.disabled,
.overlay-modal .modal-box.shortlistview .navigation .add.disabled,
.overlay-modal .modal-box.additionalloanproducts .navigation .add.disabled {
  background: #909090;
}
.overlay-modal .modal-box.checkeligibilityview .product-item,
.overlay-modal .modal-box.shortlistfilterview .product-item,
.overlay-modal .modal-box.shortlistview .product-item,
.overlay-modal .modal-box.additionalloanproducts .product-item {
  background-color: #fff;
  padding: 20px;
  height: 435px;
  position: relative;
}
.overlay-modal .modal-box.checkeligibilityview .product-item .company-logo,
.overlay-modal .modal-box.shortlistfilterview .product-item .company-logo,
.overlay-modal .modal-box.shortlistview .product-item .company-logo,
.overlay-modal .modal-box.additionalloanproducts .product-item .company-logo {
  height: 30px;
  width: 100px;
  display: block;
  margin: 0 auto;
  float: none;
  object-fit: contain;
}
.overlay-modal .modal-box.checkeligibilityview .product-item .product-name-container,
.overlay-modal .modal-box.shortlistfilterview .product-item .product-name-container,
.overlay-modal .modal-box.shortlistview .product-item .product-name-container,
.overlay-modal .modal-box.additionalloanproducts .product-item .product-name-container {
  height: 32px;
}
.overlay-modal .modal-box.checkeligibilityview .product-item h6,
.overlay-modal .modal-box.shortlistfilterview .product-item h6,
.overlay-modal .modal-box.shortlistview .product-item h6,
.overlay-modal .modal-box.additionalloanproducts .product-item h6 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  text-align: center;
  margin-top: 15px;
  color: #101922;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.overlay-modal .modal-box.checkeligibilityview .product-item .interest-rate,
.overlay-modal .modal-box.shortlistfilterview .product-item .interest-rate,
.overlay-modal .modal-box.shortlistview .product-item .interest-rate,
.overlay-modal .modal-box.additionalloanproducts .product-item .interest-rate,
.overlay-modal .modal-box.checkeligibilityview .product-item .principal-interest,
.overlay-modal .modal-box.shortlistfilterview .product-item .principal-interest,
.overlay-modal .modal-box.shortlistview .product-item .principal-interest,
.overlay-modal .modal-box.additionalloanproducts .product-item .principal-interest,
.overlay-modal .modal-box.checkeligibilityview .product-item .repayment-power,
.overlay-modal .modal-box.shortlistfilterview .product-item .repayment-power,
.overlay-modal .modal-box.shortlistview .product-item .repayment-power,
.overlay-modal .modal-box.additionalloanproducts .product-item .repayment-power {
  border-top: solid 1px #E9EBF1;
  text-align: center;
  width: 100%;
  padding: 20px 0;
}
.overlay-modal .modal-box.checkeligibilityview .product-item .interest-rate .description,
.overlay-modal .modal-box.shortlistfilterview .product-item .interest-rate .description,
.overlay-modal .modal-box.shortlistview .product-item .interest-rate .description,
.overlay-modal .modal-box.additionalloanproducts .product-item .interest-rate .description,
.overlay-modal .modal-box.checkeligibilityview .product-item .principal-interest .description,
.overlay-modal .modal-box.shortlistfilterview .product-item .principal-interest .description,
.overlay-modal .modal-box.shortlistview .product-item .principal-interest .description,
.overlay-modal .modal-box.additionalloanproducts .product-item .principal-interest .description,
.overlay-modal .modal-box.checkeligibilityview .product-item .repayment-power .description,
.overlay-modal .modal-box.shortlistfilterview .product-item .repayment-power .description,
.overlay-modal .modal-box.shortlistview .product-item .repayment-power .description,
.overlay-modal .modal-box.additionalloanproducts .product-item .repayment-power .description {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  margin-top: 5px;
  color: #727C8F;
}
.overlay-modal .modal-box.checkeligibilityview .product-item .interest-rate h5,
.overlay-modal .modal-box.shortlistfilterview .product-item .interest-rate h5,
.overlay-modal .modal-box.shortlistview .product-item .interest-rate h5,
.overlay-modal .modal-box.additionalloanproducts .product-item .interest-rate h5,
.overlay-modal .modal-box.checkeligibilityview .product-item .principal-interest h5,
.overlay-modal .modal-box.shortlistfilterview .product-item .principal-interest h5,
.overlay-modal .modal-box.shortlistview .product-item .principal-interest h5,
.overlay-modal .modal-box.additionalloanproducts .product-item .principal-interest h5,
.overlay-modal .modal-box.checkeligibilityview .product-item .repayment-power h5,
.overlay-modal .modal-box.shortlistfilterview .product-item .repayment-power h5,
.overlay-modal .modal-box.shortlistview .product-item .repayment-power h5,
.overlay-modal .modal-box.additionalloanproducts .product-item .repayment-power h5 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
.overlay-modal .modal-box.checkeligibilityview .product-item .interest-rate span,
.overlay-modal .modal-box.shortlistfilterview .product-item .interest-rate span,
.overlay-modal .modal-box.shortlistview .product-item .interest-rate span,
.overlay-modal .modal-box.additionalloanproducts .product-item .interest-rate span,
.overlay-modal .modal-box.checkeligibilityview .product-item .repayment-power span,
.overlay-modal .modal-box.shortlistfilterview .product-item .repayment-power span,
.overlay-modal .modal-box.shortlistview .product-item .repayment-power span,
.overlay-modal .modal-box.additionalloanproducts .product-item .repayment-power span {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #727C8F;
}
.overlay-modal .modal-box.checkeligibilityview .product-item .interest-rate .variable-rate,
.overlay-modal .modal-box.shortlistfilterview .product-item .interest-rate .variable-rate,
.overlay-modal .modal-box.shortlistview .product-item .interest-rate .variable-rate,
.overlay-modal .modal-box.additionalloanproducts .product-item .interest-rate .variable-rate,
.overlay-modal .modal-box.checkeligibilityview .product-item .repayment-power .variable-rate,
.overlay-modal .modal-box.shortlistfilterview .product-item .repayment-power .variable-rate,
.overlay-modal .modal-box.shortlistview .product-item .repayment-power .variable-rate,
.overlay-modal .modal-box.additionalloanproducts .product-item .repayment-power .variable-rate,
.overlay-modal .modal-box.checkeligibilityview .product-item .interest-rate .comparison-rate,
.overlay-modal .modal-box.shortlistfilterview .product-item .interest-rate .comparison-rate,
.overlay-modal .modal-box.shortlistview .product-item .interest-rate .comparison-rate,
.overlay-modal .modal-box.additionalloanproducts .product-item .interest-rate .comparison-rate,
.overlay-modal .modal-box.checkeligibilityview .product-item .repayment-power .comparison-rate,
.overlay-modal .modal-box.shortlistfilterview .product-item .repayment-power .comparison-rate,
.overlay-modal .modal-box.shortlistview .product-item .repayment-power .comparison-rate,
.overlay-modal .modal-box.additionalloanproducts .product-item .repayment-power .comparison-rate,
.overlay-modal .modal-box.checkeligibilityview .product-item .interest-rate .monthly-repayment,
.overlay-modal .modal-box.shortlistfilterview .product-item .interest-rate .monthly-repayment,
.overlay-modal .modal-box.shortlistview .product-item .interest-rate .monthly-repayment,
.overlay-modal .modal-box.additionalloanproducts .product-item .interest-rate .monthly-repayment,
.overlay-modal .modal-box.checkeligibilityview .product-item .repayment-power .monthly-repayment,
.overlay-modal .modal-box.shortlistfilterview .product-item .repayment-power .monthly-repayment,
.overlay-modal .modal-box.shortlistview .product-item .repayment-power .monthly-repayment,
.overlay-modal .modal-box.additionalloanproducts .product-item .repayment-power .monthly-repayment,
.overlay-modal .modal-box.checkeligibilityview .product-item .interest-rate .borrowing-power,
.overlay-modal .modal-box.shortlistfilterview .product-item .interest-rate .borrowing-power,
.overlay-modal .modal-box.shortlistview .product-item .interest-rate .borrowing-power,
.overlay-modal .modal-box.additionalloanproducts .product-item .interest-rate .borrowing-power,
.overlay-modal .modal-box.checkeligibilityview .product-item .repayment-power .borrowing-power,
.overlay-modal .modal-box.shortlistfilterview .product-item .repayment-power .borrowing-power,
.overlay-modal .modal-box.shortlistview .product-item .repayment-power .borrowing-power,
.overlay-modal .modal-box.additionalloanproducts .product-item .repayment-power .borrowing-power {
  display: inline-block;
  width: 49%;
}
.overlay-modal .modal-box.checkeligibilityview .product-item .interest-rate .variable-rate .rate,
.overlay-modal .modal-box.shortlistfilterview .product-item .interest-rate .variable-rate .rate,
.overlay-modal .modal-box.shortlistview .product-item .interest-rate .variable-rate .rate,
.overlay-modal .modal-box.additionalloanproducts .product-item .interest-rate .variable-rate .rate,
.overlay-modal .modal-box.checkeligibilityview .product-item .repayment-power .variable-rate .rate,
.overlay-modal .modal-box.shortlistfilterview .product-item .repayment-power .variable-rate .rate,
.overlay-modal .modal-box.shortlistview .product-item .repayment-power .variable-rate .rate,
.overlay-modal .modal-box.additionalloanproducts .product-item .repayment-power .variable-rate .rate,
.overlay-modal .modal-box.checkeligibilityview .product-item .interest-rate .comparison-rate .rate,
.overlay-modal .modal-box.shortlistfilterview .product-item .interest-rate .comparison-rate .rate,
.overlay-modal .modal-box.shortlistview .product-item .interest-rate .comparison-rate .rate,
.overlay-modal .modal-box.additionalloanproducts .product-item .interest-rate .comparison-rate .rate,
.overlay-modal .modal-box.checkeligibilityview .product-item .repayment-power .comparison-rate .rate,
.overlay-modal .modal-box.shortlistfilterview .product-item .repayment-power .comparison-rate .rate,
.overlay-modal .modal-box.shortlistview .product-item .repayment-power .comparison-rate .rate,
.overlay-modal .modal-box.additionalloanproducts .product-item .repayment-power .comparison-rate .rate,
.overlay-modal .modal-box.checkeligibilityview .product-item .interest-rate .monthly-repayment .rate,
.overlay-modal .modal-box.shortlistfilterview .product-item .interest-rate .monthly-repayment .rate,
.overlay-modal .modal-box.shortlistview .product-item .interest-rate .monthly-repayment .rate,
.overlay-modal .modal-box.additionalloanproducts .product-item .interest-rate .monthly-repayment .rate,
.overlay-modal .modal-box.checkeligibilityview .product-item .repayment-power .monthly-repayment .rate,
.overlay-modal .modal-box.shortlistfilterview .product-item .repayment-power .monthly-repayment .rate,
.overlay-modal .modal-box.shortlistview .product-item .repayment-power .monthly-repayment .rate,
.overlay-modal .modal-box.additionalloanproducts .product-item .repayment-power .monthly-repayment .rate,
.overlay-modal .modal-box.checkeligibilityview .product-item .interest-rate .borrowing-power .rate,
.overlay-modal .modal-box.shortlistfilterview .product-item .interest-rate .borrowing-power .rate,
.overlay-modal .modal-box.shortlistview .product-item .interest-rate .borrowing-power .rate,
.overlay-modal .modal-box.additionalloanproducts .product-item .interest-rate .borrowing-power .rate,
.overlay-modal .modal-box.checkeligibilityview .product-item .repayment-power .borrowing-power .rate,
.overlay-modal .modal-box.shortlistfilterview .product-item .repayment-power .borrowing-power .rate,
.overlay-modal .modal-box.shortlistview .product-item .repayment-power .borrowing-power .rate,
.overlay-modal .modal-box.additionalloanproducts .product-item .repayment-power .borrowing-power .rate {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  text-align: center;
}
.overlay-modal .modal-box.checkeligibilityview .product-item .interest-rate .borrowing-power .borrowing-value,
.overlay-modal .modal-box.shortlistfilterview .product-item .interest-rate .borrowing-power .borrowing-value,
.overlay-modal .modal-box.shortlistview .product-item .interest-rate .borrowing-power .borrowing-value,
.overlay-modal .modal-box.additionalloanproducts .product-item .interest-rate .borrowing-power .borrowing-value,
.overlay-modal .modal-box.checkeligibilityview .product-item .repayment-power .borrowing-power .borrowing-value,
.overlay-modal .modal-box.shortlistfilterview .product-item .repayment-power .borrowing-power .borrowing-value,
.overlay-modal .modal-box.shortlistview .product-item .repayment-power .borrowing-power .borrowing-value,
.overlay-modal .modal-box.additionalloanproducts .product-item .repayment-power .borrowing-power .borrowing-value {
  padding-left: 40px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 20px;
}
.overlay-modal .modal-box.checkeligibilityview .product-item .interest-rate .borrowing-power .borrowing-value.empty-value,
.overlay-modal .modal-box.shortlistfilterview .product-item .interest-rate .borrowing-power .borrowing-value.empty-value,
.overlay-modal .modal-box.shortlistview .product-item .interest-rate .borrowing-power .borrowing-value.empty-value,
.overlay-modal .modal-box.additionalloanproducts .product-item .interest-rate .borrowing-power .borrowing-value.empty-value,
.overlay-modal .modal-box.checkeligibilityview .product-item .repayment-power .borrowing-power .borrowing-value.empty-value,
.overlay-modal .modal-box.shortlistfilterview .product-item .repayment-power .borrowing-power .borrowing-value.empty-value,
.overlay-modal .modal-box.shortlistview .product-item .repayment-power .borrowing-power .borrowing-value.empty-value,
.overlay-modal .modal-box.additionalloanproducts .product-item .repayment-power .borrowing-power .borrowing-value.empty-value {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  padding-left: 0;
}
.overlay-modal .modal-box.checkeligibilityview .product-item ul.statistics,
.overlay-modal .modal-box.shortlistfilterview .product-item ul.statistics,
.overlay-modal .modal-box.shortlistview .product-item ul.statistics,
.overlay-modal .modal-box.additionalloanproducts .product-item ul.statistics {
  border-top: solid 1px #E9EBF1;
  padding: 20px 0 10px 0;
  display: block;
  vertical-align: 50%;
}
.overlay-modal .modal-box.checkeligibilityview .product-item ul.statistics li,
.overlay-modal .modal-box.shortlistfilterview .product-item ul.statistics li,
.overlay-modal .modal-box.shortlistview .product-item ul.statistics li,
.overlay-modal .modal-box.additionalloanproducts .product-item ul.statistics li {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0% 50%;
  background-size: 10px 10px;
  padding-left: 15px;
  margin-left: 10px;
}
.overlay-modal .modal-box.checkeligibilityview .product-item ul.statistics li.true,
.overlay-modal .modal-box.shortlistfilterview .product-item ul.statistics li.true,
.overlay-modal .modal-box.shortlistview .product-item ul.statistics li.true,
.overlay-modal .modal-box.additionalloanproducts .product-item ul.statistics li.true,
.overlay-modal .modal-box.checkeligibilityview .product-item ul.statistics li.Y,
.overlay-modal .modal-box.shortlistfilterview .product-item ul.statistics li.Y,
.overlay-modal .modal-box.shortlistview .product-item ul.statistics li.Y,
.overlay-modal .modal-box.additionalloanproducts .product-item ul.statistics li.Y {
  background-image: url(img/elements/check-green.png);
  color: #26BD27;
}
.overlay-modal .modal-box.checkeligibilityview .product-item ul.statistics li.false,
.overlay-modal .modal-box.shortlistfilterview .product-item ul.statistics li.false,
.overlay-modal .modal-box.shortlistview .product-item ul.statistics li.false,
.overlay-modal .modal-box.additionalloanproducts .product-item ul.statistics li.false,
.overlay-modal .modal-box.checkeligibilityview .product-item ul.statistics li.N,
.overlay-modal .modal-box.shortlistfilterview .product-item ul.statistics li.N,
.overlay-modal .modal-box.shortlistview .product-item ul.statistics li.N,
.overlay-modal .modal-box.additionalloanproducts .product-item ul.statistics li.N {
  background-image: url(img/elements/cross-red.png);
  color: #FF4E4C;
}
.overlay-modal .modal-box.checkeligibilityview .product-item ul.statistics li.additional,
.overlay-modal .modal-box.shortlistfilterview .product-item ul.statistics li.additional,
.overlay-modal .modal-box.shortlistview .product-item ul.statistics li.additional,
.overlay-modal .modal-box.additionalloanproducts .product-item ul.statistics li.additional {
  background-image: url(img/elements/money_blue.png);
  color: #2291FF;
  background-size: 8px 12px;
  background-position: 0px 50%;
}
.overlay-modal .modal-box.checkeligibilityview .product-item ul.statistics li:first-of-type,
.overlay-modal .modal-box.shortlistfilterview .product-item ul.statistics li:first-of-type,
.overlay-modal .modal-box.shortlistview .product-item ul.statistics li:first-of-type,
.overlay-modal .modal-box.additionalloanproducts .product-item ul.statistics li:first-of-type {
  margin-left: 0;
}
.overlay-modal .modal-box.checkeligibilityview .product-item .fees,
.overlay-modal .modal-box.shortlistfilterview .product-item .fees,
.overlay-modal .modal-box.shortlistview .product-item .fees,
.overlay-modal .modal-box.additionalloanproducts .product-item .fees {
  padding: 10px 0 20px 0;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  border-bottom: solid 1px #E9EBF1;
}
.overlay-modal .modal-box.checkeligibilityview .product-item .fees .upfront-fee,
.overlay-modal .modal-box.shortlistfilterview .product-item .fees .upfront-fee,
.overlay-modal .modal-box.shortlistview .product-item .fees .upfront-fee,
.overlay-modal .modal-box.additionalloanproducts .product-item .fees .upfront-fee,
.overlay-modal .modal-box.checkeligibilityview .product-item .fees .annual-fee,
.overlay-modal .modal-box.shortlistfilterview .product-item .fees .annual-fee,
.overlay-modal .modal-box.shortlistview .product-item .fees .annual-fee,
.overlay-modal .modal-box.additionalloanproducts .product-item .fees .annual-fee {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}
.overlay-modal .modal-box.checkeligibilityview .product-item .fees span,
.overlay-modal .modal-box.shortlistfilterview .product-item .fees span,
.overlay-modal .modal-box.shortlistview .product-item .fees span,
.overlay-modal .modal-box.additionalloanproducts .product-item .fees span {
  margin-top: 5px;
  display: block;
  width: 100%;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 10px;
  color: #727C8F;
}
.overlay-modal .modal-box.checkeligibilityview .product-item .more-info,
.overlay-modal .modal-box.shortlistfilterview .product-item .more-info,
.overlay-modal .modal-box.shortlistview .product-item .more-info,
.overlay-modal .modal-box.additionalloanproducts .product-item .more-info {
  width: 100%;
  margin-top: 20px;
}
.overlay-modal .modal-box.checkeligibilityview .product-item .more-info .details,
.overlay-modal .modal-box.shortlistfilterview .product-item .more-info .details,
.overlay-modal .modal-box.shortlistview .product-item .more-info .details,
.overlay-modal .modal-box.additionalloanproducts .product-item .more-info .details,
.overlay-modal .modal-box.checkeligibilityview .product-item .more-info .eligibility,
.overlay-modal .modal-box.shortlistfilterview .product-item .more-info .eligibility,
.overlay-modal .modal-box.shortlistview .product-item .more-info .eligibility,
.overlay-modal .modal-box.additionalloanproducts .product-item .more-info .eligibility {
  width: Calc(50% - 12px);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 14px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 25px 13px 25px;
  color: #2291FF;
  background-color: Transparent;
  border: solid 1px #2291FF;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  padding: 10px 5px 10px 5px;
}
.overlay-modal .modal-box.checkeligibilityview .product-item .more-info .details:hover,
.overlay-modal .modal-box.shortlistfilterview .product-item .more-info .details:hover,
.overlay-modal .modal-box.shortlistview .product-item .more-info .details:hover,
.overlay-modal .modal-box.additionalloanproducts .product-item .more-info .details:hover,
.overlay-modal .modal-box.checkeligibilityview .product-item .more-info .eligibility:hover,
.overlay-modal .modal-box.shortlistfilterview .product-item .more-info .eligibility:hover,
.overlay-modal .modal-box.shortlistview .product-item .more-info .eligibility:hover,
.overlay-modal .modal-box.additionalloanproducts .product-item .more-info .eligibility:hover {
  color: #46A3FF;
  border: solid 1px #46A3FF;
}
.overlay-modal .modal-box.checkeligibilityview .product-item .more-info .details:active,
.overlay-modal .modal-box.shortlistfilterview .product-item .more-info .details:active,
.overlay-modal .modal-box.shortlistview .product-item .more-info .details:active,
.overlay-modal .modal-box.additionalloanproducts .product-item .more-info .details:active,
.overlay-modal .modal-box.checkeligibilityview .product-item .more-info .eligibility:active,
.overlay-modal .modal-box.shortlistfilterview .product-item .more-info .eligibility:active,
.overlay-modal .modal-box.shortlistview .product-item .more-info .eligibility:active,
.overlay-modal .modal-box.additionalloanproducts .product-item .more-info .eligibility:active,
.overlay-modal .modal-box.checkeligibilityview .product-item .more-info .details:focus,
.overlay-modal .modal-box.shortlistfilterview .product-item .more-info .details:focus,
.overlay-modal .modal-box.shortlistview .product-item .more-info .details:focus,
.overlay-modal .modal-box.additionalloanproducts .product-item .more-info .details:focus,
.overlay-modal .modal-box.checkeligibilityview .product-item .more-info .eligibility:focus,
.overlay-modal .modal-box.shortlistfilterview .product-item .more-info .eligibility:focus,
.overlay-modal .modal-box.shortlistview .product-item .more-info .eligibility:focus,
.overlay-modal .modal-box.additionalloanproducts .product-item .more-info .eligibility:focus {
  color: #1C83EA;
  border: solid 1px #1C83EA;
}
.overlay-modal .modal-box.checkeligibilityview .product-item .more-info .details:first-of-type,
.overlay-modal .modal-box.shortlistfilterview .product-item .more-info .details:first-of-type,
.overlay-modal .modal-box.shortlistview .product-item .more-info .details:first-of-type,
.overlay-modal .modal-box.additionalloanproducts .product-item .more-info .details:first-of-type,
.overlay-modal .modal-box.checkeligibilityview .product-item .more-info .eligibility:first-of-type,
.overlay-modal .modal-box.shortlistfilterview .product-item .more-info .eligibility:first-of-type,
.overlay-modal .modal-box.shortlistview .product-item .more-info .eligibility:first-of-type,
.overlay-modal .modal-box.additionalloanproducts .product-item .more-info .eligibility:first-of-type {
  margin-right: 20px;
}
.overlay-modal .modal-box.checkeligibilityview .product-item .more-info .eligibility,
.overlay-modal .modal-box.shortlistfilterview .product-item .more-info .eligibility,
.overlay-modal .modal-box.shortlistview .product-item .more-info .eligibility,
.overlay-modal .modal-box.additionalloanproducts .product-item .more-info .eligibility {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  padding: 10px 5px 10px 5px;
}
.overlay-modal .modal-box.checkeligibilityview .product-item .more-info .eligibility:hover,
.overlay-modal .modal-box.shortlistfilterview .product-item .more-info .eligibility:hover,
.overlay-modal .modal-box.shortlistview .product-item .more-info .eligibility:hover,
.overlay-modal .modal-box.additionalloanproducts .product-item .more-info .eligibility:hover {
  background-color: #46A3FF;
}
.overlay-modal .modal-box.checkeligibilityview .product-item .more-info .eligibility:active,
.overlay-modal .modal-box.shortlistfilterview .product-item .more-info .eligibility:active,
.overlay-modal .modal-box.shortlistview .product-item .more-info .eligibility:active,
.overlay-modal .modal-box.additionalloanproducts .product-item .more-info .eligibility:active,
.overlay-modal .modal-box.checkeligibilityview .product-item .more-info .eligibility:focus,
.overlay-modal .modal-box.shortlistfilterview .product-item .more-info .eligibility:focus,
.overlay-modal .modal-box.shortlistview .product-item .more-info .eligibility:focus,
.overlay-modal .modal-box.additionalloanproducts .product-item .more-info .eligibility:focus {
  background-color: #1C83EA;
}
.overlay-modal .modal-box.checkeligibilityview .product-item .more-info .eligibility:disabled,
.overlay-modal .modal-box.shortlistfilterview .product-item .more-info .eligibility:disabled,
.overlay-modal .modal-box.shortlistview .product-item .more-info .eligibility:disabled,
.overlay-modal .modal-box.additionalloanproducts .product-item .more-info .eligibility:disabled,
.overlay-modal .modal-box.checkeligibilityview .product-item .more-info .eligibility.disabled,
.overlay-modal .modal-box.shortlistfilterview .product-item .more-info .eligibility.disabled,
.overlay-modal .modal-box.shortlistview .product-item .more-info .eligibility.disabled,
.overlay-modal .modal-box.additionalloanproducts .product-item .more-info .eligibility.disabled {
  background: #909090;
}
.overlay-modal .modal-box.checkeligibilityview .product-item .more-info .eligibility:hover,
.overlay-modal .modal-box.shortlistfilterview .product-item .more-info .eligibility:hover,
.overlay-modal .modal-box.shortlistview .product-item .more-info .eligibility:hover,
.overlay-modal .modal-box.additionalloanproducts .product-item .more-info .eligibility:hover {
  padding: 10px 5px 10px 5px;
  border: none;
  color: #fff;
}
.overlay-modal .modal-box.checkeligibilityview .product-item .more-detail,
.overlay-modal .modal-box.shortlistfilterview .product-item .more-detail,
.overlay-modal .modal-box.shortlistview .product-item .more-detail,
.overlay-modal .modal-box.additionalloanproducts .product-item .more-detail {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 11px;
  padding: 6px 10px 6px 10px;
  background-position: Calc(100% - 10px) 50%;
  background-repeat: no-repeat;
  padding-right: 30px;
  background-image: url(img/button/right-arrow-blue.png);
  background-size: 10px 7px;
  margin: 5px 0 15px 0;
}
.overlay-modal .modal-box.checkeligibilityview .product-item .more-detail:hover,
.overlay-modal .modal-box.shortlistfilterview .product-item .more-detail:hover,
.overlay-modal .modal-box.shortlistview .product-item .more-detail:hover,
.overlay-modal .modal-box.additionalloanproducts .product-item .more-detail:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
.overlay-modal .modal-box.checkeligibilityview .product-item .more-detail:active,
.overlay-modal .modal-box.shortlistfilterview .product-item .more-detail:active,
.overlay-modal .modal-box.shortlistview .product-item .more-detail:active,
.overlay-modal .modal-box.additionalloanproducts .product-item .more-detail:active,
.overlay-modal .modal-box.checkeligibilityview .product-item .more-detail:focus,
.overlay-modal .modal-box.shortlistfilterview .product-item .more-detail:focus,
.overlay-modal .modal-box.shortlistview .product-item .more-detail:focus,
.overlay-modal .modal-box.additionalloanproducts .product-item .more-detail:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
.overlay-modal .modal-box.checkeligibilityview .close,
.overlay-modal .modal-box.shortlistfilterview .close,
.overlay-modal .modal-box.shortlistview .close,
.overlay-modal .modal-box.additionalloanproducts .close {
  margin: -10px -10px 0 0;
  float: right;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  background-color: #fff;
  width: 20px;
  height: 20px;
  background-image: url(img/button/modal-close.png);
  background-position: 50% 50%;
  background-size: 10px 10px;
  background-repeat: no-repeat;
}
.overlay-modal .modal-box.checkeligibilityview .favourites,
.overlay-modal .modal-box.shortlistfilterview .favourites,
.overlay-modal .modal-box.shortlistview .favourites,
.overlay-modal .modal-box.additionalloanproducts .favourites {
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  border: 1px solid #D8DCE7;
  background-color: #fff;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  height: 36px;
  width: 36px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 15px 15px;
  box-shadow: 0 1px 0 0 #E9EBF1;
  background-image: url(img/button/star_grey.png);
  display: inline-block;
  float: left;
}
.overlay-modal .modal-box.checkeligibilityview .favourites:hover,
.overlay-modal .modal-box.shortlistfilterview .favourites:hover,
.overlay-modal .modal-box.shortlistview .favourites:hover,
.overlay-modal .modal-box.additionalloanproducts .favourites:hover {
  border: 1px solid #C8CCD7;
}
.overlay-modal .modal-box.checkeligibilityview .favourites.active,
.overlay-modal .modal-box.shortlistfilterview .favourites.active,
.overlay-modal .modal-box.shortlistview .favourites.active,
.overlay-modal .modal-box.additionalloanproducts .favourites.active {
  border: 1px solid #F5A623;
  background-image: url(img/button/star_orange_filled.png);
}
.overlay-modal .modal-box.checkeligibilityview .scroll-container,
.overlay-modal .modal-box.shortlistfilterview .scroll-container,
.overlay-modal .modal-box.shortlistview .scroll-container,
.overlay-modal .modal-box.additionalloanproducts .scroll-container {
  height: 100%;
  width: 1820px;
  text-align: left;
}
.overlay-modal .modal-box.checkeligibilityview .scroll-container .select-more,
.overlay-modal .modal-box.shortlistfilterview .scroll-container .select-more,
.overlay-modal .modal-box.shortlistview .scroll-container .select-more,
.overlay-modal .modal-box.additionalloanproducts .scroll-container .select-more {
  display: inline-block;
  vertical-align: top;
  width: 290px;
  background-color: #F6F7FB;
  border-right: 1px solid #D8DCE7;
  padding: 20px;
  height: 465px;
  text-align: center;
}
.overlay-modal .modal-box.checkeligibilityview .scroll-container .select-more p,
.overlay-modal .modal-box.shortlistfilterview .scroll-container .select-more p,
.overlay-modal .modal-box.shortlistview .scroll-container .select-more p,
.overlay-modal .modal-box.additionalloanproducts .scroll-container .select-more p {
  margin-top: 150px;
  display: block;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #101922;
  background-image: url(img/elements/add_loans.png);
  background-repeat: no-repeat;
  background-size: 30px 32px;
  background-position: 50% 0;
  padding-top: 50px;
}
.overlay-modal .modal-box.checkeligibilityview .scroll-container .select-more p strong,
.overlay-modal .modal-box.shortlistfilterview .scroll-container .select-more p strong,
.overlay-modal .modal-box.shortlistview .scroll-container .select-more p strong,
.overlay-modal .modal-box.additionalloanproducts .scroll-container .select-more p strong {
  font-weight: bolder;
}
.overlay-modal .modal-box.checkeligibilityview .remove,
.overlay-modal .modal-box.shortlistfilterview .remove,
.overlay-modal .modal-box.shortlistview .remove,
.overlay-modal .modal-box.additionalloanproducts .remove {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  text-align: center;
}
.overlay-modal .modal-box.checkeligibilityview .remove h2,
.overlay-modal .modal-box.shortlistfilterview .remove h2,
.overlay-modal .modal-box.shortlistview .remove h2,
.overlay-modal .modal-box.additionalloanproducts .remove h2 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  line-height: 1.2;
  margin-top: 140px;
}
.overlay-modal .modal-box.checkeligibilityview .remove .remove-navigation,
.overlay-modal .modal-box.shortlistfilterview .remove .remove-navigation,
.overlay-modal .modal-box.shortlistview .remove .remove-navigation,
.overlay-modal .modal-box.additionalloanproducts .remove .remove-navigation {
  margin-top: 20px;
}
.overlay-modal .modal-box.checkeligibilityview .remove .remove-navigation .cancel-button,
.overlay-modal .modal-box.shortlistfilterview .remove .remove-navigation .cancel-button,
.overlay-modal .modal-box.shortlistview .remove .remove-navigation .cancel-button,
.overlay-modal .modal-box.additionalloanproducts .remove .remove-navigation .cancel-button,
.overlay-modal .modal-box.checkeligibilityview .remove .remove-navigation .remove-button,
.overlay-modal .modal-box.shortlistfilterview .remove .remove-navigation .remove-button,
.overlay-modal .modal-box.shortlistview .remove .remove-navigation .remove-button,
.overlay-modal .modal-box.additionalloanproducts .remove .remove-navigation .remove-button {
  display: inline-block;
  width: Calc(50% - 10px);
}
.overlay-modal .modal-box.checkeligibilityview .remove .remove-navigation .cancel-button,
.overlay-modal .modal-box.shortlistfilterview .remove .remove-navigation .cancel-button,
.overlay-modal .modal-box.shortlistview .remove .remove-navigation .cancel-button,
.overlay-modal .modal-box.additionalloanproducts .remove .remove-navigation .cancel-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
}
.overlay-modal .modal-box.checkeligibilityview .remove .remove-navigation .cancel-button:hover,
.overlay-modal .modal-box.shortlistfilterview .remove .remove-navigation .cancel-button:hover,
.overlay-modal .modal-box.shortlistview .remove .remove-navigation .cancel-button:hover,
.overlay-modal .modal-box.additionalloanproducts .remove .remove-navigation .cancel-button:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
.overlay-modal .modal-box.checkeligibilityview .remove .remove-navigation .cancel-button:active,
.overlay-modal .modal-box.shortlistfilterview .remove .remove-navigation .cancel-button:active,
.overlay-modal .modal-box.shortlistview .remove .remove-navigation .cancel-button:active,
.overlay-modal .modal-box.additionalloanproducts .remove .remove-navigation .cancel-button:active,
.overlay-modal .modal-box.checkeligibilityview .remove .remove-navigation .cancel-button:focus,
.overlay-modal .modal-box.shortlistfilterview .remove .remove-navigation .cancel-button:focus,
.overlay-modal .modal-box.shortlistview .remove .remove-navigation .cancel-button:focus,
.overlay-modal .modal-box.additionalloanproducts .remove .remove-navigation .cancel-button:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
.overlay-modal .modal-box.checkeligibilityview .remove .remove-navigation .remove-button,
.overlay-modal .modal-box.shortlistfilterview .remove .remove-navigation .remove-button,
.overlay-modal .modal-box.shortlistview .remove .remove-navigation .remove-button,
.overlay-modal .modal-box.additionalloanproducts .remove .remove-navigation .remove-button {
  margin-left: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #fff;
  background-color: #FF4E4C;
}
.overlay-modal .modal-box.checkeligibilityview .remove .remove-navigation .remove-button:hover,
.overlay-modal .modal-box.shortlistfilterview .remove .remove-navigation .remove-button:hover,
.overlay-modal .modal-box.shortlistview .remove .remove-navigation .remove-button:hover,
.overlay-modal .modal-box.additionalloanproducts .remove .remove-navigation .remove-button:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #FF4E4C;
}
.overlay-modal .modal-box.checkeligibilityview .remove .remove-navigation .remove-button:active,
.overlay-modal .modal-box.shortlistfilterview .remove .remove-navigation .remove-button:active,
.overlay-modal .modal-box.shortlistview .remove .remove-navigation .remove-button:active,
.overlay-modal .modal-box.additionalloanproducts .remove .remove-navigation .remove-button:active,
.overlay-modal .modal-box.checkeligibilityview .remove .remove-navigation .remove-button:focus,
.overlay-modal .modal-box.shortlistfilterview .remove .remove-navigation .remove-button:focus,
.overlay-modal .modal-box.shortlistview .remove .remove-navigation .remove-button:focus,
.overlay-modal .modal-box.additionalloanproducts .remove .remove-navigation .remove-button:focus {
  background-color: #FF4E4C;
}
.overlay-modal .modal-box.checkeligibilityview .product-item {
  height: 465px;
  text-align: center;
}
.overlay-modal .modal-box.checkeligibilityview .product-item h6 {
  margin-bottom: 10px;
}
.overlay-modal .modal-box.checkeligibilityview .product-item .interest-rate .variable-rate .rate,
.overlay-modal .modal-box.checkeligibilityview .product-item .interest-rate .comparison-rate .rate,
.overlay-modal .modal-box.checkeligibilityview .product-item .repayment-power .monthly-repayment .rate,
.overlay-modal .modal-box.checkeligibilityview .product-item .repayment-power .borrowing-power .rate {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 20px;
}
.overlay-modal .modal-box.checkeligibilityview .product-item .principal-interest {
  border-top: none;
  padding: 10px 0 20px 0;
}
.overlay-modal .modal-box.checkeligibilityview .product-item .principal-interest h5 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
}
.overlay-modal .modal-box.checkeligibilityview .product-item .principal-interest .description {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 11px;
}
.overlay-modal .modal-box.checkeligibilityview .product-item .fees {
  border-bottom: none;
  padding: 15px 0;
}
.overlay-modal .modal-box.checkeligibilityview .navigation {
  height: 63px;
}
.overlay-modal .modal-box.shortlistfilterview .product-list .product,
.overlay-modal .modal-box.shortlistview .product-list .product,
.overlay-modal .modal-box.additionalloanproducts .product-list .product {
  margin-bottom: 20px;
  border-bottom: 1px solid #D8DCE7;
}
.overlay-modal .modal-box.shortlistfilterview .product-list::-webkit-scrollbar,
.overlay-modal .modal-box.shortlistview .product-list::-webkit-scrollbar,
.overlay-modal .modal-box.additionalloanproducts .product-list::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  margin-top: 20px;
  background-color: #E9EBF1;
}
.overlay-modal .modal-box.shortlistfilterview .product-list::-webkit-scrollbar-track,
.overlay-modal .modal-box.shortlistview .product-list::-webkit-scrollbar-track,
.overlay-modal .modal-box.additionalloanproducts .product-list::-webkit-scrollbar-track {
  background: #E9EBF1;
  border-radius: 4px;
}
.overlay-modal .modal-box.shortlistfilterview .product-list::-webkit-scrollbar-thumb,
.overlay-modal .modal-box.shortlistview .product-list::-webkit-scrollbar-thumb,
.overlay-modal .modal-box.additionalloanproducts .product-list::-webkit-scrollbar-thumb {
  background: #727C8F;
  border-radius: 4px;
}
.overlay-modal .modal-box.shortlistfilterview .product-list::-webkit-scrollbar-thumb:hover,
.overlay-modal .modal-box.shortlistview .product-list::-webkit-scrollbar-thumb:hover,
.overlay-modal .modal-box.additionalloanproducts .product-list::-webkit-scrollbar-thumb:hover {
  background: #666E7B;
}
.overlay-modal .modal-box.shortlistfilterview .product-item,
.overlay-modal .modal-box.shortlistview .product-item,
.overlay-modal .modal-box.additionalloanproducts .product-item {
  min-height: 455px;
}
.overlay-modal .modal-box.shortlistfilterview .product-item h6,
.overlay-modal .modal-box.shortlistview .product-item h6,
.overlay-modal .modal-box.additionalloanproducts .product-item h6 {
  margin-bottom: 20px;
}
.overlay-modal .modal-box.shortlistview .product .product-item .active .close {
  display: none;
}
.overlay-modal .modal-box.shortlistview .empty {
  text-align: center;
  margin-bottom: 50px;
}
.overlay-modal .modal-box.shortlistview .empty .illustration {
  width: 320px;
  height: 210px;
  background-color: #E9EBF1;
  margin: 0 auto;
  margin-top: 50px;
}
.overlay-modal .modal-box.shortlistview .empty h4 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  margin-top: 30px;
}
.overlay-modal .modal-box.shortlistview .empty p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  margin-top: 15px;
}
.overlay-modal .modal-box.shortlistview .empty p a {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #2291FF;
}
.overlay-modal .modal-box.checkeligibilityopen {
  width: 600px;
}
.overlay-modal .modal-box.checkeligibilityopen img {
  width: 408px;
  height: 207px;
  object-fit: contain;
  margin-bottom: 20px;
}
.overlay-modal .modal-box.checkeligibilityopen h1 {
  margin-bottom: 10px;
}
.overlay-modal .modal-box.checkeligibilityopen .modal-content p {
  margin-bottom: 0px;
}
.overlay-modal .modal-box.checkeligibilityopen .box-text {
  margin-top: 20px;
}
.overlay-modal .modal-box.checkeligibilityopen .modal-navigation button {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
.overlay-modal .modal-box.checkeligibilityunfinished {
  width: 750px;
}
.overlay-modal .modal-box.checkeligibilityunfinished .selection {
  margin-top: 20px;
}
.overlay-modal .modal-box.checkeligibilityunfinished .product-select {
  width: 210px;
}
.overlay-modal .modal-box.checkeligibilityinitial img {
  width: 186px;
  height: 186px;
}
.overlay-modal .modal-box.checkeligibilityinitial h1 {
  margin-bottom: 0;
}
.overlay-modal .modal-box.checkeligibilityinitial h4 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #575E6C;
  margin-top: 10px;
}
.overlay-modal .modal-box.checkeligibilityinitial ul {
  margin-top: 20px;
}
.overlay-modal .modal-box.checkeligibilityinitial ul li {
  margin-bottom: 20px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  color: #575E6C;
  list-style: disc;
  line-height: 1.4;
}
.overlay-modal .modal-box.contactgetadvice {
  width: 800px;
}
.overlay-modal .modal-box.contactgetadvice .left,
.overlay-modal .modal-box.contactgetadvice .right {
  width: Calc(50% - 10px);
  display: inline-block;
  vertical-align: top;
}
.overlay-modal .modal-box.contactgetadvice .right h1 {
  font-size: 30px;
  margin-top: 5px;
}
.overlay-modal .modal-box.contactgetadvice .right .product-select {
  border: 1px solid #D8DCE7;
  width: 90%;
}
.overlay-modal .modal-box.contactgetadvice .right .product-select:after {
  position: absolute;
  top: -10px;
  right: -10px;
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #26BD27;
  background-image: url(img/elements/check-white.png);
  background-size: 10px 8px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  z-index: 20;
}
.overlay-modal .modal-box.contactgetadvice .right .illustration {
  margin-top: 40px;
  width: 100%;
  background-color: #E9EBF1;
  height: 230px;
}
.overlay-modal .modal-box.contactgetadvice .right .interest-rate {
  padding-bottom: 0;
}
.overlay-modal .modal-box.contactgetadvice .left {
  text-align: center;
  margin-right: 20px;
}
.overlay-modal .modal-box.contactgetadvice .left .form {
  margin-top: 20px;
  text-align: left;
}
.overlay-modal .modal-box.contactgetadvice .left .form .form-container {
  width: 90%;
  margin: 0 auto;
}
.overlay-modal .modal-box.contactgetadvice .left .form .form-container .w280 {
  width: 100%;
}
.overlay-modal .modal-box.contactgetadvice .left .form .form-container button {
  width: 100%;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
.overlay-modal .modal-box.loginalreadyhaveaccount {
  width: 600px;
}
.overlay-modal .modal-box.loginalreadyhaveaccount h1 {
  margin-top: 20px;
}
.overlay-modal .modal-box.loginalreadyhaveaccount strong {
  font-weight: bolder;
}
.overlay-modal .modal-box.loginalreadyhaveaccount .modal-navigation {
  margin-top: 40px;
}
.overlay-modal .modal-box.loginalreadyhaveaccount .modal-navigation button {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
.overlay-modal .modal-box.newemail {
  width: 600px;
}
.overlay-modal .modal-box.newemail h1 {
  margin-top: 20px;
}
.overlay-modal .modal-box.newemail .w580 {
  width: 320px;
  margin: 0 auto;
  margin-bottom: 0px;
  margin-top: 10px;
}
.overlay-modal .modal-box.newemail .modal-navigation {
  margin-top: 40px;
}
.overlay-modal .modal-box.newemail .modal-navigation button {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
.overlay-modal .modal-box.loginaccountabused {
  width: 600px;
}
.overlay-modal .modal-box.loginaccountabused h1 {
  margin-top: 20px;
}
.overlay-modal .modal-box.loginaccountabused .modal-navigation button {
  width: 380px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
.overlay-modal .modal-box.loginstartedprocess {
  width: 600px;
}
.overlay-modal .modal-box.loginstartedprocess h1 {
  margin-top: 20px;
}
.overlay-modal .modal-box.loginstartedprocess strong {
  font-weight: bolder;
}
.overlay-modal .modal-box.loginstartedprocess .modal-navigation {
  margin-top: 40px;
}
.overlay-modal .modal-box.loginstartedprocess .modal-navigation button {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
.overlay-modal .modal-box.refinanceanalysis {
  width: 1280px;
  padding: 0;
}
.overlay-modal .modal-box.refinanceanalysis .closeButton {
  position: absolute;
  margin: 0;
  top: 30px;
  right: 30px;
}
.overlay-modal .modal-box.refinanceanalysis .refinance-wrapper .refinance-content {
  border: none;
  background-color: transparent;
}
.overlay-modal .modal-box.refinanceanalysis .refinance-wrapper .refinance-content .left-side,
.overlay-modal .modal-box.refinanceanalysis .refinance-wrapper .refinance-content .right-side {
  vertical-align: bottom;
}
.overlay-modal .modal-box.refinanceanalysis .refinance-wrapper .refinance-content .right-side {
  text-align: left;
}
.overlay-modal .modal-box.refinanceanalysis .refinance-wrapper .refinance-content .right-side .shortlist {
  display: none;
}
.overlay-modal .modal-box.refinanceanalysis .refinance-wrapper .refinance-content .next-steps,
.overlay-modal .modal-box.refinanceanalysis .refinance-wrapper .refinance-content .refinance-details {
  text-align: left;
  min-height: 165px;
}
.overlay-modal .modal-box.refinanceanalysis .refinance-wrapper .refinance-content .refinance-details p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
}
.overlay-modal .modal-box.refinanceanalysis .refinance-wrapper .refinance-content .next-steps p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  line-height: 1.6;
}
.overlay-modal .modal-box.refinanceanalysis .refinance-wrapper .refinance-content .product-details {
  min-height: 370px;
}
.overlay-modal .modal-box.refinanceanalysisstart {
  padding: 60px;
  width: 600px;
}
.overlay-modal .modal-box.refinanceanalysisstart .closeButton {
  margin-top: -40px;
  margin-right: -40px;
}
.overlay-modal .modal-box.refinanceanalysisstart img {
  width: 380px;
}
.overlay-modal .modal-box.refinanceanalysisstart p {
  text-align: left;
}
.overlay-modal .modal-box.refinanceanalysisstart .modal-navigation button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  width: 100%;
}
.overlay-modal .modal-box.refinanceanalysisstart .modal-navigation button:hover {
  background-color: #46A3FF;
}
.overlay-modal .modal-box.refinanceanalysisstart .modal-navigation button:active,
.overlay-modal .modal-box.refinanceanalysisstart .modal-navigation button:focus {
  background-color: #1C83EA;
}
.overlay-modal .modal-box.refinanceanalysisstart .modal-navigation button:disabled,
.overlay-modal .modal-box.refinanceanalysisstart .modal-navigation button.disabled {
  background: #909090;
}
.overlay-modal .modal-box.refinanceanalysisstart .references {
  margin-top: 20px;
  text-align: left;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #727C8F;
  line-height: 1.4;
}
.overlay-modal .modal-box.refinanceanalysisdetails,
.overlay-modal .modal-box.currentloandetails,
.overlay-modal .modal-box.proposalrebate,
.overlay-modal .modal-box.lendercashback,
.overlay-modal .modal-box.loanbasecashback,
.overlay-modal .modal-box.loanbasetrailrebate,
.overlay-modal .modal-box.lenderoverviewoptions,
.overlay-modal .modal-box.objectiveslist,
.overlay-modal .modal-box.introlettertemplates,
.overlay-modal .modal-box.io-term {
  padding: 80px;
}
.overlay-modal .modal-box.refinanceanalysisdetails .closeButton,
.overlay-modal .modal-box.currentloandetails .closeButton,
.overlay-modal .modal-box.proposalrebate .closeButton,
.overlay-modal .modal-box.lendercashback .closeButton,
.overlay-modal .modal-box.loanbasecashback .closeButton,
.overlay-modal .modal-box.loanbasetrailrebate .closeButton,
.overlay-modal .modal-box.lenderoverviewoptions .closeButton,
.overlay-modal .modal-box.objectiveslist .closeButton,
.overlay-modal .modal-box.introlettertemplates .closeButton,
.overlay-modal .modal-box.io-term .closeButton {
  margin-top: -60px;
  margin-right: -60px;
}
@media screen and (max-height: 900px) {
  .overlay-modal .modal-box.refinanceanalysisdetails,
  .overlay-modal .modal-box.currentloandetails,
  .overlay-modal .modal-box.proposalrebate,
  .overlay-modal .modal-box.lendercashback,
  .overlay-modal .modal-box.loanbasecashback,
  .overlay-modal .modal-box.loanbasetrailrebate,
  .overlay-modal .modal-box.lenderoverviewoptions,
  .overlay-modal .modal-box.objectiveslist,
  .overlay-modal .modal-box.introlettertemplates,
  .overlay-modal .modal-box.io-term {
    padding: 40px 80px;
    transform: scale(0.9, 0.9);
  }
  .overlay-modal .modal-box.refinanceanalysisdetails .closeButton,
  .overlay-modal .modal-box.currentloandetails .closeButton,
  .overlay-modal .modal-box.proposalrebate .closeButton,
  .overlay-modal .modal-box.lendercashback .closeButton,
  .overlay-modal .modal-box.loanbasecashback .closeButton,
  .overlay-modal .modal-box.loanbasetrailrebate .closeButton,
  .overlay-modal .modal-box.lenderoverviewoptions .closeButton,
  .overlay-modal .modal-box.objectiveslist .closeButton,
  .overlay-modal .modal-box.introlettertemplates .closeButton,
  .overlay-modal .modal-box.io-term .closeButton {
    margin-top: -20px;
  }
}
.overlay-modal .modal-box.refinanceanalysisdetails h1,
.overlay-modal .modal-box.currentloandetails h1,
.overlay-modal .modal-box.proposalrebate h1,
.overlay-modal .modal-box.lendercashback h1,
.overlay-modal .modal-box.loanbasecashback h1,
.overlay-modal .modal-box.loanbasetrailrebate h1,
.overlay-modal .modal-box.lenderoverviewoptions h1,
.overlay-modal .modal-box.objectiveslist h1,
.overlay-modal .modal-box.introlettertemplates h1,
.overlay-modal .modal-box.io-term h1 {
  text-align: left;
}
.overlay-modal .modal-box.refinanceanalysisdetails .analyse,
.overlay-modal .modal-box.currentloandetails .analyse,
.overlay-modal .modal-box.proposalrebate .analyse,
.overlay-modal .modal-box.lendercashback .analyse,
.overlay-modal .modal-box.loanbasecashback .analyse,
.overlay-modal .modal-box.loanbasetrailrebate .analyse,
.overlay-modal .modal-box.lenderoverviewoptions .analyse,
.overlay-modal .modal-box.objectiveslist .analyse,
.overlay-modal .modal-box.introlettertemplates .analyse,
.overlay-modal .modal-box.io-term .analyse {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  width: 100%;
  margin-top: 20px;
}
.overlay-modal .modal-box.refinanceanalysisdetails .analyse:hover,
.overlay-modal .modal-box.currentloandetails .analyse:hover,
.overlay-modal .modal-box.proposalrebate .analyse:hover,
.overlay-modal .modal-box.lendercashback .analyse:hover,
.overlay-modal .modal-box.loanbasecashback .analyse:hover,
.overlay-modal .modal-box.loanbasetrailrebate .analyse:hover,
.overlay-modal .modal-box.lenderoverviewoptions .analyse:hover,
.overlay-modal .modal-box.objectiveslist .analyse:hover,
.overlay-modal .modal-box.introlettertemplates .analyse:hover,
.overlay-modal .modal-box.io-term .analyse:hover {
  background-color: #46A3FF;
}
.overlay-modal .modal-box.refinanceanalysisdetails .analyse:active,
.overlay-modal .modal-box.currentloandetails .analyse:active,
.overlay-modal .modal-box.proposalrebate .analyse:active,
.overlay-modal .modal-box.lendercashback .analyse:active,
.overlay-modal .modal-box.loanbasecashback .analyse:active,
.overlay-modal .modal-box.loanbasetrailrebate .analyse:active,
.overlay-modal .modal-box.lenderoverviewoptions .analyse:active,
.overlay-modal .modal-box.objectiveslist .analyse:active,
.overlay-modal .modal-box.introlettertemplates .analyse:active,
.overlay-modal .modal-box.io-term .analyse:active,
.overlay-modal .modal-box.refinanceanalysisdetails .analyse:focus,
.overlay-modal .modal-box.currentloandetails .analyse:focus,
.overlay-modal .modal-box.proposalrebate .analyse:focus,
.overlay-modal .modal-box.lendercashback .analyse:focus,
.overlay-modal .modal-box.loanbasecashback .analyse:focus,
.overlay-modal .modal-box.loanbasetrailrebate .analyse:focus,
.overlay-modal .modal-box.lenderoverviewoptions .analyse:focus,
.overlay-modal .modal-box.objectiveslist .analyse:focus,
.overlay-modal .modal-box.introlettertemplates .analyse:focus,
.overlay-modal .modal-box.io-term .analyse:focus {
  background-color: #1C83EA;
}
.overlay-modal .modal-box.refinanceanalysisdetails .analyse:disabled,
.overlay-modal .modal-box.currentloandetails .analyse:disabled,
.overlay-modal .modal-box.proposalrebate .analyse:disabled,
.overlay-modal .modal-box.lendercashback .analyse:disabled,
.overlay-modal .modal-box.loanbasecashback .analyse:disabled,
.overlay-modal .modal-box.loanbasetrailrebate .analyse:disabled,
.overlay-modal .modal-box.lenderoverviewoptions .analyse:disabled,
.overlay-modal .modal-box.objectiveslist .analyse:disabled,
.overlay-modal .modal-box.introlettertemplates .analyse:disabled,
.overlay-modal .modal-box.io-term .analyse:disabled,
.overlay-modal .modal-box.refinanceanalysisdetails .analyse.disabled,
.overlay-modal .modal-box.currentloandetails .analyse.disabled,
.overlay-modal .modal-box.proposalrebate .analyse.disabled,
.overlay-modal .modal-box.lendercashback .analyse.disabled,
.overlay-modal .modal-box.loanbasecashback .analyse.disabled,
.overlay-modal .modal-box.loanbasetrailrebate .analyse.disabled,
.overlay-modal .modal-box.lenderoverviewoptions .analyse.disabled,
.overlay-modal .modal-box.objectiveslist .analyse.disabled,
.overlay-modal .modal-box.introlettertemplates .analyse.disabled,
.overlay-modal .modal-box.io-term .analyse.disabled {
  background: #909090;
}
.overlay-modal .modal-box.refinanceanalysisdetails form,
.overlay-modal .modal-box.currentloandetails form,
.overlay-modal .modal-box.proposalrebate form,
.overlay-modal .modal-box.lendercashback form,
.overlay-modal .modal-box.loanbasecashback form,
.overlay-modal .modal-box.loanbasetrailrebate form,
.overlay-modal .modal-box.lenderoverviewoptions form,
.overlay-modal .modal-box.objectiveslist form,
.overlay-modal .modal-box.introlettertemplates form,
.overlay-modal .modal-box.io-term form {
  text-align: left;
}
.overlay-modal .modal-box.refinanceanalysisdetails form .w580,
.overlay-modal .modal-box.currentloandetails form .w580,
.overlay-modal .modal-box.proposalrebate form .w580,
.overlay-modal .modal-box.lendercashback form .w580,
.overlay-modal .modal-box.loanbasecashback form .w580,
.overlay-modal .modal-box.loanbasetrailrebate form .w580,
.overlay-modal .modal-box.lenderoverviewoptions form .w580,
.overlay-modal .modal-box.objectiveslist form .w580,
.overlay-modal .modal-box.introlettertemplates form .w580,
.overlay-modal .modal-box.io-term form .w580 {
  width: 100%;
  margin: 5px 0 20px 0;
}
@media screen and (max-height: 1000px) {
  .overlay-modal .modal-box.refinanceanalysisdetails form .w580,
  .overlay-modal .modal-box.currentloandetails form .w580,
  .overlay-modal .modal-box.proposalrebate form .w580,
  .overlay-modal .modal-box.lendercashback form .w580,
  .overlay-modal .modal-box.loanbasecashback form .w580,
  .overlay-modal .modal-box.loanbasetrailrebate form .w580,
  .overlay-modal .modal-box.lenderoverviewoptions form .w580,
  .overlay-modal .modal-box.objectiveslist form .w580,
  .overlay-modal .modal-box.introlettertemplates form .w580,
  .overlay-modal .modal-box.io-term form .w580 {
    margin: 5px 0 10px 0;
  }
}
.overlay-modal .modal-box.refinanceanalysisdetails form .user_meta-refinanceYears .text-field,
.overlay-modal .modal-box.currentloandetails form .user_meta-refinanceYears .text-field,
.overlay-modal .modal-box.proposalrebate form .user_meta-refinanceYears .text-field,
.overlay-modal .modal-box.lendercashback form .user_meta-refinanceYears .text-field,
.overlay-modal .modal-box.loanbasecashback form .user_meta-refinanceYears .text-field,
.overlay-modal .modal-box.loanbasetrailrebate form .user_meta-refinanceYears .text-field,
.overlay-modal .modal-box.lenderoverviewoptions form .user_meta-refinanceYears .text-field,
.overlay-modal .modal-box.objectiveslist form .user_meta-refinanceYears .text-field,
.overlay-modal .modal-box.introlettertemplates form .user_meta-refinanceYears .text-field,
.overlay-modal .modal-box.io-term form .user_meta-refinanceYears .text-field,
.overlay-modal .modal-box.refinanceanalysisdetails form .newLoanPeriod .text-field,
.overlay-modal .modal-box.currentloandetails form .newLoanPeriod .text-field,
.overlay-modal .modal-box.proposalrebate form .newLoanPeriod .text-field,
.overlay-modal .modal-box.lendercashback form .newLoanPeriod .text-field,
.overlay-modal .modal-box.loanbasecashback form .newLoanPeriod .text-field,
.overlay-modal .modal-box.loanbasetrailrebate form .newLoanPeriod .text-field,
.overlay-modal .modal-box.lenderoverviewoptions form .newLoanPeriod .text-field,
.overlay-modal .modal-box.objectiveslist form .newLoanPeriod .text-field,
.overlay-modal .modal-box.introlettertemplates form .newLoanPeriod .text-field,
.overlay-modal .modal-box.io-term form .newLoanPeriod .text-field,
.overlay-modal .modal-box.refinanceanalysisdetails form .ioTerm .text-field,
.overlay-modal .modal-box.currentloandetails form .ioTerm .text-field,
.overlay-modal .modal-box.proposalrebate form .ioTerm .text-field,
.overlay-modal .modal-box.lendercashback form .ioTerm .text-field,
.overlay-modal .modal-box.loanbasecashback form .ioTerm .text-field,
.overlay-modal .modal-box.loanbasetrailrebate form .ioTerm .text-field,
.overlay-modal .modal-box.lenderoverviewoptions form .ioTerm .text-field,
.overlay-modal .modal-box.objectiveslist form .ioTerm .text-field,
.overlay-modal .modal-box.introlettertemplates form .ioTerm .text-field,
.overlay-modal .modal-box.io-term form .ioTerm .text-field {
  width: 50px;
  position: relative;
}
.overlay-modal .modal-box.refinanceanalysisdetails form .user_meta-refinanceYears .text-field .field-error-message,
.overlay-modal .modal-box.currentloandetails form .user_meta-refinanceYears .text-field .field-error-message,
.overlay-modal .modal-box.proposalrebate form .user_meta-refinanceYears .text-field .field-error-message,
.overlay-modal .modal-box.lendercashback form .user_meta-refinanceYears .text-field .field-error-message,
.overlay-modal .modal-box.loanbasecashback form .user_meta-refinanceYears .text-field .field-error-message,
.overlay-modal .modal-box.loanbasetrailrebate form .user_meta-refinanceYears .text-field .field-error-message,
.overlay-modal .modal-box.lenderoverviewoptions form .user_meta-refinanceYears .text-field .field-error-message,
.overlay-modal .modal-box.objectiveslist form .user_meta-refinanceYears .text-field .field-error-message,
.overlay-modal .modal-box.introlettertemplates form .user_meta-refinanceYears .text-field .field-error-message,
.overlay-modal .modal-box.io-term form .user_meta-refinanceYears .text-field .field-error-message,
.overlay-modal .modal-box.refinanceanalysisdetails form .newLoanPeriod .text-field .field-error-message,
.overlay-modal .modal-box.currentloandetails form .newLoanPeriod .text-field .field-error-message,
.overlay-modal .modal-box.proposalrebate form .newLoanPeriod .text-field .field-error-message,
.overlay-modal .modal-box.lendercashback form .newLoanPeriod .text-field .field-error-message,
.overlay-modal .modal-box.loanbasecashback form .newLoanPeriod .text-field .field-error-message,
.overlay-modal .modal-box.loanbasetrailrebate form .newLoanPeriod .text-field .field-error-message,
.overlay-modal .modal-box.lenderoverviewoptions form .newLoanPeriod .text-field .field-error-message,
.overlay-modal .modal-box.objectiveslist form .newLoanPeriod .text-field .field-error-message,
.overlay-modal .modal-box.introlettertemplates form .newLoanPeriod .text-field .field-error-message,
.overlay-modal .modal-box.io-term form .newLoanPeriod .text-field .field-error-message,
.overlay-modal .modal-box.refinanceanalysisdetails form .ioTerm .text-field .field-error-message,
.overlay-modal .modal-box.currentloandetails form .ioTerm .text-field .field-error-message,
.overlay-modal .modal-box.proposalrebate form .ioTerm .text-field .field-error-message,
.overlay-modal .modal-box.lendercashback form .ioTerm .text-field .field-error-message,
.overlay-modal .modal-box.loanbasecashback form .ioTerm .text-field .field-error-message,
.overlay-modal .modal-box.loanbasetrailrebate form .ioTerm .text-field .field-error-message,
.overlay-modal .modal-box.lenderoverviewoptions form .ioTerm .text-field .field-error-message,
.overlay-modal .modal-box.objectiveslist form .ioTerm .text-field .field-error-message,
.overlay-modal .modal-box.introlettertemplates form .ioTerm .text-field .field-error-message,
.overlay-modal .modal-box.io-term form .ioTerm .text-field .field-error-message {
  overflow: visible;
}
.overlay-modal .modal-box.refinanceanalysisdetails form .user_meta-refinanceYears .text-field:after,
.overlay-modal .modal-box.currentloandetails form .user_meta-refinanceYears .text-field:after,
.overlay-modal .modal-box.proposalrebate form .user_meta-refinanceYears .text-field:after,
.overlay-modal .modal-box.lendercashback form .user_meta-refinanceYears .text-field:after,
.overlay-modal .modal-box.loanbasecashback form .user_meta-refinanceYears .text-field:after,
.overlay-modal .modal-box.loanbasetrailrebate form .user_meta-refinanceYears .text-field:after,
.overlay-modal .modal-box.lenderoverviewoptions form .user_meta-refinanceYears .text-field:after,
.overlay-modal .modal-box.objectiveslist form .user_meta-refinanceYears .text-field:after,
.overlay-modal .modal-box.introlettertemplates form .user_meta-refinanceYears .text-field:after,
.overlay-modal .modal-box.io-term form .user_meta-refinanceYears .text-field:after,
.overlay-modal .modal-box.refinanceanalysisdetails form .newLoanPeriod .text-field:after,
.overlay-modal .modal-box.currentloandetails form .newLoanPeriod .text-field:after,
.overlay-modal .modal-box.proposalrebate form .newLoanPeriod .text-field:after,
.overlay-modal .modal-box.lendercashback form .newLoanPeriod .text-field:after,
.overlay-modal .modal-box.loanbasecashback form .newLoanPeriod .text-field:after,
.overlay-modal .modal-box.loanbasetrailrebate form .newLoanPeriod .text-field:after,
.overlay-modal .modal-box.lenderoverviewoptions form .newLoanPeriod .text-field:after,
.overlay-modal .modal-box.objectiveslist form .newLoanPeriod .text-field:after,
.overlay-modal .modal-box.introlettertemplates form .newLoanPeriod .text-field:after,
.overlay-modal .modal-box.io-term form .newLoanPeriod .text-field:after,
.overlay-modal .modal-box.refinanceanalysisdetails form .ioTerm .text-field:after,
.overlay-modal .modal-box.currentloandetails form .ioTerm .text-field:after,
.overlay-modal .modal-box.proposalrebate form .ioTerm .text-field:after,
.overlay-modal .modal-box.lendercashback form .ioTerm .text-field:after,
.overlay-modal .modal-box.loanbasecashback form .ioTerm .text-field:after,
.overlay-modal .modal-box.loanbasetrailrebate form .ioTerm .text-field:after,
.overlay-modal .modal-box.lenderoverviewoptions form .ioTerm .text-field:after,
.overlay-modal .modal-box.objectiveslist form .ioTerm .text-field:after,
.overlay-modal .modal-box.introlettertemplates form .ioTerm .text-field:after,
.overlay-modal .modal-box.io-term form .ioTerm .text-field:after {
  content: "Years";
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  position: absolute;
  z-index: 5;
  left: 64px;
  top: 13px;
}
.overlay-modal .modal-box.refinanceanalysisdetails form .user_meta-refinanceInterestRate .field:after,
.overlay-modal .modal-box.currentloandetails form .user_meta-refinanceInterestRate .field:after,
.overlay-modal .modal-box.proposalrebate form .user_meta-refinanceInterestRate .field:after,
.overlay-modal .modal-box.lendercashback form .user_meta-refinanceInterestRate .field:after,
.overlay-modal .modal-box.loanbasecashback form .user_meta-refinanceInterestRate .field:after,
.overlay-modal .modal-box.loanbasetrailrebate form .user_meta-refinanceInterestRate .field:after,
.overlay-modal .modal-box.lenderoverviewoptions form .user_meta-refinanceInterestRate .field:after,
.overlay-modal .modal-box.objectiveslist form .user_meta-refinanceInterestRate .field:after,
.overlay-modal .modal-box.introlettertemplates form .user_meta-refinanceInterestRate .field:after,
.overlay-modal .modal-box.io-term form .user_meta-refinanceInterestRate .field:after {
  position: absolute;
  content: "If you're not sure, an approximation is fine.";
  height: 43px;
  width: 240px;
  top: 16px;
  left: 159px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #727C8F;
}
.overlay-modal .modal-box.proposalrebate {
  padding: 40px;
  width: 400px;
}
.overlay-modal .modal-box.proposalrebate .closeButton {
  margin-top: -20px;
  margin-right: -20px;
}
.overlay-modal .modal-box.lenderoverviewoptions {
  width: 650px;
}
.overlay-modal .modal-box.lendercashback,
.overlay-modal .modal-box.loanbasecashback {
  transform: scale(1, 1) !important;
}
.overlay-modal .modal-box.requestupdate {
  width: 600px;
}
.overlay-modal .modal-box.requestupdate h1 {
  margin-top: 20px;
}
.overlay-modal .modal-box.requestupdate img {
  width: 400px;
  height: 196px;
  object-fit: contain;
  margin-bottom: -43px;
}
.overlay-modal .modal-box.requestupdate .modal-navigation {
  margin-top: 30px;
  margin-bottom: 30px;
}
.overlay-modal .modal-box.requestupdate .modal-navigation button {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  width: 380px;
}
.overlay-modal .modal-box.checkeligibilityhelptimer {
  width: 600px;
}
.overlay-modal .modal-box.checkeligibilityhelptimer img {
  margin-bottom: 30px;
  margin-top: 10px;
  width: 408px;
  height: 207px;
  object-fit: contain;
}
.overlay-modal .modal-box.checkeligibilityhelptimer .modal-navigation button {
  width: 380px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
.overlay-modal .modal-box.onboarding-1,
.overlay-modal .modal-box.onboarding-2 {
  width: 700px;
  padding-right: 0;
}
@media (max-width: 780px) {
  .overlay-modal .modal-box.onboarding-1,
  .overlay-modal .modal-box.onboarding-2 {
    margin: 20px;
  }
}
@media (max-width: 645px) {
  .overlay-modal .modal-box.onboarding-1,
  .overlay-modal .modal-box.onboarding-2 {
    padding-right: 40px;
    background-color: #F6F7FB;
    margin: 0;
    border-radius: 0;
  }
}
@media (max-width: 480px) {
  .overlay-modal .modal-box.onboarding-1,
  .overlay-modal .modal-box.onboarding-2 {
    padding: 20px 20px 0 20px;
  }
}
.overlay-modal .modal-box.onboarding-1 .closeButton,
.overlay-modal .modal-box.onboarding-2 .closeButton {
  display: none;
}
.overlay-modal .modal-box.onboarding-1 .info,
.overlay-modal .modal-box.onboarding-2 .info {
  margin-right: 0;
  width: Calc(100% + 40px);
}
.overlay-modal .modal-box.onboarding-1 .info .bank,
.overlay-modal .modal-box.onboarding-2 .info .bank {
  max-width: none !important;
  line-height: 24px !important;
}
@media (max-width: 645px) {
  .overlay-modal .modal-box.onboarding-1 .info .bank,
  .overlay-modal .modal-box.onboarding-2 .info .bank {
    line-height: 1.2 !important;
  }
}
.overlay-modal .modal-box.onboarding-2 {
  width: 750px;
  padding-right: 40px;
}
@media (max-width: 645px) {
  .overlay-modal .modal-box.onboarding-2 {
    padding: 40px;
  }
}
@media (max-width: 480px) {
  .overlay-modal .modal-box.onboarding-2 {
    padding: 20px;
  }
}
.overlay-modal .modal-box.onboarding-2 .info {
  margin-right: -20px;
  width: Calc(100% + 80px);
}
.overlay-modal .modal-box.checkeligibilityhowdoesthiswork {
  width: 690px;
}
.overlay-modal .modal-box.checkeligibilityhowdoesthiswork .box-text {
  text-align: left;
  max-width: 530px;
  margin: 0 auto;
  padding-left: 60px;
  background-image: url(img/modal/info.png);
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: 20px 50%;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
}
.overlay-modal .modal-box.checkeligibilityhowdoesthiswork .row {
  padding-bottom: 15px;
}
.overlay-modal .modal-box.checkeligibilityhowdoesthiswork .row:last-of-type {
  padding-bottom: 0;
}
.overlay-modal .modal-box.checkeligibilityhowdoesthiswork .row.step-1 {
  margin-top: 30px;
}
.overlay-modal .modal-box.checkeligibilityhowdoesthiswork .row.step-1 img {
  width: 111px;
  object-fit: contain;
}
.overlay-modal .modal-box.checkeligibilityhowdoesthiswork .row.step-2 img {
  margin-top: 50px;
  width: 123px;
  object-fit: contain;
}
.overlay-modal .modal-box.checkeligibilityhowdoesthiswork .row.step-3 img {
  margin-top: 5px;
  width: 142px;
  object-fit: contain;
}
.overlay-modal .modal-box.checkeligibilityhowdoesthiswork .row.step-4 img {
  margin-top: 10px;
  width: 250px;
  object-fit: contain;
}
.overlay-modal .modal-box.checkeligibilityhowdoesthiswork .row.step-5 img {
  margin-top: 10px;
  width: 230px;
  object-fit: contain;
}
.overlay-modal .modal-box.checkeligibilityhowdoesthiswork p {
  text-align: left;
}
.overlay-modal .modal-box.checkeligibilityhowdoesthiswork .modal-navigation {
  margin-top: 20px;
}
.overlay-modal .modal-box.checkeligibilityhowdoesthiswork .modal-navigation .left {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
.overlay-modal .modal-box.checkeligibilityhowdoesthiswork .modal-navigation .left:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
.overlay-modal .modal-box.checkeligibilityhowdoesthiswork .modal-navigation .left:active,
.overlay-modal .modal-box.checkeligibilityhowdoesthiswork .modal-navigation .left:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
.overlay-modal .modal-box.checkeligibilityhowdoesthiswork .modal-navigation .right {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
.overlay-modal .modal-box.checkeligibilityhowdoesthiswork .modal-navigation .right:hover {
  background-color: #46A3FF;
}
.overlay-modal .modal-box.checkeligibilityhowdoesthiswork .modal-navigation .right:active,
.overlay-modal .modal-box.checkeligibilityhowdoesthiswork .modal-navigation .right:focus {
  background-color: #1C83EA;
}
.overlay-modal .modal-box.checkeligibilityhowdoesthiswork .modal-navigation .right:disabled,
.overlay-modal .modal-box.checkeligibilityhowdoesthiswork .modal-navigation .right.disabled {
  background: #909090;
}
.overlay-modal .modal-box.refinanceanalysis .navigation {
  text-align: left;
  margin-top: 20px;
}
.overlay-modal .modal-box.refinanceanalysis .refinance-details p strong,
.overlay-modal .modal-box.refinanceanalysis .next-steps p strong {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
}
.bottom-modal {
  z-index: 1000;
  background-color: #1d75cc;
  opacity: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 -1px 12px 0 rgba(0, 0, 0, 0.5);
  height: 187px;
  transition: all 1s;
  overflow: hidden;
}
.bottom-modal .content {
  max-width: 1150px;
  margin: 0 auto;
  color: #fff;
  padding: 20px 0;
  min-height: 40px;
}
@media (max-width: 1270px) {
  .bottom-modal .content {
    margin: 0 20px;
    max-width: 1150px;
    padding-right: 60px;
  }
}
.bottom-modal .content .products-list {
  display: inline-block;
  vertical-align: top;
  width: Calc(100% - 373px);
}
.bottom-modal .content .products-list .product {
  width: 237px;
  height: 146px;
  border-radius: 7px;
  display: inline-block;
  margin-right: 30px;
  vertical-align: top;
  width: Calc(33% - 20px);
}
.bottom-modal .content .products-list .product:last-of-type {
  margin-right: 0;
}
.bottom-modal .content .products-list .product.empty {
  background-color: #14589c;
  box-shadow: inset -2px 2px 5px #0f4479;
  padding: 0 10px;
}
.bottom-modal .content .products-list .product.empty h3 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
  background-image: url(img/button/tick-circle-white.png);
  background-position: 50% 0;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  padding-top: 25px;
}
.bottom-modal .content .products-list .product.empty p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
}
.bottom-modal .content .products-list .product.empty .product-content {
  margin-top: 40px;
  text-align: center;
}
.bottom-modal .content .products-list .product.exist {
  background-color: #fff;
  position: relative;
}
.bottom-modal .content .products-list .product.exist .product-content {
  width: 100%;
  height: 100%;
  position: relative;
}
.bottom-modal .content .products-list .product.exist .product-content .product-hover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 7px;
  width: 100%;
  height: 100%;
  background-color: rgba(18, 98, 177, 0.65);
  z-index: 10;
  display: block;
  opacity: 0;
  transition: 0.3s;
}
.bottom-modal .content .products-list .product.exist .product-content .product-hover .product-hover-container {
  top: 50%;
  position: relative;
  transform: translate3d(0, -50%, 0);
  text-align: center;
}
.bottom-modal .content .products-list .product.exist .product-content .product-hover .product-hover-container .product-details,
.bottom-modal .content .products-list .product.exist .product-content .product-hover .product-hover-container .compare {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #fff;
  background-color: #2291FF;
  background-color: transparent;
  box-shadow: none;
  width: 120px;
  padding: 12px 0;
}
.bottom-modal .content .products-list .product.exist .product-content .product-hover .product-hover-container .product-details:hover,
.bottom-modal .content .products-list .product.exist .product-content .product-hover .product-hover-container .compare:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #46A3FF;
}
.bottom-modal .content .products-list .product.exist .product-content .product-hover .product-hover-container .product-details:active,
.bottom-modal .content .products-list .product.exist .product-content .product-hover .product-hover-container .compare:active,
.bottom-modal .content .products-list .product.exist .product-content .product-hover .product-hover-container .product-details:focus,
.bottom-modal .content .products-list .product.exist .product-content .product-hover .product-hover-container .compare:focus {
  background-color: #1C83EA;
}
.bottom-modal .content .products-list .product.exist .product-content .product-hover .product-hover-container .product-details:hover,
.bottom-modal .content .products-list .product.exist .product-content .product-hover .product-hover-container .compare:hover {
  background-color: rgba(18, 98, 177, 0.65);
}
.bottom-modal .content .products-list .product.exist .product-content .product-hover .product-hover-container .product-details {
  margin-bottom: 20px;
}
.bottom-modal .content .products-list .product.exist .product-content .logo {
  width: 75px;
  height: 40px;
  display: block;
  margin: 0 auto;
  padding-top: 8px;
}
.bottom-modal .content .products-list .product.exist .product-content .logo img {
  width: 75px;
  height: 25px;
  object-fit: contain;
}
.bottom-modal .content .products-list .product.exist .product-content .approximate-repayment,
.bottom-modal .content .products-list .product.exist .product-content .variable-comparison {
  color: #101922;
}
.bottom-modal .content .products-list .product.exist .product-content .approximate-repayment .variable-rate,
.bottom-modal .content .products-list .product.exist .product-content .variable-comparison .variable-rate,
.bottom-modal .content .products-list .product.exist .product-content .approximate-repayment .comparison-rate,
.bottom-modal .content .products-list .product.exist .product-content .variable-comparison .comparison-rate,
.bottom-modal .content .products-list .product.exist .product-content .approximate-repayment .repayment,
.bottom-modal .content .products-list .product.exist .product-content .variable-comparison .repayment {
  display: inline-block;
  vertical-align: top;
  width: 49%;
}
.bottom-modal .content .products-list .product.exist .product-content .approximate-repayment .variable-rate .rate,
.bottom-modal .content .products-list .product.exist .product-content .variable-comparison .variable-rate .rate,
.bottom-modal .content .products-list .product.exist .product-content .approximate-repayment .comparison-rate .rate,
.bottom-modal .content .products-list .product.exist .product-content .variable-comparison .comparison-rate .rate,
.bottom-modal .content .products-list .product.exist .product-content .approximate-repayment .repayment .rate,
.bottom-modal .content .products-list .product.exist .product-content .variable-comparison .repayment .rate {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bottom-modal .content .products-list .product.exist .product-content .approximate-repayment .variable-rate .rate span,
.bottom-modal .content .products-list .product.exist .product-content .variable-comparison .variable-rate .rate span,
.bottom-modal .content .products-list .product.exist .product-content .approximate-repayment .comparison-rate .rate span,
.bottom-modal .content .products-list .product.exist .product-content .variable-comparison .comparison-rate .rate span,
.bottom-modal .content .products-list .product.exist .product-content .approximate-repayment .repayment .rate span,
.bottom-modal .content .products-list .product.exist .product-content .variable-comparison .repayment .rate span {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 10px;
  color: #727C8F;
}
.bottom-modal .content .products-list .product.exist .product-content .approximate-repayment .variable-rate .rate-description,
.bottom-modal .content .products-list .product.exist .product-content .variable-comparison .variable-rate .rate-description,
.bottom-modal .content .products-list .product.exist .product-content .approximate-repayment .comparison-rate .rate-description,
.bottom-modal .content .products-list .product.exist .product-content .variable-comparison .comparison-rate .rate-description,
.bottom-modal .content .products-list .product.exist .product-content .approximate-repayment .repayment .rate-description,
.bottom-modal .content .products-list .product.exist .product-content .variable-comparison .repayment .rate-description {
  margin-top: 5px;
  text-align: center;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 11px;
  color: #727C8F;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bottom-modal .content .products-list .product.exist .product-content .approximate-repayment .repayment,
.bottom-modal .content .products-list .product.exist .product-content .variable-comparison .repayment {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}
.bottom-modal .content .products-list .product.exist .product-content .product-name {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #1262B1;
  color: #fff;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 11px;
  text-align: center;
  height: 23px;
  line-height: 23px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  font-style: italic;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 20px;
}
.bottom-modal .content .products-list .product.exist .product-content:hover .product-hover {
  opacity: 1;
  transition: 0.3s;
}
.bottom-modal .content .products-list .product.exist .product-content:hover .logo,
.bottom-modal .content .products-list .product.exist .product-content:hover .approximate-repayment,
.bottom-modal .content .products-list .product.exist .product-content:hover .variable-comparison,
.bottom-modal .content .products-list .product.exist .product-content:hover .product-name span {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
}
.bottom-modal .content .products-list .product.exist .close {
  position: absolute;
  top: -10px;
  right: -10px;
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #26BD27;
  background-image: url(img/button/cross-circle-red-2.png);
  background-position: 50% 50%;
  background-size: 24px 24px;
  background-repeat: no-repeat;
  z-index: 20;
  cursor: pointer;
}
.bottom-modal .content .navigation {
  display: inline-block;
  vertical-align: top;
  width: 120px;
  height: 146px;
  margin: 0 30px;
}
.bottom-modal .content .navigation .navigation-container {
  margin-top: 23px;
}
.bottom-modal .content .navigation .navigation-container .proceed {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  background-color: #26BD27;
  padding: 10px 10px 10px 10px;
  color: #fff;
  padding: 12px 0;
  width: 100%;
  display: block;
  box-shadow: none;
}
.bottom-modal .content .navigation .navigation-container .proceed:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #29CA2A;
}
.bottom-modal .content .navigation .navigation-container .proceed:active,
.bottom-modal .content .navigation .navigation-container .proceed:focus {
  background-color: #23B024;
}
.bottom-modal .content .navigation .navigation-container .cancel {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #fff;
  background-color: #2291FF;
  border: 1px solid #2291FF;
  padding: 12px 0;
  width: 100%;
  display: block;
  margin-top: 20px;
  box-shadow: none;
}
.bottom-modal .content .navigation .navigation-container .cancel:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #46A3FF;
}
.bottom-modal .content .navigation .navigation-container .cancel:active,
.bottom-modal .content .navigation .navigation-container .cancel:focus {
  background-color: #1C83EA;
}
.bottom-modal .content .navigation .navigation-container .cancel:hover {
  border: 1px solid #46A3FF;
}
.bottom-modal .content .navigation .navigation-container .cancel:active {
  border: 1px solid #46A3FF;
}
.bottom-modal .content .tips {
  display: inline-block;
  vertical-align: top;
  width: Calc(100% - 951px);
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  height: 146px;
  line-height: 1.4;
  width: 193px;
}
.bottom-modal .content .tips strong {
  font-weight: bolder;
}
.bottom-modal .content .tips p {
  margin-bottom: 20px;
}
.bottom-modal .content .tips p:first-of-type {
  margin-top: 2px;
}
.bottom-modal .closeButton {
  display: none;
}
.main-container {
  max-width: 1100px;
  margin: 0 auto;
  overflow: hidden;
}
@media (max-width: 950px) {
  .main-container {
    padding: 0 20px;
  }
}
@media (max-width: 480px) {
  .main-container {
    padding: 0 10px;
  }
}
.secondary-sidebar {
  background-color: #f6f6fa;
  top: 0;
  left: 0;
  position: absolute;
  width: 240px;
  height: 100%;
  box-shadow: inset -1px 0 0 0 #D8DCE7;
  padding-top: 40px;
}
@media (max-width: 1210px) {
  .secondary-sidebar {
    width: 200px;
  }
}
@media (max-height: 730px) {
  .secondary-sidebar {
    padding-top: 20px;
  }
}
.secondary-sidebar ul li a.next span {
  color: #fff;
  background-color: #2291FF;
  border: none;
  margin-bottom: 2px;
}
.secondary-sidebar ul li a.active {
  color: #223241;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
}
.secondary-sidebar ul li a.done span {
  background-color: #26BD27;
  content: "";
  color: transparent;
  background-image: url(img/elements/check-white.png);
  background-size: 13px 11px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.secondary-sidebar ul li a.disabled {
  pointer-events: none;
}
.secondary-sidebar ul li a {
  display: inline-block;
  padding: 10px 0 20px 40px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #727C8F;
}
@media (max-width: 1210px) {
  .secondary-sidebar ul li a {
    padding: 10px 0 20px 25px;
  }
}
@media (max-height: 800px) {
  .secondary-sidebar ul li a {
    padding-top: 5px;
    padding-bottom: 15px;
  }
}
.secondary-sidebar ul li a span {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #A3A8B2;
  border: 1px solid #C8CCD7;
  border-radius: 50%;
  padding-top: 3px;
  width: 25px;
  height: 25px;
  display: inline-block;
  margin-right: 15px;
  vertical-align: middle;
  text-align: center;
  line-height: 17px;
}
.secondary-sidebar .bottom-help {
  margin: 40px 40px 0 40px;
  padding-bottom: 40px;
  position: absolute;
  bottom: 0;
  border-top: solid 1px #E9EBF1;
  background-color: #f6f6fa;
}
@media (max-width: 1210px) {
  .secondary-sidebar .bottom-help {
    margin: 40px 20px 0 20px;
  }
}
.secondary-sidebar .bottom-help h3 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #223241;
  margin-bottom: 15px;
  margin-top: 30px;
}
.secondary-sidebar .bottom-help p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #575E6C;
  line-height: 1.5;
}
.secondary-sidebar .bottom-help button {
  margin-top: 25px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  width: 100%;
}
.secondary-sidebar .bottom-help button:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
.secondary-sidebar .bottom-help button:active,
.secondary-sidebar .bottom-help button:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
.test-links {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 10px;
}
span.info {
  margin-left: 5px;
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  font-size: 10px;
  background-color: #727C8F;
  font-weight: 600;
  color: #fff;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  background-color: #A3A8B2;
  margin-left: 10px;
  font-weight: 900;
  z-index: 99;
}
span.info:hover {
  text-decoration: none;
  background-color: #223241;
}
span.info > span {
  display: none;
}
span.info:hover > span {
  position: absolute;
  display: inline;
  color: red;
  width: 250px;
  height: 100px;
  background-color: red;
  left: 50%;
  text-align: center;
  z-index: 99;
}
span.info.rebate {
  background-color: transparent;
  margin: 0;
  width: 0;
  height: 70px;
  font-size: inherit;
  text-align: inherit;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-radius: 0px;
}
span.info.rebate::before,
span.info.rebate::after {
  bottom: 100%;
}
@media (max-width: 1250px) {
  span.info.rebate::before {
    max-width: 220px;
  }
}
@media (max-width: 1000px) {
  span.info.rebate::before {
    max-width: 200px;
  }
}
@media (max-width: 800px) {
  span.info.rebate::before {
    max-width: 170px;
  }
}
span.field-info {
  margin-left: 5px;
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  font-size: 10px;
  background-color: #727C8F;
  font-weight: 600;
  color: #fff;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  background-color: #A3A8B2;
  margin-left: 10px;
  vertical-align: top;
  font-weight: 900;
  z-index: 99;
}
span.field-info:hover {
  text-decoration: none;
  background-color: #223241;
}
span.field-info > span {
  display: none;
}
span.field-info:hover > span {
  position: absolute;
  display: inline;
  color: red;
  width: 250px;
  height: 100px;
  background-color: red;
  left: 50%;
  text-align: center;
  z-index: 99;
}
.form {
  max-width: 580px;
}
@media (max-width: 1210px) {
  .form {
    width: 100%;
    max-width: none;
  }
}
.form.extended {
  max-width: 650px;
}
@media (max-width: 1210px) {
  .form.extended {
    max-width: none;
  }
}
.form h1 {
  margin: 15px 0 40px 0;
}
.form .w580,
.form .w280,
.form .w100p,
.form .w50p {
  margin: 15px 0 25px 0;
}
.form label {
  margin: 0 0 10px 0;
}
.form .form-h3 {
  margin: 30px 0 10px 0;
}
.form .form-h4 {
  margin: 20px 0 20px 0;
}
.form .form-h3 p,
.form .form-h4 p {
  margin: 10px 0 0 0;
}
.form .no-title {
  margin-top: 0;
}
.form hr {
  margin: 20px 0 37px;
}
.form p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #727C8F;
  line-height: 1.5;
}
.form h1 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 22px;
}
.form h2 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 22px;
}
.form h3 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
}
.form h4 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
}
.form hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #E9EBF1;
  padding: 0;
}
.form select::placeholder,
.form input::placeholder,
.form textarea::placeholder {
  color: #A9A9A9;
}
.form select {
  -webkit-appearance: none;
  appearance: none;
  height: 40px;
  border: 1px solid #D8DCE7;
  padding-left: 15px;
  padding-right: 30px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  background-image: url(img/button/down-blue.png);
  background-size: 10px 6px;
  background-position: Calc(100% - 15px) 50%;
  background-repeat: no-repeat;
  width: 100%;
  background-color: #fff;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
  border-radius: 4px;
}
.form select:focus {
  box-shadow: 0 0 10px rgba(34, 145, 255, 0.15);
  border: 1px solid #2291FF;
  border-radius: 4px;
}
.form select:hover {
  border: 1px solid #C8CCD7;
  box-shadow: 0 0 10px rgba(34, 145, 255, 0.15);
  border: 1px solid #2291FF;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}
.form select:disabled {
  color: #D8DCE7;
}
.form input[type=text],
.form input[type=number],
.form input[type=email],
.form input[type=password] {
  -webkit-appearance: none;
  appearance: none;
  height: 40px;
  border: 1px solid #D8DCE7;
  padding: 0 15px;
  background-color: #fff;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  width: 100%;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}
.form input[type=text]:focus,
.form input[type=number]:focus,
.form input[type=email]:focus,
.form input[type=password]:focus {
  box-shadow: 0 0 10px rgba(34, 145, 255, 0.15);
  border: 1px solid #2291FF;
  border-radius: 4px;
}
.form input[type=text]:hover,
.form input[type=number]:hover,
.form input[type=email]:hover,
.form input[type=password]:hover {
  border: 1px solid #C8CCD7;
  box-shadow: 0 0 10px rgba(34, 145, 255, 0.15);
  border: 1px solid #2291FF;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}
.form input[type=password] {
  padding-right: 45px;
}
.form input[type=number]::-webkit-inner-spin-button,
.form input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.form input[type=checkbox]:checked + .slider {
  background-color: #2196F3;
}
.form input[type=checkbox]:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}
.form input[type=checkbox]:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.form label {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  line-height: 1.2;
  color: #101922;
  display: block;
}
.form label.bottom {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #727C8F;
  margin-top: 8px;
}
.form label span {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #727C8F;
}
.form label span.info {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 10px;
  color: #fff;
  font-weight: 900;
}
.form .text-area textarea {
  -webkit-appearance: none;
  appearance: none;
  resize: vertical;
  border: 1px solid #D8DCE7;
  padding: 15px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  width: 100%;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}
.form .text-area textarea:focus {
  box-shadow: 0 0 10px rgba(34, 145, 255, 0.15);
  border: 1px solid #2291FF;
  border-radius: 4px;
}
.form .text-area textarea:hover {
  border: 1px solid #C8CCD7;
  box-shadow: 0 0 10px rgba(34, 145, 255, 0.15);
  border: 1px solid #2291FF;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}
.form .double-field {
  width: 100%;
}
.form .double-field .double-field-value {
  width: 55.35714286%;
  display: inline-block;
  vertical-align: top;
}
.form .double-field .double-field-value input[type=text] {
  border-right: none;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}
.form .double-field .double-field-value input[type=text]:focus {
  box-shadow: 0 0 10px rgba(34, 145, 255, 0.15);
  border: 1px solid #2291FF;
  border-radius: 4px;
}
.form .double-field .double-field-value input[type=text]:hover {
  border: 1px solid #C8CCD7;
  box-shadow: 0 0 10px rgba(34, 145, 255, 0.15);
  border: 1px solid #2291FF;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}
.form .double-field .double-field-value input[type=text]:focus {
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}
.form .double-field .double-field-type {
  width: 44.64285714%;
  display: inline-block;
  vertical-align: top;
}
.form .double-field .double-field-type select {
  width: 100%;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.form .double-field.w580 .double-field-value {
  width: 75.86206897%;
}
@media (max-width: 645px) {
  .form .double-field.w580 .double-field-value {
    width: 60%;
  }
}
.form .double-field.w580 .double-field-type {
  width: 24.13793103%;
}
@media (max-width: 645px) {
  .form .double-field.w580 .double-field-type {
    width: 40%;
  }
}
.form .box {
  height: 121px;
  border: 1px solid #D8DCE7;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  text-align: center;
  position: relative;
  background: white;
  cursor: pointer;
}
.form .box input[type=radio] {
  position: absolute;
  opacity: 0;
}
.form .box span.subtitle {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  display: block;
  margin-top: 5px;
}
.form .box span.title {
  margin-top: 20px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  display: block;
  margin-top: 5px;
  width: 100%;
  overflow: hidden;
}
.form .box.w205 {
  margin-right: 20px;
}
@media (max-width: 1210px) {
  .form .box.w205 {
    width: Calc(50% - 10px);
    margin-bottom: 100px;
  }
  .form .box.w205:nth-last-of-type(1),
  .form .box.w205:nth-last-of-type(2) {
    margin-bottom: 0;
  }
  .form .box.w205:nth-of-type(2n) {
    margin-right: 0;
  }
}
.form .box.w205:last-of-type {
  margin-right: 0;
}
.form .box.enabled {
  border: 1px solid #C8CCD7;
  box-shadow: 0 0 10px rgba(34, 145, 255, 0.15);
  border: 1px solid #2291FF;
  transition: 0.5s ease-in-out;
}
.form .box.enabled span.title {
  color: #2291FF;
}
.form .box.enabled.howMuch,
.form .box.enabled.reducingInterest {
  background-image: url(img/forms/calculator_hover.png);
}
.form .box.enabled.gettingAdvice,
.form .box.enabled.gettingCashOut {
  background-image: url(img/forms/alert-circle-i_hover.png);
}
.form .box.enabled.compareHome,
.form .box.enabled.compareHomeLoans {
  background-image: url(img/forms/laptop-72_hover.png);
}
.form .box.enabled.loanSoon,
.form .box.enabled.consolidateDebt {
  background-image: url(img/forms/handshake_hover.png);
}
.form .box.enabled.signedContract {
  background-image: url(img/forms/signature_hover.png);
}
.form .box.enabled.foundProperty {
  background-image: url(img/forms/pin-heart_hover.png);
}
.form .box.enabled.stillLooking {
  background-image: url(img/forms/home-search_hover.png);
}
.form .box.enabled.lookingNow {
  background-image: url(img/forms/alert-circle-que_hover.png);
}
.form .box.enabled.LiveIn {
  background-image: url(img/forms/home-52_hover.png);
}
.form .box.enabled.Investment {
  background-image: url(img/forms/money-coins_hover.png);
}
.form .box.enabled.justYou {
  background-image: url(img/forms/just-you_hover.png);
}
.form .box.enabled.youAndYourPartner {
  background-image: url(img/forms/you-and-your-partner_hover.png);
}
.form .box.enabled.youAndOneOrMoreOthers {
  background-image: url(img/forms/you-and-one-or-more-others_hover.png);
}
.form .box.enabled.otherSituation {
  background-image: url(img/forms/other-situation_hover.png);
}
.form .box.enabled.purposePurchase {
  background-image: url(img/forms/payment_hover.png);
}
.form .box.enabled.purposeRefinance {
  background-image: url(img/forms/handout_hover.png);
}
.form .box.enabled.purposeConstruction {
  background-image: url(img/forms/construction_hover.png);
}
.form .box.enabled.purposeToLiveIn {
  background-image: url(img/forms/home-52_hover.png);
}
.form .box.enabled.purposeInvestment {
  background-image: url(img/forms/money-coins_hover.png);
}
.form .box.enabled.statusStillLooking {
  background-image: url(img/forms/home-search_hover.png);
}
.form .box.enabled.statusFoundAProperty {
  background-image: url(img/forms/pin-heart_hover.png);
}
.form .box.enabled.statusAlreadyOwnLand {
  background-image: url(img/forms/own_land_hover.png);
}
.form .box:hover {
  box-shadow: 0 0 10px rgba(34, 145, 255, 0.15);
  border: 1px solid #2291FF;
}
.form .box:hover span.title {
  color: #2291FF;
}
.form .box.howMuch,
.form .box.reducingInterest {
  background-image: url(img/forms/calculator.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 41px 47px;
}
.form .box.gettingAdvice,
.form .box.gettingCashOut {
  background-image: url(img/forms/alert-circle-i.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 47px 47px;
}
.form .box.compareHome,
.form .box.compareHomeLoans {
  background-image: url(img/forms/laptop-72.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 47px 35px;
}
.form .box.loanSoon,
.form .box.consolidateDebt {
  background-image: url(img/forms/handshake.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 45px 44px;
}
.form .box.signedContract {
  background-image: url(img/forms/signature.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 47px 46px;
}
.form .box.foundProperty {
  background-image: url(img/forms/pin-heart.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 43px 47px;
}
.form .box.stillLooking {
  background-image: url(img/forms/home-search.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 46px 46px;
}
.form .box.lookingNow {
  background-image: url(img/forms/alert-circle-que.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 47px 47px;
}
.form .box.LiveIn {
  background-image: url(img/forms/home-52.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 47px 47px;
}
.form .box.Investment {
  background-image: url(img/forms/money-coins.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 47px 47px;
}
.form .box.justYou {
  background-image: url(img/forms/just-you.png);
  background-repeat: no-repeat;
  background-position: 50% 23px;
  background-size: 26px 32px;
}
.form .box.youAndYourPartner {
  background-image: url(img/forms/you-and-your-partner.png);
  background-repeat: no-repeat;
  background-position: 50% 28px;
  background-size: 32px 26px;
}
.form .box.youAndOneOrMoreOthers {
  background-image: url(img/forms/you-and-one-or-more-others.png);
  background-repeat: no-repeat;
  background-position: 50% 23px;
  background-size: 60px 32px;
}
.form .box.otherSituation {
  background-image: url(img/forms/other-situation.png);
  background-repeat: no-repeat;
  background-position: 50% 23px;
  background-size: 27px 31px;
}
.form .box.purposePurchase {
  background-image: url(img/forms/payment.png);
  background-repeat: no-repeat;
  background-position: 50% 20px;
  background-size: 32px 32px;
}
.form .box.purposeRefinance {
  background-image: url(img/forms/handout.png);
  background-repeat: no-repeat;
  background-position: 50% 20px;
  background-size: 32px 32px;
}
.form .box.purposeConstruction {
  background-image: url(img/forms/construction.png);
  background-repeat: no-repeat;
  background-position: 50% 22px;
  background-size: 32px 32px;
}
.form .box.purposeToLiveIn {
  background-image: url(img/forms/home-52.png);
  background-repeat: no-repeat;
  background-position: 50% 22px;
  background-size: 30px 29px;
}
.form .box.purposeInvestment {
  background-image: url(img/forms/money-coins.png);
  background-repeat: no-repeat;
  background-position: 50% 22px;
  background-size: 32px 32px;
}
.form .box.statusStillLooking {
  background-image: url(img/forms/home-search.png);
  background-repeat: no-repeat;
  background-position: 50% 20px;
  background-size: 31px 31px;
}
.form .box.statusFoundAProperty {
  background-image: url(img/forms/pin-heart.png);
  background-repeat: no-repeat;
  background-position: 50% 20px;
  background-size: 29px 31px;
}
.form .box.statusAlreadyOwnLand {
  background-image: url(img/forms/own_land.png);
  background-repeat: no-repeat;
  background-position: 50% 20px;
  background-size: 30px 30px;
}
.form .box.box-form {
  width: 280px;
  margin-bottom: 0;
  position: relative;
}
@media (max-width: 1210px) {
  .form .box.box-form {
    width: Calc(50% - 10px);
  }
}
.form .box.box-form .title {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  margin-top: 70px;
  color: #575E6C;
}
.form .box.box-form .subtitle {
  color: #575E6C;
}
.form .box.box-form.enabled .title {
  color: #101922;
}
.form .box.box-form.enabled .subtitle {
  color: #101922;
}
.form .box.box-form.enabled::after {
  position: absolute;
  top: -10px;
  right: -10px;
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #2291FF;
  background-image: url(img/elements/check-white.png);
  background-size: 10px 8px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.form .box.box-form.small {
  height: 105px;
}
.form .fill {
  margin-right: 300px;
}
.form .w280 {
  width: 280px;
  display: inline-block;
  vertical-align: top;
}
@media (max-width: 1210px) {
  .form .w280 {
    width: Calc(50% - 10px);
  }
}
.form .w580 {
  width: 580px;
  display: inline-block;
}
@media (max-width: 1210px) {
  .form .w580 {
    width: 100%;
  }
}
.form .w100p {
  width: 100%;
  display: inline-block;
}
.form .w50p {
  width: Calc(50% - 10px);
  display: inline-block;
}
.form .w205 {
  width: 205px;
  display: inline-block;
  vertical-align: top;
  min-height: 38px;
}
.form .rs {
  margin-left: 20px;
}
.form .monetary .field {
  position: relative;
}
.form .monetary .field:before {
  content: "$";
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #2291FF;
  position: absolute;
  bottom: 0;
  left: 15px;
  height: 39px;
  line-height: 40px;
  pointer-events: none;
}
.form .monetary .field.double-field-type:before {
  display: none;
}
.form .monetary .field input {
  padding: 0 15px 0 30px;
}
.form .years-mask .field {
  position: relative;
}
.form .years-mask .field:before {
  content: "years";
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #2291FF;
  position: absolute;
  bottom: 0;
  right: 15px;
  height: 39px;
  line-height: 40px;
  pointer-events: none;
}
.form .years-mask .field input {
  padding: 0 50px 0 15px;
}
.form .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.form .switch input {
  display: none;
}
.form .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.form .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.form .slider.round {
  border-radius: 34px;
}
.form .slider.round:before {
  border-radius: 50%;
}
.form .radio-toggle-group {
  position: relative;
  margin-bottom: 30px;
}
.form .radio-toggle-group *,
.form .radio-toggle-group *:before,
.form .radio-toggle-group *:after {
  box-sizing: border-box;
}
.form .radio-toggle-group input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.form .radio-toggle-group input[type="checkbox"][disabled] ~ label {
  pointer-events: none;
}
.form .radio-toggle-group input[type="checkbox"][disabled] ~ label .radio-toggle-group-switch {
  opacity: 0.4;
}
.form .radio-toggle-group input[type="checkbox"]:checked ~ label .radio-toggle-group-switch:before {
  content: attr(data-unchecked);
  left: 0;
}
.form .radio-toggle-group input[type="checkbox"]:checked ~ label .radio-toggle-group-switch:after {
  content: attr(data-checked);
}
.form .radio-toggle-group label {
  user-select: none;
  position: relative;
  display: flex;
  align-items: center;
}
.form .radio-toggle-group label .radio-toggle-group-switch {
  position: relative;
}
.form .radio-toggle-group label .radio-toggle-group-switch:before {
  content: attr(data-checked);
  position: absolute;
  top: 0;
  text-align: center;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
}
.form .radio-toggle-group label .radio-toggle-group-switch:after {
  content: attr(data-unchecked);
  position: absolute;
  z-index: 1;
  text-align: center;
  background: white;
  transform: translate3d(0, 0, 0);
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  box-shadow: 0 1px 0 0 #D8DCE7;
}
.form .radio-toggle-group input[type="checkbox"][disabled] ~ label {
  color: rgba(119, 119, 119, 0.5);
}
.form .radio-toggle-group input[type="checkbox"]:focus ~ label .radio-toggle-group-switch,
.form .radio-toggle-group input[type="checkbox"]:hover ~ label .radio-toggle-group-switch {
  background-color: #ECEEF5;
  border: 1px solid #C8CCD7;
  -webkit-box-shadow: 0 0 10px rgba(34, 145, 255, 0.15);
  box-shadow: 0 0 10px rgba(34, 145, 255, 0.15);
  border: 1px solid #2291FF;
  -webkit-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.form .radio-toggle-group input[type="checkbox"]:focus ~ label .radio-toggle-group-switch:after,
.form .radio-toggle-group input[type="checkbox"]:hover ~ label .radio-toggle-group-switch:after {
  color: #101922;
}
.form .radio-toggle-group input[type="checkbox"]:hover ~ label {
  color: #667081;
}
.form .radio-toggle-group input[type="checkbox"]:checked ~ label:hover {
  color: #e2e5f0;
}
.form .radio-toggle-group input[type="checkbox"]:checked ~ label .radio-toggle-group-switch {
  background-color: #F6F7FB;
}
.form .radio-toggle-group input[type="checkbox"]:checked ~ label .radio-toggle-group-switch:after {
  color: #101922;
}
.form .radio-toggle-group input[type="checkbox"]:checked:focus ~ label .radio-toggle-group-switch,
.form .radio-toggle-group input[type="checkbox"]:checked:hover ~ label .radio-toggle-group-switch {
  background-color: #ECEEF5;
}
.form .radio-toggle-group input[type="checkbox"]:checked:focus ~ label .radio-toggle-group-switch:after,
.form .radio-toggle-group input[type="checkbox"]:checked:hover ~ label .radio-toggle-group-switch:after {
  color: #101922;
}
.form .radio-toggle-group label .radio-toggle-group-switch {
  transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
  background: #F6F7FB;
  border: 1px solid #D8DCE7;
}
.form .radio-toggle-group label .radio-toggle-group-switch:before {
  transition: 0.3s cubic-bezier(0, 1, 0.5, 1);
  color: #727C8F;
}
.form .radio-toggle-group label .radio-toggle-group-switch:after {
  transition: 0.3s cubic-bezier(0, 1, 0.5, 1);
  color: #101922;
}
.form .radio-toggle-group input[type="checkbox"]:focus ~ label .radio-toggle-group-switch:after,
.form .radio-toggle-group input[type="checkbox"]:hover ~ label .radio-toggle-group-switch:after {
  box-shadow: 0 1px 0 0 #D8DCE7;
}
.form .radio-toggle-group input[type="checkbox"]:checked ~ label .radio-toggle-group-switch:after {
  left: calc(50% - 4px);
}
.form .radio-toggle-group input[type="checkbox"]:checked:focus ~ label .radio-toggle-group-switch:after,
.form .radio-toggle-group input[type="checkbox"]:checked:hover ~ label .radio-toggle-group-switch:after {
  box-shadow: 0 1px 0 0 #D8DCE7;
}
.form .radio-toggle-group label {
  font-size: 14px;
}
.form .radio-toggle-group label .radio-toggle-group-switch {
  height: 40px;
  flex: 0 0 100%;
  border-radius: 4px;
}
.form .radio-toggle-group label .radio-toggle-group-switch:before {
  left: Calc(100% / 2);
  font-size: 12px;
  line-height: 40px;
  width: Calc((100% / 2));
  padding: 0 12px;
  transition: none;
}
.form .radio-toggle-group label .radio-toggle-group-switch:after {
  top: 4px;
  left: 4px;
  border-radius: 4px;
  width: Calc((100% / 2));
  line-height: 30px;
  font-size: 12px;
}
.form .radio-toggle-group label .radio-toggle-group-switch:hover:after {
  box-shadow: 0 1px 0 0 #D8DCE7;
}
.form .subtitle {
  margin-bottom: 25px;
}
.form .field-note {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #727C8F;
}
.form .field-note.bottom {
  display: block;
  margin-top: 10px;
}
.form .field-note.top {
  display: inline;
  float: right;
  padding-top: 4px;
}
.form .checkbox-option {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
.form .checkbox-option input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  position: relative;
  background-color: #fff;
  color: #666;
  top: 5px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  outline: none;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}
.form .checkbox-option input:focus {
  box-shadow: 0 0 10px rgba(34, 145, 255, 0.15);
  border: 1px solid #2291FF;
  border-radius: 4px;
}
.form .checkbox-option input:hover {
  border: 1px solid #C8CCD7;
  box-shadow: 0 0 10px rgba(34, 145, 255, 0.15);
  border: 1px solid #2291FF;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}
.form .checkbox-option input:checked {
  background-color: #2689EB;
  border: 1px solid #2689EB;
}
.form .checkbox-option input:checked::before {
  color: #fff;
  position: absolute;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  left: 5px;
  top: 0px;
  content: '\02143';
  transform: rotate(40deg);
}
.form .checkbox-option label {
  display: inline;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #223241;
  cursor: pointer;
  padding-left: 10px;
}
.form .checkbox-option:hover {
  color: #000;
}
.form .form-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  background-color: #26BD27;
  padding: 10px 10px 10px 10px;
  color: #fff;
  text-align: center;
  height: 40px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  padding: 13px 0;
}
.form .form-button:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #29CA2A;
}
.form .form-button:active,
.form .form-button:focus {
  background-color: #23B024;
}
.form .submit {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
}
.form .submit:hover {
  background-color: #46A3FF;
}
.form .submit:active,
.form .submit:focus {
  background-color: #1C83EA;
}
.form .submit:disabled,
.form .submit.disabled {
  background: #909090;
}
.progress-bar {
  background-color: #2291FF !important;
  height: 0%;
  display: block;
  height: inherit;
}
.avatar-pic {
  background-color: grey;
  background-position: 50% 50%;
  background-size: auto 100%;
  background-repeat: no-repeat;
  position: relative;
}
.avatar-pic.n1 {
  background-image: url(img/content/avatar_n1.png);
}
.avatar-pic.n2 {
  background-image: url(img/content/avatar_n2.png);
}
.avatar-pic.n3 {
  background-image: url(img/content/avatar_n3.png);
}
.avatar-pic.n4 {
  background-image: url(img/content/avatar_n4.png);
}
.avatar-pic.n5 {
  background-image: url(img/content/avatar_n5.png);
}
.avatar-pic.n6 {
  background-image: url(img/content/avatar_n6.png);
}
.avatar-pic.n7 {
  background-image: url(img/content/avatar_n7.png);
}
.avatar-pic.n8 {
  background-image: url(img/content/avatar_n8.png);
}
.avatar-pic.n9 {
  background-image: url(img/content/avatar_n9.png);
}
.upload-container {
  position: relative;
  height: 255px;
  background: #F6F7FB;
  border: 1px dashed #C8CCD7;
  width: 100%;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  cursor: pointer;
}
.upload-container.max-files {
  background: #fdd7d7;
  border: 1px dashed #d7c8c8;
}
.upload-container:focus {
  outline: 0;
}
.upload-container > p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #727C8F;
  position: absolute;
  left: 30px;
  top: 20px;
}
.upload-container .upload-directions {
  display: block;
  text-align: center;
  background-image: url(img/elements/upload_green.png);
  background-size: 64px 64px;
  background-position: 50% 0;
  background-repeat: no-repeat;
  padding-top: 90px;
  top: 50%;
  position: relative;
  transform: translate3d(0, -50%, 0);
}
.upload-container .upload-directions h2 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 22px;
  margin-top: 0;
  margin-bottom: 0;
}
.upload-container .upload-directions p {
  margin-top: 10px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
}
.upload-container .upload-directions span {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #2291FF;
}
.tooltip-container.search {
  width: 100%;
  display: block;
  margin: 15px 0 -10px 0;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  color: #727C8F;
  position: relative;
  height: 25px;
}
@media (max-width: 840px) {
  .tooltip-container.search {
    display: none;
  }
}
.tooltip-container.search .left,
.tooltip-container.search .right {
  object-fit: contain;
  display: block;
  width: 50%;
  line-height: 25px;
  position: absolute;
  top: 0;
}
.tooltip-container.search .left span,
.tooltip-container.search .right span {
  display: block;
}
.tooltip-container.search .left {
  left: 0;
}
.tooltip-container.search .right {
  right: 45px;
  background-image: url(img/tooltips/star.png);
  background-repeat: no-repeat;
  background-size: 15px 15px;
  background-position: 0 50%;
  padding-left: 20px;
  max-width: 282px;
}
.tooltip-container.search .right span {
  background-image: url(img/tooltips/arrow.png);
  background-repeat: no-repeat;
  background-size: 15px 15px;
  background-position: 100% 100%;
}
#loading {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 20;
}
#loading img {
  display: block;
  height: 40px;
  width: 40px;
  margin: 0 auto;
  top: 50%;
  position: relative;
  transform: translate3d(0, -50%, 0);
}
#loading .loader2 {
  position: relative;
  top: calc(50% - 30px);
}
#loading.show {
  display: block;
}
.loader {
  opacity: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  transition: all 0.5s;
  pointer-events: none;
}
.loader.show {
  opacity: 1;
}
.loader div {
  display: block;
  height: 40px;
  width: 40px;
  left: calc(50% + 130px);
  top: 50%;
  position: relative;
  transform: translate3d(0, -50%, 0);
  z-index: 30;
  background-image: url(img/loading/loading.gif);
  background-size: 40px 40px;
  background-position: 0 0;
  background-repeat: no-repeat;
}
.field-error-message {
  position: absolute;
  color: #ff2222;
  font-size: 11px;
  padding-top: 4px;
  padding-bottom: 4px;
  white-space: nowrap;
  left: 0;
  right: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.field-error-message.is-bottom-label {
  top: 20px;
}
input.error,
select.error {
  border: 1px solid rgba(255, 34, 34, 0.6) !important;
  box-shadow: 0 0 10px rgba(255, 34, 34, 0.1) !important;
}
.form-stage {
  opacity: 0;
  transition: opacity 500ms;
}
.form-stage.active {
  opacity: 1;
}
.fade-in {
  display: inline-block;
  animation: fade-in 0.5s ease-in-out;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.field-array {
  position: relative;
}
.field-array .delete {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  border: 1px solid #D8DCE7;
  background-color: #fff;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  height: 36px;
  width: 36px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 15px 15px;
  box-shadow: 0 1px 0 0 #E9EBF1;
  width: 40px;
  height: 40px;
  margin-left: 15px;
  margin-top: 26px;
  background-image: url(img/button/trash-red.png);
}
.field-array .delete:hover {
  border: 1px solid #C8CCD7;
}
.field-array .delete.top-right {
  margin: 0 !important;
  position: absolute;
  right: 0;
  top: 24px;
}
.address-input .google-pin {
  position: absolute;
  top: -19px;
  right: 2px;
}
.address-input .verified {
  position: absolute;
  top: -18px;
  right: 17px;
  color: #2291FF;
  font-size: 12px;
}
.address-input .text-main {
  font-size: 15px;
  font-weight: 600;
  margin-right: 5px;
}
.address-input .text-secondary {
  font-size: 13px;
}
.address-input .options-container {
  position: absolute;
  background-color: white;
  border: 1px solid #D8DCE7;
  border-top: none;
  border-radius: 0px 0px 4px 4px;
  box-shadow: 0px 3px 7px 0px #0000001f;
  z-index: 100;
}
.address-input .options-container .option {
  padding: 10px 10px 10px 15px;
  cursor: pointer;
  user-select: none;
}
.address-input .options-container .option:last-child {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.address-input .options-container .option:hover {
  background-color: #f3f3f3;
}
input.disabled {
  background-color: #f6f6fa !important;
  cursor: not-allowed;
  border-color: #d8dce7 !important;
  pointer-events: none;
}
.invisible {
  opacity: 0;
  pointer-events: none;
}
.row:after {
  overflow: hidden;
  content: "";
  display: table;
  clear: both;
}
.half-column {
  float: left;
  width: 50%;
}
.half-column.right {
  padding-left: 10px;
}
.multi-select {
  user-select: none;
}
.multi-select.open .select-box {
  border-radius: 4px 4px 0px 0px;
}
.multi-select .select-box {
  color: #101922;
  height: 40px;
  margin-top: 10px;
  width: inherit;
  border: 1px solid #D8DCE7;
  padding-left: 15px;
  background-color: white;
  background-image: url(img/button/down-blue.png);
  background-size: 10px 6px;
  background-position: Calc(100% - 15px) 50%;
  background-repeat: no-repeat;
  line-height: 40px;
  cursor: pointer;
  outline: none;
  border-radius: 4px;
}
.multi-select .select-box:focus {
  box-shadow: 0 0 10px rgba(34, 145, 255, 0.15);
  border: 1px solid #2291FF;
  border-radius: 4px;
}
.multi-select .select-box:hover {
  border: 1px solid #C8CCD7;
  box-shadow: 0 0 10px rgba(34, 145, 255, 0.15);
  border: 1px solid #2291FF;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}
.multi-select .options-container {
  position: absolute;
  background-color: white;
  border: 1px solid #D8DCE7;
  border-radius: 0px 0px 4px 4px;
  box-shadow: 0px 3px 7px 0px #0000001f;
  z-index: 100;
}
.multi-select .options-container .scroll-container {
  max-height: 200px;
  overflow-y: scroll;
}
.multi-select .options-search {
  border-bottom: #ededee 1px solid;
}
.multi-select .options-search input {
  width: 100%;
  height: 40px;
  border: none !important;
  padding-left: 32px !important;
  background-image: url(img/icons/icon-search-blue.png);
  background-repeat: no-repeat;
  background-position: 10px 50%;
  background-size: 15px auto;
}
.multi-select .select-option {
  padding: 10px 10px 10px 15px;
  cursor: pointer;
  outline: none;
}
.multi-select .select-option input[type='checkbox'] {
  margin-right: 10px;
}
.multi-select .select-option:last-child {
  border-radius: 0px 0px 4px 4px;
}
.multi-select .select-option:hover {
  background-color: #f3f3f3;
}
.field {
  position: relative;
}
.auto-calc {
  color: #26bd27;
  position: absolute;
  right: 15px;
  bottom: 13px;
  font-size: 14px;
}
.loading-inline-empty {
  position: relative;
  top: 12px;
  display: inline-block;
  height: 32px;
  width: 82px;
  transform: translate3d(0, -50%, 0);
  vertical-align: bottom;
}
.loading-inline {
  position: relative;
  top: 12px;
  display: inline-block;
  height: 32px;
  width: 32px;
  transform: translate3d(0, -50%, 0);
  background-image: url(img/loading/loading.gif);
  background-size: 32px 32px;
  background-position: 0 0;
  background-repeat: no-repeat;
  vertical-align: bottom;
}
.interest-rate-field {
  line-height: 40px !important;
  width: 100%;
  height: 42px;
}
.interest-rate-field .percentage-sign {
  position: relative;
  top: 1px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  color: #2291FF;
  margin-left: 7px;
  margin-bottom: 15px;
  vertical-align: top;
}
.interest-rate-field .digits {
  display: inline-block;
  border: 1px solid #D8DCE7;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  width: 88px;
}
.interest-rate-field .digits .decimal-point {
  font-size: 20px;
  display: block;
  position: absolute;
  top: -1px;
  left: 24px;
}
.interest-rate-field .digits:focus-within,
.interest-rate-field .digits:hover {
  box-shadow: 0 0 10px rgba(34, 145, 255, 0.15);
  border: 1px solid #2291FF;
}
.interest-rate-field .digits input {
  display: inline-block;
  width: 100%;
  letter-spacing: 14px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 17px;
  border-radius: 0 !important;
  border: none;
  padding: 0 0 0 10px;
}
.interest-rate-field .digits input:focus,
.interest-rate-field .digits input:hover {
  border: none;
  box-shadow: none;
}
.interest-rate-field .digits.n2 {
  width: 79px;
}
.interest-rate-field .digits.n2 .decimal-point {
  left: 12px;
}
.interest-rate-field .digits.n2 input {
  padding-left: 28px;
}
.interest-rate-field.empty input {
  color: #727C8F;
}
.interest-rate-field.empty .decimal-point {
  color: #727C8F;
}
.copyright-footer {
  background-color: #fff;
  padding: 15px;
  border: 1px solid #D8DCE7;
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 11px;
  color: #727C8F;
  position: fixed;
  bottom: 0;
  left: 0;
}
.password-field {
  position: relative;
}
.password-field button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  display: block;
  position: absolute;
  right: 9px;
  top: 9px;
  width: 33px;
  height: 24px;
  background-color: rgba(0, 0, 0, 0);
  background-image: url(./img/button/password_show.png);
  background-repeat: no-repeat;
  background-size: 30px 30px;
  background-position: 50% 50%;
  transition: opacity 0.5s;
}
.password-field button.hide {
  opacity: 0;
  pointer-events: none;
  cursor: default;
}
.password-field button.show {
  background-image: url(./img/button/password_hide.png);
}
.multi-select {
  user-select: none;
}
.multi-select.open .select-box {
  border-radius: 4px 4px 0px 0px;
}
.multi-select .select-box {
  color: #101922;
  height: 40px;
  margin-top: 10px;
  width: inherit;
  border: 1px solid #D8DCE7;
  padding-left: 15px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  background-color: white;
  background-image: url(img/button/down-blue.png);
  background-size: 10px 6px;
  background-position: Calc(100% - 15px) 50%;
  background-repeat: no-repeat;
  line-height: 40px;
  cursor: pointer;
  outline: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 30px;
  border-radius: 4px;
}
.multi-select .select-box:focus {
  box-shadow: 0 0 10px rgba(34, 145, 255, 0.15);
  border: 1px solid #2291FF;
  border-radius: 4px;
}
.multi-select .select-box:hover {
  border: 1px solid #C8CCD7;
  box-shadow: 0 0 10px rgba(34, 145, 255, 0.15);
  border: 1px solid #2291FF;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}
.multi-select .options-container {
  position: absolute;
  background-color: white;
  border: 1px solid #D8DCE7;
  border-radius: 0px 0px 4px 4px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  box-shadow: 0px 3px 7px 0px #0000001f;
  z-index: 100;
  overflow-y: auto;
  max-height: 300px;
  width: 100%;
}
.multi-select .options-search {
  border-bottom: #ededee 1px solid;
}
.multi-select .options-search input {
  width: 100%;
  height: 40px;
  border: none;
  padding-left: 32px;
  background-image: url(img/icons/icon-search-blue.png);
  background-repeat: no-repeat;
  background-position: 10px 50%;
  background-size: 15px auto;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}
.multi-select .select-option {
  padding: 10px 10px 10px 15px;
  cursor: pointer;
  outline: none;
}
.multi-select .select-option input[type='checkbox'] {
  margin-right: 10px;
}
.multi-select .select-option:last-child {
  border-radius: 0px 0px 4px 4px;
}
.multi-select .select-option:hover {
  background-color: #f3f3f3;
}
.multi-select .select-option .check-mark {
  display: inline-block !important;
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  border-radius: 4px;
  background: white;
  background-size: 0;
}
.multi-select .select-option .check-mark:after {
  content: "";
  position: absolute;
  display: none;
}
.multi-select .select-option .check-mark.checked {
  background: #2689EB;
  border: white;
  border-radius: 4px;
}
.multi-select .select-option .check-mark.checked:after {
  display: block;
  animation: expand 300ms ease-out forwards;
}
@keyframes expand {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.multi-select .select-option .check-box-container {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  margin-bottom: 0 !important;
  font-family: 'lato';
  line-height: 20px;
  font-size: 14px;
  color: #223241;
  font-weight: 400;
  user-select: none;
}
.multi-select .select-option .check-box-container .check-mark:after {
  left: 5px;
  top: 6px;
  height: 20px;
  width: 20px;
  background-image: url(./img/button/check-small-white.png);
  background-repeat: no-repeat;
  background-size: 10px;
}
.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}
.modal-container .modal-scroll {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.modal-container .modal-scroll .modal-vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 99% !important;
}
.select .field-error-message {
  margin-top: -8px;
}
.loader2 {
  position: relative;
  width: 80px;
  margin: 0 auto;
}
.loader2 .duo,
.loader2 .dot {
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
.loader2 .duo {
  height: 20px;
  width: 50px;
  background: hsla(0, 0%, 0%, 0);
  position: absolute;
}
.loader2 .duo.duo1 {
  left: 0;
  animation-name: spin;
}
.loader2 .duo.duo1 .dot-a {
  opacity: 0;
  animation-name: onOff;
  animation-direction: reverse;
}
.loader2 .duo.duo2 {
  left: 30px;
  animation-name: spin;
  animation-direction: reverse;
}
.loader2 .duo.duo2 .dot-b {
  animation-name: onOff;
}
.loader2 .dot {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: #2291ff;
  position: absolute;
}
.loader2 .dot.dot-a {
  left: 0px;
}
.loader2 .dot.dot-b {
  right: 0px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
@keyframes onOff {
  0% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
.sidebar-page-loader {
  opacity: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 20;
  transition: all 0.5s;
  pointer-events: none;
  left: 260px;
}
@media (max-width: 1440px) {
  .sidebar-page-loader {
    left: 80px;
  }
}
@media (max-width: 950px) {
  .sidebar-page-loader {
    left: 0;
  }
}
.sidebar-page-loader.show {
  opacity: 1;
}
.sidebar-page-loader .loader2 {
  position: relative;
  top: calc(50% - 30px);
}
@keyframes button-spinner {
  to {
    transform: rotate(360deg);
  }
}
.button-spinner {
  display: inline-block;
  margin-right: 0;
  margin-left: 0;
  width: 0;
  height: 0;
  vertical-align: text-bottom;
  border-radius: 20px;
  animation: button-spinner 0.75s linear infinite;
  transition: all 0.5s;
  border: none;
  opacity: 0;
}
.button-spinner.show {
  height: 18px;
  width: 18px;
  margin-right: 20px;
  border: 3px solid white;
  border-right-color: transparent;
  opacity: 1;
}
.button-spinner.show.small {
  margin-right: 15px;
  height: 14px;
  width: 14px;
  position: relative;
  top: -1px;
}
.button-spinner.show.right {
  margin-right: 0;
  margin-left: 20px;
}
.button-spinner.show.right.small {
  margin-left: 15px;
}
.button-spinner.show.no-transition {
  transition: none;
}
.button-spinner.show.blue {
  border: 3px solid #2291FF;
  border-right-color: transparent;
}
.image-spinner {
  display: block;
  width: 0;
  height: 0;
  margin: auto;
  border-radius: 50%;
  animation: button-spinner 0.75s linear infinite;
  transition: all 0.5s;
  height: 2em;
  width: 2em;
  border: 0.2em solid #c8c8c8;
  border-right-color: transparent;
  opacity: 1;
}
.check-eligibility-income div.form-h4 {
  margin-top: 0 !important;
}
.borrowing-value {
  display: inline-block;
  text-align: left;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 21px;
  background-repeat: no-repeat;
  background-position: 0% 50%;
  background-size: 30px 15px;
  padding-left: 45px;
}
.borrowing-value.high {
  background-image: url(img/elements/borrowing-power-3.png);
}
.borrowing-value.medium {
  background-image: url(img/elements/borrowing-power-2.png);
}
.borrowing-value.low {
  background-image: url(img/elements/borrowing-power-1.png);
}
.borrowing-value.empty-value {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  padding-left: 0;
  line-height: 21px;
  color: #2291FF;
  cursor: pointer;
}
.borrowing-value.empty-value:hover {
  color: #46A3FF;
}
.borrowing-value.empty-value:active,
.borrowing-value.empty-value:focus {
  color: #1C83EA;
}
.applicant-not-selected {
  position: relative;
}
.applicant-not-selected:after {
  content: "!";
  position: absolute;
  top: 7px;
  left: 11px;
  color: white;
  display: block;
  text-align: center;
  height: 18px;
  width: 18px;
  border-radius: 40px;
  background-color: #ff3737;
}
.applicant-not-selected select {
  color: red;
  padding-left: 34px;
}
.applicant-not-selected select option {
  color: black;
  padding-left: 15px;
}
.page-not-found .center {
  text-align: center;
  margin-bottom: 100px;
  margin-top: 70px;
}
.page-not-found .center .illustration {
  width: 450px;
  height: 210px;
  background-image: url(img/page_illustrations/search_empty.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  margin: 0 auto;
}
.page-not-found .center h4 {
  margin-top: 30px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 24px;
}
.page-not-found .center p {
  margin-top: 15px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
.under-construction img.construction {
  width: 400px;
  display: block;
  margin: 50px auto 0px auto;
}
.under-construction p.title {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  text-align: center;
  display: block;
  margin-top: 40px;
}
.under-construction p.text {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  text-align: center;
  display: block;
  margin-top: 12px;
}
.under-construction span.get-in-touch {
  color: #2291ff;
  cursor: pointer;
}
div.disclaimer {
  margin-top: 20px;
  margin-bottom: 40px;
}
div.disclaimer p {
  display: block;
  width: 100%;
  text-align: center;
  color: #727c8f;
  line-height: 14px;
  margin-bottom: 5px;
}
div.disclaimer p.text-large {
  font-size: 14px;
}
div.disclaimer p.text-small {
  font-size: 11px;
}
div.disclaimer a {
  text-decoration: underline;
  color: #727c8f;
  margin: 0 4px 0 4px;
}
.references {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}
.references ol {
  border-top: solid 1px #E9EBF1;
  margin-top: 30px;
  padding-top: 10px;
}
.references ol li {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #727C8F;
  margin-top: 8px;
  line-height: 1.2;
}
.backend-dead {
  height: 100vh;
  height: Calc(var(--vh, 1vh) * 100);
  text-align: center;
}
.backend-dead .center {
  display: block;
  position: relative;
  margin: 0 auto;
  width: 300px;
  object-fit: contain;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.backend-dead img {
  width: 200px;
  object-fit: contain;
  margin-bottom: 20px;
}
.backend-dead h2 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  margin-bottom: 10px;
}
.backend-dead p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}
.initial-question-set {
  top: 0;
  background-color: #fff;
  height: initial;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
  left: 0;
  margin-left: 330px;
  padding: 0 70px;
  min-width: auto;
}
.initial-question-set:before {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  content: url("img/forms/calculator_hover.png") url("img/forms/alert-circle-i_hover.png") url("img/forms/laptop-72_hover.png") url("img/forms/handshake_hover.png") url("img/forms/signature_hover.png") url("img/forms/pin-heart_hover.png") url("img/forms/home-search_hover.png") url("img/forms/alert-circle-que_hover.png") url("img/forms/home-52_hover.png") url("img/forms/money-coins_hover.png");
}
.initial-question-set #fullpage form {
  height: 100vh;
  height: Calc(var(--vh, 1vh) * 100);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.initial-question-set #fullpage form .form-container {
  height: 100vh;
  height: Calc(var(--vh, 1vh) * 100);
  width: 100%;
  display: table;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: fixed;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transform: translate(0, 0);
  width: Calc(100vw - 40px);
}
.initial-question-set #fullpage form .form-container .centre-align {
  display: table-cell;
  vertical-align: middle;
}
.initial-question-set #fullpage form .form-container .centre-align .centred-content {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  min-width: 270px;
  max-width: 890px;
}
.initial-question-set #fullpage form .form-container .centre-align .centred-content > * {
  text-align: initial;
}
@media (min-width: 645px) {
  .initial-question-set #fullpage form .form-container {
    width: Calc(100vw - 100px);
  }
}
@media (min-width: 770px) {
  .initial-question-set #fullpage form .form-container {
    width: Calc(100vw - 470px);
  }
}
@media (min-width: 1371px) {
  .initial-question-set #fullpage form .form-container {
    width: Calc(60vw + 80px);
  }
}
.initial-question-set #fullpage form.before .form-container {
  transform: translate(0, -500px);
  opacity: 0;
}
.initial-question-set #fullpage form.after {
  pointer-events: none;
}
.initial-question-set #fullpage form.after .form-container {
  transform: translate(0, 500px);
  opacity: 0;
}
@media (max-width: 768px) {
  .initial-question-set {
    margin: 0;
    padding: 0 50px;
    width: 100vw;
  }
}
@media (max-width: 645px) {
  .initial-question-set {
    padding: 0 20px;
  }
}
.initial-question-set .log-in-button {
  position: fixed;
  right: 70px;
  top: 23px;
  z-index: 10;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 25px 13px 25px;
  color: #2291FF;
  background-color: Transparent;
  border: solid 1px #2291FF;
}
.initial-question-set .log-in-button:hover {
  color: #46A3FF;
  border: solid 1px #46A3FF;
}
.initial-question-set .log-in-button:active,
.initial-question-set .log-in-button:focus {
  color: #1C83EA;
  border: solid 1px #1C83EA;
}
@media (max-width: 768px) {
  .initial-question-set .log-in-button {
    right: 50px;
  }
}
@media (max-width: 645px) {
  .initial-question-set .log-in-button {
    right: 20px;
    width: 50px;
    background-image: url(img/button/login_blue.png);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 25px 25px;
    color: transparent;
    height: 50px;
    overflow: hidden;
    display: none;
  }
}
.initial-question-set .logo {
  position: fixed;
  left: 0;
  top: 0;
  padding: 35px 0px 30px 70px;
  z-index: 10;
  background: linear-gradient(180deg, #ffffff 45%, rgba(255, 255, 255, 0));
}
@media (max-width: 768px) {
  .initial-question-set .logo {
    padding-left: 50px;
    width: 100%;
  }
}
@media (max-width: 645px) {
  .initial-question-set .logo {
    padding-left: 20px;
  }
}
.initial-question-set .logo img {
  width: 150px;
}
.initial-question-set .img-success {
  margin-top: 40px;
  width: 580px;
}
@media (max-width: 1210px) {
  .initial-question-set .img-success {
    width: 100%;
    max-width: 580px;
    margin: 0 auto;
    display: block;
  }
}
@media (max-width: 645px) {
  .initial-question-set .img-success {
    width: 100%;
  }
}
.initial-question-set .p-success {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  width: 580px;
  margin-top: 20px;
  text-align: center !important;
}
@media (max-width: 1210px) {
  .initial-question-set .p-success {
    width: 100%;
    max-width: 580px;
    margin: 20px auto 0 auto;
    display: block;
  }
}
@media (max-width: 645px) {
  .initial-question-set .p-success {
    width: 100%;
  }
}
.initial-question-set .fade-top,
.initial-question-set .fade-bottom {
  left: 260px;
  position: fixed;
  width: 100%;
  height: 25vh;
  z-index: 5;
}
.initial-question-set .spacing {
  background-color: transparent;
  display: inline-block;
  vertical-align: top;
  width: Calc(40vw - 550px);
}
.initial-question-set .scroll-area {
  display: inline-block;
  vertical-align: top;
  width: auto;
}
@media (max-width: 1370px) {
  .initial-question-set .scroll-area {
    width: 100%;
  }
}
.initial-question-set .main-container {
  text-align: left;
  background-color: #FFFFFF;
  position: relative;
  max-width: none;
  margin: 0;
  width: 100%;
  height: 100vh;
  height: Calc(var(--vh, 1vh) * 100);
}
@media (min-width: 1371px) {
  .initial-question-set .main-container {
    margin-left: Calc(40vw - 550px);
  }
}
.initial-question-set .overlay-1 {
  top: 0;
  left: 0;
  position: absolute;
  height: 40vh;
  width: 100vw;
  background-color: #F6F7FB;
}
.initial-question-set .navigation-options {
  transition: 0.5s opacity;
  max-width: 580px;
  position: relative;
}
@media (max-width: 1210px) {
  .initial-question-set .navigation-options {
    max-width: 100%;
  }
}
.initial-question-set .navigation-options.invisible {
  opacity: 0;
  pointer-events: none;
}
.initial-question-set .navigation-options button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  width: 205px;
  padding-right: 0;
  padding-left: 0;
  display: inline-block;
}
.initial-question-set .navigation-options button:hover {
  background-color: #46A3FF;
}
.initial-question-set .navigation-options button:active,
.initial-question-set .navigation-options button:focus {
  background-color: #1C83EA;
}
.initial-question-set .navigation-options button:disabled,
.initial-question-set .navigation-options button.disabled {
  background: #909090;
}
@media (max-width: 1000px) {
  .initial-question-set .navigation-options button {
    float: none;
    width: Calc(50% - 10px);
  }
}
@media (max-width: 645px) {
  .initial-question-set .navigation-options button {
    font-weight: 600;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    width: 100%;
  }
}
.initial-question-set .navigation-options button.green {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  background-color: #26BD27;
  padding: 10px 10px 10px 10px;
  color: #fff;
  font-size: 16px;
}
.initial-question-set .navigation-options button.green:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #29CA2A;
}
.initial-question-set .navigation-options button.green:active,
.initial-question-set .navigation-options button.green:focus {
  background-color: #23B024;
}
.initial-question-set .navigation-options .hidden-container {
  float: right;
  transition: 0.5s opacity;
  opacity: 1;
  position: relative;
}
@media (max-width: 1000px) {
  .initial-question-set .navigation-options .hidden-container {
    float: none;
    width: Calc(50% - 10px);
  }
}
@media (max-width: 645px) {
  .initial-question-set .navigation-options .hidden-container {
    float: none;
  }
}
.initial-question-set .navigation-options .hidden-container.invisible {
  opacity: 0;
  pointer-events: none;
}
.initial-question-set .navigation-options button.skip {
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
  padding: 11px 25px 11px 25px;
  font-size: 16px;
  width: 205px;
  color: #727C8F;
}
.initial-question-set .navigation-options button.skip:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
.initial-question-set .navigation-options button.skip:active,
.initial-question-set .navigation-options button.skip:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
@media (max-width: 1000px) {
  .initial-question-set .navigation-options button.skip {
    width: Calc(50% - 10px);
    margin-right: 20px;
  }
}
@media (max-width: 645px) {
  .initial-question-set .navigation-options button.skip {
    width: 100%;
    margin-bottom: 20px;
  }
}
.initial-question-set .navigation-options .press-enter {
  transition: opacity 500ms;
  color: #727C8F;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  margin-left: 20px;
  position: absolute;
  width: 83px;
  top: 16px;
}
.initial-question-set .navigation-options .press-enter strong {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
@media (max-width: 1210px) {
  .initial-question-set .navigation-options .press-enter {
    display: none;
  }
}
.initial-question-set .navigation-options .nav {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  display: inline-block;
  vertical-align: top;
}
.initial-question-set .navigation-options .nav:hover {
  background-color: #46A3FF;
}
.initial-question-set .navigation-options .nav:active,
.initial-question-set .navigation-options .nav:focus {
  background-color: #1C83EA;
}
.initial-question-set .navigation-options .nav:disabled,
.initial-question-set .navigation-options .nav.disabled {
  background: #909090;
}
.initial-question-set .navigation-options .right {
  float: right;
}
.initial-question-set .navigation-options.radiobox {
  margin-top: 150px;
}
.initial-question-set .navigation-options.right {
  display: block;
  text-align: right;
}
.initial-question-set .navigation-options.submit-form-button {
  margin-top: 60px;
}
.initial-question-set h1 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  color: #223241;
  margin-bottom: 28px;
  margin-top: 0;
}
@media (max-width: 645px) {
  .initial-question-set h1 {
    font-weight: 600;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
  }
}
.initial-question-set h2 {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  color: #727C8F;
  margin-top: -20px;
  margin-bottom: 17px;
  line-height: 1.5;
}
@media (max-width: 645px) {
  .initial-question-set h2 {
    font-weight: 600;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
  }
}
.initial-question-set .four-box .box,
.initial-question-set .two-box .box {
  height: 110px;
  background-size: 35px auto;
  width: Calc(25% - 15px);
}
@media (max-width: 1210px) {
  .initial-question-set .four-box .box:nth-of-type(2n),
  .initial-question-set .two-box .box:nth-of-type(2n) {
    margin-right: 20px;
  }
  .initial-question-set .four-box .box:last-of-type,
  .initial-question-set .two-box .box:last-of-type {
    margin-right: 0;
  }
}
@media (max-width: 1000px) {
  .initial-question-set .four-box .box,
  .initial-question-set .two-box .box {
    margin-bottom: 80px;
    height: 80px;
    width: Calc(50% - 10px);
    margin-right: 0;
  }
  .initial-question-set .four-box .box:nth-of-type(2n),
  .initial-question-set .two-box .box:nth-of-type(2n) {
    margin-right: 0;
  }
  .initial-question-set .four-box .box:nth-of-type(1),
  .initial-question-set .two-box .box:nth-of-type(1),
  .initial-question-set .four-box .box:nth-of-type(3),
  .initial-question-set .two-box .box:nth-of-type(3) {
    margin-right: 20px;
  }
}
@media (max-width: 768px) {
  .initial-question-set .four-box .box,
  .initial-question-set .two-box .box {
    width: Calc(25% - 15px);
    margin-right: 20px;
  }
  .initial-question-set .four-box .box:nth-of-type(2n),
  .initial-question-set .two-box .box:nth-of-type(2n) {
    margin-right: 20px;
  }
  .initial-question-set .four-box .box:last-of-type,
  .initial-question-set .two-box .box:last-of-type {
    margin-right: 0;
  }
}
@media (max-width: 645px) {
  .initial-question-set .four-box .box,
  .initial-question-set .two-box .box {
    margin-bottom: 60px;
    height: 80px;
    width: Calc(50% - 10px);
    margin-right: 0;
  }
  .initial-question-set .four-box .box:nth-of-type(2n),
  .initial-question-set .two-box .box:nth-of-type(2n) {
    margin-right: 0;
  }
  .initial-question-set .four-box .box:nth-of-type(1),
  .initial-question-set .two-box .box:nth-of-type(1),
  .initial-question-set .four-box .box:nth-of-type(3),
  .initial-question-set .two-box .box:nth-of-type(3) {
    margin-right: 20px;
  }
}
.initial-question-set .four-box .box span.title,
.initial-question-set .two-box .box span.title {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  text-align: center;
  width: 80%;
  margin: 0 auto;
  display: block;
  margin-top: 120px;
  line-height: 1.7;
}
@media (max-width: 1000px) {
  .initial-question-set .four-box .box span.title,
  .initial-question-set .two-box .box span.title {
    margin-top: 90px;
  }
}
@media (max-width: 768px) {
  .initial-question-set .four-box .box span.title,
  .initial-question-set .two-box .box span.title {
    margin-top: 90px;
  }
}
@media (max-width: 645px) {
  .initial-question-set .four-box .box span.title,
  .initial-question-set .two-box .box span.title {
    font-weight: 400;
    font-family: 'Lato', sans-serif;
    font-size: 12px;
    line-height: 1.4;
    margin-top: 90px;
  }
}
@media (max-width: 645px) {
  .initial-question-set .two-box .box {
    width: Calc(50% - 10px);
  }
}
.initial-question-set .form {
  max-width: 900px;
}
.initial-question-set .form select,
.initial-question-set .form input[type=text],
.initial-question-set .form input[type=email] {
  height: 50px;
}
.initial-question-set .form .interest-rate-field {
  height: 52px;
  line-height: 52px !important;
}
.initial-question-set .form .finish {
  margin-top: 50px;
}
.initial-question-set .form .finish h3 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}
.initial-question-set .form .finish p {
  margin-top: 10px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}
.initial-question-set .form .onboarding-question .field-error-message {
  padding-top: 10px;
  padding-bottom: 4px;
}
.initial-question-set .form .onboarding-question .navigation-options {
  margin-top: 6px;
}
.initial-question-set .form .onboarding-question button {
  transition: opacity 0.5s;
}
.initial-question-set .form .onboarding-question.no-button {
  pointer-events: none;
}
.initial-question-set .form .onboarding-question.no-button button,
.initial-question-set .form .onboarding-question.no-button .press-enter {
  opacity: 0;
  pointer-events: none;
}
.initial-question-set .form .onboarding-question.invisible {
  opacity: 0;
  pointer-events: none;
}
.initial-question-set .form .onboarding-question .w280,
.initial-question-set .form .onboarding-question .w580 {
  margin-top: 0px;
}
.initial-question-set .form .onboarding-question .select-box {
  height: 50px;
  line-height: 50px;
}
.initial-question-set .form .onboarding-question .bottom {
  margin: 0;
}
.initial-question-set .form .onboarding-question .img-email {
  position: absolute;
  width: 187px;
  left: 388px;
  top: 8px;
}
@media (max-width: 1210px) {
  .initial-question-set .form .onboarding-question .img-email {
    display: none;
  }
}
.initial-question-set .form .onboarding-question .img-price {
  position: absolute;
  width: 165px;
  left: 514px;
  top: 28px;
}
@media (max-width: 1210px) {
  .initial-question-set .form .onboarding-question .img-price {
    display: none;
  }
}
.initial-question-set .form .onboarding-question .year-input-wrapper label {
  height: 0;
}
.initial-question-set .form .onboarding-question .year-input-wrapper .w580 {
  width: 50px !important;
}
.initial-question-set .form .onboarding-question .year-input-wrapper span {
  transition: opacity 500ms;
  color: #727C8F;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  margin-left: 12px;
}
.initial-question-set .form .onboarding-question .onboarding-currency {
  position: relative;
}
.initial-question-set .form .onboarding-question .onboarding-currency .field:first-of-type:before {
  content: "$";
  color: #2291FF;
  position: absolute;
  bottom: 17px;
  left: 14px;
}
.initial-question-set .form .onboarding-question .onboarding-currency .field:first-of-type input {
  padding-left: 28px;
}
.initial-question-set .form .onboarding-question .content {
  display: block;
}
.initial-question-set .form .onboarding-question .content.four-box {
  margin-bottom: 70px;
}
@media (max-width: 645px) {
  .initial-question-set .form .onboarding-question .content.four-box {
    margin-bottom: 45px;
  }
}
.initial-question-set .form .onboarding-question .content.two-box {
  margin-bottom: 50px;
}
.initial-question-set .form .onboarding-question .content.two-box.button {
  margin-bottom: 0;
}
.initial-question-set .form .button label.box {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
  height: auto;
  padding: 12px 0;
}
.initial-question-set .form .button label.box:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
.initial-question-set .form .button label.box:active,
.initial-question-set .form .button label.box:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
.initial-question-set .form .button label.box .title {
  margin: 0;
  text-align: center;
  width: 100%;
}
.initial-question-set .form .button label.box.enabled {
  box-shadow: 0 0 10px rgba(34, 145, 255, 0.15);
  border: 1px solid #2291FF;
  -webkit-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.initial-question-set .form .button label.box.enabled .title {
  color: #2291FF;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
@media (max-width: 645px) {
  .initial-question-set .form .button label.box.enabled .title {
    font-weight: 600;
    font-family: 'Lato', sans-serif;
    font-size: 12px;
  }
}
.initial-question-set .submit-form form:first-of-type {
  margin-top: 0;
}
.progress-container {
  width: 5px;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: Calc(100% - 1081px);
  height: 100%;
  display: block;
  z-index: 10;
  left: 330px;
}
@media (max-width: 645px) {
  .progress-container {
    display: none;
  }
}
.mobile-unoptimised {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1100;
  background-color: #ffffff;
  display: none;
}
@media (max-width: 645px) {
  .mobile-unoptimised {
    display: block;
  }
}
.mobile-unoptimised .log-out {
  position: fixed;
  top: 23px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 25px 13px 25px;
  color: #2291FF;
  background-color: Transparent;
  border: solid 1px #2291FF;
  right: 20px;
  width: 50px;
  background-image: url(img/button/logout_blue.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 25px 25px;
  color: transparent;
  height: 50px;
  overflow: hidden;
}
.mobile-unoptimised .log-out:hover {
  color: #46A3FF;
  border: solid 1px #46A3FF;
}
.mobile-unoptimised .log-out:active,
.mobile-unoptimised .log-out:focus {
  color: #1C83EA;
  border: solid 1px #1C83EA;
}
.mobile-unoptimised .center {
  display: block;
  position: relative;
  margin: 0 auto;
  max-width: 400px;
  width: 100%;
  padding: 0 25px;
  object-fit: contain;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.mobile-unoptimised .center h2 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  margin-bottom: 15px;
}
.mobile-unoptimised .center p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  line-height: 1.3;
  color: #575E6C;
  margin-bottom: 25px;
}
.mobile-unoptimised .center button,
.mobile-unoptimised .center a {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px 25px 12px 25px;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 0 0 #E9EBF1;
  color: #223241;
  width: 100%;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  margin-bottom: 10px;
  text-align: center;
}
.mobile-unoptimised .center button:hover,
.mobile-unoptimised .center a:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border: 1px solid #C8CCD7;
  color: #223241;
}
.mobile-unoptimised .center button:active,
.mobile-unoptimised .center a:active,
.mobile-unoptimised .center button:focus,
.mobile-unoptimised .center a:focus {
  border: 1px solid #2291FF;
  color: #223241;
}
.mobile-unoptimised .center button.lets-chat span,
.mobile-unoptimised .center a.lets-chat span {
  background-image: url(img/button/chat-blue.png);
  background-repeat: no-repeat;
  background-position: 0% 50%;
  background-size: 12px 12px;
  padding-left: 20px;
}
.mobile-unoptimised .center button.call span,
.mobile-unoptimised .center a.call span {
  background-image: url(img/elements/phone_blue.png);
  background-repeat: no-repeat;
  background-position: 0% 50%;
  background-size: 12px 12px;
  padding-left: 20px;
}
.mobile-unoptimised .center .search-loan-button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  border: none !important;
  cursor: pointer !important;
  outline: inherit !important;
  font-weight: 600 !important;
  font-family: 'Lato', sans-serif !important;
  font-size: 16px !important;
  border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
  padding: 13px 50px 13px 50px !important;
  color: #fff !important;
  background-color: #2291FF !important;
  transition: background 500ms !important;
}
.mobile-unoptimised .center .search-loan-button:hover {
  background-color: #46A3FF !important;
}
.mobile-unoptimised .center .search-loan-button:active,
.mobile-unoptimised .center .search-loan-button:focus {
  background-color: #1C83EA !important;
}
.mobile-unoptimised .center .search-loan-button:disabled,
.mobile-unoptimised .center .search-loan-button.disabled {
  background: #909090 !important;
}
.mobile-unoptimised img {
  position: absolute;
  top: 25px;
  left: 25px;
  width: 179px;
}
@media (max-width: 645px) {
  .onboarding #intercom-container {
    display: none;
  }
}
.proposal .col-m1 {
  border: 0 !important;
  margin: 0 !important;
  border-radius: 0 !important;
}
.proposal .proposal-content .message-sent {
  display: inline-block;
  margin-top: 13px;
}
.proposal .proposal-content .animation-container {
  padding: 20px 100px 20px 100px;
  margin-left: auto;
  margin-right: auto;
}
.proposal .proposal-content h2 {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #223241;
  margin-bottom: 15px;
}
.proposal .proposal-content p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #575E6C;
  line-height: 1.2;
}
.proposal .proposal-content .player-wrapper {
  position: relative;
  margin: 20px 0 30px 0;
  padding-top: 56.25%;
  /* Player ratio: 100 / (1280 / 720) */
}
.proposal .proposal-content .player-wrapper .video {
  position: absolute;
  top: 0;
  left: 0;
}
.proposal h2 {
  margin-bottom: 10px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #223241;
}
.proposal p {
  color: #575E6C;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  line-height: 1.2;
}
.proposal p a {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #575E6C;
  font-weight: bold;
  text-decoration: underline;
}
.proposal .broker-section {
  margin-top: 40px;
}
@media (max-width: 465px) {
  .proposal .broker-section {
    margin-top: 20px;
  }
}
.proposal .broker-section .broker,
.proposal .broker-section .broker-info {
  display: inline-block;
  vertical-align: top;
}
.proposal .broker-section .broker {
  width: 220px;
  margin-right: 40px;
  color: #575E6C;
}
.proposal .broker-section .broker img {
  width: 220px;
  object-fit: contain;
  margin-bottom: 15px;
  display: block;
}
@media (max-width: 680px) {
  .proposal .broker-section .broker {
    width: 100%;
    display: block;
  }
  .proposal .broker-section .broker .broker-detail {
    width: 180px;
    display: inline-block;
    vertical-align: top;
  }
  .proposal .broker-section .broker img {
    width: 220px;
    display: inline-block;
    margin-right: 23px;
  }
}
@media (max-width: 680px) and (max-width: 465px) {
  .proposal .broker-section .broker .broker-detail {
    width: 100%;
  }
}
.proposal .broker-section .broker .name {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  margin-bottom: 5px;
}
.proposal .broker-section .broker .position {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}
.proposal .broker-section .broker ul {
  margin: 20px 0;
}
.proposal .broker-section .broker ul li {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  margin-bottom: 10px;
  padding-left: 20px;
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: auto 12px;
}
.proposal .broker-section .broker ul li.email {
  background-image: url(img/elements/letter-blue.png);
  background-size: 11px auto;
}
.proposal .broker-section .broker ul li.phone {
  background-image: url(img/elements/phone_blue.png);
}
.proposal .broker-section .broker ul li.hotline {
  background-image: url(img/elements/headphones-blue.png);
}
.proposal .broker-section .broker button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 50px 13px 50px;
  color: #fff;
  background-color: #2291FF;
  transition: background 500ms;
  width: 100%;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  padding: 5px 0;
  max-width: 225px;
}
.proposal .broker-section .broker button:hover {
  background-color: #46A3FF;
}
.proposal .broker-section .broker button:active,
.proposal .broker-section .broker button:focus {
  background-color: #1C83EA;
}
.proposal .broker-section .broker button:disabled,
.proposal .broker-section .broker button.disabled {
  background: #909090;
}
@media (max-width: 360px) {
  .proposal .broker-section .broker button {
    max-width: 1000px;
  }
}
.proposal .broker-section .broker button span {
  background-image: url(img/button/calendar-white.png);
  background-size: 16px 16px;
  background-position: 0 50%;
  padding: 5px 0 5px 25px;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
}
.proposal .broker-section .broker-info {
  width: Calc(100% - 260px);
}
@media (max-width: 680px) {
  .proposal .broker-section .broker-info {
    width: 100%;
    margin-top: 20px;
  }
}
.proposal .broker-section .broker-info .form {
  margin-top: 25px;
  max-width: 100%;
}
.proposal .broker-section .broker-info .form .text {
  width: 100%;
  margin: 0 0 25px 0;
}
.proposal .broker-section .broker-info .form .text label {
  margin: 0;
}
.proposal .broker-section .broker-info .form .text textarea {
  min-height: 150px;
}
.proposal .broker-section .broker-info .form button {
  float: right;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  background-color: #26BD27;
  padding: 10px 10px 10px 10px;
  color: #fff;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  padding: 14px 45px;
}
.proposal .broker-section .broker-info .form button:hover {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  background-color: #29CA2A;
}
.proposal .broker-section .broker-info .form button:active,
.proposal .broker-section .broker-info .form button:focus {
  background-color: #23B024;
}
@media (max-width: 480px) {
  .proposal .broker-section .broker-info .form button {
    float: none;
    width: 100%;
    margin: auto;
  }
}
.switch input[type="checkbox"] {
  display: block;
  font-size: 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 40px;
  height: 20px;
  background: #ddd;
  border-radius: 3em;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.switch input[type="checkbox"]:checked {
  background: #26BD27;
}
.switch input[type="checkbox"]:after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  -webkit-box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  left: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.switch input[type="checkbox"]:checked:after {
  left: calc(100% - 20px);
}
.sms-code .number {
  margin: 0 0 0 0;
  max-width: 250px;
}
@media (max-width: 600px) {
  .sms-code .number {
    max-width: none;
  }
}
.sms-code .number input {
  height: 50px !important;
  font-size: 17px !important;
}
.sms-code .wrong-number {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #727C8F;
}
.sms-code .wrong-number span {
  color: #2291FF;
  cursor: pointer;
}
.sms-code .wrong-number span:hover {
  color: #46A3FF;
}
.sms-code .submit-container {
  margin-top: 20px;
  max-width: 250px;
}
@media (max-width: 600px) {
  .sms-code .submit-container {
    margin-top: 15px;
    max-width: none;
  }
}
.sms-code .submit-container .submit {
  width: 100%;
}
.sms-code .submit-container .resend-verification {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  height: 15px;
  color: #2291FF;
  text-align: center;
  margin: 10px auto 10px auto;
  cursor: pointer;
  transition: all 1s ease-in-out;
}
.sms-code .submit-container .resend-verification:hover {
  color: #46A3FF;
}
.sms-code .enter-mobile .mobile-container {
  position: relative;
}
.sms-code .enter-mobile .mobile-container .number-overlay {
  position: absolute;
  width: 80px;
  height: 50px;
  background: #F6F7FB;
  border: 1px solid #D8DCE7;
  border-radius: 4px 0 0 4px;
  z-index: 10;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 17px;
  top: 10px;
  line-height: 50px;
  background-image: url(img/elements/australia.png);
  background-size: 21px 14px;
  background-position: 10px 50%;
  padding-left: 38px;
  background-repeat: no-repeat;
  color: #727C8F;
  pointer-events: none;
}
.sms-code .enter-mobile .mobile-container input {
  padding-left: 90px !important;
  font-weight: 600 !important;
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
}
body.modal-open {
  overflow: hidden;
  overflow-y: hidden;
  height: 100vh;
  height: Calc(var(--vh, 1vh) * 100);
}
