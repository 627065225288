h1 {
  font-size: 59px;
  font-family: "Open Sans", sans-serif;
  font-weight: 200;
  padding-bottom: 5px;
}

h2 {
  font-size: 42px;
  padding-bottom: 5px;
  font-family: "Open Sans", sans-serif;
}

h3 {
  font-size: 29px;
  padding-bottom: 5px;

  font-family: "Open Sans", sans-serif;
}

p {
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  line-height: 32px;
  padding-bottom: 10px;
  letter-spacing: 0.5px;
}

ul.arrow-list {
  list-style: none;
  padding-left: 0;
  padding-bottom: 10px;
}

ul.arrow-list li {
  position: relative;
  padding: 5px 0 5px 20px;
  line-height: 32px;
}

ul.arrow-list li::before {
  content: "→";
  position: absolute;
  left: 0;
  color: #333;
}
